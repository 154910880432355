import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AmountInput from 'uikit/AmountInput';
import i18nContext from 'components/i18n-context';
import './LimitInputControll.scss';

export function LimitActionsInputControl({
  onChange,
  currency,
  hold,
  notification,
  reject,
  editable,
  type,
  limitType
}) {
  const i18n = useContext(i18nContext);
  const handleChange =
    (type) =>
    ({ target: { value } }) => {
      onChange(type, value);
    };

  let outgoingType = type && type === 'OUTGOING';
  let count = limitType && limitType === 'COUNT';

  return (
    <div className={'limitValue-wrapper'}>
      {count && (
        <div className={'limitValue-wrapper-count'}>
          <AmountInput
            name={'count'}
            label={i18n.getMessage('limits.details.table.row.count')}
            className={'table-cell-input'}
            isDisabled={!editable}
            value={hold}
            precision={0}
            onChange={handleChange('hold')}
          />
        </div>
      )}
      {!count && (
        <div className={'limitValue-wrapper-hold'}>
          <div className={'limitValue-wrapper-hold-input'}>
            <AmountInput
              name={'hold'}
              label={i18n.getMessage('limits.details.table.row.hold')}
              className={'table-cell-input'}
              isDisabled={!editable}
              precision={2}
              value={hold}
              onChange={handleChange('hold')}
            />
            <div className={'limitValue-wrapper-hold-currency'}>{currency || 'EUR'}</div>
          </div>
        </div>
      )}
      {outgoingType && (
        <div className={'limitValue-wrapper-reject'}>
          <div className={'limitValue-wrapper-reject-input'}>
            <AmountInput
              name={'reject'}
              label={i18n.getMessage('limits.details.table.row.reject')}
              className={'table-cell-input'}
              isDisabled={!editable}
              precision={2}
              value={reject}
              onChange={handleChange('reject')}
            />
            <div className={'limitValue-wrapper-reject-currency'}>{currency || 'EUR'}</div>
          </div>
        </div>
      )}
      {/* TODO: Add notification*/}
    </div>
  );
}

LimitActionsInputControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  currency: PropTypes.string,
  hold: PropTypes.string.isRequired,
  notification: PropTypes.string.isRequired,
  reject: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  type: PropTypes.string,
  limitType: PropTypes.string
};
