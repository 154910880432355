import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import { LogsTabHeadRow } from './components/LogsTabHeadRow';
import { LogsTabTableRow } from './components/LogsTabTableRow';
import { MENU_DIRECTION } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { formatDateTime } from 'services/utils';
import './LogsTab.scss';

const LogsTab = ({
  isLoading,
  logs,
  firstAccountCreatedAt,
  handleLoadLogs,
  pagination,
  setPage,
  setSize,
  setFiltersFromUrl
}) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const location = useLocation();

  const updateFiltersInUrl = () => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.set('page', pagination.page.toString());
    currentParams.set('size', pagination.size.toString());

    navigate({ search: `?${currentParams.toString()}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateFiltersInUrl();
    handleLoadLogs();
  }, [pagination.size, pagination.page]);

  const handleSetCurrentPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleSetPageSize = (size) => {
    setSize(size);
  };

  if (!logs) {
    return <Loader className={'application-loader logs-tab-loader'} />;
  }

  return (
    <div className={'logs-container'}>
      <table className={'table-wrapper'}>
        <tbody className={'table-body'}>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('customerLogs.firstAccountCreationDate')}</td>
            <td className={'table-cell'}>{firstAccountCreatedAt ? formatDateTime(firstAccountCreatedAt) : ''}</td>
          </tr>
        </tbody>
      </table>

      <div className={'logs-container-table'}>
        <Pagination
          isLoading={isLoading}
          isShowPaginationSize={true}
          paginationSize={pagination.size}
          handleChangePaginationSize={handleSetPageSize}
          currentPage={pagination.page}
          numberOfPages={pagination.totalPages}
          setCurrentPage={handleSetCurrentPage}
          menuDirection={MENU_DIRECTION.DOWN}
        />

        <Table responsive>
          <thead>
            <LogsTabHeadRow />
          </thead>
          <tbody>
            {logs.map((log, index) => (
              <LogsTabTableRow isLoading={false} logsTabData={log} key={index} />
            ))}
          </tbody>
        </Table>

        <Pagination
          isLoading={isLoading}
          isShowPaginationSize={true}
          paginationSize={pagination.size}
          handleChangePaginationSize={handleSetPageSize}
          currentPage={pagination.page}
          numberOfPages={pagination.totalPages}
          setCurrentPage={handleSetCurrentPage}
          menuDirection={MENU_DIRECTION.DOWN}
        />
      </div>
    </div>
  );
};

LogsTab.propTypes = {
  isLoading: PropTypes.bool,
  logs: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  firstAccountCreatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  handleLoadLogs: PropTypes.func,
  pagination: PropTypes.shape({
    size: PropTypes.number,
    page: PropTypes.number,
    totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])])
  }),
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  setFiltersFromUrl: PropTypes.func
};

export default LogsTab;
