import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import UserDetailsTable from 'components/UserDetailsTable';
import { Container } from 'uikit/Container/Container';
import './UserDetails.scss';

const UserDetails = ({ usersStore, customerNumber }) => {
  const i18n = useContext(i18nContext);
  const { id: userId } = useParams();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (userId) {
      usersStore.getUserInfoById(userId).then(() => setIsInitialized(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Container className={'user-details-container'} header={i18n.getMessage('container.userDetails')}>
      <UserDetailsTable
        userInfo={usersStore.userInfo}
        isInitialized={isInitialized}
        customerNumber={customerNumber}
        isUserDeletionAvailable={true}
        isCustomerDetails={false}
      />
    </Container>
  );
};

UserDetails.propTypes = {
  customerNumber: PropTypes.string,
  usersStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  usersStore: stores.usersStore
}))(observer(UserDetails));
