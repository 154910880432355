import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'react-bootstrap';
import i18nContext from 'components/i18n-context';
import './TariffsTransactionalTableRow.scss';

const TariffTransactionalTableRow = ({ tariffTransactional, isLoading }) => {
  const i18n = useContext(i18nContext);

  const getTariffsInfoBlock = (cardBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'card-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{cardBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'tariffs-transactional-table-cell'}>
        {getTariffsInfoBlock(i18n.getMessage(`paymentProvider.${tariffTransactional.transfer_provider}`))}
      </td>
      <td className={'tariffs-transactional-table-cell'}>
        {getTariffsInfoBlock(i18n.getMessage(`transactionType.${tariffTransactional.transaction_type}`))}
      </td>
      <td className={'tariffs-transactional-table-cell'}>
        {getTariffsInfoBlock(i18n.getMessage(`paymentMethod.${tariffTransactional.payment_method}`))}
      </td>
      <td className={'tariffs-transactional-table-cell'}>
        {getTariffsInfoBlock(i18n.getMessage(`transferType.${tariffTransactional.transfer_type}`))}
      </td>
      <td className={'tariffs-transactional-table-cell'}>
        {tariffTransactional.value.fee_type === 'PERCENT' && (
          <>
            {getTariffsInfoBlock(`${tariffTransactional.value.value || '0.00'} %`)}
            {getTariffsInfoBlock(
              `Min: ${tariffTransactional.value.min || '0.00'} ${tariffTransactional.value.currency}`
            )}
            {getTariffsInfoBlock(
              `Max: ${tariffTransactional.value.max || '0.00'} ${tariffTransactional.value.currency}`
            )}
          </>
        )}
        {tariffTransactional.value.fee_type === 'FIXED' &&
          getTariffsInfoBlock(`${tariffTransactional.value.value || '0.00'} ${tariffTransactional.value.currency}`)}
      </td>
    </tr>
  );
};

TariffTransactionalTableRow.prototype = {
  tariffTransactional: PropTypes.object,
  isLoading: PropTypes.bool
};

export default TariffTransactionalTableRow;
