import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import { IS_TRANSFER_TYPE_AVAILABLE } from 'config/config';
import './TransactionsTableHead.scss';

export const TRANSACTIONS_TABLE_HEADER = [
  {
    label: 'transactionNumber'
  },
  {
    label: 'dateInit',
    sortBy: 'created_at'
  },
  {
    label: 'dateLastUpdate',
    sortBy: 'updated_at'
  },
  {
    label: IS_TRANSFER_TYPE_AVAILABLE ? 'typeOfProvider' : null,
    sortBy: 'transfer_type'
  },
  {
    label: 'typeOfTransfer'
  },
  {
    label: 'transactionType',
    sortBy: 'type'
  },
  {
    label: 'paymentMethod'
  },
  {
    label: 'transactionStatus',
    sortBy: 'status'
  },
  {
    label: 'amount',
    sortBy: 'amount'
  },
  {
    label: 'currency',
    sortBy: 'currency_code'
  },
  {
    label: 'commission',
    sortBy: 'total_commissions'
  },
  {
    label: 'recipientName',
    sortBy: 'recipient_name'
  },
  {
    label: 'recipientBank',
    sortBy: 'recipient_bank'
  },
  {
    label: 'recipientBankCountry'
  },
  {
    label: 'senderName',
    sortBy: 'sender_name'
  },
  {
    label: 'senderBank',
    sortBy: 'sender_bank'
  },
  {
    label: 'senderBankCountry'
  },
  {
    label: 'comment',
    sortBy: 'comment'
  },
  {
    label: 'documents'
  },
  {
    label: 'externalReferences'
  }
];

const TransactionsTableHead = ({ handleOnSortTable, sortBy, direction }) => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {TRANSACTIONS_TABLE_HEADER.map(
        (head) =>
          head.label && (
            <th key={head.label} className={'transactions-table-head'}>
              <div className={'transactions-table-head-block'}>
                <span>{i18n.getMessage(`transactions.table.header.${head.label}`)}</span>
                {head.sortBy && (
                  <ArrowSelect
                    onClick={() => handleOnSortTable(head.sortBy)}
                    className={`transactions-sorting-arrow-icon ${sortBy === head.sortBy && direction}`}
                  />
                )}
              </div>
            </th>
          )
      )}
    </tr>
  );
};

TransactionsTableHead.propTypes = {
  handleOnSortTable: PropTypes.func,
  sortBy: PropTypes.string,
  direction: PropTypes.string
};

export default TransactionsTableHead;
