import React from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { ReactComponent as DeleteIconButton } from 'assets/iconButtons/delete.svg';
import { formatDateTime } from 'services/utils';
import Button from 'uikit/Button/Button';
import './AmlNotesTabTableRow.scss';

const AmlNotesTabTableRow = ({ isLoading, amlNoteData, handleRemoveAmlNote, handleDownloadDocument }) => {
  const getDateAndTimeRow = (noteDate) => {
    const date = formatDateTime(noteDate);

    return <i className={'customer-note-table-cell-note-info-created-date'}>{date}</i>;
  };

  const handleDeleteNote = () => {
    handleRemoveAmlNote(amlNoteData.id);
  };

  const getCustomerNoteInfoBlock = (customerNoteBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'customer-note-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{customerNoteBlockInfo}</p>;
    }
  };

  return (
    <tr key={amlNoteData.id}>
      <td className={'customer-note-table-cell'}>
        <div className={'customer-note-table-cell-note-info'}>
          {getCustomerNoteInfoBlock(amlNoteData.author.name)}
          {getCustomerNoteInfoBlock(getDateAndTimeRow(amlNoteData.createdAt))}
        </div>
      </td>
      <td className={'customer-note-table-cell'}>
        {amlNoteData?.assignee?.name ? getCustomerNoteInfoBlock(amlNoteData?.assignee?.name) : null}
      </td>
      <td className={'customer-note-table-cell'}>
        <div className={'customer-notes-table-cell-note'}>
          <div>{parse(amlNoteData.note)}</div>
          {amlNoteData.deleteAvailable && (
            <div className={'customer-notes-table-cell-note-delete-icon'}>
              <DeleteIconButton onClick={handleDeleteNote} />
            </div>
          )}
        </div>

        <div>
          {amlNoteData.documents &&
            amlNoteData.documents.map((document, index) => {
              return (
                <div key={index}>
                  <Button
                    className={'link-button document-link'}
                    onClick={() => handleDownloadDocument(document.id)}
                    isDisabled={false}
                  >
                    {document.name}
                  </Button>
                </div>
              );
            })}
        </div>
      </td>
    </tr>
  );
};

AmlNotesTabTableRow.propTypes = {
  isLoading: PropTypes.bool,
  amlNoteData: PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    note: PropTypes.string,
    operator_name: PropTypes.string,
    deleteAvailable: PropTypes.bool
  }),
  handleRemoveAmlNote: PropTypes.func,
  handleDownloadDocument: PropTypes.func
};

export default AmlNotesTabTableRow;
