import countries from 'components/countries.json';
import { PERSON_TITLE } from 'components/constants';
import i18n from 'i18n';

export const countriesOptions = Object.values(countries).map((value) => ({
  key: value,
  value: i18n.getMessage(value)
}));

export const passCheckOptions = ['PASS', 'FAIL'].map((value) => ({
  key: value,
  value: i18n.getMessage(`companyStructure.options.${value.toLowerCase()}`)
}));

export const yesOrNoOPTIONS = ['YES', 'NO'].map((value) => ({
  key: value === 'YES',
  value: i18n.getMessage(`companyStructure.options.${value.toLowerCase()}`)
}));

export const personOptions = Object.keys(PERSON_TITLE).map((key) => ({
  key: key,
  value: i18n.getMessage(PERSON_TITLE[key] + '.')
}));
