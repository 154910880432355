import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import { emailRegExp } from 'services/utils';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import '../RepresentativesTab.scss';

export const AddRepresentativeScheme = ({ addNewClick, closePopUp, error }) => {
  const i18n = useContext(i18nContext);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      email: ''
    },
    initialStatus: { email: true },
    validationSchema: Yup.object({
      email: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('representatives.error.emptyEmail'))
        .max(50, i18n.getMessage('error.emailSize'))
        .trim()
        .email(i18n.getMessage('representatives.error.invalidEmail'))
        .matches(emailRegExp, { message: i18n.getMessage('representatives.error.invalidEmail') })
    }),
    onSubmit: (values) => {
      addNewClick(values.email);
    }
  });

  const { values, errors, handleSubmit, handleChange, validateField, submitCount } = form;

  return (
    <form className={'scheme-wrapper add-representatives'} onSubmit={handleSubmit}>
      <div className={'representatives-add-header-wrapper'}>
        <h2 className={'representatives-popUp-header'}>
          {i18n.getMessage('representatives.label.addNewRepresentative')}
        </h2>
        <CloseIcon className={'representatives-icon'} onClick={closePopUp} />
      </div>
      <Input
        className={'representatives-email'}
        name={'email'}
        label={i18n.getMessage('representatives.header.email')}
        value={values.email}
        onChange={handleChange}
        onBlur={() => validateField('email')}
        error={errors.email}
        placeholder={i18n.getMessage('representatives.placeholder.email')}
        initialStatus={submitCount}
      />
      <Button roleType={'submit'} size={'large'} fullWidth={true}>
        {i18n.getMessage('representatives.label.next')}
      </Button>
    </form>
  );
};

AddRepresentativeScheme.propTypes = {
  addNewClick: PropTypes.func,
  closePopUp: PropTypes.func,
  error: PropTypes.any
};
