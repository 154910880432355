import { useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { getHeaderButtons } from 'components/MenuButtons/MenuButtons';
import { PopUpLogOutConfirmationScheme } from 'components/PopUpScheme/PopUpLogOutConfirmationScheme';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { IconButton } from 'uikit/IconButton/IconButton';
import { PopUp } from 'uikit/PopUp/PopUp';

const UserPanel = ({ onClick = () => {}, isMobile = false }) => {
  const [showLogOutPopUp, setShowLogOutPopUp] = useState(false);
  const isHeaderButtonNames = useMediaQuery(MEDIA_QUERY.HEADER_BUTTON_NAMES);

  const onHeaderClick = (id, buttonClick) => {
    if (id === 4) {
      return setShowLogOutPopUp(true);
    }
    onClick ? onClick(buttonClick) : buttonClick();
  };

  return (
    <>
      <div className={'account-dropdown-container'}></div>
      <div className={`user-panel ${!isHeaderButtonNames && !isMobile ? 'collapsed' : ''}`}>
        {getHeaderButtons().map((button) => (
          <IconButton
            key={button.id}
            text={isHeaderButtonNames || isMobile ? button.text : ''}
            onClick={() => onHeaderClick(button.id, button.click)}
            path={button.path}
            navigationType={button.id !== 4}
            Icon={button.icon}
            isDisabled={button.isDisabled}
            // unreadTopics={button.path === ROUTE_PATHS.MESSAGES ? messagesStore.unreadTopics : null}
          />
        ))}
        <PopUp alignOnCenter show={showLogOutPopUp}>
          <PopUpLogOutConfirmationScheme onClose={() => setShowLogOutPopUp(false)} />
        </PopUp>
      </div>
    </>
  );
};

UserPanel.propTypes = {
  onClick: PropTypes.any,
  isMobile: PropTypes.bool,
  authStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  authStore: stores.authStore
}))(observer(UserPanel));
