import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LogOutIcon } from 'assets/log-out-icon.svg';
import i18nContext from 'components/i18n-context';
import { runLogout } from 'services/requestAgent';
import Button from 'uikit/Button/Button';

export const PopUpLogOutConfirmationScheme = ({ onClose }) => {
  const i18n = useContext(i18nContext);

  return (
    <div>
      <div className={'scheme-wrapper pop-up-logOut'}>
        <LogOutIcon className={'icon-error'} />
        <h2 className={'scheme-success-header'}>{i18n.getMessage('navigationBar.logOut')}</h2>
        <p className={'scheme-success-message'}>{i18n.getMessage('popUp.confirmLogOut.description')}</p>
        <div className={'scheme-wrapper-buttons'}>
          <Button className={'scheme-button'} size={'large'} type={'outline'} onClick={runLogout}>
            {i18n.getMessage('session.logout.btn.ok')}
          </Button>
          <Button className={'scheme-error-button scheme-button'} size={'large'} onClick={() => onClose()}>
            {i18n.getMessage('session.logout.btn.cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
};

PopUpLogOutConfirmationScheme.propTypes = {
  onClose: PropTypes.func
};
