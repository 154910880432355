import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import './CardAuthorisationsTableHead.scss';

export const CARD_AUTHORIZATIONS_TABLE_HEADER = [
  {
    label: 'authorizationId'
  },
  {
    label: 'cardId'
  },
  {
    label: 'amount',
    sortBy: 'amount'
  },
  {
    label: 'remaining',
    sortBy: 'remainingOnHold'
  },
  {
    label: 'currency',
    sortBy: 'currency'
  },
  {
    label: 'status',
    sortBy: 'status'
  },
  {
    label: 'updatedAt',
    sortBy: 'updatedAt'
  },
  {
    label: 'externalReference'
  },
  {
    label: 'actions'
  }
];

const CardAuthorisationsTableHead = ({ handleOnSortTable, sortBy, direction }) => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {CARD_AUTHORIZATIONS_TABLE_HEADER.map(
        (head) =>
          head.label && (
            <th key={head.label} className={'card-authorizations-table-head'}>
              <div className={'card-authorizations-table-head-block'}>
                <span>{i18n.getMessage(`cardAuthorizations.table.header.${head.label}`)}</span>
                {head.sortBy && (
                  <ArrowSelect
                    onClick={() => handleOnSortTable(head.sortBy)}
                    className={`card-authorizations-sorting-arrow-icon ${sortBy === head.sortBy && direction}`}
                  />
                )}
              </div>
            </th>
          )
      )}
    </tr>
  );
};

CardAuthorisationsTableHead.propTypes = {
  handleOnSortTable: PropTypes.func,
  sortBy: PropTypes.string,
  direction: PropTypes.string
};

export default CardAuthorisationsTableHead;
