import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getCustomerInfoRequest } from 'services/requestAgent';
import { formatDateTime, getCustomerLink } from 'services/utils';
import Button from 'uikit/Button/Button';
import './EmailStatisticsTableRow.scss';

const EmailStatisticsTableRow = ({ isLoading, emailStatisticsData }) => {
  const navigate = useNavigate();

  const handleRedirectToCustomer = async (customerNumber) => {
    const customer = await getCustomerInfoRequest(customerNumber);

    navigate(getCustomerLink(customerNumber, customer.status, customer.type));
  };

  const getEmailStatisticsInfoBlock = (emailStatisticsBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'email-statistics-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (Array.isArray(emailStatisticsBlockInfo)) {
      return (
        <p>
          {emailStatisticsBlockInfo.map((item, index) => {
            if (item) {
              return (
                <Fragment key={index}>
                  {item}
                  {index < emailStatisticsBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </p>
      );
    } else {
      return <p>{emailStatisticsBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'email-statistics-table-cell'}>
        <Button
          className={'link-button email-statistics-nav-link-text'}
          onClick={async () => await handleRedirectToCustomer(emailStatisticsData.customerNumber)}
          isDisabled={false}
        >
          {emailStatisticsData.customerNumber}
        </Button>
      </td>
      <td className={'email-statistics-table-cell'}>{getEmailStatisticsInfoBlock(emailStatisticsData.email)}</td>
      <td className={'email-statistics-table-cell'}>{getEmailStatisticsInfoBlock(emailStatisticsData.templateName)}</td>
      <td className={'email-statistics-table-cell'}>{getEmailStatisticsInfoBlock(emailStatisticsData.status)}</td>
      <td className={'email-statistics-table-cell'}>
        {getEmailStatisticsInfoBlock(formatDateTime(emailStatisticsData.createdAt))}
      </td>
    </tr>
  );
};

EmailStatisticsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  emailStatisticsData: PropTypes.object
};

export default EmailStatisticsTableRow;
