import { Outlet, useLocation } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import BackButton from 'components/BackButton';
import { ROUTE_PATHS } from 'routes/constants';

const Messages = () => {
  const location = useLocation();

  return (
    <section className={'right-section'}>
      {location.pathname !== ROUTE_PATHS.MESSAGES && <BackButton />}
      <Outlet />
    </section>
  );
};

Messages.propTypes = {
  messagesStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  messagesStore: stores.messagesStore
}))(observer(Messages));
