import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { TariffsTransactionalTableHeadRow } from './components/TariffsTransactionalTableHeadRow';
import TariffsTransactionalTableRow from './components/TariffsTransactionalTableRow';
import { TariffsTableHeadRow } from './components/TariffsTableHeadRow';
import TariffTableRow from './components/TarrifsTableRow';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { PopUp } from 'uikit/PopUp/PopUp';
import './TariffsTab.scss';

const TariffsTab = ({
  isLoading,
  tariffs,
  customerTariffsGroup,
  selectedTariffsGroup,
  getTariffGroupsList,
  getTariffGroup,
  changeCustomerTariffGroup,
  applyTariff
}) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [tariffsGroupsOptions, setTariffsGroupsOptions] = useState([]);
  const [applyTariffType, setApplyTariffType] = useState(null);
  const [isOpenApplyTariff, setIsOpenApplyTariff] = useState(false);

  useEffect(() => {
    getTariffGroupsList();
    getTariffGroup(customerTariffsGroup);
  }, []);

  const handleChangeTariffsGroup = async (name, value) => {
    await getTariffGroup(value);
  };

  const handleApplyTariff = (tariffType) => {
    setApplyTariffType(tariffType);
    setIsOpenApplyTariff(true);
  };

  const handleCloseConfirmTariff = () => {
    setApplyTariffType(null);
    setIsOpenApplyTariff(false);
  };

  const handleConfirmApplyTariff = async () => {
    await applyTariff(applyTariffType);
    handleCloseConfirmTariff();
  };

  useEffect(() => {
    if (tariffs) {
      setTariffsGroupsOptions(
        tariffs.map((group) => ({
          key: group.name,
          value: group.name
        }))
      );
    } else {
      setTariffsGroupsOptions([]);
    }
  }, [tariffs]);

  const handleEditTariffs = () => {
    navigate(ROUTE_PATHS.TARIFFS);
  };

  const handleSaveTariffs = () => {
    changeCustomerTariffGroup(selectedTariffsGroup.name);
  };

  if (!tariffs || !customerTariffsGroup || !selectedTariffsGroup) {
    return <Loader className={'application-loader tariffs-tab-loader'} />;
  }

  return (
    <div className={'tariffs-container'}>
      <table className={'table-wrapper'}>
        <tbody className={'table-body'}>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('tariffsTab.tariffGroup')}</td>
            <td className={'table-cell'}>
              <div className={'tariffs-container-actions'}>
                <InputDropDown
                  className={'table-cell-input'}
                  options={tariffsGroupsOptions}
                  value={selectedTariffsGroup?.name}
                  onChange={handleChangeTariffsGroup}
                />
                <Button
                  type={'outline'}
                  size={'small'}
                  className={'tariffs-container-actions-button'}
                  onClick={handleEditTariffs}
                  isDisabled={true}
                >
                  {i18n.getMessage('tariffsTab.buttons.edit')}
                </Button>
                <Button
                  type={'primary'}
                  size={'small'}
                  className={'tariffs-container-actions-button'}
                  onClick={handleSaveTariffs}
                >
                  {i18n.getMessage('tariffsTab.buttons.save')}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Table responsive>
        <thead>
          <TariffsTransactionalTableHeadRow />
        </thead>
        <tbody>
          {selectedTariffsGroup?.tariffs
            .filter((t) => t.transactional && t.value.enabled)
            .map((tariff, index) => (
              <TariffsTransactionalTableRow key={index} tariffTransactional={tariff} />
            ))}
        </tbody>
      </Table>
      <Table responsive>
        <thead>
          <TariffsTableHeadRow />
        </thead>
        <tbody>
          {selectedTariffsGroup?.tariffs
            .filter((t) => !t.transactional && t.value.enabled)
            .map((tariff, index) => (
              <TariffTableRow key={index} tariff={tariff} handleApplyTariff={() => handleApplyTariff(tariff.type)} />
            ))}
        </tbody>
      </Table>
      <PopUp show={isOpenApplyTariff} onClose={handleCloseConfirmTariff}>
        <PopConfirmationScheme
          title={i18n.getMessage('tariffsTab.confirmation.title')}
          onConfirmButtonTitle={i18n.getMessage('tariffsTab.confirmation.buttons.yes')}
          onCloseButtonTitle={i18n.getMessage('tariffsTab.confirmation.buttons.no')}
          onClose={handleCloseConfirmTariff}
          onConfirm={handleConfirmApplyTariff}
        />
      </PopUp>
    </div>
  );
};

TariffsTab.propTypes = {
  isLoading: PropTypes.bool,
  tariffs: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  customerTariffsGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  selectedTariffsGroup: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  getTariffGroupsList: PropTypes.func.isRequired,
  getTariffGroup: PropTypes.func.isRequired,
  changeCustomerTariffGroup: PropTypes.func.isRequired,
  applyTariff: PropTypes.func.isRequired
};

export default TariffsTab;
