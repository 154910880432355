import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import RichTextEditor from 'react-rte/lib/RichTextEditor';
import { AmlNotesTabHeadRow } from './AmlNotesTabHeadRow';
import AmlNotesTabTableRow from './AmlNotesTabTableRow';
import {
  LATIN_LETTERS_AND_DIGITS_AND_SYMBOLS_REG_EXP,
  MAX_MESSAGE_LENGTH,
  MAX_UPLOADED_FILES_SIZE
} from 'components/constants';
import Loader from 'components/Loader';
import i18nContext from 'components/i18n-context';
import OperatorsAsyncSelect from 'components/OperatorsAsyncSelect';
import { convertBytesToMegabytes, convertHtmlToString, convertMessageToHtmlAndTrimSpaces } from 'services/utils';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import './AmlNotesTab.scss';

const AmlNotesTab = ({
  isLoading,
  amlNotes,
  handleLoadAmlNotes,
  handleAddAmlNote,
  handleDeleteAmlNote,
  handleDownloadDocument
}) => {
  const [noteValue, setNoteValue] = useState(RichTextEditor.createEmptyValue());
  const [assigneeOperator, setAssigneeOperator] = useState(null);
  const [documents, setDocuments] = useState([]);
  const i18n = useContext(i18nContext);

  useEffect(() => {
    handleLoadAmlNotes();
  }, []);

  const addAmlNote = () => {
    const convertedNote = convertMessageToHtmlAndTrimSpaces(noteValue);
    handleAddAmlNote(convertedNote, assigneeOperator?.id, documents).then(() => {
      setNoteValue(RichTextEditor.createEmptyValue());
      setAssigneeOperator(null);
      setDocuments([]);
    });
  };

  const handleAttachDocument = ({ target: { files } }) => {
    if (Object.keys(files).length) {
      for (let i = 0; i < files.length; i++) {
        // fix undefined id
        files[i]['id'] = documents.length + i;
      }

      setDocuments([...documents, ...files]);
    }
  };

  const handleRemoveDocument = (documentId) => {
    setDocuments(documents.filter((document) => document.id !== documentId));
  };

  if (!amlNotes) {
    return <Loader className={'application-loader aml-notes-tab-loader'} />;
  }

  return (
    <div className={'customer-aml-notes-container'}>
      <Table responsive>
        <thead>
          <AmlNotesTabHeadRow />
        </thead>
        <tbody>
          {amlNotes.map((amlNote) => (
            <AmlNotesTabTableRow
              key={amlNote.id}
              isLoading={false}
              amlNoteData={amlNote}
              handleRemoveAmlNote={handleDeleteAmlNote}
              handleDownloadDocument={handleDownloadDocument}
            />
          ))}
        </tbody>
      </Table>

      <OperatorsAsyncSelect
        className={'customer-aml-notes-select'}
        handleValueChanged={setAssigneeOperator}
        value={assigneeOperator}
      />

      <Input
        className={'customer-aml-notes-container-input'}
        type={'text-editor'}
        name={'note'}
        isDisabled={false}
        value={noteValue}
        onChange={setNoteValue}
        max={MAX_MESSAGE_LENGTH}
        rows={5}
        /* eslint-disable-next-line max-len */
        subText={i18n.getMessage('amlNotes.input.note.symbolsLeft', {
          symbolsLeft: MAX_MESSAGE_LENGTH - convertHtmlToString(noteValue).length
        })}
      />

      <AttachDoc
        label={i18n.getMessage('customerNotes.attachments')}
        className={'aml-notes-attachment'}
        type={'file'}
        size={MAX_UPLOADED_FILES_SIZE}
        files={documents}
        onChange={handleAttachDocument}
        onRemoveFiles={handleRemoveDocument}
        isDisabled={isLoading}
        hintText={i18n.getMessage('attachDoc.hint.text', {
          maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
        })}
      />

      <div className={'customer-aml-notes-container-add-note'}>
        <Button
          className={'customer-aml-notes-container-add-note-button'}
          type={'primary'}
          size={'small'}
          onClick={addAmlNote}
          isDisabled={!noteValue || isLoading}
        >
          {isLoading ? <Loader /> : i18n.getMessage('amlNotes.button.addNote')}
        </Button>
      </div>
    </div>
  );
};

AmlNotesTab.propTypes = {
  isLoading: PropTypes.bool,
  amlNotes: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  handleLoadAmlNotes: PropTypes.func,
  handleAddAmlNote: PropTypes.func,
  handleDeleteAmlNote: PropTypes.func,
  handleDownloadDocument: PropTypes.func
};

export default AmlNotesTab;
