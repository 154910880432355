import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import i18nContext from 'components/i18n-context';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import PopUpAddNewLimitScheme from './PopUpScheme/PopUpAddNewLimitScheme';
import Table from 'react-bootstrap/Table';
import { LimitDetailsHeadRow } from './LimitDetailsHeadRow';
import { LimitDetailsTableRow } from './LimitDetailsTableRow';
import './LimitsDetails.scss';

// TODO: Remove this mock data and use real data
const CUSTOMER_ID = 'Z1NCSVCIQGK83QQ0';

// TODO: Remove this mock data and use real data
const LIMIT_GROUP = 'ZERO_LEVEL_LIMIT';
const LIMIT_GROUPS_OBJECT = [
  { key: 'ZERO_LEVEL_LIMIT', value: 'Zero Level Limit' },
  { key: 'FIRST_LEVEL_LIMIT', value: 'First Level Limit' }
];

// TODO: Remove this mock data and use real data
const TABLE_ROWS_DATA = [
  {
    limit_type: 'AMOUNT',
    limitType: 'AMOUNT',
    _limitType: 'AMOUNT',
    account_number: '1234567890',
    created_at: '2021-09-01',
    updated_at: '2021-09-02',
    group: 'DEFAULT',
    currency: 'USD',
    notification: '100',
    reject: '200',
    type: 'OUTGOING',
    behavior: 'NOTIFICATION',
    hold: '300',
    enabled: true
  },
  {
    limit_type: 'KEYWORDS',
    limitType: 'KEYWORDS',
    _limitType: 'KEYWORDS',
    account_number: '1234567891',
    created_at: '2021-09-02',
    updated_at: '2021-09-03',
    group: 'DEFAULT',
    currency: 'USD',
    notification: '100',
    reject: '200',
    type: 'AMOUNT',
    behavior: 'HOLD',
    hold: '0',
    enabled: true
  }
];

// TODO: Remove this mock data and use real data
const PARENT_GROUP = 'DEFAULT';

const LimitsDetails = () => {
  const i18n = useContext(i18nContext);
  const [limitGroup, setGroup] = useState(LIMIT_GROUP);
  const [isOpenPopUp, setOpenPopUp] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [individualLimits, setIndividualLimits] = useState({});
  const handleIsOpenPopUp = () => {
    setOpenPopUp((prev) => !prev);
  };

  const handleSaveChanges = () => {};

  const handleChangeLimitGroup = (name, value) => {
    setGroup(value);
  };

  return (
    <div className={'limitsDetails-container limits-table'}>
      <table className={'table-wrapper'}>
        <tbody className={'table-body'}>
          <tr className={'table-row table-row-highlighted'}>
            <td className={'table-cell'}>{i18n.getMessage('limits.details.customerId')}</td>
            <td className={'table-cell'}>{CUSTOMER_ID}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('limits.details.limitGroup')}</td>
            <td className={'table-cell'}>
              <div className={'table-input-wrapper'}>
                <InputDropDown
                  className={'table-cell-input'}
                  options={LIMIT_GROUPS_OBJECT}
                  value={limitGroup}
                  onChange={handleChangeLimitGroup}
                />
              </div>
            </td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('limits.details.parentGroup')}</td>
            <td className={'table-cell'}>{PARENT_GROUP}</td>
          </tr>
        </tbody>
      </table>
      <Button type={'primary'} size={'small'} className={'limits-actions-button'} onClick={handleIsOpenPopUp}>
        {i18n.getMessage('limits.details.addNewIndividualLimit')}
      </Button>

      <Table responsive>
        <thead>
          <LimitDetailsHeadRow />
        </thead>
        <tbody>
          {TABLE_ROWS_DATA.map((data) => (
            <LimitDetailsTableRow key={data.limitType} limitDetailsData={data} isLoading={false} />
          ))}
        </tbody>
      </Table>

      <PopUp alignOnCenter show={isOpenPopUp}>
        <PopUpAddNewLimitScheme
          onClose={handleIsOpenPopUp}
          limits={individualLimits}
          isAccountLimit={true}
          group={groupName}
        />
      </PopUp>
      <div className={'limits-details-button'}>
        <Button type={'primary'} size={'small'} onClick={handleSaveChanges} className={'limits-actions-button'}>
          {i18n.getMessage('limits.details.button.saveChanges')}
        </Button>
      </div>
    </div>
  );
};

LimitsDetails.propTypes = {};

export default inject((stores) => ({}))(observer(LimitsDetails));
