import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import AddNewPaymentProviderScheme from './AddNewPaymentProviderScheme';
import { ProvidersTableHeadRow } from './components/ProvidersTableHeadRow';
import { ProvidersTableContentRow } from './components/ProvidersTableContentRow';
import RuleEngineProviders from './components/RuleEngineProviders';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { useApplicationContext } from 'contexts/ApplicationContext';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './ProvidersTab.scss';

const ProvidersTab = ({
  isLoading,
  providers,
  linkedRuleEngineProvider,
  getCustomerProviders,
  addNewProvider,
  getLinkedRuleEngineProvider,
  linkRuleEngineProvider,
  unlinkRuleEngineProvider
}) => {
  const i18n = useContext(i18nContext);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const { constants } = useApplicationContext();

  useEffect(() => {
    getCustomerProviders();
    getLinkedRuleEngineProvider();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closePopUp = () => {
    setIsOpenPopUp(false);
  };

  const handleAddPaymentProvider = async (paymentProvider) => {
    await addNewProvider(paymentProvider);
    await getCustomerProviders();
    closePopUp();
  };

  if (!providers) {
    return <Loader className={'application-loader providers-tab-loader'} />;
  }

  return (
    <div className={'provider-details-container providers-table'}>
      <span className={'provider-header'}>{i18n.getMessage('providersTab.paymentProviders.header')}</span>
      <Table responsive>
        <thead>
          <ProvidersTableHeadRow />
        </thead>
        <tbody>
          {Object.entries(providers).map(([providerName, providerDetails]) => (
            <ProvidersTableContentRow
              key={providerName}
              isLoading={false}
              providerName={providerName}
              providerDetails={providerDetails}
            />
          ))}
        </tbody>
      </Table>

      <div className={'add-provider-button'}>
        <Button
          type={'primary'}
          size={'small'}
          onClick={() => setIsOpenPopUp(true)}
          className={'provider-actions-button'}
          isDisabled={isLoading}
        >
          {isLoading ? <Loader /> : i18n.getMessage('providersTab.table.action.addPaymentProvider')}
        </Button>
      </div>

      <RuleEngineProviders
        linkedRuleEngineProvider={linkedRuleEngineProvider}
        ruleEngineProviders={constants?.ruleEngineProviders}
        ruleEngineCallInProgress={isLoading}
        handleAddRuleEngineProvider={linkRuleEngineProvider}
        handleRemoveRuleEngineProvider={unlinkRuleEngineProvider}
      />

      <PopUp alignOnCenter show={isOpenPopUp}>
        <AddNewPaymentProviderScheme
          isLoading={isLoading}
          addNewProvider={handleAddPaymentProvider}
          paymentProviders={constants?.transferProviders}
          onClose={closePopUp}
        />
      </PopUp>
    </div>
  );
};

ProvidersTab.propTypes = {
  isLoading: PropTypes.bool,
  providers: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  linkedRuleEngineProvider: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  getCustomerProviders: PropTypes.func.isRequired,
  addNewProvider: PropTypes.func.isRequired,
  getLinkedRuleEngineProvider: PropTypes.func,
  linkRuleEngineProvider: PropTypes.func,
  unlinkRuleEngineProvider: PropTypes.func
};

export default ProvidersTab;
