import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import CommissionTransactionsFiltersBar from './components/CommissionTransactionsFiltersBar';
import CommissionTransactionsTableHead from './components/CommissionTransactionsTableHead';
import CommissionTransactionsTableRow from './components/CommissionTransactionsTableRow';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import './CommissionTransactions.scss';

const CommissionTransactions = ({ commissionTransactionsStore }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination, sortColumn) => {
    const filtersParams = commissionTransactionsStore.prepareFiltersParams();
    const queryParams = {
      ...filtersParams,
      page: pagination.page,
      size: pagination.size,
      sort_column: sortColumn.sort_column,
      sort_direction: sortColumn.sort_direction
    };
    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    commissionTransactionsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!commissionTransactionsStore.isInitialized) {
      commissionTransactionsStore.getCommissionTransactionsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissionTransactionsStore.isInitialized]);

  useEffect(() => {
    if (commissionTransactionsStore.isInitialized) {
      commissionTransactionsStore.resetCommissionTransactionStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(
      commissionTransactionsStore.filters,
      commissionTransactionsStore.pagination,
      commissionTransactionsStore.sortColumn
    );
    if (commissionTransactionsStore.isInitialized) {
      commissionTransactionsStore.getCommissionTransactionsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    commissionTransactionsStore.filters.from,
    commissionTransactionsStore.filters.to,
    commissionTransactionsStore.filters.transferProviders,
    commissionTransactionsStore.filters.types,
    commissionTransactionsStore.filters.currencies,
    commissionTransactionsStore.pagination.size,
    commissionTransactionsStore.pagination.page,
    commissionTransactionsStore.sortColumn.sort_column,
    commissionTransactionsStore.sortColumn.sort_direction
  ]);

  const handleOnSortTable = (newSortBy) => {
    commissionTransactionsStore.setSortData({
      sortBy: newSortBy,
      direction:
        commissionTransactionsStore.sortColumn.sort_column !== newSortBy
          ? DIRECTION.ASC
          : commissionTransactionsStore.sortColumn.sort_direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  if (!commissionTransactionsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'commission-transactions-wrapper'}>
      <CommissionTransactionsFiltersBar
        isLoading={commissionTransactionsStore.isLoading}
        isInitialized={commissionTransactionsStore.isInitialized}
        isLoadingCommissionTransactions={commissionTransactionsStore.isLoadingCommissionTransactions}
        filters={commissionTransactionsStore.filters}
        handleSetFilters={commissionTransactionsStore.setFilter}
        handleDownloadCommissionTransactionsReport={commissionTransactionsStore.downloadCommissionTransactionsReportCsv}
      />

      <Pagination
        isShowPaginationSize={true}
        paginationSize={commissionTransactionsStore.pagination.size}
        handleChangePaginationSize={commissionTransactionsStore.setPageSize}
        isLoading={commissionTransactionsStore.isLoading}
        currentPage={commissionTransactionsStore.pagination.page}
        numberOfPages={commissionTransactionsStore.pagination.totalPages}
        setCurrentPage={commissionTransactionsStore.setPageNumber}
      />

      <Table responsive>
        <thead>
          <CommissionTransactionsTableHead
            handleOnSortTable={handleOnSortTable}
            sortBy={commissionTransactionsStore.sortColumn.sort_column}
            direction={commissionTransactionsStore.sortColumn.sort_direction}
          />
        </thead>
        <tbody>
          {commissionTransactionsStore.commissionTransactionList?.map((commissionTransaction, index) => (
            <CommissionTransactionsTableRow
              key={index}
              type={'list'}
              isLoading={commissionTransactionsStore.isLoading}
              commissionTransactionData={commissionTransaction}
            />
          ))}
        </tbody>
      </Table>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={commissionTransactionsStore.pagination.size}
        handleChangePaginationSize={commissionTransactionsStore.setPageSize}
        isLoading={commissionTransactionsStore.isLoading}
        currentPage={commissionTransactionsStore.pagination.page}
        numberOfPages={commissionTransactionsStore.pagination.totalPages}
        setCurrentPage={commissionTransactionsStore.setPageNumber}
        menuDirection={MENU_DIRECTION.UP}
      />
    </div>
  );
};

CommissionTransactions.propTypes = {
  commissionTransactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  commissionTransactionsStore: stores.commissionTransactionsStore
}))(observer(CommissionTransactions));
