import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { formatDate } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import './UsersTableRow.scss';

export const UsersTableRow = ({ isLoading, userData }) => {
  const i18n = useContext(i18nContext);

  const getUserInfoBlock = (userBlockInfo, isUserId = false) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'users-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isUserId) {
      return (
        <NavLink className={'user-id-text'} to={`${ROUTE_PATHS.USERS}/${userData.id}`}>
          {userBlockInfo}
        </NavLink>
      );
    } else {
      return <p>{userBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'users-table-cell'}>{getUserInfoBlock(userData.id, true)}</td>
      <td className={'users-table-cell'}>{getUserInfoBlock(i18n.getMessage(`users.status.${userData.status}`))}</td>
      <td className={'users-table-cell'}>{getUserInfoBlock(userData.phone)}</td>
      <td className={'users-table-cell'}>{getUserInfoBlock(formatDate(userData.last_login_date))}</td>
      <td className={'users-table-cell'}>{getUserInfoBlock(formatDate(userData.registration_date))}</td>
      <td className={'users-table-cell'}>{getUserInfoBlock(userData.registration_ip)}</td>
    </tr>
  );
};

UsersTableRow.propTypes = {
  isLoading: PropTypes.bool,
  userData: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    phone: PropTypes.string,
    last_login_date: PropTypes.string,
    registration_date: PropTypes.string,
    registration_ip: PropTypes.string
  })
};
