import { Fragment, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { formatDate, formatMoney } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import './CommissionTransactionsTableRow.scss';

const CommissionTransactionsTableRow = ({ isLoading, commissionTransactionData }) => {
  const i18n = useContext(i18nContext);

  const getCommissionTransactionInfoBlock = (transactionBlockInfo, isTransactionId = false) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'commission-transactions-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isTransactionId) {
      return (
        <NavLink
          className={'commission-transaction-id-text'}
          to={`${ROUTE_PATHS.TRANSACTIONS}/${transactionBlockInfo}`}
        >
          {transactionBlockInfo}
        </NavLink>
      );
    } else if (Array.isArray(transactionBlockInfo)) {
      return (
        <p>
          {transactionBlockInfo.map((item, index) => {
            if (item) {
              return (
                <Fragment key={index}>
                  {item}
                  {index < transactionBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </p>
      );
    } else {
      return <p>{transactionBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'commission-transactions-table-cell'}>
        {getCommissionTransactionInfoBlock(commissionTransactionData?.transaction_number, true)}
      </td>
      <td className={'commission-transactions-table-cell'}>
        {getCommissionTransactionInfoBlock(formatDate(commissionTransactionData?.created_at))}
      </td>
      <td className={'commission-transactions-table-cell'}>
        {getCommissionTransactionInfoBlock(
          i18n.getMessage(`paymentProvider.${commissionTransactionData?.transfer_provider}`)
        )}
      </td>
      <td className={'commission-transactions-table-cell'}>
        {getCommissionTransactionInfoBlock(i18n.getMessage(`transactionType.${commissionTransactionData?.type}`))}
      </td>
      <td className={'commission-transactions-table-cell'}>
        {getCommissionTransactionInfoBlock(formatMoney(commissionTransactionData?.amount, 2))}
      </td>
      <td className={'commission-transactions-table-cell'}>
        {getCommissionTransactionInfoBlock(commissionTransactionData?.currency)}
      </td>
      <td className={'commission-transactions-table-cell'}>
        {getCommissionTransactionInfoBlock(commissionTransactionData?.sender_name)}
      </td>
      <td className={'commission-transactions-table-cell'}>
        {getCommissionTransactionInfoBlock(commissionTransactionData?.recipient_name)}
      </td>
      <td className={'commission-transactions-table-cell'}>
        {getCommissionTransactionInfoBlock(commissionTransactionData?.comment)}
      </td>
    </tr>
  );
};

CommissionTransactionsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  commissionTransactionData: PropTypes.object
};

export default CommissionTransactionsTableRow;
