import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { DIRECTION, TRANSIT_TRANSACTION_STATUS } from 'components/constants';
import i18n from 'i18n';
import {
  createTransitTransactionRequest,
  deleteTransitTransactionRequest,
  getCommissionSystemTransactionsListRequest,
  getTransitTransactionsListRequest,
  loadCommissionTransactionsCsvReportRequest,
  loadPeriodTransitTransactionCsvReportRequest,
  loadTransitTransactionsCsvReportRequest,
  repeatTransitTransactionRequest,
  returnTransitTransactionRequest
} from 'services/requestAgent';
import { convertDate, convertToFullDate } from 'services/utils';

class TransitTransactionsStore {
  isInitialized = false;
  isLoading = false;
  isNewTransitTransactionCreated = false;
  isDownloadCommissionSystemTransactionsReport = false;
  error = null;
  transitTransactionList = [];
  commissionSystemTransactionsList = [];
  totalElements = null;
  sortColumn = {
    sort_column: 'created_at',
    sort_direction: DIRECTION.DESC
  };
  pagination = {
    size: 20,
    page: 0,
    totalPages: null
  };
  filters = {
    search_text: null,
    from: null,
    to: null,
    transfer_providers: [],
    statuses: [],
    currencies: [],
    direction: []
  };
  periodDate = null;

  constructor() {
    makeAutoObservable(this);
  }

  resetTransitTransactionStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.isNewTransitTransactionCreated = false;
    this.isDownloadCommissionSystemTransactionsReport = false;
    this.error = null;
    this.transitTransactionList = [];
    this.commissionSystemTransactionsList = [];
    this.totalElements = null;
    this.sortColumn = {
      sort_column: 'created_at',
      sort_direction: DIRECTION.DESC
    };
    this.pagination = {
      size: 20,
      page: 0,
      totalPages: null
    };
    this.filters = {
      search_text: null,
      from: null,
      to: null,
      transfer_providers: [],
      statuses: [],
      currencies: [],
      direction: []
    };
    this.periodDate = null;
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsNewTransitTransactionCreated = (status) => {
    this.isNewTransitTransactionCreated = status;
  };

  setIsDownloadCommissionSystemTransactionsReport = (status) => {
    this.isDownloadCommissionSystemTransactionsReport = status;
  };

  setPeriodDate = (fieldName, value) => {
    this.periodDate = value;
  };

  setPageNumber = (page) => {
    this.pagination.page = page;
  };

  setPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setSortData = (sortData) => {
    this.sortColumn.sort_column = sortData.sortBy;
    this.sortColumn.sort_direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    const filters = { ...this.filters };
    const pagination = { ...this.pagination };

    // Mapping URL filter parameters to corresponding properties in the 'filters' object
    const filterParamsMapping = {
      search_text: 'search_text',
      from: 'from',
      to: 'to',
      statuses: 'statuses',
      currencies: 'currencies',
      transfer_providers: 'transfer_providers',
      direction: 'direction'
    };

    // Iterating over each URL parameter and assigning its values to 'filters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        let value = params[param];

        // Convert `from` and `to` if they exist
        if (param === 'from' || param === 'to') {
          value = convertToFullDate(value);
        }

        // Handle only array values as arrays, keep others as their original types
        if (Array.isArray(value)) {
          filters[filterParamsMapping[param]] = value;
        } else {
          filters[filterParamsMapping[param]] = value;
        }
      }
    }

    // Assigning pagination parameters from the URL
    pagination.page = parseInt(params.page) || pagination.page;
    pagination.size = parseInt(params.size) || pagination.size;

    // Assigning sorting parameters from the URL
    this.sortColumn.sort_column = params.sort_column || this.sortColumn.sort_column;
    this.sortColumn.sort_direction = params.sort_direction || this.sortColumn.sort_direction;

    // Updating the state of filters and pagination
    this.filters = filters;
    this.pagination = pagination;
  };

  prepareFiltersParams = () => {
    const params = { ...this.filters };

    if (this.filters.from) {
      params.from = convertDate(this.filters.from);
    } else {
      delete params.from;
    }

    if (this.filters.to) {
      params.to = convertDate(this.filters.to);
    } else {
      delete params.to;
    }

    return params;
  };

  getTransitTransactionList = async () => {
    this.setIsLoading(true);
    try {
      const filtersParams = this.prepareFiltersParams();

      const res = await getTransitTransactionsListRequest(
        this.pagination,
        this.sortColumn.sort_column,
        this.sortColumn.sort_direction,
        filtersParams.search_text,
        filtersParams
      );

      runInAction(() => {
        this.isInitialized = true;
        this.transitTransactionList = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getCommissionSystemTransactionsList = async () => {
    this.setIsLoading(true);
    try {
      const filtersParams = this.prepareFiltersParams();

      const res = await getCommissionSystemTransactionsListRequest(
        this.pagination,
        this.sortColumn.sort_column,
        this.sortColumn.sort_direction,
        filtersParams
      );

      runInAction(() => {
        this.isInitialized = true;
        this.commissionSystemTransactionsList = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  loadWithdrawalTransactionsCsvReport = async () => {
    this.setIsDownloadCommissionSystemTransactionsReport(true);

    const filtersParams = this.prepareFiltersParams();

    const toastPromise = toast.promise(
      loadCommissionTransactionsCsvReportRequest(
        this.pagination,
        this.sortColumn.sort_column,
        this.sortColumn.sort_direction,
        filtersParams
      ),
      {
        pending: i18n.getMessage('transitTransactionsStore.loadCommissionTransactionsCsvReport.toastPromise.pending'),
        success: i18n.getMessage('transitTransactionsStore.loadCommissionTransactionsCsvReport.toastPromise.success')
      }
    );

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsDownloadCommissionSystemTransactionsReport(false);
    }
  };

  loadTransitTransactionsCsvReport = async () => {
    this.setIsLoading(true);

    const filtersParams = this.prepareFiltersParams();

    const toastPromise = toast.promise(
      loadTransitTransactionsCsvReportRequest(
        this.pagination,
        this.sortColumn.sort_column,
        this.sortColumn.sort_direction,
        filtersParams.search_text,
        filtersParams
      ),
      {
        pending: i18n.getMessage('transitTransactionsStore.loadTransitTransactionsCsvReport.toastPromise.pending'),
        success: i18n.getMessage('transitTransactionsStore.loadTransitTransactionsCsvReport.toastPromise.success')
      }
    );

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  loadPeriodTransitTransactionCsvReport = async () => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(loadPeriodTransitTransactionCsvReportRequest(convertDate(this.periodDate)), {
      pending: i18n.getMessage('transitTransactionsStore.loadPeriodTransitCsvReport.toastPromise.pending'),
      success: i18n.getMessage('transitTransactionsStore.loadPeriodTransitCsvReport.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  repeatTransitTransaction = async (transitTransactionId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(repeatTransitTransactionRequest(transitTransactionId), {
      pending: i18n.getMessage('transitTransactionsStore.repeatTransitTransaction.toastPromise.pending')
    });

    try {
      const transitTransactionResData = await toastPromise;

      if (transitTransactionResData.status === TRANSIT_TRANSACTION_STATUS.FAILED) {
        toast.error(i18n.getMessage('transitTransactionsStore.repeatTransitTransaction.toastPromise.error'));
      } else {
        toast.success(i18n.getMessage('transitTransactionsStore.repeatTransitTransaction.toastPromise.success'));

        const updatedTransitTransactionsList = this.transitTransactionList.map((transitTransaction) =>
          transitTransaction.transaction_id === transitTransactionId ? transitTransactionResData : transitTransaction
        );

        runInAction(() => {
          this.transitTransactionList = updatedTransitTransactionsList;
        });
      }
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  returnTransitTransaction = async (transitTransactionId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(returnTransitTransactionRequest(transitTransactionId), {
      pending: i18n.getMessage('transitTransactionsStore.returnTransitTransaction.toastPromise.pending'),
      success: i18n.getMessage('transitTransactionsStore.returnTransitTransaction.toastPromise.success')
    });

    try {
      const transitTransactionResData = await toastPromise;

      const updatedTransitTransactionsList = this.transitTransactionList.map((transitTransaction) =>
        transitTransaction.transaction_id === transitTransactionId ? transitTransactionResData : transitTransaction
      );

      runInAction(() => {
        this.transitTransactionList = updatedTransitTransactionsList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  deleteTransitTransaction = async (transitTransactionId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deleteTransitTransactionRequest(transitTransactionId), {
      pending: i18n.getMessage('transitTransactionsStore.deleteTransitTransaction.toastPromise.pending'),
      success: i18n.getMessage('transitTransactionsStore.deleteTransitTransaction.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.transitTransactionList = this.transitTransactionList.filter(
          (transitTransaction) => transitTransaction.transaction_id !== transitTransactionId
        );
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  createTransitTransaction = async (data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createTransitTransactionRequest(data), {
      pending: i18n.getMessage('transitTransactionsStore.createTransitTransaction.toastPromise.pending'),
      success: i18n.getMessage('transitTransactionsStore.createTransitTransaction.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.isNewTransitTransactionCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };
}

export default new TransitTransactionsStore();
