import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { convertDate, getAccountTitle } from 'services/utils';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import Button from 'uikit/Button/Button';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './CustomerStatementTab.scss';

const CustomerStatementTab = ({ customerAccounts, loadCustomerStatementPdfReport, loadCustomerStatementCsvReport }) => {
  const i18n = useContext(i18nContext);
  const currentDate = new Date();
  const [accountValue, setAccountValue] = useState('');
  const [fromDate, setFromDate] = useState({
    name: 'from',
    value: null
  });
  const [toDate, setToDate] = useState({
    name: 'to',
    value: null
  });

  const handleFromDateChange = (name, value) => {
    setFromDate({ name, value });
  };

  const handleToDateChange = (name, value) => {
    setToDate({ name, value });
  };

  const handleAccountValueChange = (name, value) => {
    setAccountValue(value);
  };

  const handleDownloadPdf = () => {
    loadCustomerStatementPdfReport(convertDate(fromDate.value), convertDate(toDate.value), accountValue?.wallet_number);
  };

  const handleDownloadCsv = () => {
    loadCustomerStatementCsvReport(convertDate(fromDate.value), convertDate(toDate.value), accountValue?.wallet_number);
  };

  const accountsOptions = customerAccounts?.map((account) => {
    const title = getAccountTitle(account);

    return {
      key: account,
      value: title,
      currency: account?.currency
    };
  });

  return (
    <div className={'statement-container'}>
      <div>
        <span>{i18n.getMessage('customerStatementTab.input.from')}</span>
        <CustomDatePicker
          isRequired={true}
          onChange={handleFromDateChange}
          name={'from'}
          value={fromDate.value}
          maxDate={toDate.value || currentDate}
        />
      </div>
      <div>
        <span>{i18n.getMessage('customerStatementTab.input.to')}</span>
        <CustomDatePicker
          isRequired={true}
          onChange={handleToDateChange}
          name={'to'}
          value={toDate.value}
          maxDate={currentDate}
          minDate={fromDate.value}
        />
      </div>
      <InputDropDown
        className={'account-number-selector'}
        label={i18n.getMessage('customerStatementTab.input.accountNumber')}
        value={accountValue}
        currency={accountValue.currency}
        onChange={handleAccountValueChange}
        options={accountsOptions}
        isMulti={false}
      />
      <Button
        className={'statement-container-button'}
        onClick={handleDownloadPdf}
        isDisabled={!fromDate.value || !toDate.value}
      >
        {i18n.getMessage('customerStatementTab.button.pdf')}
      </Button>
      <Button
        className={'statement-container-button'}
        onClick={handleDownloadCsv}
        isDisabled={!fromDate.value || !toDate.value}
      >
        {i18n.getMessage('customerStatementTab.button.csv')}
      </Button>
    </div>
  );
};

CustomerStatementTab.propTypes = {
  customerAccounts: PropTypes.array.isRequired,
  loadCustomerStatementPdfReport: PropTypes.func.isRequired,
  loadCustomerStatementCsvReport: PropTypes.func.isRequired
};

export default CustomerStatementTab;
