import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './ProvidersTableHeadRow.scss';

export const PROVIDER_DETAILS_TABLE_HEADER = [
  {
    label: 'providerName'
  },
  {
    label: 'status'
  },
  {
    label: 'details'
  },
  {
    label: 'externalReference'
  },
  {
    label: 'action'
  }
];

export const ProvidersTableHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {PROVIDER_DETAILS_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'provider-table-head'}>
          <div className={'provider-table-head-block'}>
            <span>{i18n.getMessage(`providersTab.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};
