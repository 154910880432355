import { useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { formatMoney } from 'services/utils';
import './CommissionAccountsTableRow.scss';

const CommissionAccountsTableRow = ({ isLoading, commissionAccountData }) => {
  const i18n = useContext(i18nContext);

  const getCommissionAccountInfoBlock = (accountBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'commission-accounts-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{accountBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'commission-accounts-table-cell'}>
        {getCommissionAccountInfoBlock(commissionAccountData.account_number)}
      </td>
      <td className={'commission-accounts-table-cell'}>
        {getCommissionAccountInfoBlock(i18n.getMessage(`paymentProvider.${commissionAccountData.transfer_provider}`))}
      </td>
      <td className={'commission-accounts-table-cell'}>
        {getCommissionAccountInfoBlock(formatMoney(commissionAccountData.amount))}
      </td>
      <td className={'commission-accounts-table-cell'}>
        {getCommissionAccountInfoBlock(commissionAccountData.currency)}
      </td>
    </tr>
  );
};

CommissionAccountsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  commissionAccountData: PropTypes.shape({
    account_number: PropTypes.string,
    amount: PropTypes.number,
    currency: PropTypes.string,
    transfer_provider: PropTypes.string,
    type: PropTypes.string
  })
};

export default CommissionAccountsTableRow;
