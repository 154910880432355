import { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import { COMMON_CURRENCY_PRECISION, CRYPTO_CURRENCY_PRECISION, PAYMENT_PROVIDERS } from 'components/constants';
import i18nContext from 'components/i18n-context';
import { hasRole, WALLET_UPDATE } from 'services/roles';
import { formatMoney, isCryptoProvider } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import './AccountsTableContentRow.scss';

export const AccountsTableContentRow = ({
  isLoading,
  isMultiAccount,
  accountDetails,
  handleChangeAccountStatus,
  handleOpenAccountDetailsPopUp
}) => {
  const i18n = useContext(i18nContext);

  const getAccountInfoBlock = (accountBlockInfo, isAccountNumber) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'account-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isAccountNumber) {
      return (
        <NavLink className={'account-number-text'} to={`${ROUTE_PATHS.TRANSACTIONS}?search_text=${accountBlockInfo}`}>
          {accountBlockInfo}
        </NavLink>
      );
    } else {
      return <p>{accountBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'account-table-cell'}>
        {getAccountInfoBlock(accountDetails.wallet_number, true)}
        {accountDetails.is_primary_wallet &&
          getAccountInfoBlock(
            <small>
              <i>{i18n.getMessage('accountsTab.table.accountNumber.primary')}</i>
            </small>
          )}
      </td>
      {!isMultiAccount && (
        <td className={'account-table-cell'}>
          {getAccountInfoBlock(
            accountDetails.transfer_provider === PAYMENT_PROVIDERS.GRAPHEREX
              ? accountDetails.crypto_address
              : accountDetails.iban || accountDetails.multi_iban
          )}
        </td>
      )}
      <td className={'account-table-cell'}>
        {getAccountInfoBlock(i18n.getMessage(`paymentProvider.${accountDetails.transfer_provider}`))}
        {accountDetails.external_reference && getAccountInfoBlock(`(${accountDetails.external_reference})`)}
      </td>
      <td className={'account-table-cell'}>
        {getAccountInfoBlock(
          `${formatMoney(
            accountDetails.available,
            isCryptoProvider(accountDetails.transfer_provider) ? CRYPTO_CURRENCY_PRECISION : COMMON_CURRENCY_PRECISION
          )} ${accountDetails.currency}`
        )}
      </td>
      <td className={'account-table-cell'}>
        {getAccountInfoBlock(
          `${formatMoney(
            accountDetails.on_hold,
            isCryptoProvider(accountDetails.transfer_provider) ? CRYPTO_CURRENCY_PRECISION : COMMON_CURRENCY_PRECISION
          )} ${accountDetails.currency}`
        )}
      </td>
      <td className={'account-table-cell'}>
        <CheckBox
          className={'account-table-checkbox'}
          isActive={accountDetails.status === 'ACTIVE'}
          showCheckBox={true}
          disabled={!hasRole(WALLET_UPDATE)}
          onChange={() => handleChangeAccountStatus(accountDetails)}
          value={i18n.getMessage('accountsTab.table.checkbox.enabled')}
        />
      </td>
      <td className={'account-table-cell'}>
        <div className={'account-table-cell-actions disabled'}>
          <Button
            onClick={() => handleOpenAccountDetailsPopUp(accountDetails)}
            type={'primary'}
            size={'small'}
            isDisabled={isLoading}
          >
            {i18n.getMessage('accountsTab.table.action.details')}
          </Button>
        </div>
      </td>
    </tr>
  );
};

AccountsTableContentRow.propTypes = {
  isLoading: PropTypes.bool,
  isMultiAccount: PropTypes.bool,
  accountDetails: PropTypes.object,
  handleChangeAccountStatus: PropTypes.func,
  handleOpenAccountDetailsPopUp: PropTypes.func
};
