import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import EmailStatisticsFiltersBar from './components/EmailStatisticsFiltersBar';
import EmailStatisticsTableHead from './components/EmailStatisticsTableHead';
import EmailStatisticsTableRow from './components/EmailStatisticsTableRow';
import i18nContext from 'components/i18n-context';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { Container } from 'uikit/Container/Container';
import './EmailStatisticsTable.scss';

const EmailStatisticsTable = ({ emailStatisticsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination, sortColumn) => {
    const filtersParams = emailStatisticsStore.prepareFiltersParams();
    const queryParams = {
      ...filtersParams,
      page: pagination.page,
      size: pagination.size,
      sort_column: sortColumn.sort_column,
      sort_direction: sortColumn.sort_direction
    };
    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    emailStatisticsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!emailStatisticsStore.isInitialized) {
      emailStatisticsStore.getEmailStatisticsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailStatisticsStore.isInitialized]);

  useEffect(() => {
    if (emailStatisticsStore.isInitialized) {
      emailStatisticsStore.resetEmailStatisticsStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(emailStatisticsStore.filters, emailStatisticsStore.pagination, emailStatisticsStore.sortColumn);
    if (emailStatisticsStore.isInitialized) {
      emailStatisticsStore.getEmailStatisticsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    emailStatisticsStore.filters.searchText,
    emailStatisticsStore.filters.fromDate,
    emailStatisticsStore.filters.toDate,
    emailStatisticsStore.pagination.size,
    emailStatisticsStore.pagination.page,
    emailStatisticsStore.sortColumn.sort_column,
    emailStatisticsStore.sortColumn.sort_direction
  ]);

  const handleOnSortTable = (newSortBy) => {
    emailStatisticsStore.setSortData({
      sortBy: newSortBy,
      direction:
        emailStatisticsStore.sortColumn.sort_column !== newSortBy
          ? DIRECTION.ASC
          : emailStatisticsStore.sortColumn.sort_direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  if (!emailStatisticsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'email-statistics-container'} header={i18n.getMessage('container.emailStatistics')}>
      <EmailStatisticsFiltersBar
        filters={emailStatisticsStore.filters}
        totalElements={emailStatisticsStore.totalElements}
        handleSetFilters={emailStatisticsStore.setFilter}
      />

      <Pagination
        isShowPaginationSize={true}
        paginationSize={emailStatisticsStore.pagination.size}
        handleChangePaginationSize={emailStatisticsStore.setPageSize}
        isLoading={emailStatisticsStore.isLoading}
        currentPage={emailStatisticsStore.pagination.page}
        numberOfPages={emailStatisticsStore.pagination.totalPages}
        setCurrentPage={emailStatisticsStore.setPageNumber}
      />

      <Table responsive>
        <thead>
          <EmailStatisticsTableHead
            handleOnSortTable={handleOnSortTable}
            sortBy={emailStatisticsStore.sortColumn.sort_column}
            direction={emailStatisticsStore.sortColumn.sort_direction}
          />
        </thead>
        <tbody>
          {emailStatisticsStore.emailStatisticsList?.map((emailStatisticsData, index) => (
            <EmailStatisticsTableRow
              key={index}
              type={'list'}
              isLoading={emailStatisticsStore.isLoading}
              emailStatisticsData={emailStatisticsData}
            />
          ))}
        </tbody>
      </Table>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={emailStatisticsStore.pagination.size}
        handleChangePaginationSize={emailStatisticsStore.setPageSize}
        isLoading={emailStatisticsStore.isLoading}
        currentPage={emailStatisticsStore.pagination.page}
        numberOfPages={emailStatisticsStore.pagination.totalPages}
        setCurrentPage={emailStatisticsStore.setPageNumber}
        menuDirection={MENU_DIRECTION.UP}
      />
    </Container>
  );
};

EmailStatisticsTable.propTypes = {
  emailStatisticsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  emailStatisticsStore: stores.emailStatisticsStore
}))(observer(EmailStatisticsTable));
