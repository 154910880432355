import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import TransactionDetailsTable from './components/TransactionDetailsTable';
import { paymentTransactionDocumentRequestDto } from './components/PaymentTransactionDocumentRequestDto';
import i18nContext from 'components/i18n-context';
import { POP_UP_TYPE, TRANSACTION_STATUS } from 'components/constants';
import Loader from 'components/Loader';
import NotesTab from 'components/NotesTab';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import { PopUpAttachDocumentScheme } from 'components/PopUpScheme/PopUpAttachDocumentScheme';
import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import './TransactionDetails.scss';

const popUpInitialData = {
  isShowPopUp: false,
  popUpType: '',
  title: '',
  description: '',
  confirmButtonLabel: '',
  cancelButtonLabel: '',
  onCancel: () => {},
  onConfirm: () => {}
};

const TransactionDetails = ({ transactionsStore }) => {
  const i18n = useContext(i18nContext);
  const { id: transactionNumber } = useParams();
  const [popUpData, setPopUpData] = useState(popUpInitialData);
  const [isOpenAttachDocumentPopUp, setIsOpenAttachDocumentPopUp] = useState(false);
  const [editedDocument, setEditedDocument] = useState(null);

  useEffect(() => {
    transactionsStore.resetTransactionStore();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (transactionNumber) {
      transactionsStore.getTransaction(transactionNumber);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionNumber]);

  const resetPopUpData = () => setPopUpData(popUpInitialData);

  const handleAcceptOnHold = () => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('transactionDetails.confirmModal.accept.title'),
      description: i18n.getMessage('transactionDetails.confirmModal.accept.onHold.message'),
      confirmButtonLabel: i18n.getMessage('transactionDetails.confirmModal.confirm'),
      cancelButtonLabel: i18n.getMessage('transactionDetails.confirmModal.cancel'),
      onConfirm: () => transactionsStore.acceptOnHoldTransaction(transactionNumber).then(() => resetPopUpData()),
      onCancel: resetPopUpData
    });
  };

  const handleAcceptProcessing = () => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('transactionDetails.confirmModal.accept.title'),
      description: i18n.getMessage('transactionDetails.confirmModal.accept.processing.message'),
      confirmButtonLabel: i18n.getMessage('transactionDetails.confirmModal.confirm'),
      cancelButtonLabel: i18n.getMessage('transactionDetails.confirmModal.cancel'),
      onConfirm: () => transactionsStore.acceptProcessingTransaction(transactionNumber).then(() => resetPopUpData()),
      onCancel: resetPopUpData
    });
  };

  const handleReject = () => {
    const description =
      transactionsStore.currentTransactionDetails.status === TRANSACTION_STATUS.ON_HOLD
        ? i18n.getMessage('transactionDetails.confirmModal.reject.onHold.message')
        : i18n.getMessage('transactionDetails.confirmModal.reject.processing.message');

    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('transactionDetails.confirmModal.reject.title'),
      description: description,
      confirmButtonLabel: i18n.getMessage('transactionDetails.confirmModal.confirm'),
      cancelButtonLabel: i18n.getMessage('transactionDetails.confirmModal.cancel'),
      onConfirm: () => transactionsStore.rejectTransaction(transactionNumber).then(() => resetPopUpData()),
      onCancel: resetPopUpData
    });
  };

  const handleReturn = () => {
    const description =
      transactionsStore.currentTransactionDetails.status === TRANSACTION_STATUS.ON_HOLD
        ? i18n.getMessage('transactionDetails.confirmModal.return.onHold.message')
        : i18n.getMessage('transactionDetails.confirmModal.return.paid.message');

    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('transactionDetails.confirmModal.return.title'),
      description: description,
      confirmButtonLabel: i18n.getMessage('transactionDetails.confirmModal.confirm'),
      cancelButtonLabel: i18n.getMessage('transactionDetails.confirmModal.cancel'),
      onConfirm: () => transactionsStore.returnTransaction(transactionNumber).then(() => resetPopUpData()),
      onCancel: resetPopUpData
    });
  };

  const handleChangeDocumentStatus = async (name, status) => {
    await transactionsStore.changeTransactionDocumentStatus(transactionNumber, status);
  };

  const handleSaveMlroApproval = async (status) => {
    await transactionsStore.updateTransactionMlroApproval(transactionNumber, status);
  };

  const handleLoadTransactionNotes = async () => {
    await transactionsStore.getTransactionNotes(transactionNumber);
  };

  const handleAddTransactionNote = async (transactionNumber, note, assigneeId, documents) => {
    await transactionsStore.addTransactionNote(
      transactionsStore?.currentTransactionDetails?.accountNumber,
      transactionNumber,
      note,
      assigneeId,
      documents
    );
  };

  const handleDeleteTransactionNote = async (noteId) => {
    await transactionsStore.deleteTransactionNote(noteId);
  };

  const handleDownloadDocument = (documentId) => transactionsStore.downloadTransactionNoteDocument(documentId);

  const handleOpenAttachDocumentPopUp = (document = null) => {
    if (document) {
      setEditedDocument(document);
    }
    setIsOpenAttachDocumentPopUp(true);
  };
  const handleCloseAttachDocumentPopUp = () => {
    setIsOpenAttachDocumentPopUp(false);
    setEditedDocument(null);
  };

  const handleAttachPaymentTransactionDocument = async (document, requestDto) => {
    const data = new FormData();
    data.append(
      'dto',
      new Blob(
        [JSON.stringify(paymentTransactionDocumentRequestDto(requestDto.hidden, requestDto.category, requestDto.note))],
        {
          type: 'application/json'
        }
      )
    );
    data.append('file', document);
    await transactionsStore.attachPaymentTransactionDocument(transactionNumber, data);
  };

  useEffect(() => {
    if (transactionsStore.isTransactionDocumentAttachedOrUpdated) {
      handleCloseAttachDocumentPopUp();
      transactionsStore.setIsTransactionDocumentAttachedOrUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsStore.isTransactionDocumentAttachedOrUpdated]);

  if (!transactionsStore.currentTransactionDetails) {
    return <Loader className={'application-loader transaction-details-loader'} />;
  }

  return (
    <Container className={'transaction-details-container'} header={i18n.getMessage('container.transactionDetails')}>
      <TransactionDetailsTable
        isLoading={transactionsStore.isLoading}
        transactionData={transactionsStore.currentTransactionDetails}
        paymentLimits={transactionsStore.currentTransactionDetails?.transactionMonitoringDetails?.paymentLimits}
        sanctions={transactionsStore.currentTransactionDetails?.transactionMonitoringDetails?.sanctionDetails}
        blacklistDetails={transactionsStore.currentTransactionDetails?.transactionMonitoringDetails?.blacklistDetails}
        ruleEngineScoring={transactionsStore.currentTransactionDetails?.transactionMonitoringDetails?.ruleEngineScoring}
        handleAcceptOnHold={handleAcceptOnHold}
        handleAcceptProcessing={handleAcceptProcessing}
        handleReject={handleReject}
        handleReturn={handleReturn}
        handleChangeDocumentStatus={handleChangeDocumentStatus}
        handleSaveMlroApproval={handleSaveMlroApproval}
        handleOpenAttachDocumentPopUp={handleOpenAttachDocumentPopUp}
        uploadTransactionDocument={handleAttachPaymentTransactionDocument}
        downloadTransactionDocument={transactionsStore.downloadPaymentTransactionDocument}
        deleteTransactionDocument={transactionsStore.deletePaymentTransactionDocument}
        updateTransactionDocumentDetails={transactionsStore.updatePaymentDocumentDetails}
      />

      <NotesTab
        isLoading={transactionsStore.isLoading}
        notes={transactionsStore.currentTransactionNotes}
        transactionNumber={transactionNumber}
        handleLoadNotes={handleLoadTransactionNotes}
        handleAddNote={handleAddTransactionNote}
        handleDeleteNote={handleDeleteTransactionNote}
        handleDownloadDocument={handleDownloadDocument}
      />

      <PopUp alignOnCenter show={popUpData.isShowPopUp || isOpenAttachDocumentPopUp}>
        {popUpData.isShowPopUp && (
          <PopConfirmationScheme
            title={popUpData.title}
            description={popUpData.description}
            onConfirmButtonTitle={popUpData.confirmButtonLabel}
            onCloseButtonTitle={popUpData.cancelButtonLabel}
            onConfirm={popUpData.onConfirm}
            onClose={popUpData.onCancel}
          />
        )}
        {isOpenAttachDocumentPopUp && (
          <PopUpAttachDocumentScheme
            isLoading={transactionsStore.isLoading}
            handleClose={handleCloseAttachDocumentPopUp}
            editedDocument={editedDocument}
            handleAttachTransactionDocument={handleAttachPaymentTransactionDocument}
            handleUpdateTransactionDocument={transactionsStore.updatePaymentDocumentDetails}
          />
        )}
      </PopUp>
    </Container>
  );
};

TransactionDetails.propTypes = {
  customerNumber: PropTypes.string,
  userInfo: PropTypes.object,
  isInitialized: PropTypes.bool,
  isUserDeletionAvailable: PropTypes.bool,
  usersStore: MobXPropTypes.observableObject,
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  usersStore: stores.usersStore,
  transactionsStore: stores.transactionsStore
}))(observer(TransactionDetails));
