/*
 * XXX: do named imports _only_ from two config files below to prevent
 *      server-side configuration constants be injected by Webpack in the production's bundle
 *      (see "sideEffects" in "package.json", which commands tree-shaking in production mode).
 */
import defaults from './defaults.json';
import custom from './config.json';

export const getConfigValue = (key) =>
  custom.client && key in custom.client ? custom.client[key] : defaults.client[key];

export const API_HOST = getConfigValue('API_HOST');
export const API_URL_PATH_PREFIX = getConfigValue('API_URL_PATH_PREFIX');
export const OAUTH_HOST = getConfigValue('OAUTH_HOST');
export const OAUTH_URL_PATH_PREFIX = getConfigValue('OAUTH_URL_PATH_PREFIX');
export const COMPANY_CAPTION = getConfigValue('COMPANY_CAPTION');
export const CURRENCIES = getConfigValue('CURRENCIES');
export const IS_CRYPTO = getConfigValue('IS_CRYPTO');
export const IS_MESSAGING = getConfigValue('IS_MESSAGING');
export const STATUS_UPDATE_INTERVAL = getConfigValue('STATUS_UPDATE_INTERVAL');
export const KYC_MODE = getConfigValue('KYC_MODE');
export const SESSION_BEFORE_LOGOUT_DIALOG_TIMEOUT = getConfigValue('SESSION_BEFORE_LOGOUT_DIALOG_TIMEOUT');
export const SESSION_AFTER_LOGOUT_DIALOG_TIMEOUT = getConfigValue('SESSION_AFTER_LOGOUT_DIALOG_TIMEOUT');
export const VERSION = getConfigValue('VERSION');
export const IS_DEVMODE = getConfigValue('IS_DEVMODE');
export const FRONTEND_COMMITS_LINK = getConfigValue('FRONTEND_COMMITS_LINK');
export const BACKEND_COMMITS_LINK = getConfigValue('BACKEND_COMMITS_LINK');
export const IS_COMMISSION_AVAILABLE = getConfigValue('IS_COMMISSION_AVAILABLE');
export const IS_TRANSFER_TYPE_AVAILABLE = getConfigValue('IS_TRANSFER_TYPE_AVAILABLE');
export const SHOW_LIMITS_IN_CRM = getConfigValue('SHOW_LIMITS_IN_CRM');
export const SHOW_RECIPIENT_TYPE = getConfigValue('SHOW_RECIPIENT_TYPE');
export const IS_REGISTRATION_PERSONAL = getConfigValue('IS_REGISTRATION_PERSONAL');
export const IS_CREATE_WALLET = getConfigValue('IS_CREATE_WALLET');
export const IS_API = getConfigValue('IS_API');
export const IS_PROVIDERS_MANAGEMENT = getConfigValue('IS_PROVIDERS_MANAGEMENT');
export const IS_MONEY_EXCHANGE = getConfigValue('IS_MONEY_EXCHANGE');
