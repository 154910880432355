import { useContext, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import i18nContext from 'components/i18n-context';
import AuthorizationLayout from 'components/EntryLayouts/AuthorizationLayout';
import ApplicationLayout from 'components/EntryLayouts/ApplicationLayout';
import { ROUTE_PATHS, UNAUTHORIZED_ROUTE_PATHS } from 'routes/constants';
import { ElementWrapper } from 'routes/ElementWrapper';
import LoginPage from 'pages/Login';
import CustomersPage from 'pages/Customers';
import CustomersTable from 'pages/Customers/components/CustomersTable';
import IndividualCustomerDetails from 'pages/Customers/components/IndividualCustomerDetails';
import CorporateCustomerDetails from 'pages/Customers/components/CorporateCustomerDetails';
import UsersPage from 'pages/Users';
import UsersTable from 'pages/Users/components/UsersTable';
import UserDetails from 'pages/Users/components/UserDetails';
import CreateUser from 'pages/Users/components/CreateUser';
import AccountsPage from 'pages/Accounts';
import AccountsTable from 'pages/Accounts/components/AccountsTable';
import SystemAccountsPage from 'pages/SystemAccounts';
import SystemAccountsTable from 'pages/SystemAccounts/components/SystemAccountsTable';
import TransactionsPage from 'pages/Transactions';
import TransactionsTable from 'pages/Transactions/components/TrasactionsTable';
import TransactionsDetails from 'pages/Transactions/components/TransactionDetails';
import TransitTransactionsPage from 'pages/TransitTransactions';
import TransitTransactionsTable from 'pages/TransitTransactions/components/TransitTransactionsTable';
import PaymentObligationsPage from 'pages/PaymentObligations';
import PaymentObligationsTable from 'pages/PaymentObligations/components/PaymentObligationsTable';
import CreateTransactionPage from 'pages/CreateTransaction';
import CreatePaymentTransactionTab from 'pages/CreateTransaction/components/PaymentTransaction';
import CreateTransitTransactionTab from 'pages/CreateTransaction/components/TransitTransaction';
import CreateCommissionTransactionTab from 'pages/CreateTransaction/components/CommissionTransaction';
import CreateCryptoTransactionTab from 'pages/CreateTransaction/components/CryptoTransaction';
import CardAuthorisationsPage from 'pages/CardAuthorisations';
import CardAuthorisationsTable from 'pages/CardAuthorisations/components/CardAuthorisationsTable';
import MessagesPage from 'pages/Messages';
import MessagesTopicsTable from 'pages/Messages/components/MessagesTopicsTable';
import NewMessage from 'pages/Messages/components/NewMessage';
import TopicMessages from 'pages/Messages/components/TopicMessages';
import CommissionsPage from 'pages/Commissions';
import CommissionAccountsTab from 'pages/Commissions/components/CommissionAccounts';
import CommissionTransactionsTab from 'pages/Commissions/components/CommissionTransactions';
import WithdrawalTransactionsTab from 'pages/Commissions/components/WithdrawalTransactions';
import SettingsPage from 'pages/Settings';
import ConfigurationTab from 'pages/Settings/components/ConfigurationTab';
import PaymentsCutoffTimesTab from 'pages/Settings/components/PaymentsCutoffTimesTab';
import BlacklistsTab from 'pages/Settings/components/BlacklistsTab';
import EmailStatisticsPage from 'pages/EmailStatistics';
import EmailStatisticsTable from 'pages/EmailStatistics/components/EmailStatisticsTable';
import { getAccessExpiresOn, isTokenValid } from 'services/authUtils';
import { setTokenRefresh } from 'services/requestAgent';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App({ authStore }) {
  const navigate = useNavigate();
  const location = useLocation();
  const i18n = useContext(i18nContext);

  useEffect(() => {
    if (isTokenValid()) {
      const expiresOn = getAccessExpiresOn();
      setTokenRefresh((expiresOn - Date.now()) / 1000);
      if (!authStore.isLoginSuccess) {
        authStore.getUserRoles(true).then(() => {});
      }
    }

    if (
      !isTokenValid() &&
      !Object.keys(UNAUTHORIZED_ROUTE_PATHS).filter((path) =>
        location.pathname.includes(UNAUTHORIZED_ROUTE_PATHS[path])
      ).length
    ) {
      const redirectPath = encodeURIComponent(`${location.pathname}${location.search}`);

      navigate(`${ROUTE_PATHS.LOGIN}?redirectPath=${redirectPath}`);
    }

    // Check to redirect to CRM page if the current URL is "/"
    if (isTokenValid() && location.pathname === '/') {
      navigate(ROUTE_PATHS.CUSTOMERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname, authStore]);

  return (
    <>
      <Routes>
        <Route path={ROUTE_PATHS.LOGIN} element={<AuthorizationLayout />}>
          <Route index element={<ElementWrapper component={LoginPage} />} />
        </Route>
        <Route path={ROUTE_PATHS.ROOT} element={<ApplicationLayout />}>
          <Route path={ROUTE_PATHS.CUSTOMERS} element={<ElementWrapper component={CustomersPage} />}>
            <Route
              index
              element={
                <ElementWrapper component={CustomersTable} headerText={i18n.getMessage('container.customers')} />
              }
            />
            <Route
              path={ROUTE_PATHS.CUSTOMER_CORPORATE}
              element={
                <ElementWrapper
                  component={CorporateCustomerDetails}
                  headerText={i18n.getMessage('container.corporateCustomerDetails')}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.CUSTOMER_INDIVIDUAL}
              element={
                <ElementWrapper
                  component={IndividualCustomerDetails}
                  headerText={i18n.getMessage('container.individualCustomerDetails')}
                />
              }
            />
          </Route>
          <Route path={ROUTE_PATHS.ACTIVE_CUSTOMERS} element={<ElementWrapper component={CustomersPage} />}>
            <Route
              index
              element={
                <ElementWrapper component={CustomersTable} headerText={i18n.getMessage('container.activeCustomers')} />
              }
            />
            <Route
              path={ROUTE_PATHS.ACTIVE_CUSTOMER_CORPORATE}
              element={
                <ElementWrapper
                  component={CorporateCustomerDetails}
                  headerText={i18n.getMessage('container.corporateCustomerDetails')}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.ACTIVE_CUSTOMER_INDIVIDUAL}
              element={
                <ElementWrapper
                  component={IndividualCustomerDetails}
                  headerText={i18n.getMessage('container.individualCustomerDetails')}
                />
              }
            />
          </Route>
          <Route path={ROUTE_PATHS.USERS} element={<ElementWrapper component={UsersPage} />}>
            <Route
              index
              element={<ElementWrapper component={UsersTable} headerText={i18n.getMessage('container.users')} />}
            />
            <Route
              path={ROUTE_PATHS.USER_DETAILS}
              element={<ElementWrapper component={UserDetails} headerText={i18n.getMessage('container.userDetails')} />}
            />
            <Route
              path={ROUTE_PATHS.USER_CREATE}
              element={<ElementWrapper component={CreateUser} headerText={i18n.getMessage('container.createUser')} />}
            />
          </Route>
          <Route path={ROUTE_PATHS.ACCOUNTS} element={<ElementWrapper component={AccountsPage} />}>
            <Route
              index
              element={<ElementWrapper component={AccountsTable} headerText={i18n.getMessage('container.accounts')} />}
            />
          </Route>
          <Route path={ROUTE_PATHS.SYSTEM_ACCOUNTS} element={<ElementWrapper component={SystemAccountsPage} />}>
            <Route
              index
              element={
                <ElementWrapper
                  component={SystemAccountsTable}
                  headerText={i18n.getMessage('container.systemAccounts')}
                />
              }
            />
          </Route>
          <Route path={ROUTE_PATHS.TRANSACTIONS} element={<ElementWrapper component={TransactionsPage} />}>
            <Route
              index
              element={
                <ElementWrapper component={TransactionsTable} headerText={i18n.getMessage('container.transactions')} />
              }
            />
            <Route
              path={ROUTE_PATHS.TRANSACTION_DETAILS}
              element={
                <ElementWrapper
                  component={TransactionsDetails}
                  headerText={i18n.getMessage('container.transactionDetails')}
                />
              }
            />
          </Route>
          <Route
            path={ROUTE_PATHS.TRANSIT_TRANSACTIONS}
            element={<ElementWrapper component={TransitTransactionsPage} />}
          >
            <Route
              index
              element={
                <ElementWrapper
                  component={TransitTransactionsTable}
                  headerText={i18n.getMessage('container.transitTransactions')}
                />
              }
            />
          </Route>
          <Route path={ROUTE_PATHS.PAYMENT_OBLIGATIONS} element={<ElementWrapper component={PaymentObligationsPage} />}>
            <Route
              index
              element={
                <ElementWrapper
                  component={PaymentObligationsTable}
                  headerText={i18n.getMessage('container.paymentObligations')}
                />
              }
            />
          </Route>
          <Route path={ROUTE_PATHS.CREATE_TRANSACTION} element={<ElementWrapper component={CreateTransactionPage} />}>
            <Route
              path={ROUTE_PATHS.CREATE_PAYMENT_TRANSACTION}
              element={
                <ElementWrapper
                  component={CreatePaymentTransactionTab}
                  headerText={i18n.getMessage('container.createTransaction')}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.CREATE_TRANSIT_TRANSACTION}
              element={
                <ElementWrapper
                  component={CreateTransitTransactionTab}
                  headerText={i18n.getMessage('container.createTransaction')}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.CREATE_COMMISSION_TRANSACTION}
              element={
                <ElementWrapper
                  component={CreateCommissionTransactionTab}
                  headerText={i18n.getMessage('container.createTransaction')}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.CREATE_CRYPTO_TRANSACTION}
              element={
                <ElementWrapper
                  component={CreateCryptoTransactionTab}
                  headerText={i18n.getMessage('container.createTransaction')}
                />
              }
            />
          </Route>

          <Route path={ROUTE_PATHS.CARD_AUTHORIZATIONS} element={<ElementWrapper component={CardAuthorisationsPage} />}>
            <Route
              index
              element={
                <ElementWrapper
                  component={CardAuthorisationsTable}
                  headerText={i18n.getMessage('container.cardAuthorisations')}
                />
              }
            />
          </Route>

          <Route path={ROUTE_PATHS.MESSAGES} element={<ElementWrapper component={MessagesPage} />}>
            <Route
              index
              element={
                <ElementWrapper component={MessagesTopicsTable} headerText={i18n.getMessage('container.messages')} />
              }
            />
            <Route
              path={ROUTE_PATHS.NEW_MESSAGE}
              element={<ElementWrapper component={NewMessage} headerText={i18n.getMessage('container.newMessage')} />}
            />
            <Route
              path={ROUTE_PATHS.TOPIC_MESSAGES}
              element={
                <ElementWrapper component={TopicMessages} headerText={i18n.getMessage('container.commissions')} />
              }
            />
          </Route>

          <Route path={ROUTE_PATHS.COMMISSIONS} element={<ElementWrapper component={CommissionsPage} />}>
            <Route
              path={ROUTE_PATHS.COMMISSION_ACCOUNTS}
              element={
                <ElementWrapper
                  component={CommissionAccountsTab}
                  headerText={i18n.getMessage('container.commissions')}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.COMMISSION_TRANSACTIONS}
              element={
                <ElementWrapper
                  component={CommissionTransactionsTab}
                  headerText={i18n.getMessage('container.commissions')}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.COMMISSION_WITHDRAWAL}
              element={
                <ElementWrapper
                  component={WithdrawalTransactionsTab}
                  headerText={i18n.getMessage('container.commissions')}
                />
              }
            />
          </Route>

          <Route path={ROUTE_PATHS.SETTINGS} element={<ElementWrapper component={SettingsPage} />}>
            <Route
              path={ROUTE_PATHS.SETTINGS_CONFIGURATION}
              element={
                <ElementWrapper component={ConfigurationTab} headerText={i18n.getMessage('container.settings')} />
              }
            />
            <Route
              path={ROUTE_PATHS.SETTINGS_PAYMENTS_CUTOFF_TIMES}
              element={
                <ElementWrapper component={PaymentsCutoffTimesTab} headerText={i18n.getMessage('container.settings')} />
              }
            />
            <Route
              path={ROUTE_PATHS.SETTINGS_BLACKLISTS}
              element={<ElementWrapper component={BlacklistsTab} headerText={i18n.getMessage('container.settings')} />}
            />
          </Route>

          <Route path={ROUTE_PATHS.EMAIL_STATISTICS} element={<ElementWrapper component={EmailStatisticsPage} />}>
            <Route
              index
              element={
                <ElementWrapper
                  component={EmailStatisticsTable}
                  headerText={i18n.getMessage('container.emailStatistics')}
                />
              }
            />
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        position={'top-right'}
        autoClose={false}
        newestOnTop={false}
        stacked
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme={'light'}
        transition={Flip}
      />
    </>
  );
}

App.propTypes = {
  authStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  authStore: stores.authStore
}))(observer(App));
