import request from 'superagent';
import noCache from 'superagent-no-cache';
import moment from 'moment-timezone';
import {
  getRefreshToken,
  getAccessToken,
  saveTokenInfo,
  logoutActiveSessionWithoutToken,
  setLastUserActivityDateTime,
  resetLastUserActivityDateTime
} from './authUtils';
import { showErrorNotification } from './notificationUtils';
import { errorMapper } from './Error';
import { BLACKLIST_STATUS, CUSTOMER_STATUSES, CUSTOMER_TYPE } from 'components/constants';

const requestAgent = request.agent().use(noCache);

const {
  REACT_APP_API_URL_PATH_PREFIX,
  REACT_APP_OAUTH_URL_PATH_PREFIX,
  REACT_APP_API_HOST,
  REACT_APP_AUTHORIZATION_CLIENT,
  REACT_APP_AUTHORIZATION_SECRET
} = process.env;

const TRANSPORT_DELAY = 20; // max seconds client waits for server response.
let isRefreshingToken = false;

/*
 "refreshTokenAwaiter" is a method that is used to create an artificial delay for any server calls if a request
 for refreshing the access token has been sent and a response with a new access token is expected.
 The delay step in this method is 100ms.
 The maximum number of iterations is 200, which is equal to the value of TRANSPORT_DELAY = 20sec.
 This method is handled using the "isRefreshingToken" variable.
 */
const refreshTokenAwaiter = () => {
  return new Promise((resolve) => {
    let count = 0;
    const interval = setInterval(() => {
      if (!isRefreshingToken) {
        clearInterval(interval);
        resolve();

        return;
      }
      count++;
      if (count > 200) {
        // 200 * 100ms (timeout) = 20 sec (TRANSPORT_DELAY)
        clearInterval(interval);
        resolve(errorMapper('invalid_refresh_token'));
      }
    }, 100);
  });
};

// Common method! We need to use it for all API-calls
export const api = async (args) => {
  const {
    path: urlPathPostfix,
    method = 'post',
    query = {},
    data = {},
    pathPrefix = REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth = true,
    isRequestWithBasicAuth = false,
    isPutRequestWithData = false,
    securityCode,
    returnFullResponse = false,
    responseType = null,
    captcha = null,
    isShowErrorNotification = true
  } = args;

  if (isRefreshingToken) {
    await refreshTokenAwaiter();
  }

  // eslint-disable-next-line no-console
  console.debug(
    '### API',
    method.toUpperCase(),
    'REQUEST',
    urlPathPostfix,
    ...((method === 'post' && ['\n', data]) ||
      (method === 'get' && query && Object.keys(query).length && ['\n', query]) ||
      [])
  );

  const req = requestAgent[method](pathPrefix + urlPathPostfix);

  if (isRequestWithAuth) {
    req.auth(getAccessToken(), { type: 'bearer' });
  }

  if (isRequestWithBasicAuth) {
    req.auth('web', '');
  }

  if (responseType) {
    req.responseType(responseType);
  }

  if (query && Object.keys(query).length !== 0) {
    req.query(query);
  }

  if (method === 'post' || (method === 'put' && isPutRequestWithData)) {
    req.send(data);
  }

  if (securityCode) {
    req.set('Confirmation-Code', securityCode);
  }

  if (captcha) {
    req.set('Captcha', captcha);
  }

  setLastUserActivityDateTime();

  try {
    const res = await req; // XXX: `body` is `null` for 204 response.
    // eslint-disable-next-line no-console
    // console.log('RES api:', res);
    // eslint-disable-next-line no-console
    console.debug('### API', method.toUpperCase(), 'RESPONSE', res?.body);
    return returnFullResponse ? res : res.body;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.debug('### API', method.toUpperCase(), 'ERROR', error);

    if (isShowErrorNotification) {
      showErrorNotification(
        error?.response?.status,
        error?.response?.body?.message || error?.response?.body?.error_description || error?.message
      );
    }

    return errorMapper(error);
  }
};

const downloadFile = (response, isPreview = false, filename) => {
  if (!filename && response.headers['content-disposition']) {
    const parts = response.headers['content-disposition'].split('filename=');

    if (parts.length === 2) {
      // eslint-disable-next-line no-param-reassign
      filename = parts[1].replace(/"/g, '');
    }
  }

  const url = URL.createObjectURL(response.body);

  if (isPreview) {
    return url;
  }
  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  downloadLink.setAttribute('download', filename || 'downloadedFile');
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

/* █████████████████████ *\
 * ███ OAUTH-related ███ *
\* █████████████████████ */

let tokenTimeoutId;

const tokenize = (data) => async () => {
  if (isRefreshingToken) return;

  try {
    isRefreshingToken = true;

    const {
      body: {
        access_token: accessToken,
        refresh_token: refreshToken,
        token_type: tokenType,
        expires_in: expiresIn // access_token life-span in seconds.
      }
    } = await requestAgent
      .post(REACT_APP_OAUTH_URL_PATH_PREFIX + '/token')
      .type('form')
      .auth(REACT_APP_AUTHORIZATION_CLIENT, REACT_APP_AUTHORIZATION_SECRET)
      .send(data);
    // .set('Content-Type', 'application/json; charset=utf-8') // Set the correct content type
    // .send(JSON.stringify(data)); // Send data as JSON

    saveTokenInfo({ accessToken, refreshToken, tokenType, accessExpiresOn: Date.now() + expiresIn * 1000 });
    setTokenRefresh(expiresIn);
  } catch (error) {
    showErrorNotification(error?.response?.status, error?.response?.body?.error);

    return errorMapper(error);
  } finally {
    isRefreshingToken = false;
  }
};

/**
 * @param {number} expiresIn - number of seconds after which access_token expires.
 */
export const setTokenRefresh = (expiresIn) => {
  clearTimeout(tokenTimeoutId);
  tokenTimeoutId = setTimeout(
    tokenize({
      grant_type: 'refresh_token',
      refresh_token: getRefreshToken()
    }),
    (expiresIn - TRANSPORT_DELAY) * 1000
  );
};

/* ███████████████████████████████████████ *\
 * ███ API REQUESTS FOR TOKEN MANAGING ███ *
\* ███████████████████████████████████████ */

export const loginUser = ({ username, password }) =>
  tokenize({
    username,
    password,
    grant_type: 'password'
  })();

export const runLogout = async () => {
  resetLastUserActivityDateTime();
  clearTimeout(tokenTimeoutId);
  logoutActiveSessionWithoutToken();
};

export const getAdminAvailablePermissions = () =>
  api({
    path: '/admins/roles',
    pathHost: REACT_APP_API_HOST,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: false,
    isRequestWithBasicAuth: true,
    query: { token: getAccessToken() }
  });

export const getUserRolesRequest = async () => {
  const res = await api({
    method: 'get',
    path: '/account/roles',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const getApplicationState = async () => {
  return await api({
    method: 'get',
    path: '/application/state',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const getApplicationConstants = async () => {
  return await api({
    method: 'get',
    path: '/application/constants',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const getApplicationConfiguration = async () => {
  return await api({
    method: 'get',
    path: '/application/configuration',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const getApplicationPropertiesRequest = async () => {
  return await api({
    method: 'get',
    path: '/application/properties',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const updatePaymentWorkTimeListRequest = async (paymentWorkTimeList) => {
  return await api({
    method: 'post',
    path: '/application/property/payment-time',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: {
      paymentWorkTimes: paymentWorkTimeList
    }
  });
};

/* ███████████████████████████████████████████ *\
 * ███ API REQUESTS FOR PIN/PASSWORD RESET ███ *
\* ███████████████████████████████████████████ */

export const resetPasswordRequest = async (customerNumber) => {
  const res = await api({
    method: 'post',
    path: `/account/${customerNumber}/reset_password`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

/* ███████████████████████████████████████ *\
 * ███               USERS             ███ *
\* ███████████████████████████████████████ */
export const getUsersListRequest = async (sortBy, page, amountPerPage, direction, searchText, userStatuses) => {
  const res = await api({
    method: 'get',
    path: '/user/list',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query: {
      sort_column: sortBy,
      page: page,
      size: amountPerPage,
      sort_direction: direction,
      search_text: searchText,
      user_statuses: userStatuses
    }
  });

  return { ...res };
};

export const getUserInfoById = async (userId) => {
  const res = await api({
    method: 'get',
    path: `/user/${userId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const createUserRequest = async (newUserData) => {
  const requestData = {
    phone: newUserData.phone,
    password: newUserData.password,
    account_data: {
      email: newUserData.email,
      account_type: newUserData.customerType
    }
  };

  // Add condition based on customerType
  if (newUserData.customerType === CUSTOMER_TYPE.CORPORATE) {
    // If customer type is CORPORATE, send companyName
    requestData.account_data.company_name = newUserData.companyName;
  } else if (newUserData.customerType === CUSTOMER_TYPE.INDIVIDUAL) {
    // If customer type is INDIVIDUAL, send firstName and lastName
    requestData.account_data.first_name = newUserData.firstName;
    requestData.account_data.last_name = newUserData.lastName;
  }

  return await api({
    method: 'post',
    path: '/user/create',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: requestData
  });
};

export const deleteUserRequest = async (userId) => {
  const res = await api({
    method: 'delete',
    path: `/user/${userId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const sendPasswordSetupEmailRequest = async (customerNumber) => {
  const res = await api({
    method: 'get',
    path: `/user/email/${customerNumber}/password_setup`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const updateUserPhoneRequest = async (userId, phone) => {
  const res = await api({
    method: 'post',
    path: `/user/${userId}/phone`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    isShowErrorNotification: false,
    data: { phone }
  });

  return { ...res };
};

export const exportUsersList = async (filters) => {
  const res = await api({
    method: 'get',
    path: '/user/export/csv',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query: filters
  });

  downloadFile(res);
};

/* ████████████████████████████████████████ *\
 * ███            CUSTOMERS             ███ *
\* ████████████████████████████████████████ */

export const getCustomersListRequest = async (
  sortBy,
  page,
  amountPerPage,
  direction,
  searchText,
  filters,
  isShowActiveCustomers
) => {
  const query = {
    page: page,
    size: amountPerPage,
    sort_direction: direction,
    search_text: searchText,
    onboarding_statuses: filters.onboarding_statuses,
    customer_owners: filters.customer_owners,
    types: filters.types,
    account_statuses:
      filters.account_statuses.length === 0 && isShowActiveCustomers
        ? [CUSTOMER_STATUSES.VERIFIED]
        : filters.account_statuses.length === 0 && !isShowActiveCustomers
          ? Object.keys(CUSTOMER_STATUSES).filter((s) => s !== CUSTOMER_STATUSES.VERIFIED)
          : filters.account_statuses,
    kyc_statuses: filters.kyc_statuses,
    risk_profiles: filters.risk_profiles
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (filters.from_date) {
    query.from_date = filters.from_date;
  }
  if (filters.to_date) {
    query.to_date = filters.to_date;
  }

  const res = await api({
    method: 'get',
    path: '/account',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });

  return { ...res };
};

export const getMessageClients = async (searchText, amount) => {
  return await api({
    method: 'get',
    path: '/account/message_clients',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query: { search_text: searchText, size: amount }
  });
};

/* █████████████████████████████████████████ *\
 * ███ API REQUESTS FOR CUSTOMER DETAILS ███ *
\* █████████████████████████████████████████ */

export const activateCustomerRequest = async (userId) => {
  const res = await api({
    method: 'post',
    path: `/account/${userId}/activate`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: { account_number: userId }
  });

  return { ...res };
};

export const blockCustomerRequest = async (userId) => {
  const res = await api({
    method: 'post',
    path: `/account/${userId}/block`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: { account_number: userId }
  });

  return { ...res };
};

export const administrateCustomerRequest = async (userId) => {
  const res = await api({
    method: 'post',
    path: `/account/${userId}/administrate`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: { account_number: userId }
  });

  return { ...res };
};

export const getCustomerInfoRequest = async (customerNumber) => {
  return await api({
    method: 'get',
    path: `/account/${customerNumber}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const getIndividualCustomerDetailsRequest = async (customerNumber) => {
  const res = await api({
    method: 'get',
    path: `/account/${customerNumber}/individual_details`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const updateIndividualCustomerDetailsRequest = async (customerNumber, data) => {
  const res = await api({
    method: 'post',
    path: `/account/${customerNumber}/individual_details`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    isShowErrorNotification: false,
    data
  });

  return { ...res };
};

export const getCorporateCustomerDetailsRequest = async (customerNumber) => {
  const res = await api({
    method: 'get',
    path: `/account/${customerNumber}/corporate_details`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const updateCorporateCustomerDetailsRequest = async (customerNumber, data) => {
  const res = await api({
    method: 'post',
    path: `/account/${customerNumber}/corporate_details`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    isShowErrorNotification: false,
    data
  });

  return { ...res };
};

export const exportCustomersList = async (filters) => {
  const res = await api({
    method: 'get',
    path: '/account/export/csv',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query: filters
  });

  downloadFile(res);
};

export const updateCustomerEmailRequest = async (customerNumber, email) => {
  const res = await api({
    method: 'post',
    path: `/account/${customerNumber}/email`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: { email }
  });

  return { ...res };
};

export const updateCustomerStatusRequest = async (customerNumber, newCustomerStatus) => {
  const res = await api({
    method: 'post',
    path: `/account/${customerNumber}/update_status`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: {
      status: newCustomerStatus
    }
  });

  return { ...res };
};

export const updateOnboardingStatusRequest = async (customerNumber, onboardingStatus) => {
  const res = await api({
    method: 'get',
    path: `/account/${customerNumber}/onboarding/${onboardingStatus}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const updateKycStatusRequest = async (customerNumber, kysStatus) => {
  const res = await api({
    method: 'get',
    path: `/account/${customerNumber}/kyc/status/${kysStatus}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const updateCustomerExternalKycReferenceRequest = async (customerNumber, externalKycReference) => {
  const res = await api({
    method: 'post',
    path: `/account/${customerNumber}/kyc/external_reference`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: {
      external_reference: externalKycReference
    }
  });

  return { ...res };
};

export const updateNextAMLCheckDateRequest = async (customerNumber, date) => {
  const res = await api({
    method: 'post',
    path: `/account/${customerNumber}/aml/update`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: {
      next_aml_check_Date: date
    }
  });

  return { ...res };
};

export const updateMlroApprovalRequest = async (customerNumber, status) => {
  const res = await api({
    method: 'get',
    path: `/account/${customerNumber}/mlro_approval/${status}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const updateDirectorApprovalRequest = async (customerNumber, status) => {
  const res = await api({
    method: 'get',
    path: `/account/${customerNumber}/director_approval/${status}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const updateRiskProfileRequest = async (customerNumber, status) => {
  const res = await api({
    method: 'get',
    path: `/account/${customerNumber}/risk_profile/${status}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const requestExternalKyc = async (customerNumber) => {
  const res = await api({
    method: 'get',
    path: `/account/${customerNumber}/verification/check`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const exportBalancesList = async (filters) => {
  const res = await api({
    method: 'get',
    path: '/account/wallet/export/csv',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query: filters
  });

  downloadFile(res);
};

export const getCompanyStructureRequest = async (customerNumber) => {
  return await api({
    method: 'get',
    path: `/account/${customerNumber}/corporate/structure`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const sendCompanyStructureRequest = async (customerNumber, companyStructure) => {
  return await api({
    method: 'post',
    path: `/account/${customerNumber}/corporate/structure`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: companyStructure
  });
};

export const deleteCompanyStructureMemberRequest = async (customerNumber, memberCustomerNumber) => {
  return await api({
    method: 'delete',
    path: `/account/${customerNumber}/corporate/structure/member/${memberCustomerNumber}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const getPaymentProvidersInfo = async (customerNumber) => {
  const res = await api({
    method: 'get',
    path: `/account/${customerNumber}/payment_provider/linked`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const addNewPaymentProviderRequest = async (customerNumber, data) => {
  return await api({
    method: 'post',
    path: `/account/${customerNumber}/payment_provider/link`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    isShowErrorNotification: false,
    data
  });
};

export const getLinkedRuleEngineProviderRequest = async (customerNumber) => {
  return await api({
    method: 'get',
    path: `/rule-engine/customer/${customerNumber}/linked`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const linkRuleEngineProviderRequest = async (customerNumber, ruleEngine) => {
  return await api({
    method: 'get',
    path: `/rule-engine/customer/${customerNumber}/${ruleEngine}/link`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const unlinkRuleEngineProviderRequest = async (customerNumber) => {
  return await api({
    method: 'get',
    path: `/rule-engine/customer/${customerNumber}/unlink`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const getCustomerCardsRequest = async (customerNumber) => {
  const res = await api({
    method: 'get',
    path: `/card/${customerNumber}/list`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return res;
};

export const blockCustomerCardRequest = async (customerNumber, cardNumber, cardBlockType) => {
  const res = await api({
    method: 'post',
    path: `/card/${customerNumber}/${cardNumber}/block`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: { block_type: cardBlockType }
  });

  return { ...res };
};

export const unblockCustomerCardRequest = async (customerNumber, cardNumber) => {
  const res = await api({
    method: 'get',
    path: `/card/${customerNumber}/${cardNumber}/unblock`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const replaceCustomerCardRequest = async (customerNumber, cardNumber) => {
  const res = await api({
    method: 'get',
    path: `/card/${customerNumber}/${cardNumber}/replace`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const updateCardsPerCustomerLimitRequest = async (customerNumber, cardsLimit) => {
  const res = await api({
    method: 'post',
    path: `/account/${customerNumber}/card/limit/customer`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: { cards_limit: cardsLimit }
  });

  return { ...res };
};

export const updateCardsPerAccountLimitRequest = async (customerNumber, cardsLimit) => {
  const res = await api({
    method: 'post',
    path: `/account/${customerNumber}/card/limit/account`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: { cards_limit: cardsLimit }
  });

  return { ...res };
};

export const addRepresentativeRequest = async (customerNumber, email, permissions) => {
  const res = await api({
    method: 'post',
    path: `/account/representative/${customerNumber}/add`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: { representative_email: email, permissions }
  });

  return { ...res };
};

export const removeRepresentativeRequest = async (customerNumber, representativeId) => {
  const res = await api({
    method: 'post',
    path: `/account/representative/${customerNumber}/remove/${representativeId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const updateRepresentativeRequest = async (customerNumber, representativeId, permissions) => {
  const res = await api({
    method: 'post',
    path: `/account/representative/${customerNumber}/update/${representativeId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: permissions
  });

  return { ...res };
};

export const getAmlRequest = async (customerNumber) => {
  return await api({
    method: 'get',
    path: `/aml/${customerNumber}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const saveAmlRequest = async (customerNumber, aml) => {
  return await api({
    method: 'post',
    path: `/aml/${customerNumber}/save`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: aml
  });
};
export const getAmlTemplateRequest = async (customerType) => {
  return await api({
    method: 'get',
    path: `/aml/template/${customerType}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const generateAmlPdfReportRequest = async (customerNumber) => {
  const res = await api({
    method: 'get',
    path: `/aml/${customerNumber}/report/pdf`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob'
  });

  downloadFile(res);
};

export const loadCustomerPdfReportRequest = async (customerNumber, from, to, accountNumber) => {
  const query = {
    from_date: from,
    to_date: to
  };

  if (accountNumber) {
    query.wallet_number = accountNumber;
  }

  const res = await api({
    method: 'get',
    path: `/account/${customerNumber}/review/pdf`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query
  });

  downloadFile(res);
};

export const loadCustomerCsvReportRequest = async (customerNumber, from, to, accountNumber) => {
  const query = {
    from_date: from,
    to_date: to
  };

  if (accountNumber) {
    query.wallet_number = accountNumber;
  }

  const res = await api({
    method: 'get',
    path: `/account/${customerNumber}/review/csv`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query
  });

  downloadFile(res);
};

/* ██████████████████████████████ *\
 * ███      EVENT LOGS        ███ *
\* ██████████████████████████████ */

export const getActionLogsRequest = async (authorType) => {
  return await api({
    method: 'get',
    path: '/event_log',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query: { author_type: authorType }
  });
};

export const getCustomerLogsRequest = async (customerNumber, pagination) => {
  const query = {
    page: pagination.page,
    size: pagination.size
  };

  if (pagination.totalPages) {
    query.totalPages = pagination.totalPages;
  }

  if (pagination.totalElements) {
    query.totalElements = pagination.totalElements;
  }

  return await api({
    method: 'get',
    path: `/logs/account/${customerNumber}/list`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });
};

/* ███████████████████████████████████████ *\
 * ███             Accounts            ███ *
\* ███████████████████████████████████████ */

export const getAccountsRequest = async (sortBy, page, amountPerPage, direction, searchText) => {
  const query = {
    page: page,
    size: amountPerPage,
    sort_direction: direction,
    search_text: searchText
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }

  const res = await api({
    method: 'get',
    path: '/wallet/list',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });

  return { ...res };
};

export const exportAccountsList = async (sortBy, page, amountPerPage, direction, filters) => {
  const query = {
    page: page,
    size: amountPerPage,
    sort_direction: direction
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }

  if (filters.search_text) {
    query.search_text = filters.search_text;
  }

  const res = await api({
    method: 'get',
    path: '/wallet/export/csv',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query
  });

  downloadFile(res);
};

export const getSystemAccountsRequest = async (searchText, types) => {
  const query = {
    types: types
  };

  if (searchText) {
    query.search_text = searchText;
  }

  const res = await api({
    method: 'get',
    path: '/system_account/list',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });

  return { ...res };
};

export const exportSystemAccountsBalancesReport = async (type) => {
  const query = {
    type
  };

  const res = await api({
    method: 'get',
    path: '/system_account/balances/report/csv',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query
  });

  downloadFile(res);
};

export const enableAccountRequest = async (accountNumber) => {
  const res = await api({
    method: 'get',
    path: `/wallet/${accountNumber}/enable`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const disableAccountRequest = async (accountNumber) => {
  const res = await api({
    method: 'get',
    path: `/wallet/${accountNumber}/disable`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });

  return { ...res };
};

export const createNewAccountRequest = async (customerNumber, transferProvider, data) => {
  return await api({
    method: 'post',
    path: `/wallet/${customerNumber}/create`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: {
      transfer_provider: transferProvider,
      data
    }
  });
};

export const setAccountPrimaryRequest = async (accountNumber) => {
  return await api({
    method: 'post',
    path: `/wallet/${accountNumber}/primary`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: {}
  });
};

export const adjustAccountAmountRequest = async (data) => {
  return await api({
    method: 'post',
    path: '/wallet/adjust-amount',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

/* ████████████████████████████████████████ *\
 * ███            TARIFFS               ███ *
\* ████████████████████████████████████████ */

export const getTariffGroupsListRequest = async () => {
  return await api({
    method: 'get',
    path: '/tariff',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const getTariffsGroupRequest = async (groupId) => {
  return await api({
    method: 'get',
    path: `/tariff/${groupId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const changeCustomerTariffGroupRequest = async (customerNumber, groupId) => {
  return await api({
    method: 'post',
    path: `/account/${customerNumber}/tariff/assign/${groupId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: null
  });
};

export const applyTariffRequest = async (customerNumber, tariffType) => {
  return await api({
    method: 'post',
    path: `/account/${customerNumber}/tariff/${tariffType}/apply`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

/* ██████████████████████ *\
 * ███ LIMITS' GROUPS ███ *
\* ██████████████████████ */

export const getLimitListRequest = async () => {
  return await api({
    method: 'get',
    path: '/limit',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

/* ███████████████████████████████ *\
 * ███    PAYMENT DOCUMENTS    ███ *
\* ███████████████████████████████ */

export const uploadPaymentDocumentRequest = async (file) => {
  return await api({
    method: 'post',
    path: '/payment-document',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: file
  });
};

export const getPaymentDocumentsRequest = async (customerNumber) => {
  return await api({
    method: 'get',
    path: `/payment-document/${customerNumber}/list`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const downloadPaymentTransactionDocumentRequest = async (documentId) => {
  const res = await api({
    method: 'get',
    path: `/payment-document/${documentId}/download`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob'
  });

  downloadFile(res);
};

export const downloadAllCustomerMonitoringDocumentsRequest = async (customerNumber) => {
  const res = await api({
    method: 'get',
    path: `/payment-document/${customerNumber}/download-all`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob'
  });

  downloadFile(res);
};

export const attachPaymentTransactionDocumentRequest = async (transactionNumber, file) => {
  return await api({
    method: 'post',
    path: `/payment-document/${transactionNumber}/attach`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: file
  });
};

export const deletePaymentTransactionDocumentRequest = async (documentId) => {
  return await api({
    method: 'delete',
    path: `/payment-document/${documentId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const updatePaymentDocumentDetailsRequest = async (documentId, data) => {
  return await api({
    method: 'post',
    path: `/payment-document/${documentId}/update`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

/* ████████████████████████████████████████ *\
 * ███ API REQUESTS FOR ADMINS MANAGING ███ *
\* ████████████████████████████████████████ */

export const getAdminsListRequest = async (searchText) => {
  return await api({
    method: 'get',
    path: '/admins/list',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query: {
      search_text: searchText
    }
  });
};

export const getEmailStatisticsRequest = async (pagination, sortBy, direction, filters) => {
  const query = {
    page: pagination.page,
    size: pagination.size,
    sort_direction: direction,
    searchText: filters.searchText
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (filters.fromDate) {
    query.fromDate = filters.fromDate;
  }
  if (filters.toDate) {
    query.toDate = filters.toDate;
  }

  return await api({
    method: 'get',
    path: '/email-statistic/list',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });
};

/* █████████████ *\
 * ███ NOTES ███ *
\* █████████████ */

export const getCustomerNotesRequest = async (customerNumber) => {
  return await api({
    method: 'get',
    path: `/note/account/${customerNumber}/list`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const getCustomerAmlNotesRequest = async (customerNumber) => {
  return await api({
    method: 'get',
    path: `/note/account/${customerNumber}/aml/list`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const getTransactionNotesRequest = async (transactionNumber) => {
  return await api({
    method: 'get',
    path: `/note/transaction/${transactionNumber}/list`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const getCustomerTransactionsNotesRequest = async (customerNumber, pagination) => {
  return await api({
    method: 'get',
    path: `/note/account/${customerNumber}/transactions/list`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query: { ...pagination }
  });
};

export const addCustomerNoteRequest = async (customerNumber, note, assigneeId, documents) => {
  return await addNote(`/note/${customerNumber}/add`, { note, assigneeId }, documents);
};

export const addTransactionNoteRequest = async (customerNumber, transactionNumber, note, assigneeId, documents) => {
  return await addNote(`/note/${customerNumber}/transaction/add`, { note, transactionNumber, assigneeId }, documents);
};

export const addAmlNoteRequest = async (customerNumber, note, assigneeId, documents) => {
  return await addNote(`/note/${customerNumber}/aml/add`, { note, assigneeId }, documents);
};

const addNote = (path, dto, documents) => {
  const data = new FormData();
  data.append(
    'dto',
    new Blob([JSON.stringify(dto)], {
      type: 'application/json'
    })
  );

  for (let i = 0; i < documents.length; i++) {
    data.append('documents', documents[i]);
  }

  return api({
    method: 'post',
    path,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

export const deleteNoteRequest = async (noteId) => {
  return await api({
    method: 'delete',
    path: `/note/${noteId}/delete`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const downloadNoteDocumentRequest = async (documentId) => {
  const res = await api({
    method: 'get',
    path: `/note/document/${documentId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob'
  });

  downloadFile(res);
};

/* █████████████████████████████████████ *\
 * ███ API REQUESTS FOR TRANSACTIONS ███ *
\* █████████████████████████████████████ */

export const getTransactionsListRequest = async (pagination, sortBy, direction, searchText, filters) => {
  const query = {
    page: pagination.page,
    size: pagination.size,
    sort_direction: direction,
    search_text: searchText,
    transfer_providers: filters.transfer_providers,
    types: filters.types,
    statuses: filters.statuses,
    currencies: filters.currencies,
    payment_methods: filters.payment_methods,
    transfer_types: filters.transfer_types,
    document_statuses: filters.document_statuses
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (filters.from_date) {
    query.from_date = filters.from_date;
  }
  if (filters.to_date) {
    query.to_date = filters.to_date;
  }

  return await api({
    method: 'get',
    path: '/transaction',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });
};

export const exportTransactionListRequest = async (filters) => {
  const res = await api({
    method: 'get',
    path: '/transaction/export/csv',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query: { ...filters }
  });

  downloadFile(res);
};

export const exportIfxTransactionListRequest = async (filters) => {
  const res = await api({
    method: 'get',
    path: '/transaction/export/ifx/csv',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query: { ...filters }
  });

  downloadFile(res);
};

export const getTransactionRequest = async (transactionNumber) => {
  return await api({
    method: 'get',
    path: `/transaction/${transactionNumber}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const acceptOnHoldTransactionRequest = async (transactionNumber) => {
  return await api({
    method: 'get',
    path: `/transaction/${transactionNumber}/hold/accept`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const rejectTransactionRequest = async (transactionNumber) => {
  return await api({
    method: 'get',
    path: `/transaction/${transactionNumber}/reject`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const returnTransactionRequest = async (transactionNumber) => {
  return await api({
    method: 'get',
    path: `/transaction/${transactionNumber}/return`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const acceptProcessingTransactionRequest = async (transactionNumber) => {
  return await api({
    method: 'get',
    path: `/transaction/${transactionNumber}/processing/accept`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const changeTransactionDocumentStatusRequest = async (transactionNumber, status) => {
  return await api({
    method: 'get',
    path: `/transaction/${transactionNumber}/document/status/${status}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const changeOnHoldStatusRequest = async (transactionNumber, holdStatus, type) => {
  return await api({
    method: 'post',
    path: `/transaction/${type.toLowerCase()}/${transactionNumber}/hold/${holdStatus}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const updateTransactionMlroApprovalRequest = async (transactionNumber, approvalStatus) => {
  return await api({
    method: 'get',
    path: `/transaction/${transactionNumber}/mlro_approval/${approvalStatus}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

/* █████████████████████████████████████████████ *\
 * ███ API REQUESTS FOR TRANSIT TRANSACTIONS ███ *
\* █████████████████████████████████████████████ */

export const getTransitTransactionsListRequest = async (pagination, sortBy, direction, searchText, filters) => {
  const query = {
    page: pagination.page,
    size: pagination.size,
    sort_direction: direction,
    search_text: searchText,
    transfer_providers: filters.transfer_providers,
    statuses: filters.statuses,
    currencies: filters.currencies,
    direction: filters.direction
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (filters.from) {
    query.from = filters.from;
  }
  if (filters.to) {
    query.to = filters.to;
  }

  return await api({
    method: 'get',
    path: '/transit-transaction/transit-account/list',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });
};

export const getCommissionSystemTransactionsListRequest = async (pagination, sortBy, direction, filters) => {
  const query = {
    page: pagination.page,
    size: pagination.size,
    sort_direction: direction,
    transfer_providers: filters.transfer_providers
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (filters.from) {
    query.from = filters.from;
  }
  if (filters.to) {
    query.to = filters.to;
  }
  if (filters.currencies) {
    query.currencies = filters.currencies;
  }

  return await api({
    method: 'get',
    path: '/transit-transaction/commission-account/list',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });
};

export const repeatTransitTransactionRequest = async (transactionId) => {
  return await api({
    method: 'get',
    path: `/transit-transaction/${transactionId}/repeat`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const returnTransitTransactionRequest = async (transactionId) => {
  return await api({
    method: 'get',
    path: `/transit-transaction/${transactionId}/return`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const deleteTransitTransactionRequest = async (transactionId) => {
  return await api({
    method: 'get',
    path: `/transit-transaction/${transactionId}/delete`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const createTransitTransactionRequest = async (data) => {
  return await api({
    method: 'post',
    path: '/transit-transaction/create',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

export const loadTransitTransactionsCsvReportRequest = async (pagination, sortBy, direction, searchText, filters) => {
  const query = {
    page: pagination.page,
    size: pagination.size,
    sort_direction: direction,
    search_text: searchText,
    transfer_providers: filters.transfer_providers,
    statuses: filters.statuses,
    currencies: filters.currencies,
    direction: filters.direction
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (filters.from) {
    query.from = filters.from;
  }
  if (filters.to) {
    query.to = filters.to;
  }

  const res = await api({
    method: 'get',
    path: '/transit-transaction/transit-account/report/csv',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query: { ...pagination, ...filters }
  });

  downloadFile(res);
};

export const loadCommissionTransactionsCsvReportRequest = async (pagination, sortBy, direction, filters) => {
  const query = {
    page: pagination.page,
    size: pagination.size,
    transfer_providers: filters.transfer_providers,
    currencies: filters.currencies
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (filters.from) {
    query.from = filters.from;
  }
  if (filters.to) {
    query.to = filters.to;
  }

  const res = await api({
    method: 'get',
    path: '/transit-transaction/commission-account/report/csv',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query
  });

  downloadFile(res);
};

export const loadPeriodTransitTransactionCsvReportRequest = async (periodDate) => {
  const res = await api({
    method: 'get',
    path: '/transit-transaction/period-report/csv',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query: { date: periodDate }
  });

  downloadFile(res);
};

/* ████████████████████████████████████████████████ *\
 * ███ API REQUESTS FOR COMMISSION TRANSACTIONS ███ *
\* ████████████████████████████████████████████████ */

export const getCommissionTransactionsRequest = async (pagination, sortBy, direction, searchText, filters) => {
  const query = {
    page: pagination.page,
    size: pagination.size,
    sort_direction: direction,
    transfer_providers: filters.transferProviders,
    types: filters.types,
    currencies: filters.currencies
  };

  if (searchText) {
    query.search_text = searchText;
  }
  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (filters.from) {
    query.from = filters.from;
  }
  if (filters.to) {
    query.to = filters.to;
  }

  return await api({
    method: 'get',
    path: '/commission-transaction/list',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });
};

export const createCommissionReturnTransactionRequest = async (data) => {
  return await api({
    method: 'post',
    path: '/commission-transaction/return/create',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

export const createCommissionWithdrawTransactionRequest = async (data) => {
  return await api({
    method: 'post',
    path: '/commission-transaction/withdraw/create',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

export const createCommissionTransactionRequest = async (data) => {
  return await api({
    method: 'post',
    path: '/commission-transaction/commission/create',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

export const downloadCommissionTransactionsReportCsvRequest = async (searchText, filters) => {
  const query = {
    transfer_providers: filters.transferProviders,
    types: filters.types,
    currencies: filters.currencies
  };

  if (searchText) {
    query.search_text = searchText;
  }
  if (filters.from) {
    query.from = filters.from;
  }
  if (filters.to) {
    query.to = filters.to;
  }

  const res = await api({
    method: 'get',
    path: '/commission-transaction/report/csv',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob',
    query
  });

  downloadFile(res);
};

/* ███████████████████████████████████████████████ *\
 * ███ API REQUESTS FOR CREATE NEW TRANSACTION ███ *
\* ███████████████████████████████████████████████ */

export const createOutgoingTransactionRequest = async (data) => {
  return await api({
    method: 'post',
    path: '/transaction/outgoing/create',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

export const createIncomingTransactionRequest = async (data) => {
  return await api({
    method: 'post',
    path: '/transaction/incoming/create',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

/* ████████████████████████████████████████████ *\
 * ███ API REQUESTS FOR PAYMENT OBLIGATIONS ███ *
\* ████████████████████████████████████████████ */

export const getPaymentObligationsListRequest = async (pagination, sortBy, direction, searchText, filters) => {
  const query = {
    page: pagination.page,
    size: pagination.size,
    sort_direction: direction,
    search_text: searchText,
    statuses: filters.statuses,
    currencies: filters.currencies
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (filters.from_date) {
    query.from_date = filters.from_date;
  }
  if (filters.to_date) {
    query.to_date = filters.to_date;
  }

  return await api({
    method: 'get',
    path: '/payment-obligation/list',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });
};

export const releasePaymentObligationRequest = async (paymentObligationId) => {
  return await api({
    method: 'get',
    path: `/payment-obligation/${paymentObligationId}/release`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const chargePaymentObligationRequest = async (paymentObligationId) => {
  return await api({
    method: 'get',
    path: `/payment-obligation/${paymentObligationId}/charge`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

/* ███████████████████████████████████████ *\
 * ███      CARD AUTHORIZATIONS        ███ *
\* ███████████████████████████████████████ */

export const getCardAuthorizationsRequest = async (pagination, sortBy, direction, searchText, filters) => {
  const query = {
    page: pagination.page,
    size: pagination.size,
    sort_direction: direction,
    search_text: searchText,
    statuses: filters.statuses
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (filters.from_date) {
    query.from_date = filters.from_date;
  }
  if (filters.to_date) {
    query.to_date = filters.to_date;
  }

  return await api({
    method: 'get',
    path: '/card/authorization/list',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });
};

export const releaseCardAuthorizationRequest = async (cardAuthorizationId) => {
  return await api({
    method: 'get',
    path: `/card/authorization/${cardAuthorizationId}/release`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

/* ████████████████████████████████ *\
 * ███    CUSTOMER DOCUMENTS    ███ *
\* ████████████████████████████████ */

export const getCustomerDocumentsRequest = async (customerNumber) => {
  return await api({
    method: 'get',
    path: `/document/account/${customerNumber}/list`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const uploadCustomerDocumentRequest = async (customerNumber, data) => {
  return await api({
    method: 'post',
    path: `/document/account/${customerNumber}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

export const updateCustomerDocumentRequest = async (customerNumber, documentId, data) => {
  return await api({
    method: 'post',
    path: `/document/account/${customerNumber}/${documentId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

export const deleteCustomerDocumentRequest = async (customerNumber, documentId) => {
  return await api({
    method: 'delete',
    path: `/document/account/${customerNumber}/${documentId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const downloadDocumentByDocumentIdRequest = async (customerNumber, documentId) => {
  const res = await api({
    method: 'get',
    path: `/document/account/${customerNumber}/${documentId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob'
  });

  downloadFile(res);
};

/* ██████████████████████████████████ *\
 * ███ API REQUESTS FOR MESSENGER ███ *
\* ██████████████████████████████████ */

export const getTopicsListRequest = async (pagination, sortBy, direction, searchText, unreadOnly, filters) => {
  const query = {
    page: pagination.page,
    size: pagination.size,
    sort_direction: direction,
    timezone: moment.tz.guess()
  };

  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (searchText) {
    query.search_text = searchText;
  }
  if (filters.from) {
    query.from = filters.from;
  }
  if (filters.to) {
    query.to = filters.to;
  }
  if (filters.statuses) {
    query.statuses = filters.statuses;
  }
  if (filters.operator) {
    query.topic_owner = filters.operator?.id;
  }
  if (unreadOnly) {
    query.unread_only = unreadOnly;
  }

  return await api({
    method: 'get',
    path: '/topic/list',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });
};

export const getTopicByIdRequest = async (topicId) => {
  return await api({
    method: 'get',
    path: `/topic/${topicId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const getTopicMessagesRequest = async (topicId, pagination) => {
  const query = {
    page: pagination.page,
    size: pagination.size
  };

  return await api({
    method: 'get',
    path: `/topic/${topicId}/message/list`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });
};

export const downloadTopicDocumentRequest = async (documentId) => {
  const res = await api({
    method: 'get',
    path: `/topic/document/${documentId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    returnFullResponse: true,
    responseType: 'blob'
  });

  downloadFile(res);
};

export const sendTopicDocumentRequest = async (file) => {
  return await api({
    method: 'post',
    path: '/topic/document',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: file
  });
};

export const markTopicAsReadRequest = async (topicId) => {
  return await api({
    method: 'get',
    path: `/topic/${topicId}/read`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const updateTopicOwnerOperatorRequest = async (topicId, ownerOperatorId) => {
  return await api({
    method: 'post',
    path: `/topic/${topicId}/owner_operator/update`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: { ownerOperatorId }
  });
};

export const updateTopicStatusRequest = async (topicId, status) => {
  return await api({
    method: 'post',
    path: `/topic/${topicId}/status/update`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: { status }
  });
};

export const sendTopicMessageRequest = async (topicId, message) => {
  return await api({
    method: 'post',
    path: `/topic/${topicId}/message/create`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: message
  });
};

export const createNewTopicRequest = async (type = '', data) => {
  return await api({
    method: 'post',
    path: `/topic/${type}/create`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data
  });
};

/* █████████████████████████████████████████ *\
 * ███            BLACKLISTS             ███ *
\* █████████████████████████████████████████ */

export const getBlacklistsRequest = async (pagination, sortBy, direction, filters) => {
  const query = {
    page: pagination.page,
    size: pagination.size,
    sort_direction: direction
  };

  if ((filters.status && filters.status !== BLACKLIST_STATUS.ALL) || filters.status === false) {
    query.active = filters.status;
  }
  if (filters.searchText) {
    query.value = filters.searchText;
  }
  if (sortBy) {
    query.sort_column = sortBy;
  }
  if (filters.from) {
    query.from = filters.from;
  }
  if (filters.to) {
    query.to = filters.to;
  }

  return await api({
    method: 'get',
    path: '/blacklist',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    query
  });
};

export const createBlacklistRequest = async (value) => {
  return await api({
    method: 'post',
    path: '/blacklist',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    data: { value }
  });
};

export const updateBlacklistRequest = async (blacklistId, value) => {
  return await api({
    method: 'put',
    path: `/blacklist/${blacklistId}`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false,
    isPutRequestWithData: true,
    data: { value }
  });
};

export const activateBlacklistRequest = async (blacklistId) => {
  return await api({
    method: 'put',
    path: `/blacklist/${blacklistId}/activate`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

export const deactivateBlacklistRequest = async (blacklistId) => {
  return await api({
    method: 'put',
    path: `/blacklist/${blacklistId}/deactivate`,
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth: true,
    isRequestWithBasicAuth: false
  });
};

// export const getAdminAvailablePermissions = () =>
//   request.get(API_HOST + API_URL_PATH_PREFIX + '/admins/roles').use(adminManagementErrorChecker);

// export const getUserRoles = () =>
//   request.get(API_HOST + API_URL_PATH_PREFIX + '/account/roles').use(adminManagementErrorChecker);
