import Table from 'react-bootstrap/Table';
import ConfigurationTableHeadRow from './components/ConfigurationTableHeadRow';
import ConfigurationTableRow from './components/ConfigurationTableRow';
import Loader from 'components/Loader';
import { useApplicationContext } from 'contexts/ApplicationContext';
import './ConfigurationTab.scss';

const ConfigurationTab = () => {
  const { configuration } = useApplicationContext();

  if (!configuration) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'configuration-wrapper'}>
      <Table responsive>
        <thead>
          <ConfigurationTableHeadRow />
        </thead>
        <tbody>
          {configuration?.parameters &&
            configuration?.parameters.map((configurationParameter, index) => (
              <ConfigurationTableRow key={index} type={'list'} configurationParameterData={configurationParameter} />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ConfigurationTab;
