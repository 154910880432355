/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { ROUTE_PATHS } from 'routes/constants';
import { clearLocalStorage } from 'services/authUtils';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import './Login.scss';

const Login = ({ authStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handlePopState = () => {
      if (authStore.isLoginSuccess && location.pathname === ROUTE_PATHS.LOGIN) {
        authStore.resetStore();
        clearLocalStorage();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      username: '',
      password: ''
    },
    initialStatus: { password: true, username: true },
    validationSchema: Yup.object({
      username: Yup.string().nullable(true).required(i18n.getMessage('validation.emptyField')),
      password: Yup.string()
        .nullable(true)
        .min(7, i18n.getMessage('validation.passwordRequirements'))
        .max(30, i18n.getMessage('validation.fieldSize', { amount: '30' }))
        .required(i18n.getMessage('login.error.emptyFiled', { field: 'password' }))
    }),
    onSubmit: async (values) => {
      authStore.sendAuthorizationData(values).then(() => {
        authStore.getUserRoles(true).then(() => {
          const redirectPath = searchParams.get('redirectPath') || ROUTE_PATHS.CUSTOMERS;

          navigate(decodeURIComponent(redirectPath));
        });
      });
    }
  });

  const { values, errors, handleSubmit, handleChange, submitCount } = form;

  return (
    <section className={'authorization-form login'}>
      <h1 className={'authorization-title'}>{i18n.getMessage('login.title')}</h1>
      {/*<Alert type={'warning'} message={storeAPIError && getErrorMessageForAlert(i18n, storeAPIError)} />*/}
      <form action={'#'} id={'send'} onSubmit={handleSubmit} className={'form'}>
        <div className={'form-inputs-wrapper'}>
          <Input
            type={'text'}
            label={i18n.getMessage('login.input.login')}
            name={'username'}
            autoComplete={'off'}
            value={values.username}
            onChange={handleChange}
            initialStatus={submitCount}
            error={errors?.username}
            // isApiError={!!storeAPIError}
          />
          <Input
            type={'password'}
            name={'password'}
            label={i18n.getMessage('login.input.password')}
            placeholder={i18n.getMessage('login.placeholder.password')}
            autoComplete={'off'}
            value={values.password}
            onChange={handleChange}
            initialStatus={submitCount}
            error={errors?.password}
            // isApiError={!!storeAPIError}
          />
        </div>
        <Button
          type={'primary'}
          roleType={'submit'}
          size={'large'}
          fullWidth={true}
          onClick={() => {}}
          isDisabled={authStore.isLoading}
        >
          {authStore.isLoading ? <Loader /> : i18n.getMessage('login.button')}
        </Button>
      </form>
    </section>
  );
};

Login.propTypes = {
  authStore: MobXPropTypes.observableObject
};

export default inject('authStore')(observer(Login));
