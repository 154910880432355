import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './MonitoringTabHeadRow.scss';

export const MONITORING_TABLE_HEADER = [
  {
    label: 'transactionId'
  },
  {
    label: 'counterpartyName'
  },
  {
    label: 'document'
  }
];

export const MonitoringTabHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {MONITORING_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'monitoring-table-head'} width={head.width}>
          <div className={'monitoring-table-head-block'}>
            <span>{i18n.getMessage(`monitoringTab.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};
