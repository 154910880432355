import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { classNames } from 'uikit/utils';
import './CustomAsyncSelect.scss';

const CustomAsyncSelect = ({
  isRequired,
  isAutoFocus,
  onChange,
  onBlur,
  loadList,
  handleInputChange,
  error,
  className,
  noOptionsMessage,
  loadingMessage,
  placeholder,
  name,
  label
}) => {
  const operatorsSelectClasses = classNames({
    'async-select-wrapper': true,
    [className]: !!className,
    error: !!error
  });

  return (
    <div className={operatorsSelectClasses}>
      {label && <label htmlFor={name}>{label + (isRequired ? '*' : '')}</label>}
      <AsyncSelect
        autoFocus={isAutoFocus}
        className={'async-select'}
        id={'async-select'}
        name={name}
        isMulti={false}
        cacheOptions
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 9999 })
        }}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        loadOptions={loadList}
        onInputChange={handleInputChange}
      />
      {error && <p className={'input-sub-text input-error'}>{error}</p>}
    </div>
  );
};

CustomAsyncSelect.propTypes = {
  isRequired: PropTypes.bool,
  isAutoFocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  loadList: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  className: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  loadingMessage: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string
};

export default CustomAsyncSelect;
