import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './CommissionAccountsHeadRow.scss';

export const COMMISSION_ACCOUNTS_TABLE_HEADER = [
  {
    label: 'accountNumber'
  },
  {
    label: 'paymentProvider'
  },
  {
    label: 'amount'
  },
  {
    label: 'currency'
  }
];

const CommissionAccountsHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {COMMISSION_ACCOUNTS_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'commission-accounts-table-head'}>
          <div className={'commission-accounts-table-head-block'}>
            <span>{i18n.getMessage(`commissions.commissionAccounts.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};

export default CommissionAccountsHeadRow;
