export const toReturnDto = (data) => ({
  systemAccountNumber: data.systemAccount.account_number,
  amount: Number(data.amount),
  walletNumber: data.recipientAccount.wallet_number,
  comment: data.comment
});

export const toWithdrawDto = (data) => ({
  systemAccountNumber: data.systemAccount.account_number,
  amount: Number(data.amount),
  comment: data.comment
});

export const toCommissionDto = (data) => ({
  walletNumber: data.recipientAccount.wallet_number,
  amount: Number(data.amount),
  comment: data.comment
});
