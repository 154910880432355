import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { POP_UP_TYPE, USER_STATUS } from 'components/constants';
import Loader from 'components/Loader';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import { PopErrorScheme } from 'components/PopUpScheme/PopErrorScheme';
import { PopSuccessScheme } from 'components/PopUpScheme/PopSuccessScheme';
import { hasRole, USER_DETAILS_UPDATE, RESET_PASSWORD_USER_UPDATE } from 'services/roles';
import { formatDateTime } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { PopUp } from 'uikit/PopUp/PopUp';
import './UserDetailsTable.scss';

const popUpInitialData = {
  isShowPopUp: false,
  popUpType: null,
  title: '',
  description: '',
  confirmButtonLabel: '',
  cancelButtonLabel: '',
  onConfirm: null,
  onCancel: null
};

const UserDetailsTable = ({
  customersStore,
  usersStore,
  customerNumber,
  userInfo,
  isInitialized,
  isUserDeletionAvailable,
  isCustomerDetails
}) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [phone, setPhone] = useState(userInfo?.phone ? userInfo.phone : '');
  const [status, setStatus] = useState(userInfo?.status ? userInfo.status : '');
  const [popUpData, setPopUpData] = useState(popUpInitialData);

  useEffect(() => {
    if (userInfo?.phone) {
      setPhone(userInfo?.phone);
    }
  }, [userInfo?.phone]);

  useEffect(() => {
    if (userInfo?.status) {
      setStatus(userInfo?.status);
    }
  }, [userInfo?.status]);

  const resetPopUpData = () => setPopUpData(popUpInitialData);

  const onConfirmUserDelete = () => {
    usersStore.deleteUser(userInfo.id).then(() => {
      if (usersStore.error) {
        setPopUpData({
          isShowPopUp: true,
          popUpType: POP_UP_TYPE.ERROR,
          title: i18n.getMessage('user.details.popUp.error'),
          description:
            usersStore.error?.message && usersStore.error?.message !== 'Error'
              ? usersStore.error?.message
              : usersStore.error?.code
                ? usersStore.error?.code
                : '',
          confirmButtonLabel: '',
          cancelButtonLabel: i18n.getMessage('user.details.popUp.confirm.button.ok'),
          onCancel: resetPopUpData
        });
      } else {
        setPopUpData({
          isShowPopUp: true,
          popUpType: POP_UP_TYPE.SUCCESS,
          title: i18n.getMessage('user.details.popUp.successful.userDelete'),
          description: '',
          confirmButtonLabel: '',
          cancelButtonLabel: i18n.getMessage('user.details.popUp.confirm.button.ok'),
          onCancel: () => {
            resetPopUpData();
            navigate(ROUTE_PATHS.USERS);
          }
        });
      }
    });
  };

  const handleUserDeleteButtonClick = () => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('user.details.popUp.confirm.userDelete.title'),
      description: i18n.getMessage('user.details.popUp.confirm.userDelete.description'),
      confirmButtonLabel: i18n.getMessage('user.details.popUp.confirm.button.confirm'),
      cancelButtonLabel: i18n.getMessage('user.details.popUp.confirm.button.cancel'),
      onConfirm: onConfirmUserDelete,
      onCancel: resetPopUpData
    });
  };

  const handleChangeUserPhone = ({ target: { name, value } }) => {
    setPhone(value);
  };

  const handleChangeUserStatus = (name, value) => {
    setStatus(value);
  };

  const handleSaveUserPhoneButtonClick = () => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.CONFIRMATION,
      title: i18n.getMessage('user.details.popUp.confirm.updateUserPhone.title'),
      description: i18n.getMessage('user.details.popUp.confirm.updateUserPhone.description'),
      confirmButtonLabel: i18n.getMessage('user.details.popUp.confirm.button.confirm'),
      cancelButtonLabel: i18n.getMessage('user.details.popUp.confirm.button.cancel'),
      onConfirm: handleSaveUserPhone,
      onCancel: resetPopUpData
    });
  };

  const handleSaveUserPhone = () => {
    usersStore.updateUserPhone(userInfo.id, phone).then(() => {
      if (usersStore.error) {
        resetPopUpData();
      } else {
        setPopUpData({
          isShowPopUp: true,
          popUpType: POP_UP_TYPE.SUCCESS,
          title: i18n.getMessage('user.details.popUp.successful.updateUserPhone'),
          description: '',
          confirmButtonLabel: '',
          cancelButtonLabel: i18n.getMessage('user.details.popUp.confirm.button.ok'),
          onCancel: resetPopUpData
        });
      }
    });
  };

  const statusOptions = Object.values(USER_STATUS).map((value) => ({
    key: value,
    value: value
  }));

  const handleSaveUserStatus = async () => {
    if (status === userInfo.status) {
      return;
    }

    if (isCustomerDetails) {
      switch (status) {
        case USER_STATUS.ACTIVE:
          await customersStore.activateUser(customerNumber);
          break;
        case USER_STATUS.ADMINISTRATE:
          await customersStore.administrateUser(customerNumber);
          break;
        case USER_STATUS.BLOCKED:
          await customersStore.blockUser(customerNumber);
          break;
      }
    } else {
      switch (status) {
        case USER_STATUS.ACTIVE:
          await usersStore.activateUser(customerNumber);
          break;
        case USER_STATUS.ADMINISTRATE:
          await usersStore.administrateUser(customerNumber);
          break;
        case USER_STATUS.BLOCKED:
          await usersStore.blockUser(customerNumber);
          break;
      }
    }
  };

  if (!isInitialized) {
    return <Loader className={'application-loader user-details-loader'} />;
  }

  return (
    <div className={'user-details-table'}>
      {isUserDeletionAvailable && (
        <div className={'delete-user-wrapper'}>
          <Button
            className={'delete-user-button'}
            size={'medium'}
            type={'outline'}
            onClick={handleUserDeleteButtonClick}
          >
            {i18n.getMessage('user.details.button.delete')}
          </Button>
        </div>
      )}

      <table className={'table-wrapper'}>
        <tbody className={'table-body'}>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('user.details.registrationDate')}</td>
            <td className={'table-cell'}>{formatDateTime(userInfo.registration_date)}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('user.details.registrationIp')}</td>
            <td className={'table-cell'}>{userInfo.registration_ip}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('user.details.userId')}</td>
            <td className={'table-cell'}>{userInfo.id}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('user.details.lastLoginDate')}</td>
            <td className={'table-cell'}>
              {userInfo.last_login_date ? formatDateTime(userInfo.last_login_date) : null}
            </td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('user.details.phoneNumber')}</td>
            <td className={'table-cell'}>
              <div className={'user-details-table-input-wrapper'}>
                <Input className={'table-cell-input'} name={'phone'} value={phone} onChange={handleChangeUserPhone} />
                <Button
                  className={'user-password-actions-button'}
                  size={'small'}
                  type={'primary'}
                  onClick={handleSaveUserPhoneButtonClick}
                >
                  {i18n.getMessage('user.details.button.save')}
                </Button>
              </div>
            </td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('user.details.userStatus')}</td>
            <td className={'table-cell'}>
              <div className={'user-details-table-input-wrapper'}>
                <InputDropDown
                  className={'table-cell-input'}
                  options={statusOptions}
                  value={status}
                  onChange={handleChangeUserStatus}
                  isDisabled={!hasRole(USER_DETAILS_UPDATE) || !customerNumber}
                />
                <Button
                  className={'user-password-actions-button'}
                  size={'small'}
                  type={'primary'}
                  onClick={handleSaveUserStatus}
                  isDisabled={!hasRole(USER_DETAILS_UPDATE) || !customerNumber}
                >
                  {i18n.getMessage('user.details.button.save')}
                </Button>
              </div>
            </td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('user.details.utmFields')}</td>
            <td className={'table-cell'}>
              {userInfo.utm_tags
                ? Object.keys(userInfo.utm_tags).map((key, index) => (
                    <div key={index}>
                      {key} {userInfo.utm_tags[key]}
                    </div>
                  ))
                : null}
            </td>
          </tr>
        </tbody>
      </table>
      <div className={'user-password-actions-wrapper'}>
        <Button
          className={'user-tab-actions-button'}
          size={'medium'}
          type={'primary'}
          onClick={handleUserDeleteButtonClick}
          // isDisabled={!hasRole(RESET_PASSWORD_USER_UPDATE) || !customerNumber}
          isDisabled={true}
        >
          {i18n.getMessage('user.details.button.resetPassword')}
        </Button>
        <Button
          className={'user-tab-actions-button'}
          size={'medium'}
          type={'primary'}
          onClick={handleUserDeleteButtonClick}
          // isDisabled={!hasRole(USER_DETAILS_UPDATE) || !customerNumber}
          isDisabled={true}
        >
          {i18n.getMessage('user.details.button.sendPassword')}
        </Button>
      </div>
      <PopUp alignOnCenter show={popUpData.isShowPopUp}>
        {popUpData.popUpType === POP_UP_TYPE.CONFIRMATION ? (
          <PopConfirmationScheme
            title={popUpData.title}
            description={popUpData.description}
            onConfirmButtonTitle={popUpData.confirmButtonLabel}
            onCloseButtonTitle={popUpData.cancelButtonLabel}
            onConfirm={popUpData.onConfirm}
            onClose={popUpData.onCancel}
          />
        ) : popUpData.popUpType === POP_UP_TYPE.ERROR ? (
          <PopErrorScheme
            title={popUpData.title}
            description={popUpData.description}
            onCloseButtonTitle={popUpData.cancelButtonLabel}
            onClose={popUpData.onCancel}
          />
        ) : (
          <PopSuccessScheme
            title={popUpData.title}
            description={popUpData.description}
            onCloseButtonTitle={popUpData.cancelButtonLabel}
            onClose={popUpData.onCancel}
          />
        )}
      </PopUp>
    </div>
  );
};

UserDetailsTable.propTypes = {
  customerNumber: PropTypes.string,
  userInfo: PropTypes.object,
  isInitialized: PropTypes.bool,
  isUserDeletionAvailable: PropTypes.bool,
  isCustomerDetails: PropTypes.bool,
  customersStore: MobXPropTypes.observableObject,
  usersStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customersStore: stores.customersStore,
  usersStore: stores.usersStore
}))(observer(UserDetailsTable));
