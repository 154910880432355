import PropTypes from 'prop-types';
import { ReactComponent as SuccessIcon } from 'assets/success-icon.svg';
import Button from 'uikit/Button/Button';

export const PopSuccessScheme = ({ title, description, onCloseButtonTitle, onClose }) => {
  return (
    <div>
      <div className={'scheme-wrapper pop-up-logOut'}>
        <SuccessIcon className={'icon-hit'} />
        {title && <h2 className={'scheme-success-header'}>{title}</h2>}
        {description && <p className={'scheme-success-message'}>{description}</p>}
        <div className={'scheme-wrapper-buttons'}>
          <Button className={'scheme-button scheme-success-button'} size={'large'} onClick={onClose}>
            {onCloseButtonTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

PopSuccessScheme.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onCloseButtonTitle: PropTypes.string,
  onClose: PropTypes.func
};
