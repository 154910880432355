import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import {
  createCommissionReturnTransactionRequest,
  createCommissionTransactionRequest,
  createCommissionWithdrawTransactionRequest,
  downloadCommissionTransactionsReportCsvRequest,
  exportSystemAccountsBalancesReport,
  getCommissionTransactionsRequest,
  getSystemAccountsRequest
} from 'services/requestAgent';
import { DIRECTION, SYSTEM_ACCOUNT_TYPE } from '../components/constants';
import { convertDate, convertToFullDate } from '../services/utils';

class CommissionTransactionsStore {
  isInitialized = false;
  isLoading = false;
  isNewCommissionTransactionCreated = false;
  isLoadingCommissionBalances = false;
  isLoadingCommissionTransactions = false;
  error = null;
  commissionAccounts = null;
  commissionTransactionList = [];
  totalElements = null;
  sortColumn = {
    sort_column: 'created_at',
    sort_direction: DIRECTION.DESC
  };
  pagination = {
    size: 20,
    page: 0,
    totalPages: null
  };
  filters = {
    search_text: null,
    from: null,
    to: null,
    types: [],
    currencies: [],
    transferProviders: []
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetCommissionTransactionStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.isNewCommissionTransactionCreated = false;
    this.isLoadingCommissionBalances = false;
    this.isLoadingCommissionTransactions = false;
    this.error = null;
    this.commissionAccounts = false;
    this.commissionTransactionList = [];
    this.sortColumn = {
      sort_column: 'created_at',
      sort_direction: DIRECTION.DESC
    };
    this.pagination = {
      size: 20,
      page: 0,
      totalPages: null
    };
    this.filters = {
      search_text: null,
      from: null,
      to: null,
      types: [],
      currencies: [],
      transferProviders: []
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsNewCommissionTransactionCreated = (status) => {
    this.isNewCommissionTransactionCreated = status;
  };

  setIsLoadingCommissionBalances = (status) => {
    this.isLoadingCommissionBalances = status;
  };

  setIsLoadingCommissionTransactions = (status) => {
    this.isLoadingCommissionTransactions = status;
  };

  setPageNumber = (page) => {
    this.pagination.page = page;
  };

  setPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setSortData = (sortData) => {
    this.sortColumn.sort_column = sortData.sortBy;
    this.sortColumn.sort_direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    const filters = { ...this.filters };
    const pagination = { ...this.pagination };

    // Mapping URL filter parameters to corresponding properties in the 'filters' object
    const filterParamsMapping = {
      search_text: 'search_text',
      from: 'from',
      to: 'to',
      transferProviders: 'transferProviders',
      types: 'types',
      currencies: 'currencies'
    };

    // Iterating over each URL parameter and assigning its values to 'filters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        let value = params[param];

        // Convert `from` and `to` if they exist
        if (param === 'from' || param === 'to') {
          value = convertToFullDate(value);
        }

        // Handle only array values as arrays, keep others as their original types
        if (Array.isArray(value)) {
          filters[filterParamsMapping[param]] = value;
        } else {
          filters[filterParamsMapping[param]] = value;
        }
      }
    }

    // Assigning pagination parameters from the URL
    pagination.page = parseInt(params.page) || pagination.page;
    pagination.size = parseInt(params.size) || pagination.size;

    // Assigning sorting parameters from the URL
    this.sortColumn.sort_column = params.sort_column || this.sortColumn.sort_column;
    this.sortColumn.sort_direction = params.sort_direction || this.sortColumn.sort_direction;

    // Updating the state of filters and pagination
    this.filters = filters;
    this.pagination = pagination;
  };

  prepareFiltersParams = () => {
    const params = { ...this.filters };

    if (this.filters.from) {
      params.from = convertDate(this.filters.from);
    } else {
      delete params.from;
    }

    if (this.filters.to) {
      params.to = convertDate(this.filters.to);
    } else {
      delete params.to;
    }

    return params;
  };

  getCommissionTransactionsList = async () => {
    this.setIsLoading(true);
    try {
      const filtersParams = this.prepareFiltersParams();

      const res = await getCommissionTransactionsRequest(
        this.pagination,
        this.sortColumn.sort_column,
        this.sortColumn.sort_direction,
        filtersParams.search_text,
        filtersParams
      );

      runInAction(() => {
        this.isInitialized = true;
        this.commissionTransactionList = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  downloadCommissionTransactionsReportCsv = async () => {
    this.setIsLoadingCommissionTransactions(true);

    const filtersParams = this.prepareFiltersParams();

    const toastPromise = toast.promise(
      downloadCommissionTransactionsReportCsvRequest(filtersParams.search_text, filtersParams),
      {
        pending: i18n.getMessage(
          'commissionTransactionsStore.downloadCommissionTransactionsReportCsv.toastPromise.pending'
        ),
        success: i18n.getMessage(
          'commissionTransactionsStore.downloadCommissionTransactionsReportCsv.toastPromise.success'
        )
      }
    );

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingCommissionTransactions(false);
    }
  };

  getCommissionAccounts = async () => {
    this.setIsLoading(true);
    try {
      const commissionAccounts = await getSystemAccountsRequest(null, SYSTEM_ACCOUNT_TYPE.COMMISSION);

      runInAction(() => {
        this.commissionAccounts = commissionAccounts;
        this.isInitialized = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleExportCommissionBalances = async () => {
    this.setIsLoadingCommissionBalances(true);

    const toastPromise = toast.promise(exportSystemAccountsBalancesReport(SYSTEM_ACCOUNT_TYPE.COMMISSION), {
      pending: i18n.getMessage('commissionTransactionsStore.handleExportCommissionBalances.toastPromise.pending'),
      success: i18n.getMessage('commissionTransactionsStore.handleExportCommissionBalances.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingCommissionBalances(false);
    }
  };

  createCommissionReturnTransaction = async (data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createCommissionReturnTransactionRequest(data), {
      pending: i18n.getMessage('commissionTransactionsStore.createCommissionReturnTransaction.toastPromise.pending'),
      success: i18n.getMessage('commissionTransactionsStore.createCommissionReturnTransaction.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.isNewCommissionTransactionCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  createCommissionWithdrawTransaction = async (data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createCommissionWithdrawTransactionRequest(data), {
      pending: i18n.getMessage('commissionTransactionsStore.createCommissionWithdrawTransaction.toastPromise.pending'),
      success: i18n.getMessage('commissionTransactionsStore.createCommissionWithdrawTransaction.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.isNewCommissionTransactionCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  createCommissionTransaction = async (data) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createCommissionTransactionRequest(data), {
      pending: i18n.getMessage('commissionTransactionsStore.createCommissionTransaction.toastPromise.pending'),
      success: i18n.getMessage('commissionTransactionsStore.createCommissionTransaction.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.isNewCommissionTransactionCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };
}

export default new CommissionTransactionsStore();
