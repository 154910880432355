import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import BackButton from 'components/BackButton';
import { KYC_STATUSES } from 'components/constants';
import { ROUTE_PATHS } from 'routes/constants';

const Customers = ({ customersStore }) => {
  const location = useLocation();
  const [redirectPath, setRedirectPath] = useState(null);

  useEffect(() => {
    if (customersStore?.currentCustomerDetails?.kyc_status) {
      if (customersStore?.currentCustomerDetails?.kyc_status === KYC_STATUSES.VERIFIED) {
        setRedirectPath(ROUTE_PATHS.ACTIVE_CUSTOMERS);
      } else {
        setRedirectPath(ROUTE_PATHS.CUSTOMERS);
      }
    } else {
      setRedirectPath(null);
    }
  }, [customersStore?.currentCustomerDetails?.kyc_status]);

  return (
    <section className={'right-section'}>
      {location.pathname !== ROUTE_PATHS.CUSTOMERS && location.pathname !== ROUTE_PATHS.ACTIVE_CUSTOMERS && (
        <BackButton redirectRoutePath={redirectPath} />
      )}
      <Outlet />
    </section>
  );
};

Customers.propTypes = {
  customersStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customersStore: stores.customersStore
}))(observer(Customers));
