import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './LimitDetailsHeadRow.scss';

export const LIMIT_DETAILS_TABLE_HEADER = [
  {
    label: 'limitType'
  },
  {
    label: 'limitValue'
  },
  {
    label: 'group'
  },
  {
    label: 'createDate'
  },
  {
    label: 'lastUpdate'
  },

  {
    label: 'action'
  }
];

export const LimitDetailsHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {LIMIT_DETAILS_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'limit-table-head'}>
          <div className={'limit-table-head-block'}>
            <span>{i18n.getMessage(`limits.details.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};
