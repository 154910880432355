import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './TopicMessagesTableHead.scss';

export const TOPIC_MESSAGES_TABLE_HEADER = [
  {
    label: 'message'
  }
];

const TopicMessagesTableHead = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {TOPIC_MESSAGES_TABLE_HEADER.map(
        (head) =>
          head.label && (
            <th key={head.label} className={'topic-messages-table-head'}>
              <div className={'topic-messages-table-head-block'}>
                <span>{i18n.getMessage(`messages.topicMessages.table.header.${head.label}`)}</span>
              </div>
            </th>
          )
      )}
    </tr>
  );
};

export default TopicMessagesTableHead;
