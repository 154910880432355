import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'react-bootstrap';
import I18nContext from 'components/i18n-context';
import { formatDate } from 'services/utils';
import Button from 'uikit/Button/Button';
import './DocumentsTableRow.scss';

const DocumentsTableRow = ({
  isLoading,
  documentData,
  handleDownloadDocument,
  handleEditedDocument,
  handleDeleteDocument
}) => {
  const i18n = useContext(I18nContext);

  const isExpired = (expirationDate) => {
    return new Date(expirationDate) < new Date();
  };

  const getCustomerNoteInfoBlock = (documentBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'document-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{documentBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'document-table-cell'}>
        <div className={'document-table-cell-info'}>
          {getCustomerNoteInfoBlock(documentData.author)}
          {getCustomerNoteInfoBlock(
            <i className={'document-table-cell-info-date'}>{formatDate(documentData.updatedDate)}</i>
          )}
        </div>
      </td>
      <td className={'document-table-cell'}>{getCustomerNoteInfoBlock(documentData.note)}</td>
      <td className={'document-table-cell'}>
        {isLoading ? (
          getCustomerNoteInfoBlock()
        ) : (
          /*eslint-disable-next-line*/
          <a
            className={'document-table-cell-link'}
            href={'#'}
            onClick={() => handleDownloadDocument(documentData.documentUid)}
          >
            {documentData.documentName}
          </a>
        )}
      </td>
      <td className={'document-table-cell'}>{getCustomerNoteInfoBlock(formatDate(documentData.notificationDate))}</td>
      <td className={'document-table-cell'}>
        <div className={isExpired(documentData.expirationDate) ? 'document-table-cell-expired' : ''}>
          {getCustomerNoteInfoBlock(formatDate(documentData.expirationDate))}
        </div>
      </td>
      <td className={'document-table-cell'}>
        <div className={'document-table-cell-actions'}>
          <Button
            onClick={() => handleEditedDocument(documentData)}
            type={'outline'}
            className={'document-table-cell-actions-button'}
            isDisabled={isLoading}
          >
            {i18n.getMessage('documents.table.action.update')}
          </Button>
          <Button
            onClick={() => handleDeleteDocument(documentData.documentUid)}
            type={'outline'}
            className={'document-table-cell-actions-button-delete'}
            isDisabled={isLoading}
          >
            {i18n.getMessage('documents.table.action.delete')}
          </Button>
        </div>
      </td>
    </tr>
  );
};

DocumentsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  handleDownloadDocument: PropTypes.func,
  handleEditedDocument: PropTypes.func,
  handleDeleteDocument: PropTypes.func,
  documentData: PropTypes.shape({
    author: PropTypes.string,
    note: PropTypes.string,
    documentName: PropTypes.string,
    documentUid: PropTypes.string,
    updatedDate: PropTypes.string,
    expirationDate: PropTypes.string,
    notificationDate: PropTypes.string
  })
};

export default DocumentsTableRow;
