import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthorizedPerson from './components/AuthorizedPerson';
import Beneficiaries from './components/Beneficiaries';
import Director from './components/Director';
import companyStructureValidator from './companyStructureValidator';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import conversionModel from 'pages/Customers/components/CorporateCustomerDetails/components/CorporateCustomerDetailsModel.js';
import { CORPORATE_ACCOUNT_DETAILS_UPDATE, hasRole } from 'services/roles';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './CompanyStructure.scss';

const CompanyStructure = ({
  isLoading,
  isCompanyStructureDataLoaded,
  companyStructureData,
  getCompanyStructure,
  sendCompanyStructure,
  deleteCompanyStructureMember
}) => {
  const i18n = useContext(i18nContext);
  const [isOpenConfirmationPopUp, setIsOpenConfirmationPopUp] = useState(false);
  const [type, setType] = useState('');
  const [memberAccountNumber, setMemberAccountNumber] = useState('');
  const [memberIndex, setMemberIndex] = useState(null);

  useEffect(() => {
    getCompanyStructure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialFormState = {
    hasAuthorizedPerson: !!companyStructureData?.authorizedPerson,
    authorizedPerson: companyStructureData?.authorizedPerson
      ? conversionModel.fromAuthorizedPersonDto(companyStructureData.authorizedPerson)
      : null,
    beneficiaries:
      companyStructureData?.beneficiaries.length > 0
        ? companyStructureData?.beneficiaries.map((beneficiary) => conversionModel.fromBeneficiaryDto(beneficiary))
        : [],
    directors:
      companyStructureData?.directors.length > 0
        ? companyStructureData?.directors.map((director) => conversionModel.fromBeneficiaryDto(director))
        : []
  };

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: initialFormState,

    validationSchema: Yup.object().shape({
      authorizedPerson: Yup.lazy((value, { parent }) => {
        if (parent.hasAuthorizedPerson) {
          return companyStructureValidator.authorizedPersonValidator;
        }
        return Yup.object().nullable();
      }),
      beneficiaries: Yup.array().of(companyStructureValidator.beneficiaryValidator),
      directors: Yup.array().of(companyStructureValidator.directorValidator)
    }),

    onSubmit: (values) => {
      sendCompanyStructure({
        authorized_person: values.authorizedPerson
          ? conversionModel.toAuthorizedPersonDto(values.authorizedPerson)
          : null,
        beneficiaries: values.beneficiaries.map((beneficiary) => conversionModel.toBeneficiaryDto(beneficiary)),
        directors: values.directors.map((director) => conversionModel.toDirectorDto(director))
      });
    }
  });

  const { values, handleSubmit, setFieldValue, validateForm, errors, handleChange } = form;

  const scrollToError = () => {
    const errorElement = document.querySelector('.error');
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleAddAuthorizedPerson = () => {
    setFieldValue('hasAuthorizedPerson', true);
    setFieldValue('authorizedPerson', conversionModel.fromAuthorizedPersonDto({}));
  };

  const handleAddBeneficiary = () => {
    setFieldValue('beneficiaries', [...values.beneficiaries, conversionModel.fromBeneficiaryDto({})]);
  };

  const handleAddDirector = () => {
    setFieldValue('directors', [...values.directors, conversionModel.fromDirectorDto({})]);
  };

  const handleSave = () => {
    validateForm(values).then(() => {
      scrollToError();
    });
  };

  const handleDeleteAuthorizedPerson = async (memberAccountNumber) => {
    setType('authorizedPerson');
    setMemberAccountNumber(memberAccountNumber);
    setIsOpenConfirmationPopUp(true);
  };

  const handleDeleteBeneficiary = async (memberAccountNumber, index) => {
    setType('beneficiary');
    setMemberAccountNumber(memberAccountNumber);
    setMemberIndex(index);
    setIsOpenConfirmationPopUp(true);
  };

  const handleDeleteDirector = async (memberAccountNumber, index) => {
    setType('director');
    setMemberAccountNumber(memberAccountNumber);
    setMemberIndex(index);
    setIsOpenConfirmationPopUp(true);
  };

  const handleCloseConfirmationPopUp = () => {
    setType('');
    setMemberAccountNumber('');
    setMemberIndex(null);
    setIsOpenConfirmationPopUp(false);
  };

  const handleConfirmDelete = () => {
    if (type === 'authorizedPerson') {
      if (memberAccountNumber) {
        deleteCompanyStructureMember(memberAccountNumber);
      }
      setFieldValue('hasAuthorizedPerson', false);
      setFieldValue('authorizedPerson', null);
    } else if (type === 'beneficiary') {
      if (memberAccountNumber) {
        deleteCompanyStructureMember(memberAccountNumber);
      }
      const updatedBeneficiaries = values.beneficiaries.filter((_, index) => index !== memberIndex);
      setFieldValue('beneficiaries', updatedBeneficiaries);
    } else if (type === 'director') {
      if (memberAccountNumber) {
        deleteCompanyStructureMember(memberAccountNumber);
      }
      const updatedDirectors = values.directors.filter((_, index) => index !== memberIndex);
      setFieldValue('directors', updatedDirectors);
    }

    handleCloseConfirmationPopUp();
  };

  if (!isCompanyStructureDataLoaded) {
    return <Loader className={'application-loader company-structure-tab-loader'} />;
  }

  return (
    <form className={'company-structure-form'} onSubmit={handleSubmit}>
      <PopUp show={isOpenConfirmationPopUp}>
        <PopConfirmationScheme
          title={i18n.getMessage(`companyStructure.${type}.deleteAlert.header`)}
          description={i18n.getMessage(`companyStructure.${type}.deleteAlert.message`)}
          onConfirmButtonTitle={i18n.getMessage('companyStructure.deleteAlert.btn.confirm')}
          onCloseButtonTitle={i18n.getMessage('companyStructure.deleteAlert.btn.cancel')}
          onClose={handleCloseConfirmationPopUp}
          onConfirm={handleConfirmDelete}
        />
      </PopUp>
      <div className={'company-structure-form-buttons'}>
        {hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE) && !values.hasAuthorizedPerson && (
          <Button className={'company-structure-form-buttons-add'} type={'button'} onClick={handleAddAuthorizedPerson}>
            {i18n.getMessage('companyStructure.button.addAuthorizedPerson')}
          </Button>
        )}
        {hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE) && (
          <Button className={'company-structure-form-buttons-add'} type={'button'} onClick={handleAddBeneficiary}>
            {i18n.getMessage('companyStructure.button.addBeneficiary')}
          </Button>
        )}
        {hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE) && (
          <Button className={'company-structure-form-buttons-add'} type={'button'} onClick={handleAddDirector}>
            {i18n.getMessage('companyStructure.button.addDirector')}
          </Button>
        )}
      </div>
      <table className={'table-wrapper'}>
        <tbody className={'table-body'}>
          {values.hasAuthorizedPerson && (
            <AuthorizedPerson
              data={values.authorizedPerson}
              errors={errors?.authorizedPerson}
              handleChange={handleChange}
              propertyPrefix={'authorizedPerson'}
              setFieldValue={setFieldValue}
              handleDelete={() => handleDeleteAuthorizedPerson(values.authorizedPerson.accountNumber)}
            />
          )}
          {values.beneficiaries.map((beneficiary, index) => (
            <Beneficiaries
              key={index}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              errors={errors?.beneficiaries && errors?.beneficiaries[index]}
              propertyPrefix={`beneficiaries[${index}]`}
              data={beneficiary}
              handleDelete={() => handleDeleteBeneficiary(beneficiary.accountNumber, index)}
            />
          ))}
          {values.directors.map((director, index) => (
            <Director
              key={index}
              propertyPrefix={`directors[${index}]`}
              data={values.directors[index]}
              errors={errors.directors && errors?.directors[index]}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleDelete={() => handleDeleteDirector(director.accountNumber, index)}
            />
          ))}
        </tbody>
      </table>
      <div className={'company-structure-form-buttons'}>
        {hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE) &&
          (values.hasAuthorizedPerson || values.beneficiaries.length > 0 || values.directors.length > 0) && (
            <Button
              className={'company-structure-form-buttons-save'}
              roleType={'submit'}
              onClick={handleSave}
              isDisabled={isLoading}
            >
              {isLoading ? <Loader /> : i18n.getMessage('companyStructure.button.save')}
            </Button>
          )}
      </div>
    </form>
  );
};

CompanyStructure.propTypes = {
  isLoading: PropTypes.bool,
  isCompanyStructureDataLoaded: PropTypes.bool,
  companyStructureData: PropTypes.oneOfType([
    PropTypes.shape({
      authorizedPerson: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
      beneficiaries: PropTypes.arrayOf(PropTypes.object),
      directors: PropTypes.arrayOf(PropTypes.object)
    }),
    PropTypes.oneOf([null])
  ]),
  getCompanyStructure: PropTypes.func,
  sendCompanyStructure: PropTypes.func,
  deleteCompanyStructureMember: PropTypes.func
};

export default CompanyStructure;
