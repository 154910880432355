import { useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';
import './ProvidersTableContentRow.scss';

export const ProvidersTableContentRow = ({ isLoading, providerName, providerDetails }) => {
  const i18n = useContext(i18nContext);

  const getProviderInfoBlock = (providerBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'provider-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{providerBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'provider-table-cell'}>
        {getProviderInfoBlock(i18n.getMessage(`paymentProvider.${providerName}`))}
      </td>
      <td className={'provider-table-cell'}>
        {getProviderInfoBlock(i18n.getMessage(`paymentProvider.status.${providerDetails.providerLinkStatus}`))}
      </td>
      <td className={'provider-table-cell'}>{getProviderInfoBlock(providerDetails.details)}</td>
      <td className={'provider-table-cell'}>{getProviderInfoBlock(providerDetails.externalReference)}</td>
      <td className={'provider-table-cell'}>
        <div className={'provider-table-cell-actions disabled'}>
          <Button onClick={() => {}} type={'primary'} size={'small'} isDisabled={true}>
            {i18n.getMessage(`providersTab.table.action.delete`)}
          </Button>
        </div>
      </td>
    </tr>
  );
};

ProvidersTableContentRow.propTypes = {
  isLoading: PropTypes.bool,
  providerName: PropTypes.string,
  providerDetails: PropTypes.shape({
    accountNumber: PropTypes.string,
    providerLinkStatus: PropTypes.string,
    externalReference: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    details: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])])
  })
};
