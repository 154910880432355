import { useContext } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import i18nContext from '../i18n-context';
import { getAdminsListRequest } from 'services/requestAgent';
import { classNames } from 'uikit/utils';
import './OperatorsAsyncSelect.scss';

const OperatorsAsyncSelect = ({ value, handleValueChanged, error }) => {
  const i18n = useContext(i18nContext);
  const operatorsSelectClasses = classNames({
    'operator-select-wrapper': true,
    error: !!error
  });

  const onChange = (el) => {
    handleValueChanged(el.value);
  };

  const loadAdminsList = async (searchText) => {
    const operatorsArray = await getAdminsListRequest(searchText);

    return operatorsArray.map((operator) => {
      return {
        value: operator,
        label: operator ? operator.name : ''
      };
    });
  };

  return (
    <div className={operatorsSelectClasses}>
      <AsyncSelect
        className={'operator-select'}
        id={'operator-select'}
        name={'operator-select'}
        isMulti={false}
        cacheOptions
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 9999 })
        }}
        noOptionsMessage={() => i18n.getMessage('operatorAsyncSelect.noAccountFound')}
        loadingMessage={() => i18n.getMessage('operatorAsyncSelect.loading')}
        placeholder={i18n.getMessage('operatorAsyncSelect.placeholder')}
        value={{ label: value ? value.name : '' }}
        onChange={onChange}
        loadOptions={loadAdminsList}
      />
      {error && <p className={'input-sub-text input-error'}>{error}</p>}
    </div>
  );
};

OperatorsAsyncSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleValueChanged: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default OperatorsAsyncSelect;
