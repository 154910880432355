import PropTypes from 'prop-types';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';

const BEHAVIOURS = ['NOTIFICATION', 'HOLD'];

const OPTIONS = BEHAVIOURS.map((behaviour) => ({ key: behaviour, value: behaviour }));

export function LimitBehaviourInputControl({ onChange, behaviour, editable }) {
  return (
    <div>
      <div>
        <InputDropDown
          options={OPTIONS}
          className={'table-cell-input'}
          onChange={onChange}
          isDisabled={editable}
          value={behaviour}
        />
      </div>
    </div>
  );
}

LimitBehaviourInputControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  behaviour: PropTypes.oneOf(BEHAVIOURS).isRequired,
  editable: PropTypes.bool.isRequired
};
