import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import './WithdrawalTransactionsTableHead.scss';

export const WITHDRAWAL_TRANSACTIONS_TABLE_HEADER = [
  {
    label: 'transactionNumber'
  },
  {
    label: 'createdAt',
    sortBy: 'created_at'
  },
  {
    label: 'transferProvider',
    sortBy: 'transfer_provider'
  },
  {
    label: 'amount',
    sortBy: 'amount'
  },
  {
    label: 'currency',
    sortBy: 'currency'
  },
  {
    label: 'comment'
  }
];

const WithdrawalTransactionsTableHead = ({ handleOnSortTable, sortBy, direction }) => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {WITHDRAWAL_TRANSACTIONS_TABLE_HEADER.map(
        (head) =>
          head.label && (
            <th key={head.label} className={'withdrawal-transactions-table-head'}>
              <div className={'withdrawal-transactions-table-head-block'}>
                <span>{i18n.getMessage(`commissions.withdrawalTransactions.table.header.${head.label}`)}</span>
                {head.sortBy && (
                  <ArrowSelect
                    onClick={() => handleOnSortTable(head.sortBy)}
                    className={`withdrawal-transactions-sorting-arrow-icon ${sortBy === head.sortBy && direction}`}
                  />
                )}
              </div>
            </th>
          )
      )}
    </tr>
  );
};

WithdrawalTransactionsTableHead.propTypes = {
  handleOnSortTable: PropTypes.func,
  sortBy: PropTypes.string,
  direction: PropTypes.string
};

export default WithdrawalTransactionsTableHead;
