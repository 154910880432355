import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';
import i18nContext from 'components/i18n-context';
import {
  CURRENCY,
  PAYMENT_PROVIDER,
  TRANSIT_TRANSACTION_DIRECTION,
  TRANSIT_TRANSACTION_STATUS
} from 'components/constants';
import CustomSearchInput from 'components/CustomSearchInput';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './TransitTransactionsFiltersBar.scss';

const TransitTransactionsFiltersBar = ({
  isLoading,
  isInitialized,
  periodDate,
  filters,
  totalElements,
  handleSetPeriodDate,
  handleSetFilters,
  handleExportPeriodTransitTransactionList,
  handleExportTransitTransactionList
}) => {
  const i18n = useContext(i18nContext);
  const [searchValue, setSearchValue] = useState(filters.search_text ? filters.search_text : '');
  const currentDate = new Date();

  const debouncedSearch = useRef(
    debounce((name, value) => {
      handleSetFilters(name, value);
    }, 400)
  ).current;

  const handleSearchTextInputChange = ({ target: { name, value } }) => {
    setSearchValue(value);

    debouncedSearch(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const transferProviderOptions = Object.keys(PAYMENT_PROVIDER).map((provider) => {
    return {
      key: PAYMENT_PROVIDER[provider].name,
      value: i18n.getMessage('paymentProvider.' + PAYMENT_PROVIDER[provider].name)
    };
  });

  const transitTransactionsStatusesOptions = Object.keys(TRANSIT_TRANSACTION_STATUS).map((status) => {
    return {
      key: TRANSIT_TRANSACTION_STATUS[status],
      value: i18n.getMessage('transitTransactionStatus.' + TRANSIT_TRANSACTION_STATUS[status])
    };
  });

  const currencyOptions = Object.keys(CURRENCY).map((status) => {
    return {
      key: CURRENCY[status],
      value: CURRENCY[status]
    };
  });

  const transitTransactionsDirectionOptions = Object.keys(TRANSIT_TRANSACTION_DIRECTION).map((status) => {
    return {
      key: TRANSIT_TRANSACTION_DIRECTION[status],
      value: i18n.getMessage('transitTransactionDirection.' + TRANSIT_TRANSACTION_DIRECTION[status])
    };
  });

  return (
    <div className={'transit-transactions-filters-wrapper'}>
      <div className={'transit-transactions-actions-wrapper'}>
        <div className={'transit-transactions-date-range-filters-wrapper'}>
          <div>
            <span>{i18n.getMessage('transitTransactions.filter.periodDate.label')}</span>
            <CustomDatePicker onChange={handleSetPeriodDate} name={'periodDate'} value={periodDate} />
          </div>
          <div>
            <span>{i18n.getMessage('transitTransactions.filter.from.label')}</span>
            <CustomDatePicker
              onChange={handleSetFilters}
              name={'from'}
              value={filters.from}
              maxDate={filters.to || currentDate}
            />
          </div>
          <div>
            <span>{i18n.getMessage('transitTransactions.filter.to.label')}</span>
            <CustomDatePicker
              onChange={handleSetFilters}
              name={'to'}
              value={filters.to}
              maxDate={currentDate}
              minDate={filters.from}
            />
          </div>
        </div>
        <div className={'export-transit-transactions-reports-actions-wrapper'}>
          <Button
            className={'transit-transactions-button'}
            size={'medium'}
            type={'primary'}
            onClick={handleExportPeriodTransitTransactionList}
            isDisabled={!isInitialized || isLoading}
          >
            {isLoading ? <Loader /> : i18n.getMessage('transitTransactions.button.downloadPeriodCsv')}
          </Button>
          <Button
            className={'transit-transactions-button'}
            size={'medium'}
            type={'primary'}
            onClick={handleExportTransitTransactionList}
            isDisabled={!isInitialized || isLoading}
          >
            {isLoading ? <Loader /> : i18n.getMessage('transitTransactions.button.downloadCsv')}
          </Button>
        </div>
      </div>

      <InputDropDown
        className={'transit-transactions-filter-wrapper'}
        label={i18n.getMessage('transitTransactions.filter.transferProvider.label')}
        name={'transfer_providers'}
        value={filters.transfer_providers}
        options={transferProviderOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'transit-transactions-filter-wrapper'}
        label={i18n.getMessage('transitTransactions.filter.transactionStatus.label')}
        name={'statuses'}
        value={filters.statuses}
        options={transitTransactionsStatusesOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'transit-transactions-filter-wrapper'}
        label={i18n.getMessage('transitTransactions.filter.currency.label')}
        name={'currencies'}
        value={filters.currencies}
        options={currencyOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'transit-transactions-filter-wrapper'}
        label={i18n.getMessage('transitTransactions.filter.direction.label')}
        name={'direction'}
        value={filters.direction}
        options={transitTransactionsDirectionOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <CustomSearchInput
        wrapperClassName={'search-wrapper'}
        inputClassName={'transit-transactions-filter-wrapper'}
        label={i18n.getMessage('transitTransactions.filter.search.label')}
        placeholder={i18n.getMessage('transitTransactions.filter.search.placeholder')}
        name={'search_text'}
        value={searchValue}
        onChange={handleSearchTextInputChange}
        totalElements={totalElements}
      />
    </div>
  );
};

TransitTransactionsFiltersBar.propTypes = {
  isLoading: PropTypes.bool,
  isInitialized: PropTypes.bool,
  periodDate: PropTypes.object,
  filters: PropTypes.object,
  totalElements: PropTypes.number,
  handleSetPeriodDate: PropTypes.func,
  handleSetFilters: PropTypes.func,
  handleExportPeriodTransitTransactionList: PropTypes.func,
  handleExportTransitTransactionList: PropTypes.func
};

export default TransitTransactionsFiltersBar;
