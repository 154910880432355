import { useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { COMMON_CURRENCY_PRECISION, CRYPTO_CURRENCY_PRECISION } from 'components/constants';
import i18nContext from 'components/i18n-context';
import { formatMoney, isCryptoProvider } from 'services/utils';
import './SystemAccountsTableRow.scss';

const SystemAccountsTableRow = ({ isLoading, systemAccountData }) => {
  const i18n = useContext(i18nContext);

  const getAccountInfoBlock = (accountBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'system-accounts-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{accountBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'system-accounts-table-cell'}>{getAccountInfoBlock(systemAccountData.account_number)}</td>
      <td className={'system-accounts-table-cell'}>
        {getAccountInfoBlock(i18n.getMessage(`paymentProvider.${systemAccountData.transfer_provider}`))}
      </td>
      <td className={'system-accounts-table-cell'}>
        {getAccountInfoBlock(
          formatMoney(
            systemAccountData.amount,
            isCryptoProvider(systemAccountData.transfer_provider)
              ? CRYPTO_CURRENCY_PRECISION
              : COMMON_CURRENCY_PRECISION
          )
        )}
      </td>
      <td className={'system-accounts-table-cell'}>{getAccountInfoBlock(systemAccountData.currency)}</td>
    </tr>
  );
};

SystemAccountsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  systemAccountData: PropTypes.shape({
    account_number: PropTypes.string,
    amount: PropTypes.number,
    currency: PropTypes.string,
    transfer_provider: PropTypes.string,
    type: PropTypes.string
  })
};

export default SystemAccountsTableRow;
