import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import './AccountsHeadRow.scss';

export const ACCOUNTS_TABLE_HEADER = [
  {
    label: 'accountNumber',
    sortBy: 'wallet_number'
  },
  {
    label: 'multiCurrencyAccount',
    sortBy: 'multi_wallet_number'
  },
  {
    label: 'customerNumber',
    sortBy: 'account_number'
  },
  {
    label: 'iban',
    sortBy: 'iban'
  },
  {
    label: 'currency'
  },
  {
    label: 'total',
    sortBy: 'total'
  },
  {
    label: 'status',
    sortBy: 'status'
  },
  {
    label: 'provider',
    sortBy: 'provider'
  },
  {
    label: 'createdAt',
    sortBy: 'createdAt'
  },
  {
    label: 'externalReference'
  }
];

export const AccountsHeadRow = ({ handleOnSortTable, sortBy, direction }) => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {ACCOUNTS_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'accounts-table-head'}>
          <div className={`accounts-table-head-block ${head.label === 'multiCurrencyAccount' ? 'w-min-content' : ''}`}>
            <span>{i18n.getMessage(`accounts.table.header.${head.label}`)}</span>
            {head.sortBy && (
              <ArrowSelect
                onClick={() => handleOnSortTable(head.sortBy)}
                className={`arrow-icon ${sortBy === head.sortBy && direction}`}
              />
            )}
          </div>
        </th>
      ))}
    </tr>
  );
};

AccountsHeadRow.propTypes = {
  handleOnSortTable: PropTypes.func,
  sortBy: PropTypes.string,
  direction: PropTypes.string
};
