import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import RichTextEditor from 'react-rte';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import i18nContext from 'components/i18n-context';
import {
  MAX_MESSAGE_LENGTH,
  MAX_TOPIC_SUBJECT_LENGTH,
  MAX_UPLOADED_FILES_SIZE,
  MESSAGE_CLIENTS_AMOUNT_RESULT,
  MESSAGE_REG_EXP,
  MESSAGE_TOPIC_PERMISSIONS,
  SEARCH_BOUND_MINIMUM_LENGTH_CLIENT
} from 'components/constants';
import CustomAsyncSelect from 'components/CustomAsyncSelect';
import Loader from 'components/Loader';
import { getMessageClients } from 'services/requestAgent';
import { convertBytesToMegabytes, convertHtmlToString, convertMessageToHtmlAndTrimSpaces } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import './NewMessage.scss';

const NewMessage = ({ messagesStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [messageValue, setMessageValue] = useState(RichTextEditor.createEmptyValue());

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      client: undefined,
      subject: '',
      isAnnouncement: false,
      message: ''
    },
    validationSchema: Yup.object({
      client: Yup.object().required(i18n.getMessage('messages.error.client.empty')),
      subject: Yup.string()
        .transform((value) => value.trim())
        .required(i18n.getMessage('messages.error.subject.empty'))
        .max(
          MAX_TOPIC_SUBJECT_LENGTH,
          i18n.getMessage('messages.error.subject.maxSize', { size: MAX_TOPIC_SUBJECT_LENGTH })
        )
        .matches(MESSAGE_REG_EXP, i18n.getMessage('messages.error.message.invalidFormat')),
      isAnnouncement: Yup.bool(),
      message: Yup.string()
        .transform((value) => value.trim())
        .required(i18n.getMessage('messages.error.message.empty'))
        .max(MAX_MESSAGE_LENGTH, i18n.getMessage('messages.error.message.maxSize', { size: MAX_MESSAGE_LENGTH }))
        .matches(MESSAGE_REG_EXP, i18n.getMessage('messages.error.message.invalidFormat'))
    }),
    onSubmit: (values) => {
      const messageBody = convertMessageToHtmlAndTrimSpaces(messageValue);

      messagesStore
        .createNewTopic('private', {
          account_number: values.client.value,
          documents: messagesStore.uploadedFiles.filter(({ id }) => id).map(({ id }) => id),
          message_body: messageBody,
          subject: values.subject,
          permission: `${
            values.isAnnouncement ? MESSAGE_TOPIC_PERMISSIONS.ANNOUNCEMENT : MESSAGE_TOPIC_PERMISSIONS.DEFAULT
          }`
        })
        .then(() => navigate(ROUTE_PATHS.MESSAGES));
    }
  });

  const { values, errors, handleSubmit, handleChange, validateField, setFieldValue, submitCount } = form;

  const handleChangeMessageField = (value) => {
    setMessageValue(value);
    setFieldValue('message', convertHtmlToString(value));
  };

  const handleBlurMessageField = () => {
    validateField('message');
  };

  const noOptionsMessage = () => i18n.getMessage('messages.newMessage.field.client.noOptionsMessage');
  const loadingMessage = ({ inputValue }) =>
    inputValue.trim().length < SEARCH_BOUND_MINIMUM_LENGTH_CLIENT
      ? i18n.getMessage('messages.newMessage.field.client.searchWillStartWith') +
        SEARCH_BOUND_MINIMUM_LENGTH_CLIENT +
        i18n.getMessage('messages.newMessage.field.client.characters')
      : i18n.getMessage('messages.newMessage.field.client.searching');

  const loadOptions = (inputValue) => {
    let trimmedValue = inputValue.trim();
    return (
      trimmedValue.length >= SEARCH_BOUND_MINIMUM_LENGTH_CLIENT &&
      getMessageClients(trimmedValue, MESSAGE_CLIENTS_AMOUNT_RESULT).then(({ content }) =>
        content.map(({ common_name: name, account_number: number }) => {
          return {
            value: number,
            label: `${name} (${number})`
          };
        })
      )
    );
  };
  const handleClientInputChange = (inputValue) => {
    if (!inputValue) return inputValue;

    return inputValue.replace(/[^a-zA-Z0-9_ @.\-+'"&]/g, '');
  };

  const handleChangeClientOption = (option) => {
    setFieldValue('client', option);
  };

  const handleBlurClientOption = () => {
    validateField('client');
  };

  const handleChangeIsAnnouncement = () => {
    setFieldValue('isAnnouncement', !values.isAnnouncement);
  };

  return (
    <Container className={'new-message-container'} header={i18n.getMessage('container.newMessage')}>
      <div className={'new-message-form-wrapper'}>
        <form action={'#'} id={'send'} onSubmit={handleSubmit} className={'new-message-form'}>
          <CustomAsyncSelect
            isAutoFocus={true}
            onChange={handleChangeClientOption}
            onBlur={handleBlurClientOption}
            loadList={loadOptions}
            handleInputChange={handleClientInputChange}
            className={'new-message-form-client-input'}
            name={'client'}
            error={errors?.client}
            noOptionsMessage={noOptionsMessage}
            loadingMessage={loadingMessage}
            placeholder={i18n.getMessage('messages.newMessage.field.client.placeholder')}
          />
          <Input
            className={'new-message-form-subject-input'}
            type={'text'}
            label={i18n.getMessage('messages.newMessage.field.subject.label')}
            name={'subject'}
            value={values.subject}
            onChange={handleChange}
            onBlur={() => validateField('subject')}
            initialStatus={submitCount}
            error={errors?.subject}
            max={MAX_TOPIC_SUBJECT_LENGTH}
            /* eslint-disable-next-line max-len */
            subText={i18n.getMessage('messages.newMessage.field.subject.symbolsLeft', {
              amount: MAX_TOPIC_SUBJECT_LENGTH - values.subject.length
            })}
          />
          <CheckBox
            className={'new-message-form-announcement-checkbox'}
            isActive={values.isAnnouncement}
            isReverseView={true}
            showCheckBox={true}
            onChange={handleChangeIsAnnouncement}
            value={i18n.getMessage('messages.newMessage.checkbox.announcement.label')}
          />
          <Input
            className={'new-message-form-message-input'}
            type={'text-editor'}
            label={i18n.getMessage('messages.newMessage.field.message.label')}
            name={'message'}
            value={messageValue}
            onChange={handleChangeMessageField}
            onBlur={handleBlurMessageField}
            initialStatus={submitCount}
            max={MAX_MESSAGE_LENGTH}
            rows={4}
            error={errors?.message}
            /* eslint-disable-next-line max-len */
            subText={i18n.getMessage('messages.newMessage.field.message.symbolsLeft', {
              amount: MAX_MESSAGE_LENGTH - values.message.length
            })}
          />
          <AttachDoc
            label={i18n.getMessage('messages.newMessage.field.attachDoc.label', {
              amount: messagesStore.uploadedFiles.length
            })}
            className={'new-message-form-attachments'}
            type={'file'}
            size={MAX_UPLOADED_FILES_SIZE}
            files={messagesStore.uploadedFiles}
            onChange={messagesStore.uploadAttachments}
            onRemoveFiles={messagesStore.removeAttachment}
            isDisabled={messagesStore.isLoading || messagesStore.isFileUploading}
            // error={error?.type === 'attachDoc' && getErrorMessageForAlert(i18n, error)}
            hintText={i18n.getMessage('attachDoc.hint.text', {
              maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
            })}
          />
          <Button
            className={'new-message-form-button'}
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            fullWidth={true}
            onClick={() => {}}
            isDisabled={
              messagesStore.isLoading ||
              messagesStore.isFileUploading ||
              !!errors?.client ||
              !!errors?.subject ||
              !!errors?.message
            }
          >
            {messagesStore.isLoading ? <Loader /> : i18n.getMessage('messages.newMessage.button.sendMessage')}
          </Button>
        </form>
      </div>
    </Container>
  );
};

NewMessage.propTypes = {
  messagesStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  messagesStore: stores.messagesStore
}))(observer(NewMessage));
