import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './LogsTabHeadRow.scss';

export const LOGS_TABLE_HEADER = [
  {
    label: 'authorAndDate',
    width: '20%'
  },
  {
    label: 'action',
    width: '55%'
  },
  {
    label: 'change',
    width: '25%'
  }
];

export const LogsTabHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {LOGS_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'logs-table-head'} width={head.width}>
          <div className={'logs-table-head-block'}>
            <span>{i18n.getMessage(`customerLogs.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};
