import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import {
  MAX_TRANSACTION_NOTE_LENGTH,
  MAX_UPLOADED_FILES_SIZE,
  TRANSACTION_DOCUMENT_CATEGORY
} from 'components/constants';
import { convertBytesToMegabytes, convertHtmlToString } from 'services/utils';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './PopUpAttachDocumentScheme.scss';

const DEFAULT_FORM_STATE = {
  note: null,
  category: null,
  hidden: false,
  document: []
};

export const PopUpAttachDocumentScheme = ({
  isLoading,
  handleClose,
  editedDocument,
  handleAttachTransactionDocument,
  handleUpdateTransactionDocument
}) => {
  const i18n = useContext(i18nContext);

  const validationSchema = Yup.object().shape({
    note: Yup.string()
      .nullable()
      .max(1000, i18n.getMessage('transactionDetails.addDocument.modal.error.note.size', { size: 1000 })),
    category: Yup.string().required(i18n.getMessage('transactionDetails.addDocument.modal.error.category.empty')),
    document: Yup.array().min(1, i18n.getMessage('transactionDetails.addDocument.modal.error.document.empty'))
  });

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: editedDocument
      ? {
          note: editedDocument.note,
          category: editedDocument.category,
          hidden: editedDocument.hidden,
          document: [{ name: editedDocument.name }]
        }
      : { ...DEFAULT_FORM_STATE },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (editedDocument) {
        await handleUpdateTransactionDocument(editedDocument.id, {
          hidden: values.hidden,
          category: values.category,
          note: values.note
        });
      } else {
        await handleAttachTransactionDocument(values.document[0], {
          hidden: values.hidden,
          category: values.category,
          note: values.note
        });
      }
    }
  });

  const { values, handleSubmit, setFieldValue, errors, setErrors } = form;

  const documentCategoryOptions = Object.values(TRANSACTION_DOCUMENT_CATEGORY).map((value) => ({
    key: value,
    value: i18n.getMessage(`transactionDocumentCategory.${value}`)
  }));

  const handleAttachFile = ({ target: { files } }) => {
    if (Object.keys(files).length) {
      setFieldValue('document', [files[0]]);
      setErrors({ document: '' });
    }
  };

  const handleRemoveDocument = () => {
    setFieldValue('document', []);
  };

  const handleChangeValue = (name, value) => {
    setFieldValue(name, value);
  };

  const handleChangeHiddenStatus = () => {
    setFieldValue('hidden', !values.hidden);
  };

  return (
    <div className={'scheme-wrapper attach-transaction-document-container'}>
      <div className={'scheme-header attach-transaction-document-container-header'}>
        <h2>{i18n.getMessage('transactionDetails.addDocument.modal.header')}</h2>
        <CloseIcon className={'attach-transaction-document-container-header-icon'} onClick={handleClose} />
      </div>
      <form onSubmit={handleSubmit} className={'attach-transaction-document-container-form'}>
        <div className={'attach-transaction-document-container-form-wrapper'}>
          <div className={'attach-transaction-document-container-form-wrapper-attach'}>
            <AttachDoc
              isRequired={true}
              isMultiple={false}
              name={'document'}
              files={values.document}
              onRemoveFiles={handleRemoveDocument}
              size={MAX_UPLOADED_FILES_SIZE}
              hintText={i18n.getMessage('attachDoc.hint.text', {
                maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
              })}
              label={i18n.getMessage('transactionDetails.addDocument.modal.document')}
              onChange={handleAttachFile}
              error={errors?.document}
              className={'attach-transaction-document-container-form-wrapper-attach-input'}
              isDisabled={!!editedDocument || isLoading}
            />
          </div>
          <InputDropDown
            isRequired={true}
            className={'table-cell-input'}
            name={'category'}
            label={i18n.getMessage('transactionDetails.addDocument.modal.category')}
            options={documentCategoryOptions}
            value={values.category}
            onChange={handleChangeValue}
            isDisabled={isLoading}
            isAbsoluteErrorPosition={true}
            error={errors?.category}
          />
          <CheckBox
            className={'attach-transaction-document-checkbox'}
            name={'hidden'}
            isActive={values.hidden}
            isReverseView={true}
            showCheckBox={true}
            onChange={handleChangeHiddenStatus}
            value={i18n.getMessage('transactionDetails.addDocument.modal.checkbox.hidden')}
            disabled={isLoading}
          />
        </div>
        <Input
          className={'attach-transaction-document-container-form-note'}
          type={'textarea'}
          value={values.note}
          max={MAX_TRANSACTION_NOTE_LENGTH}
          label={i18n.getMessage('transactionDetails.addDocument.modal.note')}
          name={'note'}
          error={errors?.note}
          onChange={(e) => {
            setFieldValue('note', e.target.value);
          }}
          isDisabled={isLoading}
          /* eslint-disable-next-line max-len */
          subText={i18n.getMessage('transactionDetails.addDocument.modal.note.symbolsLeft', {
            amount: MAX_TRANSACTION_NOTE_LENGTH - convertHtmlToString(values.note).length
          })}
        />
        <div className={'attach-transaction-document-container-form-button-wrapper'}>
          <Button
            roleType={'submit'}
            className={'attach-transaction-document-container-form-button'}
            isDisabled={isLoading}
          >
            {editedDocument
              ? i18n.getMessage('transactionDetails.addDocument.modal.updateDocument')
              : i18n.getMessage('transactionDetails.addDocument.modal.attachDocument')}
          </Button>
        </div>
      </form>
    </div>
  );
};

PopUpAttachDocumentScheme.propTypes = {
  isLoading: PropTypes.bool,
  handleClose: PropTypes.func,
  editedDocument: PropTypes.object,
  handleAttachTransactionDocument: PropTypes.func,
  handleUpdateTransactionDocument: PropTypes.func
};
