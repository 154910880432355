import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';
import { classNames } from 'uikit/utils';
import './CustomDatePicker.scss';

export const CustomDatePicker = ({
  isDisabled,
  isRequired,
  className,
  label,
  name,
  onChange,
  value,
  error,
  maxDate,
  minDate
}) => {
  const customDatePickerClasses = classNames({
    datepicker: true,
    error: !!error,
    disabled: isDisabled,
    [className]: true
  });

  const pickDate = (date) => {
    onChange(name, date);
  };

  return (
    <div className={customDatePickerClasses}>
      <div className={'datepicker-block'}>
        {label && <label htmlFor={name}>{label + (isRequired ? '*' : '')}</label>}
        <DatePicker
          showMonthDropdown={true}
          className={'date-input'}
          disabled={isDisabled}
          dateFormat={'dd/MM/yyyy'}
          name={name}
          id={name}
          placeholderText={'dd/mm/yyyy'}
          selected={value}
          onChange={(date) => pickDate(date)}
          maxDate={maxDate}
          minDate={minDate}
        />
        <CalendarIcon className={'date-icon'} />
      </div>
      {error && <p className={'date-picker-error'}>{error}</p>}
    </div>
  );
};

CustomDatePicker.propTypes = {
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxDate: PropTypes.object,
  minDate: PropTypes.object
};
