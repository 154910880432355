import { convertDate } from 'services/utils';

export const toDto = (data) => ({
  amount: Number(data.amount),
  currency: data.currency,
  transfer_provider: data.transferProvider,
  external_id: data.externalId,
  provider_created_at: convertDate(data.providerCreatedAt),
  direction: data.transitDirection
});
