import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';
import i18nContext from 'components/i18n-context';
import CustomSearchInput from 'components/CustomSearchInput';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import './EmailStatisticsFiltersBar.scss';

const EmailStatisticsFiltersBar = ({ filters, totalElements, handleSetFilters }) => {
  const i18n = useContext(i18nContext);
  const [searchValue, setSearchValue] = useState(filters.searchText ? filters.searchText : '');
  const currentDate = new Date();

  const debouncedSearch = useRef(
    debounce((name, value) => {
      handleSetFilters(name, value);
    }, 400)
  ).current;

  const handleSearchTextInputChange = ({ target: { name, value } }) => {
    setSearchValue(value);

    debouncedSearch(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <div className={'email-statistics-filters-wrapper'}>
      <div className={'email-statistics-date-range-filters-wrapper'}>
        <div>
          <span>{i18n.getMessage('emailStatistics.filter.from.label')}</span>
          <CustomDatePicker
            onChange={handleSetFilters}
            name={'fromDate'}
            value={filters.fromDate}
            maxDate={filters.toDate || currentDate}
          />
        </div>
        <div>
          <span>{i18n.getMessage('emailStatistics.filter.to.label')}</span>
          <CustomDatePicker
            onChange={handleSetFilters}
            name={'toDate'}
            value={filters.toDate}
            maxDate={currentDate}
            minDate={filters.fromDate}
          />
        </div>
      </div>

      <CustomSearchInput
        wrapperClassName={'search-wrapper'}
        inputClassName={'email-statistics-filter-wrapper'}
        label={i18n.getMessage('emailStatistics.filter.search.label')}
        placeholder={i18n.getMessage('emailStatistics.filter.search.placeholder')}
        name={'searchText'}
        value={searchValue}
        onChange={handleSearchTextInputChange}
        totalElements={totalElements}
      />
    </div>
  );
};

EmailStatisticsFiltersBar.propTypes = {
  filters: PropTypes.object,
  totalElements: PropTypes.number,
  handleSetFilters: PropTypes.func
};

export default EmailStatisticsFiltersBar;
