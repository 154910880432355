import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import i18n from 'i18n';
import { CUSTOM_TABS_DIRECTION } from 'components/constants';
import CustomTabs from 'components/CustomTabs';
import i18nContext from 'components/i18n-context';
import { ROUTE_PATHS } from 'routes/constants';
import { Container } from 'uikit/Container/Container';
import './Commissions.scss';

const COMMISSIONS_TABS = [
  {
    name: 'commissionAccounts',
    title: i18n.getMessage('commissions.tab.commissionAccounts'),
    icon: null,
    route: ROUTE_PATHS.COMMISSION_ACCOUNTS
  },
  {
    name: 'commissionTransactions',
    title: i18n.getMessage('commissions.tab.commissionTransactions'),
    icon: null,
    route: ROUTE_PATHS.COMMISSION_TRANSACTIONS
  },
  {
    name: 'withdrawalTransactions',
    title: i18n.getMessage('commissions.tab.withdrawalTransactions'),
    icon: null,
    route: ROUTE_PATHS.COMMISSION_WITHDRAWAL
  }
];

const Commissions = () => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const path = location.pathname;

    const activeTabFromPath = COMMISSIONS_TABS.find((tab) => tab.route === path);
    if (activeTabFromPath) {
      setActiveTab(activeTabFromPath.name);
    } else if (path === ROUTE_PATHS.COMMISSIONS) {
      setActiveTab(COMMISSIONS_TABS[0].name);
      navigate(COMMISSIONS_TABS[0].route);
    }
  }, [location.pathname, navigate]);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    const selectedTab = COMMISSIONS_TABS.find((tab) => tab.name === tabName);
    if (selectedTab) {
      navigate(selectedTab.route);
    }
  };

  return (
    <section className={'right-section'}>
      <Container className={'commissions-container'} header={i18n.getMessage('container.commissions')}>
        <div className={'commissions-wrapper'}>
          <CustomTabs
            tabList={COMMISSIONS_TABS}
            direction={CUSTOM_TABS_DIRECTION.HORIZONTAL}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
          />
          <Outlet />
        </div>
      </Container>
    </section>
  );
};

export default Commissions;
