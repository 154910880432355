import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './RepresentativesTableHeadRow.scss';

export const REPRESENTATIVE_DETAILS_TABLE_HEADER = [
  {
    label: 'name'
  },
  {
    label: 'customerNumber'
  },
  {
    label: 'permissions'
  },
  {
    label: 'action'
  }
];

export const RepresentativesTableHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {REPRESENTATIVE_DETAILS_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'representatives-table-head'}>
          <div className={'representatives-table-head-block'}>
            <span>{i18n.getMessage(`representativesTab.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};
