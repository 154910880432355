import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import './AccountsTableHeadRow.scss';
import Button from '../../../../../uikit/Button/Button';

export const ACCOUNT_DETAILS_TABLE_HEADER = [
  {
    label: 'accountNumber'
  },
  {
    label: 'iban'
  },
  {
    label: 'paymentProvider'
  },
  {
    label: 'availableBalance'
  },
  {
    label: 'onHold'
  },
  {
    label: 'show'
  },
  {
    label: 'action'
  }
];

export const MULTI_CURRENCY_ACCOUNT_DETAILS_TABLE_HEADER = [
  {
    label: 'accountNumber'
  },
  {
    label: 'paymentProvider'
  },
  {
    label: 'availableBalance'
  },
  {
    label: 'onHold'
  },
  {
    label: 'show'
  },
  {
    label: 'action'
  }
];

export const AccountsTableHeadRow = ({
  isMultiCurrency,
  accountNumber,
  iban,
  transferProvider,
  openAddNewCurrencyPopUp,
  existingCurrencies
}) => {
  const i18n = useContext(i18nContext);

  const getAccountsTableHeaderArray = () => {
    return isMultiCurrency ? MULTI_CURRENCY_ACCOUNT_DETAILS_TABLE_HEADER : ACCOUNT_DETAILS_TABLE_HEADER;
  };

  return (
    <>
      {isMultiCurrency ? (
        <tr>
          <th className={'account-table-head'} colSpan={6}>
            <div className={'account-table-head-wrapper'}>
              <div className={'account-table-head-block multi-account-head-block'}>
                <span>
                  {i18n.getMessage('accountsTab.table.header.multiCurrencyAccountNumber', {
                    accountNumber: accountNumber
                  })}
                </span>
                <span>{i18n.getMessage('accountsTab.table.header.multiCurrencyIban', { iban: iban })}</span>
              </div>
              {transferProvider === 'OPEN_PAYD' && (
                <div className={'add-currency-button'}>
                  <Button
                    type={'primary'}
                    size={'small'}
                    onClick={() => openAddNewCurrencyPopUp(accountNumber, existingCurrencies)}
                    className={'provider-actions-button'}
                  >
                    {i18n.getMessage('accountsTab.table.action.addCurrency')}
                  </Button>
                </div>
              )}
            </div>
          </th>
        </tr>
      ) : (
        <tr>
          <th className={'account-table-head'} colSpan={7}>
            <div className={'account-table-head-block head-block-center'}>
              <span>{i18n.getMessage('accountsTab.table.header.singleAccount')}</span>
            </div>
          </th>
        </tr>
      )}
      <tr>
        {getAccountsTableHeaderArray().map((head) => (
          <th key={head.label} className={'account-table-head'}>
            <div className={'account-table-head-block'}>
              <span>{i18n.getMessage(`accountsTab.table.header.${head.label}`)}</span>
            </div>
          </th>
        ))}
      </tr>
    </>
  );
};

AccountsTableHeadRow.propTypes = {
  isMultiCurrency: PropTypes.bool,
  accountNumber: PropTypes.string,
  iban: PropTypes.string,
  transferProvider: PropTypes.string,
  openAddNewCurrencyPopUp: PropTypes.func,
  existingCurrencies: PropTypes.array
};
