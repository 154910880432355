import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { PAYMENT_METHOD, PAYMENT_PROVIDER, SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT } from 'components/constants';
import i18nContext from 'components/i18n-context';
import CustomAsyncSelect from 'components/CustomAsyncSelect';
import { useApplicationContext } from 'contexts/ApplicationContext';
import { getAccountsRequest } from 'services/requestAgent';
import { getAllCryptoCurrencies, isCryptoProvider } from 'services/utils';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './InternalCryptoTransactionPartyControl.scss';

const InternalCryptoTransactionPartyControl = ({
  account,
  paymentProvider,
  paymentMethod,
  handleChangeByFormik,
  handleChangeValue,
  validateField,
  accountLabel,
  property,
  values,
  isShowPaymentProvider,
  isShowPaymentMethod,
  isShowRegularAccountField,
  errors
}) => {
  const i18n = useContext(i18nContext);

  const noOptionsMessage = () => i18n.getMessage('createTransaction.crypto.accountSelect.noAccountsFound');
  const loadingMessage = ({ inputValue }) =>
    inputValue.trim().length < SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT
      ? i18n.getMessage('createTransaction.crypto.accountSelect.searchWillStartWith') +
        SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT +
        i18n.getMessage('createTransaction.crypto.accountSelect.characters')
      : i18n.getMessage('createTransaction.crypto.accountSelect.searching');

  const transferProviderOptions = Object.keys(PAYMENT_PROVIDER).map((provider) => {
    return {
      key: PAYMENT_PROVIDER[provider].name,
      value: i18n.getMessage('paymentProvider.' + PAYMENT_PROVIDER[provider].name)
    };
  });

  const paymentMethodOptions = [PAYMENT_METHOD.CRYPTO, PAYMENT_METHOD.INTERNAL].map((paymentMethod) => {
    return {
      key: paymentMethod,
      value: i18n.getMessage('paymentMethod.' + paymentMethod)
    };
  });

  const handleChangeAccountOption = (account) => {
    handleChangeValue(property, account.value);
    handleChangeValue('currency', account.value.currency);
  };

  const loadOptions = async (searchText) => {
    let response = null;
    if (searchText.length >= SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT) {
      response = await getAccountsRequest('iban', 0, 10, 'ASC', searchText);
    }

    return response
      ? response.content
          .filter((account) => isCryptoProvider(account?.transfer_provider))
          .map((account) => ({
            value: account,
            label: `${account?.crypto_address} (${account.wallet_number})`
          }))
      : [];
  };

  return (
    <div className={'internal-crypto-transaction-party-control-wrapper'}>
      {isShowRegularAccountField ? (
        <Input
          isRequired
          className={'internal-crypto-transaction-party-control-input'}
          label={accountLabel}
          placeholder={i18n.getMessage('createTransaction.crypto.cryptoAddress.placeholder')}
          name={property}
          value={values?.[property]}
          onChange={handleChangeByFormik}
          onBlur={() => validateField(property)}
          error={errors?.[property]}
        />
      ) : (
        <CustomAsyncSelect
          isRequired
          className={'internal-crypto-transaction-party-control-account'}
          onChange={handleChangeAccountOption}
          loadList={loadOptions}
          handleInputChange={handleChangeValue}
          name={property}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          label={accountLabel}
          placeholder={i18n.getMessage('createTransaction.crypto.accountSelect.placeholder')}
          error={errors?.[property]}
        />
      )}

      {isShowPaymentMethod && (
        <>
          <InputDropDown
            isRequired
            className={'internal-crypto-transaction-party-control-select'}
            label={i18n.getMessage('createTransaction.crypto.paymentProvider')}
            id={'paymentProvider'}
            name={'paymentProvider'}
            value={paymentProvider}
            options={transferProviderOptions}
            // onChange={handlePaymentProviderChange}
            error={errors?.paymentProvider}
            isDisabled={true}
          />
          <InputDropDown
            isRequired
            className={'internal-crypto-transaction-party-control-select'}
            label={i18n.getMessage('createTransaction.crypto.paymentMethod')}
            id={'paymentMethod'}
            name={'paymentMethod'}
            value={paymentMethod}
            options={paymentMethodOptions}
            onChange={handleChangeValue}
            error={errors?.paymentMethod}
          />
        </>
      )}
    </div>
  );
};

InternalCryptoTransactionPartyControl.propTypes = {
  account: PropTypes.object,
  paymentProvider: PropTypes.string,
  paymentMethod: PropTypes.string,
  handleChangeByFormik: PropTypes.func,
  handleChangeValue: PropTypes.func,
  validateField: PropTypes.func,
  property: PropTypes.string,
  values: PropTypes.object,
  accountLabel: PropTypes.string,
  isShowPaymentProvider: PropTypes.bool,
  isShowPaymentMethod: PropTypes.bool,
  isShowRegularAccountField: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])])
};

export default InternalCryptoTransactionPartyControl;
