import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';
import i18nContext from 'components/i18n-context';
import { MESSAGE_TOPIC_STATUS } from 'components/constants';
import CustomSearchInput from 'components/CustomSearchInput';
import OperatorsAsyncSelect from 'components/OperatorsAsyncSelect';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './MessagesTopicsFiltersBar.scss';

const MessagesTopicsFiltersBar = ({ filters, totalElements, handleSetFilters }) => {
  const i18n = useContext(i18nContext);
  const [searchValue, setSearchValue] = useState(filters.search_text ? filters.search_text : '');
  const currentDate = new Date();

  const debouncedSearch = useRef(
    debounce((name, value) => {
      handleSetFilters(name, value);
    }, 400)
  ).current;

  const handleSearchTextInputChange = ({ target: { name, value } }) => {
    setSearchValue(value);

    debouncedSearch(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const messagesTopicStatusesOptions = Object.keys(MESSAGE_TOPIC_STATUS).map((status) => {
    return {
      key: MESSAGE_TOPIC_STATUS[status],
      value: i18n.getMessage('messages.topics.status.' + MESSAGE_TOPIC_STATUS[status])
    };
  });

  const handleSetOwnerOperator = (value) => {
    handleSetFilters('operator', value);
  };

  const handleChangeUnreadOnlyCheckbox = () => {
    handleSetFilters('unread_only', !filters.unread_only);
  };

  return (
    <div className={'messages-topics-filters-wrapper'}>
      <div className={'messages-topics-date-range-filters-wrapper'}>
        <div>
          <span>{i18n.getMessage('messages.topics.filter.from.label')}</span>
          <CustomDatePicker
            onChange={handleSetFilters}
            name={'from'}
            value={filters.from}
            maxDate={filters.to || currentDate}
          />
        </div>
        <div>
          <span>{i18n.getMessage('messages.topics.filter.to.label')}</span>
          <CustomDatePicker
            onChange={handleSetFilters}
            name={'to'}
            value={filters.to}
            maxDate={currentDate}
            minDate={filters.from}
          />
        </div>
        <div className={'messages-topics-date-range-filter-block'}>
          <span>{i18n.getMessage('messages.topics.filter.ownerOperator.label')}</span>
          <OperatorsAsyncSelect
            className={'messages-topics-date-range-filter'}
            handleValueChanged={handleSetOwnerOperator}
            value={filters.operator}
          />
        </div>
        <CheckBox
          className={'messages-topics-checkbox'}
          isActive={filters.unread_only !== false}
          showCheckBox={true}
          onChange={handleChangeUnreadOnlyCheckbox}
          value={i18n.getMessage('messages.topics.filter.unreadOnly.label')}
        />
      </div>

      <InputDropDown
        className={'messages-topics-filter-wrapper'}
        label={i18n.getMessage('messages.topics.filter.status.label')}
        name={'statuses'}
        value={filters.statuses}
        options={messagesTopicStatusesOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <CustomSearchInput
        wrapperClassName={'search-wrapper'}
        inputClassName={'messages-topics-filter-wrapper'}
        label={i18n.getMessage('messages.topics.filter.search.label')}
        placeholder={i18n.getMessage('messages.topics.filter.search.placeholder')}
        name={'search_text'}
        value={searchValue}
        onChange={handleSearchTextInputChange}
        totalElements={totalElements}
      />
    </div>
  );
};

MessagesTopicsFiltersBar.propTypes = {
  filters: PropTypes.object,
  totalElements: PropTypes.number,
  handleSetFilters: PropTypes.func
};

export default MessagesTopicsFiltersBar;
