import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './TariffsTransactionalTableHeadRow.scss';

export const TARIFFS_TRANSACTIONAL_TABLE_HEADER = [
  {
    label: 'paymentProvider'
  },
  {
    label: 'transactionType'
  },
  {
    label: 'paymentMethod'
  },
  {
    label: 'transferType'
  },
  {
    label: 'amount'
  }
];

export const TariffsTransactionalTableHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {TARIFFS_TRANSACTIONAL_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'tariffs-transactional-table-head'}>
          <div className={'tariffs-transactional-table-head-block'}>
            <span>{i18n.getMessage(`tariffsTab.transactional.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};
