import { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { ALLOWED_FILE_EXTENSIONS, MAX_UPLOADED_FILES_SIZE } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import './FileSelector.scss';

const FileSelector = ({ isLoading, id, onFileSelected, name, accept }) => {
  const i18n = useContext(i18nContext);
  const fileInputRef = useRef(null);

  const handleFileSelected = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

      if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
        // toast.error(i18n.getMessage('fileSelector.typeNotSupported'));
        return;
      }

      if (file.size > MAX_UPLOADED_FILES_SIZE) {
        // toast.error(i18n.getMessage('fileSelector.sizeExceedsLimit'));
        return;
      }

      onFileSelected(file);
    }

    e.target.value = null;
  };

  const acceptTypes = accept ? accept.map((ext) => `${ext}`).join(',') : '*';

  return (
    <div className={'file-selector'}>
      <input
        className={'file-input'}
        type={'file'}
        id={id || 'fileInput'}
        onChange={handleFileSelected}
        accept={acceptTypes}
        ref={fileInputRef}
      />
      <Button
        type={'primary'}
        size={'medium'}
        onClick={() => fileInputRef.current.click()}
        className={'file-select-button'}
        isDisabled={isLoading}
      >
        {isLoading ? <Loader /> : name || i18n.getMessage('fileSelector.choose')}
      </Button>
    </div>
  );
};

FileSelector.propTypes = {
  isLoading: PropTypes.bool,
  id: PropTypes.string,
  onFileSelected: PropTypes.func.isRequired,
  name: PropTypes.string,
  accept: PropTypes.arrayOf(PropTypes.string)
};

export default FileSelector;
