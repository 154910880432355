import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import PaymentObligationsFiltersBar from './components/PaymentObligationsFiltersBar';
import PaymentObligationsTableHead from './components/PaymentObligationsTableHead';
import PaymentObligationsTableRow from './components/PaymentObligationsTableRow';
import i18nContext from 'components/i18n-context';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { Container } from 'uikit/Container/Container';
import './PaymentObligationsTable.scss';

const PaymentObligationsTable = ({ paymentObligationsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination, sortColumn) => {
    const filtersParams = paymentObligationsStore.prepareFiltersParams();
    const queryParams = {
      ...filtersParams,
      page: pagination.page,
      size: pagination.size,
      sort_column: sortColumn.sort_column,
      sort_direction: sortColumn.sort_direction
    };
    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    paymentObligationsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!paymentObligationsStore.isInitialized) {
      paymentObligationsStore.getPaymentObligationsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentObligationsStore.isInitialized]);

  useEffect(() => {
    if (paymentObligationsStore.isInitialized) {
      paymentObligationsStore.resetPaymentObligationsStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(
      paymentObligationsStore.filters,
      paymentObligationsStore.pagination,
      paymentObligationsStore.sortColumn
    );
    if (paymentObligationsStore.isInitialized) {
      paymentObligationsStore.getPaymentObligationsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentObligationsStore.filters.search_text,
    paymentObligationsStore.filters.from_date,
    paymentObligationsStore.filters.to_date,
    paymentObligationsStore.filters.statuses,
    paymentObligationsStore.filters.currencies,
    paymentObligationsStore.pagination.size,
    paymentObligationsStore.pagination.page,
    paymentObligationsStore.sortColumn.sort_column,
    paymentObligationsStore.sortColumn.sort_direction
  ]);

  const handleOnSortTable = (newSortBy) => {
    paymentObligationsStore.setSortData({
      sortBy: newSortBy,
      direction:
        paymentObligationsStore.sortColumn.sort_column !== newSortBy
          ? DIRECTION.ASC
          : paymentObligationsStore.sortColumn.sort_direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  if (!paymentObligationsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'payment-obligations-container'} header={i18n.getMessage('container.paymentObligations')}>
      <PaymentObligationsFiltersBar
        filters={paymentObligationsStore.filters}
        totalElements={paymentObligationsStore.totalElements}
        handleSetFilters={paymentObligationsStore.setFilter}
      />

      <Pagination
        isShowPaginationSize={true}
        paginationSize={paymentObligationsStore.pagination.size}
        handleChangePaginationSize={paymentObligationsStore.setPageSize}
        isLoading={paymentObligationsStore.isLoading}
        currentPage={paymentObligationsStore.pagination.page}
        numberOfPages={paymentObligationsStore.pagination.totalPages}
        setCurrentPage={paymentObligationsStore.setPageNumber}
      />

      <Table responsive>
        <thead>
          <PaymentObligationsTableHead
            handleOnSortTable={handleOnSortTable}
            sortBy={paymentObligationsStore.sortColumn.sort_column}
            direction={paymentObligationsStore.sortColumn.sort_direction}
          />
        </thead>
        <tbody>
          {paymentObligationsStore.paymentObligationsList?.map((paymentObligation, index) => (
            <PaymentObligationsTableRow
              key={index}
              type={'list'}
              isLoading={paymentObligationsStore.isLoading}
              paymentObligationData={paymentObligation}
              releasePaymentObligation={paymentObligationsStore.releasePaymentObligation}
              chargePaymentObligation={paymentObligationsStore.chargePaymentObligation}
            />
          ))}
        </tbody>
      </Table>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={paymentObligationsStore.pagination.size}
        handleChangePaginationSize={paymentObligationsStore.setPageSize}
        isLoading={paymentObligationsStore.isLoading}
        currentPage={paymentObligationsStore.pagination.page}
        numberOfPages={paymentObligationsStore.pagination.totalPages}
        setCurrentPage={paymentObligationsStore.setPageNumber}
        menuDirection={MENU_DIRECTION.UP}
      />
    </Container>
  );
};

PaymentObligationsTable.propTypes = {
  paymentObligationsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  paymentObligationsStore: stores.paymentObligationsStore
}))(observer(PaymentObligationsTable));
