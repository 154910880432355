import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as EditIcon } from 'assets/iconButtons/edit.svg';
import i18nContext from 'components/i18n-context';
import {
  APPROVAL_STATUS,
  COMMON_CURRENCY_PRECISION,
  CRYPTO_CURRENCY_PRECISION,
  DOCUMENT_STATUS,
  MAX_UPLOADED_FILES_SIZE,
  PAYMENT_METHOD,
  PAYMENT_PROVIDER,
  TRANSACTION_STATUS,
  TRANSACTION_TYPES as TRANSACTION_TYPE
} from 'components/constants';
import { getCustomerInfoRequest } from 'services/requestAgent';
import { hasRole, TRANSACTION_UPDATE } from 'services/roles';
import {
  convertBytesToMegabytes,
  formatDateTime,
  formatMoney,
  getCustomerLink,
  isCryptoProvider,
  truncateFileName
} from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { IconButton } from 'uikit/IconButton/IconButton';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './TransactionDetailsTable.scss';

const TransactionDetailsTable = ({
  isLoading,
  transactionData,
  paymentLimits,
  sanctions,
  blacklistDetails,
  ruleEngineScoring,
  handleAcceptOnHold,
  handleAcceptProcessing,
  handleReject,
  handleReturn,
  handleChangeDocumentStatus,
  handleSaveMlroApproval,
  handleOpenAttachDocumentPopUp,
  downloadTransactionDocument,
  deleteTransactionDocument
}) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const initialedDate = transactionData ? formatDateTime(transactionData.createdAt) : null;
  const updatedDate = transactionData ? formatDateTime(transactionData.updatedAt) : null;
  const [mlroApproval, setMlroApproval] = useState(
    transactionData?.mlroApproval?.status ? transactionData.mlroApproval.status : ''
  );

  const documentStatusOptions = Object.values(DOCUMENT_STATUS).map((value) => ({
    key: value,
    value: i18n.getMessage(`documentStatus.${value}`)
  }));

  const mlroApprovalStatusOptions = Object.values(APPROVAL_STATUS).map((value) => ({
    key: value,
    value: i18n.getMessage(`mlroApprovalStatus.${value}`)
  }));

  const isAcceptRejectProcessing = (data) => {
    return (
      data.status === TRANSACTION_STATUS.PROCESSING &&
      (data.transferProvider === PAYMENT_PROVIDER.CLEAR_JUNCTION.name ||
        data.transferProvider === PAYMENT_PROVIDER.MANUAL.name ||
        data.transferProvider === PAYMENT_PROVIDER.RYVYL.name) &&
      TRANSACTION_TYPE.OUTGOING === data.transactionType
    );
  };

  const isReturn = (data) => {
    return (
      data.transactionType === TRANSACTION_TYPE.INCOMING &&
      data.paymentMethod !== PAYMENT_METHOD.INTERNAL &&
      (data.status === TRANSACTION_STATUS.ON_HOLD || data.status === TRANSACTION_STATUS.PAID)
    );
  };

  const handleRedirectToCustomer = async (customerNumber) => {
    const customer = await getCustomerInfoRequest(customerNumber);

    navigate(getCustomerLink(customerNumber, customer.status, customer.type));
  };

  const handleChangeMlroApprovalStatus = (name, status) => {
    setMlroApproval(status);
  };

  const handleRedirectToLimits = async (group) => {
    let redirectUrl = ROUTE_PATHS.LIMITS;

    if (group === 'individual') {
      const customerInfo = await getCustomerInfoRequest(transactionData.accountNumber);

      redirectUrl = getCustomerLink(transactionData.accountNumber, customerInfo.status, customerInfo.type);
    }
    navigate(redirectUrl);
  };

  return (
    <div className={'transaction-details-info-wrapper'}>
      {hasRole(TRANSACTION_UPDATE) && transactionData && (
        <div className={'transaction-details-actions-wrapper'}>
          {transactionData.status === TRANSACTION_STATUS.ON_HOLD && (
            <Button
              className={'transaction-details-actions-button'}
              size={'medium'}
              type={'primary'}
              onClick={handleAcceptOnHold}
              isDisabled={isLoading}
            >
              {i18n.getMessage('transactionDetails.button.accept')}
            </Button>
          )}

          {isAcceptRejectProcessing(transactionData) && (
            <Button
              className={'transaction-details-actions-button'}
              size={'medium'}
              type={'primary'}
              onClick={handleAcceptProcessing}
              isDisabled={isLoading}
            >
              {i18n.getMessage('transactionDetails.button.accept')}
            </Button>
          )}

          {(transactionData.status === TRANSACTION_STATUS.ON_HOLD || isAcceptRejectProcessing(transactionData)) && (
            <Button
              className={'transaction-details-actions-button'}
              size={'medium'}
              type={'primary'}
              onClick={handleReject}
              isDisabled={false}
            >
              {i18n.getMessage('transactionDetails.button.reject')}
            </Button>
          )}

          {isReturn(transactionData) && (
            <Button
              className={'transaction-details-actions-button'}
              size={'medium'}
              type={'primary'}
              onClick={handleReturn}
              isDisabled={transactionData?.relatedTransactionNumber}
            >
              {transactionData?.relatedTransactionNumber
                ? i18n.getMessage('transactionDetails.button.returned')
                : i18n.getMessage('transactionDetails.button.return')}
            </Button>
          )}
        </div>
      )}

      <div className={'transaction-details-tables-wrapper'}>
        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.transactionNumber')}
              </td>
              <td className={'table-cell'}>{transactionData.transactionNumber}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.customerId')}</td>
              <td className={'table-cell'}>
                <Button
                  className={'link-button nav-link-text'}
                  onClick={async () => await handleRedirectToCustomer(transactionData.accountNumber)}
                  isDisabled={false}
                >
                  {transactionData.accountNumber}
                </Button>
              </td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.createdAt')}</td>
              <td className={'table-cell'}>{initialedDate}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.updatedAt')}</td>
              <td className={'table-cell'}>{updatedDate}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.transferProvider')}</td>
              <td className={'table-cell'}>{i18n.getMessage(`paymentProvider.${transactionData.transferProvider}`)}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.transferType')}</td>
              <td className={'table-cell'}>{i18n.getMessage(`transferType.${transactionData.transferType}`)}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.transactionType')}</td>
              <td className={'table-cell'}>{i18n.getMessage(`transactionType.${transactionData.transactionType}`)}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.paymentMethod')}</td>
              <td className={'table-cell'}>{i18n.getMessage(`paymentMethod.${transactionData.paymentMethod}`)}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.transactionStatus')}
              </td>
              <td className={'table-cell'}>{i18n.getMessage(`transaction.status.${transactionData.status}`)}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.documentStatus')}</td>
              <td className={'table-cell'}>
                {
                  <InputDropDown
                    className={'table-cell-input'}
                    name={'documentStatus'}
                    options={documentStatusOptions}
                    value={transactionData.documentStatus}
                    onChange={handleChangeDocumentStatus}
                    isDisabled={
                      !hasRole(TRANSACTION_UPDATE) ||
                      transactionData.status === TRANSACTION_STATUS.REJECTED ||
                      transactionData.status === TRANSACTION_STATUS.FAILED
                    }
                  />
                }
              </td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.externalReference')}
              </td>
              <td className={'table-cell'}>{transactionData.externalReference}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.amount')}</td>
              <td className={'table-cell'}>
                {formatMoney(
                  transactionData.amount,
                  isCryptoProvider(transactionData.transferProvider)
                    ? CRYPTO_CURRENCY_PRECISION
                    : COMMON_CURRENCY_PRECISION
                )}
              </td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.currency')}</td>
              <td className={'table-cell'}>{transactionData.currency}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.exchangeRate')}</td>
              <td className={'table-cell'}>{transactionData.appliedRate?.rate || null}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.commission')}</td>
              <td className={'table-cell'}>
                {formatMoney(
                  transactionData.commission,
                  isCryptoProvider(transactionData.transferProvider)
                    ? CRYPTO_CURRENCY_PRECISION
                    : COMMON_CURRENCY_PRECISION
                )}
              </td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.comment')}</td>
              <td className={'table-cell'}>{transactionData.comment}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.commentToAdmin')}</td>
              <td className={'table-cell'}>{transactionData.commentToAdmin}</td>
            </tr>

            {transactionData?.cardAuthorization && (
              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.field.cardAuthorization')}
                </td>
                <td className={'table-cell'}>{transactionData.cardAuthorization.authorizationId}</td>
              </tr>
            )}

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.errorReason')}</td>
              <td className={'table-cell'}>{transactionData.statusDetails}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.documents')}
                <p className={'attach-doc-hint-text'}>
                  {i18n.getMessage('attachDoc.hint.text', {
                    maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
                  })}
                </p>
              </td>
              <td className={'table-cell'}>
                <div key={document.id} className={'documents-list-wrapper'}>
                  {transactionData?.documents.map((document) => (
                    <div key={document.id} className={'document-item-wrapper'}>
                      <Button
                        className={'link-button nav-link-text document-item-label'}
                        onClick={async () => await downloadTransactionDocument(document.id)}
                        isDisabled={false}
                      >
                        {truncateFileName(document.name, 40)}
                      </Button>

                      <div className={'document-item-actions-wrapper'}>
                        <IconButton
                          className={'document-item-action-edit'}
                          Icon={EditIcon}
                          onClick={async () => await handleOpenAttachDocumentPopUp(document)}
                        />
                        <IconButton
                          className={'document-item-action-remove'}
                          Icon={DeleteIcon}
                          onClick={async () => await deleteTransactionDocument(document.id)}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className={'transaction-file-selector-wrapper'}>
                  <Button
                    className={'transaction-file-selector-button'}
                    type={'primary'}
                    roleType={'button'}
                    onClick={() => handleOpenAttachDocumentPopUp()}
                  >
                    {i18n.getMessage('transactionDetails.button.attachDocument')}
                  </Button>
                </div>
              </td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.mlroApproval')}
                <br />
                <small>
                  <i>
                    {transactionData.mlroApproval.updatedAt && formatDateTime(transactionData.mlroApproval.updatedAt)}
                    {transactionData.mlroApproval.updatedBy && ' ' + transactionData.mlroApproval.updatedBy}
                  </i>
                </small>
              </td>
              <td className={'table-cell'}>
                {
                  <div className={'transaction-details-table-input-wrapper'}>
                    <InputDropDown
                      className={'table-cell-input'}
                      options={mlroApprovalStatusOptions}
                      value={mlroApproval}
                      onChange={handleChangeMlroApprovalStatus}
                      isDisabled={!hasRole(TRANSACTION_UPDATE)}
                    />
                    <Button
                      className={'transaction-details-save-button'}
                      size={'small'}
                      type={'primary'}
                      onClick={() => handleSaveMlroApproval(mlroApproval)}
                      isDisabled={!hasRole(TRANSACTION_UPDATE)}
                    >
                      {i18n.getMessage('transactionDetails.button.save')}
                    </Button>
                  </div>
                }
              </td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.delayed')}</td>
              <td className={'table-cell'}>
                {transactionData?.delayed
                  ? i18n.getMessage('transactionDetails.field.delayed.yes')
                  : i18n.getMessage('transactionDetails.field.delayed.no')}
              </td>
            </tr>
          </tbody>
        </table>

        <table className={'table-wrapper'}>
          <tbody className={'table-body'}>
            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.bankAccountHolder')}
              </td>
              <td className={'table-cell'}>{transactionData?.bankAccountHolder}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.recipientName')}</td>
              <td className={'table-cell'}>{transactionData.recipient?.partyName}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.recipientAddress')}</td>
              <td className={'table-cell'}>{transactionData?.recipient?.partyAddress}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.recipientCity')}</td>
              <td className={'table-cell'}>{transactionData?.recipient?.partyCity}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.recipientPostCode')}
              </td>
              <td className={'table-cell'}>{transactionData?.recipient?.partyPostCode}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.recipientCountry')}</td>
              <td className={'table-cell'}>{transactionData?.recipient?.partyCountry}</td>
            </tr>

            {isCryptoProvider(transactionData.transferProvider) ? (
              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.field.recipientCryptoWalletAddress')}
                </td>
                <td className={'table-cell'}>{transactionData?.recipient?.cryptoWalletAddress}</td>
              </tr>
            ) : (
              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.recipientIban')}</td>
                <td className={'table-cell'}>{transactionData?.recipient?.iban}</td>
              </tr>
            )}

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.recipientAccountNumber')}
              </td>
              <td className={'table-cell'}>{transactionData?.recipient?.accountNumber}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.recipientSortCode')}
              </td>
              <td className={'table-cell'}>{transactionData?.recipient?.sortCode}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.recipientBank')}</td>
              <td className={'table-cell'}>{transactionData?.recipient?.bankName}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.recipientBankCountry')}
              </td>
              <td className={'table-cell'}>{transactionData?.recipient?.bankCountry}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.recipientBankAddress')}
              </td>
              <td className={'table-cell'}>{transactionData?.recipient?.bankAddress}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.recipientRegistrationNumber')}
              </td>
              <td className={'table-cell'}>{transactionData?.recipient?.partyRegistrationNumber}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.recipientBankSwiftCode')}
              </td>
              <td className={'table-cell'}>{transactionData?.recipient?.bic}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.senderName')}</td>
              <td className={'table-cell'}>{transactionData.sender?.partyName}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.senderCountry')}</td>
              <td className={'table-cell'}>{transactionData.sender?.partyCountry}</td>
            </tr>

            {isCryptoProvider(transactionData.transferProvider) ? (
              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.field.senderCryptoWalletAddress')}
                </td>
                <td className={'table-cell'}>{transactionData?.sender?.cryptoWalletAddress}</td>
              </tr>
            ) : (
              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.senderIban')}</td>
                <td className={'table-cell'}>{transactionData.sender?.iban}</td>
              </tr>
            )}

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.senderAccountNumber')}
              </td>
              <td className={'table-cell'}>{transactionData.sender?.accountNumber}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.field.senderBank')}</td>
              <td className={'table-cell'}>{transactionData.sender?.bankName}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.senderBankCountry')}
              </td>
              <td className={'table-cell'}>{transactionData.sender?.bankCountry}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.senderBankAddress')}
              </td>
              <td className={'table-cell'}>{transactionData?.sender?.bankAddress}</td>
            </tr>

            <tr className={'table-row'}>
              <td className={'table-cell row-label'}>
                {i18n.getMessage('transactionDetails.field.senderBankSwiftCode')}
              </td>
              <td className={'table-cell'}>{transactionData.sender?.bic}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p className={'rule-engine-header'}>{i18n.getMessage('transactionDetails.limits')}</p>
      {paymentLimits ? (
        <div className={'transaction-details-payment-limits-wrapper'}>
          <div className={'transaction-details-tables-wrapper'}>
            <table className={'table-wrapper'}>
              <tbody className={'table-body'}>
                <tr className={'table-row'}>
                  <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.limits.status')}</td>
                  <td className={'table-cell'}>{paymentLimits?.status}</td>
                </tr>
              </tbody>
            </table>

            <table className={'table-wrapper'}>
              <tbody className={'table-body'}>
                <tr className={'table-row'}>
                  <td className={'table-cell row-label'}>
                    {i18n.getMessage('transactionDetails.ruleEngine.externalStatus')}
                  </td>
                  <td className={'table-cell'}>{formatDateTime(paymentLimits?.createdAt)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {paymentLimits?.details && (
            <Table responsive>
              <thead>
                <tr>
                  <th className={'advanced-details-table-head'}>
                    <div className={'advanced-details-table-head-block'}>
                      <span>{i18n.getMessage('transactionDetails.limits.details.limitGroup')}</span>
                    </div>
                  </th>
                  <th className={'advanced-details-table-head'}>
                    <div className={'advanced-details-table-head-block'}>
                      <span>{i18n.getMessage('transactionDetails.limits.details.limitType')}</span>
                    </div>
                  </th>
                  <th className={'advanced-details-table-head'}>
                    <div className={'advanced-details-table-head-block'}>
                      <span>{i18n.getMessage('transactionDetails.limits.details.subtype')}</span>
                    </div>
                  </th>
                  <th className={'advanced-details-table-head'}>
                    <div className={'advanced-details-table-head-block'}>
                      <span>{i18n.getMessage('transactionDetails.limits.details.hold')}</span>
                    </div>
                  </th>
                  <th className={'advanced-details-table-head'}>
                    <div className={'advanced-details-table-head-block'}>
                      <span>{i18n.getMessage('transactionDetails.limits.details.reject')}</span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {JSON.parse(paymentLimits.details).map((limit, index) => (
                  <tr key={index}>
                    <td className={'advanced-details-table-cell'}>
                      <Button
                        className={'link-button advanced-details-table-cell-button'}
                        onClick={async () => await handleRedirectToLimits(limit.group)}
                        isDisabled={false}
                      >
                        {limit.group}
                      </Button>
                    </td>
                    <td className={'advanced-details-table-cell'}>
                      <p>{limit.type}</p>
                    </td>
                    <td className={'advanced-details-table-cell'}>
                      <p>{limit.subType}</p>
                    </td>
                    <td className={'advanced-details-table-cell'}>
                      <p>{limit.holdAmount}</p>
                    </td>
                    <td className={'advanced-details-table-cell'}>
                      <p>{limit.rejectAmount}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      ) : (
        <div className={'transaction-details-tables-wrapper'}>
          <table className={'table-wrapper'}>
            <tbody className={'table-body'}>
              <tr className={'table-row'}>
                <td className={'table-cell empty-rule-engine-cell'}>
                  {i18n.getMessage('transactionDetails.limits.notFound')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <p className={'rule-engine-header'}>{i18n.getMessage('transactionDetails.amlCheckResult')}</p>
      {sanctions ? (
        <div className={'transaction-details-tables-wrapper'}>
          <table className={'table-wrapper'}>
            <tbody className={'table-body'}>
              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.amlCheckResult.field.status')}
                </td>
                <td className={'table-cell'}>{sanctions?.status}</td>
              </tr>

              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.amlCheckResult.field.createdAt')}
                </td>
                <td className={'table-cell'}>{formatDateTime(sanctions?.createdAt)}</td>
              </tr>

              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.amlCheckResult.field.updatedAt')}
                </td>
                <td className={'table-cell'}>{formatDateTime(sanctions?.updatedAt)}</td>
              </tr>
            </tbody>
          </table>

          <table className={'table-wrapper'}>
            <tbody className={'table-body'}>
              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.amlCheckResult.field.partyName')}
                </td>
                <td className={'table-cell'}>{sanctions?.partyDetails}</td>
              </tr>

              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.amlCheckResult.field.counterPartyName')}
                </td>
                <td className={'table-cell'}>{sanctions?.counterPartyDetails}</td>
              </tr>

              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.amlCheckResult.field.counterPartyBankName')}
                </td>
                <td className={'table-cell'}>{sanctions?.counterPartyBankDetails}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className={'transaction-details-tables-wrapper'}>
          <table className={'table-wrapper'}>
            <tbody className={'table-body'}>
              <tr className={'table-row'}>
                <td className={'table-cell empty-rule-engine-cell'}>
                  {i18n.getMessage('transactionDetails.amlCheckResult.passed')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <p className={'rule-engine-header'}>{i18n.getMessage('transactionDetails.blacklistDetails')}</p>
      {blacklistDetails ? (
        <div className={'transaction-details-tables-wrapper'}>
          <table className={'table-wrapper'}>
            <tbody className={'table-body'}>
              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.blacklistDetails.field.status')}
                </td>
                <td className={'table-cell'}>{blacklistDetails?.status}</td>
              </tr>

              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.blacklistDetails.field.createdAt')}
                </td>
                <td className={'table-cell'}>{formatDateTime(blacklistDetails?.createdAt)}</td>
              </tr>

              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.blacklistDetails.field.commentDetails')}
                </td>
                <td className={'table-cell'}>{blacklistDetails?.commentDetails}</td>
              </tr>
            </tbody>
          </table>

          <table className={'table-wrapper'}>
            <tbody className={'table-body'}>
              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.blacklistDetails.field.partyName')}
                </td>
                <td className={'table-cell'}>{blacklistDetails?.partyDetails}</td>
              </tr>

              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.blacklistDetails.field.counterPartyName')}
                </td>
                <td className={'table-cell'}>{blacklistDetails?.counterPartyDetails}</td>
              </tr>

              <tr className={'table-row'}>
                <td className={'table-cell row-label'}>
                  {i18n.getMessage('transactionDetails.blacklistDetails.field.counterPartyBankName')}
                </td>
                <td className={'table-cell'}>{blacklistDetails?.counterPartyBankDetails}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className={'transaction-details-tables-wrapper'}>
          <table className={'table-wrapper'}>
            <tbody className={'table-body'}>
              <tr className={'table-row'}>
                <td className={'table-cell empty-rule-engine-cell'}>
                  {i18n.getMessage('transactionDetails.blacklistDetails.notFound')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <p className={'rule-engine-header'}>{i18n.getMessage('transactionDetails.ruleEngine')}</p>
      {ruleEngineScoring ? (
        <div className={'transaction-details-rule-engine-scoring-wrapper'}>
          <div className={'transaction-details-tables-wrapper'}>
            <table className={'table-wrapper'}>
              <tbody className={'table-body'}>
                <tr className={'table-row'}>
                  <td className={'table-cell row-label'}>
                    {i18n.getMessage('transactionDetails.ruleEngine.provider')}
                  </td>
                  <td className={'table-cell'}>{ruleEngineScoring?.provider}</td>
                </tr>

                <tr className={'table-row'}>
                  <td className={'table-cell row-label'}>{i18n.getMessage('transactionDetails.ruleEngine.status')}</td>
                  <td className={'table-cell'}>{ruleEngineScoring?.status}</td>
                </tr>

                <tr className={'table-row'}>
                  <td className={'table-cell row-label'}>
                    {i18n.getMessage('transactionDetails.ruleEngine.createdAt')}
                  </td>
                  <td className={'table-cell'}>{ruleEngineScoring?.createdAt}</td>
                </tr>

                <tr className={'table-row'}>
                  <td className={'table-cell row-label'}>
                    {i18n.getMessage('transactionDetails.ruleEngine.updatedAt')}
                  </td>
                  <td className={'table-cell'}>{ruleEngineScoring?.updatedAt}</td>
                </tr>
              </tbody>
            </table>

            <table className={'table-wrapper'}>
              <tbody className={'table-body'}>
                <tr className={'table-row'}>
                  <td className={'table-cell row-label'}>
                    {i18n.getMessage('transactionDetails.ruleEngine.externalStatus')}
                  </td>
                  <td className={'table-cell'}>{ruleEngineScoring?.externalStatus}</td>
                </tr>

                <tr className={'table-row'}>
                  <td className={'table-cell row-label'}>
                    {i18n.getMessage('transactionDetails.ruleEngine.externalId')}
                  </td>
                  <td className={'table-cell'}>{ruleEngineScoring?.externalId}</td>
                </tr>

                <tr className={'table-row'}>
                  <td className={'table-cell row-label'}>
                    {i18n.getMessage('transactionDetails.ruleEngine.externalScore')}
                  </td>
                  <td className={'table-cell'}>{ruleEngineScoring?.externalScore}</td>
                </tr>

                <tr className={'table-row'}>
                  <td className={'table-cell row-label'}>
                    {i18n.getMessage('transactionDetails.ruleEngine.externalLink')}
                  </td>
                  <td className={'table-cell'}>
                    {ruleEngineScoring?.externalLink && (
                      <a
                        className={'nav-link-text'}
                        href={ruleEngineScoring.externalLink}
                        target={'_blank'}
                        rel={'noreferrer'}
                      >
                        {ruleEngineScoring.externalLink}
                      </a>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {ruleEngineScoring?.details && (
            <Table responsive>
              <thead>
                <tr>
                  <th className={'advanced-details-table-head'}>
                    <div className={'advanced-details-table-head-block'}>
                      <span>{i18n.getMessage('transactionDetails.ruleEngine.appliedRules.name')}</span>
                    </div>
                  </th>
                  <th className={'advanced-details-table-head'}>
                    <div className={'advanced-details-table-head-block'}>
                      <span>{i18n.getMessage('transactionDetails.ruleEngine.appliedRules.score')}</span>
                    </div>
                  </th>
                  <th className={'advanced-details-table-head'}>
                    <div className={'advanced-details-table-head-block'}>
                      <span>{i18n.getMessage('transactionDetails.ruleEngine.appliedRules.status')}</span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {JSON.parse(ruleEngineScoring.details).rules?.map((rule, index) => (
                  <tr key={index}>
                    <td className={'advanced-details-table-cell'}>
                      <p>{rule.name}</p>
                    </td>
                    <td className={'advanced-details-table-cell'}>
                      <p>{rule.score}</p>
                    </td>
                    <td className={'advanced-details-table-cell'}>
                      <p>{rule.status}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      ) : (
        <div className={'transaction-details-tables-wrapper'}>
          <table className={'table-wrapper'}>
            <tbody className={'table-body'}>
              <tr className={'table-row'}>
                <td className={'table-cell empty-rule-engine-cell'}>
                  {i18n.getMessage('transactionDetails.ruleEngine.notFound')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

TransactionDetailsTable.propTypes = {
  isLoading: PropTypes.bool,
  transactionData: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  paymentLimits: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  sanctions: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  blacklistDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  ruleEngineScoring: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  handleAcceptOnHold: PropTypes.func,
  handleAcceptProcessing: PropTypes.func,
  handleReject: PropTypes.func,
  handleReturn: PropTypes.func,
  handleChangeDocumentStatus: PropTypes.func,
  handleSaveMlroApproval: PropTypes.func,
  handleOpenAttachDocumentPopUp: PropTypes.func,
  downloadTransactionDocument: PropTypes.func,
  deleteTransactionDocument: PropTypes.func
};

export default TransactionDetailsTable;
