import i18n from 'i18n';
import { ReactComponent as MenuIcon } from 'assets/iconButtons/menu-icon.svg';
import { ReactComponent as MenuIconMobile } from 'assets/iconButtons/menu-mobile-icon.svg';
import { ReactComponent as LogoutIcon } from 'assets/iconButtons/logout.svg';
import { ReactComponent as CrmIcon } from 'assets/iconButtons/crm.svg';
import { ReactComponent as ActiveCustomersIcon } from 'assets/iconButtons/active-customers.svg';
import { ReactComponent as UsersIcon } from 'assets/iconButtons/users.svg';
import { ReactComponent as AccountsIcon } from 'assets/iconButtons/accounts.svg';
import { ReactComponent as SystemAccountsIcon } from 'assets/iconButtons/system-accounts.svg';
import { ReactComponent as TransactionsIcon } from 'assets/iconButtons/transactions.svg';
import { ReactComponent as TransitTransactionsIcon } from 'assets/iconButtons/transit-transaction.svg';
import { ReactComponent as PaymentObligationsIcon } from 'assets/iconButtons/payment-obligations.svg';
import { ReactComponent as CreateTransactionIcon } from 'assets/iconButtons/create-transaction.svg';
import { ReactComponent as CardAuthorizationsIcon } from 'assets/iconButtons/card-authorisations.svg';
import { ReactComponent as MessagesIcon } from 'assets/iconButtons/messages.svg';
import { ReactComponent as CommissionIcon } from 'assets/iconButtons/commissions.svg';
import { ReactComponent as SettingsIcon } from 'assets/iconButtons/settings.svg';
import { ReactComponent as EmailStatisticsIcon } from 'assets/iconButtons/email-statistics.svg';
import { ICON_BUTTONS_TYPE } from 'components/constants';
import { ROUTE_PATHS } from 'routes/constants';

export const getAppRouteButtons = () => [
  {
    id: 0,
    type: ICON_BUTTONS_TYPE.OTHER,
    icon: MenuIcon,
    mobileIcon: MenuIconMobile,
    text: i18n.getMessage('navigationBar.menu'),
    onClick: () => {},
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 4,
    type: ICON_BUTTONS_TYPE.NAVIGATION,
    icon: LogoutIcon,
    text: i18n.getMessage('navigationBar.logOut'),
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 5,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: CrmIcon,
    text: i18n.getMessage('navigationBar.customers'),
    path: ROUTE_PATHS.CUSTOMERS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 6,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: ActiveCustomersIcon,
    text: i18n.getMessage('navigationBar.activeCustomers'),
    path: ROUTE_PATHS.ACTIVE_CUSTOMERS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 7,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: UsersIcon,
    text: i18n.getMessage('navigationBar.users'),
    path: ROUTE_PATHS.USERS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 8,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: AccountsIcon,
    text: i18n.getMessage('navigationBar.accounts'),
    path: ROUTE_PATHS.ACCOUNTS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 9,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: SystemAccountsIcon,
    text: i18n.getMessage('navigationBar.systemAccounts'),
    path: ROUTE_PATHS.SYSTEM_ACCOUNTS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 10,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: TransactionsIcon,
    text: i18n.getMessage('navigationBar.transactions'),
    path: ROUTE_PATHS.TRANSACTIONS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 11,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: TransitTransactionsIcon,
    text: i18n.getMessage('navigationBar.transitTransactions'),
    path: ROUTE_PATHS.TRANSIT_TRANSACTIONS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 12,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: PaymentObligationsIcon,
    text: i18n.getMessage('navigationBar.paymentObligations'),
    path: ROUTE_PATHS.PAYMENT_OBLIGATIONS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 13,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: CreateTransactionIcon,
    text: i18n.getMessage('navigationBar.createTransaction'),
    path: ROUTE_PATHS.CREATE_TRANSACTION,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 14,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: CardAuthorizationsIcon,
    text: i18n.getMessage('navigationBar.cardAuthorizations'),
    path: ROUTE_PATHS.CARD_AUTHORIZATIONS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 15,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: MessagesIcon,
    text: i18n.getMessage('navigationBar.messages'),
    path: ROUTE_PATHS.MESSAGES,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 16,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: CommissionIcon,
    text: i18n.getMessage('navigationBar.commissions'),
    path: ROUTE_PATHS.COMMISSIONS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 17,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: SettingsIcon,
    text: i18n.getMessage('navigationBar.settings'),
    path: ROUTE_PATHS.SETTINGS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 18,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: EmailStatisticsIcon,
    text: i18n.getMessage('navigationBar.emailStatistics'),
    path: ROUTE_PATHS.EMAIL_STATISTICS,
    isDisabled: false,
    isDisplay: true
  }
];

export const getHeaderButtons = () =>
  getAppRouteButtons().filter((button) => button && button.type === ICON_BUTTONS_TYPE.NAVIGATION && button.isDisplay);
export const getNavigationButtons = () =>
  getAppRouteButtons().filter((button) => button && button.type === ICON_BUTTONS_TYPE.MENU && button.isDisplay);
