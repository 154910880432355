import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import { TransactionNotesHeadRow } from './TransactionNotesHeadRow';
import TransactionNotesTableRow from './TransactionNotesTableRow';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import './TransactionNotes.scss';

const TransactionNotes = ({
  isLoading,
  transactionNotes,
  handleLoadTransactionNotes,
  pagination,
  setPage,
  setSize,
  setFiltersFromUrl
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = () => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.set('page', pagination.page.toString());
    currentParams.set('size', pagination.size.toString());

    navigate({ search: `?${currentParams.toString()}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateFiltersInUrl();
    handleLoadTransactionNotes();
  }, [pagination.size, pagination.page]);

  const handleSetCurrentPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleSetPageSize = (size) => {
    setSize(size);
  };

  if (!transactionNotes) {
    return <Loader className={'application-loader transaction-notes-tab-loader'} />;
  }

  return (
    <div className={'transactionNotes-container'}>
      <Pagination
        isShowPaginationSize={true}
        paginationSize={pagination.size}
        handleChangePaginationSize={handleSetPageSize}
        isLoading={isLoading}
        currentPage={pagination.page}
        numberOfPages={pagination.totalPages}
        setCurrentPage={handleSetCurrentPage}
      />

      <Table responsive>
        <thead>
          <TransactionNotesHeadRow />
        </thead>
        <tbody>
          {Object.keys(transactionNotes).map((transactionId, index) => {
            const notes = transactionNotes[transactionId];

            return (
              <TransactionNotesTableRow
                isLoading={false}
                transactionNoteData={notes}
                transactionId={transactionId}
                key={index}
              />
            );
          })}
        </tbody>
      </Table>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={pagination.size}
        handleChangePaginationSize={handleSetPageSize}
        isLoading={isLoading}
        currentPage={pagination.page}
        numberOfPages={pagination.totalPages}
        setCurrentPage={handleSetCurrentPage}
      />
    </div>
  );
};

TransactionNotes.propTypes = {
  isLoading: PropTypes.bool,
  transactionNotes: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  handleLoadTransactionNotes: PropTypes.func,
  pagination: PropTypes.shape({
    size: PropTypes.number,
    page: PropTypes.number,
    totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])])
  }),
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  setFiltersFromUrl: PropTypes.func
};

export default TransactionNotes;
