import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { CURRENCY, PAYMENT_PROVIDER, TRANSACTION_TYPES } from 'components/constants';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './CommissionTransactionsFiltersBar.scss';

const CommissionTransactionsFiltersBar = ({
  isLoading,
  isInitialized,
  isLoadingCommissionTransactions,
  filters,
  handleSetFilters,
  handleDownloadCommissionTransactionsReport
}) => {
  const i18n = useContext(i18nContext);
  const currentDate = new Date();

  const transferProviderOptions = Object.keys(PAYMENT_PROVIDER).map((provider) => {
    return {
      key: PAYMENT_PROVIDER[provider].name,
      value: i18n.getMessage('paymentProvider.' + PAYMENT_PROVIDER[provider].name)
    };
  });

  const transactionsTypesOptions = Object.keys(TRANSACTION_TYPES).map((status) => {
    return {
      key: TRANSACTION_TYPES[status],
      value: i18n.getMessage('transactionType.' + TRANSACTION_TYPES[status])
    };
  });

  const currencyOptions = Object.keys(CURRENCY).map((status) => {
    return {
      key: CURRENCY[status],
      value: CURRENCY[status]
    };
  });

  return (
    <div className={'commission-transactions-filters-wrapper'}>
      <div className={'commission-transactions-actions-wrapper'}>
        <div className={'commission-transactions-date-range-filters-wrapper'}>
          <div>
            <span>{i18n.getMessage('commissions.commissionTransactions.filter.from.label')}</span>
            <CustomDatePicker
              onChange={handleSetFilters}
              name={'from'}
              value={filters.from}
              maxDate={filters.to || currentDate}
            />
          </div>
          <div>
            <span>{i18n.getMessage('commissions.commissionTransactions.filter.to.label')}</span>
            <CustomDatePicker
              onChange={handleSetFilters}
              name={'to'}
              value={filters.to}
              maxDate={currentDate}
              minDate={filters.from}
            />
          </div>
        </div>
        <div className={'export-commission-transactions-reports-actions-wrapper'}>
          <Button
            className={'commission-transactions-button'}
            size={'medium'}
            type={'primary'}
            onClick={handleDownloadCommissionTransactionsReport}
            isDisabled={!isInitialized || isLoadingCommissionTransactions || isLoading}
          >
            {isLoadingCommissionTransactions ? (
              <Loader />
            ) : (
              i18n.getMessage('commissions.commissionTransactions.button.downloadReport')
            )}
          </Button>
        </div>
      </div>

      <InputDropDown
        className={'commission-transactions-filter-wrapper'}
        name={'transferProviders'}
        label={i18n.getMessage('commissions.commissionTransactions.filter.transferProvider.label')}
        value={filters.transferProviders}
        options={transferProviderOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'commission-transactions-filter-wrapper'}
        label={i18n.getMessage('commissions.commissionTransactions.filter.transactionType.label')}
        name={'types'}
        value={filters.types}
        options={transactionsTypesOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDownSearch
        className={'commission-transactions-filter-search-select-wrapper'}
        label={i18n.getMessage('commissions.commissionTransactions.filter.currency.label')}
        name={'currencies'}
        value={filters.currencies}
        options={currencyOptions}
        onChange={handleSetFilters}
        isMulti
      />
    </div>
  );
};

CommissionTransactionsFiltersBar.propTypes = {
  isLoading: PropTypes.bool,
  isInitialized: PropTypes.bool,
  isLoadingCommissionTransactions: PropTypes.bool,
  filters: PropTypes.object,
  handleSetFilters: PropTypes.func,
  handleDownloadCommissionTransactionsReport: PropTypes.func
};

export default CommissionTransactionsFiltersBar;
