import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import useDeviceCheck from 'services/useDeviceCheck';
import { ROUTE_PATHS } from 'routes/constants';
import { classNames } from 'uikit/utils';
import './IconButton.scss';

export const IconButton = ({
  Icon,
  text,
  onClick,
  path,
  navigationType,
  active,
  className,
  isDisabled,
  unreadTopics,
  tooltipText,
  isTab
}) => {
  const isMobileDevice = useDeviceCheck();
  const IconButtonClasses = classNames({
    'icon-button': true,
    active: !!active,
    disabled: isDisabled,
    [className]: true
  });

  const handleClick = (e) => {
    if (isDisabled || isTab) e.preventDefault();
    if (onClick) onClick();
  };

  if (navigationType) {
    return (
      <div className={'icon-button-wrapper'}>
        <NavLink className={IconButtonClasses} to={{ pathname: path }} onClick={handleClick}>
          {unreadTopics && unreadTopics > 0 && path === ROUTE_PATHS.MESSAGES ? (
            <div className={'message-icon-wrapper'}>
              <div className={'unread-message-count'}>{unreadTopics}</div>
              {Icon && <Icon />}
            </div>
          ) : (
            <>{Icon && <Icon />}</>
          )}
          <p className={'icon-button-label'}>{text}</p>
        </NavLink>
        {tooltipText && (
          <div className={'tooltip'}>
            <p className={'tooltip-text'}>{tooltipText}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={'icon-button-wrapper'}>
      <button
        className={IconButtonClasses}
        onClick={isMobileDevice ? null : handleClick}
        disabled={isDisabled}
        onTouchEnd={isMobileDevice ? handleClick : null}
      >
        {Icon && <Icon />}
        <p className={'icon-button-label'}>{text}</p>
      </button>
      {tooltipText && (
        <div className={'tooltip'}>
          <p className={'tooltip-text'}>{tooltipText}</p>
        </div>
      )}
    </div>
  );
};

IconButton.propTypes = {
  Icon: PropTypes.object,
  text: PropTypes.string,
  navigationType: PropTypes.bool,
  path: PropTypes.string,
  active: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  unreadTopics: PropTypes.number,
  tooltipText: PropTypes.string,
  isTab: PropTypes.bool
};
