import PropTypes from 'prop-types';
import { Placeholder } from 'react-bootstrap';
import './MonitoringTabTableRow.scss';

export const MonitoringTabTableRow = ({
  isLoading,
  monitoringData,
  handleOpenTransactionDetails,
  downloadDocumentById
}) => {
  const getMonitoringTabInfoBlock = (monitoringBlockInfo, callBack) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'monitoring-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (callBack) {
      return (
        // eslint-disable-next-line
        <p className={'monitoring-table-cell-info-link'} onClick={callBack}>
          {monitoringBlockInfo}
        </p>
      );
    } else {
      return <p>{monitoringBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'monitoring-table-cell'}>
        <div className={'monitoring-table-cell-info'}>
          {getMonitoringTabInfoBlock(monitoringData.transactionNumber, () =>
            handleOpenTransactionDetails(monitoringData.transactionNumber)
          )}
        </div>
      </td>
      <td className={'monitoring-table-cell'}>{getMonitoringTabInfoBlock(monitoringData.partyName)}</td>
      <td className={'monitoring-table-cell'}>
        <div className={'monitoring-table-cell-info'}>
          {getMonitoringTabInfoBlock(monitoringData.name, () => downloadDocumentById(monitoringData.id))}
        </div>
      </td>
    </tr>
  );
};

MonitoringTabTableRow.propTypes = {
  isLoading: PropTypes.bool,
  handleOpenTransactionDetails: PropTypes.func,
  downloadDocumentById: PropTypes.func,
  monitoringData: PropTypes.shape({
    transactionNumber: PropTypes.string,
    transaction_updated_at: PropTypes.string,
    partyName: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string
  })
};
