import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as BackArrowIcon } from 'assets/iconButtons/back-arrow-icon.svg';
import i18nContext from 'components/i18n-context';
import './BackButton.scss';

const BackButton = ({ redirectRoutePath }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const navigateBackOneStep = () => {
    if (redirectRoutePath) {
      navigate(redirectRoutePath);
    } else {
      const pathSegments = location.pathname.split('/');

      pathSegments.pop();
      navigate(pathSegments.join('/'));
    }
  };

  return (
    <div
      className={'back-button-container'}
      role={'button'}
      tabIndex={0}
      onClick={navigateBackOneStep}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          navigateBackOneStep();
        }
      }}
    >
      <BackArrowIcon />
      <span className={'back-button-text'}>{i18n.getMessage('button.back')}</span>
    </div>
  );
};

BackButton.propTypes = {
  redirectRoutePath: PropTypes.string
};

export default BackButton;
