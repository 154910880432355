import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import SystemAccountsHeadRow from './components/SystemAccountsHeadRow';
import SystemAccountsTableRow from './components/SystemAccountsTableRow';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import './SystemAccountsTable.scss';

const SystemAccountsTable = ({ accountsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();

  useEffect(() => {
    if (accountsStore.isInitialized) {
      accountsStore.resetAccountsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (!accountsStore.isInitialized) {
      accountsStore.getSystemAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsStore.isInitialized]);

  if (!accountsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'system-accounts-container'} header={i18n.getMessage('container.systemAccounts')}>
      <div className={'system-accounts-actions-wrapper'}>
        <Button
          className={'system-accounts-button'}
          size={'medium'}
          type={'primary'}
          onClick={accountsStore.handleExportSystemAccounts}
          isDisabled={!accountsStore.isInitialized || accountsStore.isLoadingTransitAccountsBalancesCsv}
        >
          {accountsStore.isLoadingTransitAccountsBalancesCsv ? (
            <Loader />
          ) : (
            i18n.getMessage('systemAccounts.button.transitAccountsCsv')
          )}
        </Button>
      </div>

      <Table responsive>
        <thead>
          <SystemAccountsHeadRow />
        </thead>
        <tbody>
          {accountsStore.systemAccounts &&
            Object.values(accountsStore.systemAccounts).map((systemAccount, index) => (
              <SystemAccountsTableRow
                key={index}
                type={'list'}
                isLoading={accountsStore.isLoading}
                systemAccountData={systemAccount}
              />
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

SystemAccountsTable.propTypes = {
  accountsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  accountsStore: stores.accountsStore
}))(observer(SystemAccountsTable));
