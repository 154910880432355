import { useContext, useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toDto } from './components/TransitTransactionModel';
import { AMOUNT_INPUT_VALUE_TYPE, PAYMENT_PROVIDER, TRANSIT_TRANSACTION_DIRECTION } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import AmountInput from 'uikit/AmountInput';
import Button from 'uikit/Button/Button';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './TransitTransaction.scss';

const TransitTransaction = ({ transitTransactionsStore }) => {
  const i18n = useContext(i18nContext);
  const currentDate = new Date();
  const [currencyOptions, setCurrencyOptions] = useState([]);

  const form = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      amount: '',
      currency: '',
      transferProvider: '',
      externalId: '',
      providerCreatedAt: null,
      transitDirection: TRANSIT_TRANSACTION_DIRECTION.IN
    },
    validationSchema: Yup.object({
      transferProvider: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      transitDirection: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      amount: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      currency: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      externalId: Yup.string().max(255, i18n.getMessage('createTransaction.validation.maxSize', { size: 255 })),
      providerCreatedAt: Yup.date().required(i18n.getMessage('createTransaction.validation.isRequired'))
    }),
    onSubmit: async (values) => {
      await transitTransactionsStore.createTransitTransaction(toDto(values));
    }
  });

  const { values, errors, handleSubmit, handleChange, setFieldValue, resetForm } = form;

  useEffect(() => {
    if (transitTransactionsStore.isNewTransitTransactionCreated) {
      resetForm();
      transitTransactionsStore.setIsNewTransitTransactionCreated(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitTransactionsStore.isNewTransitTransactionCreated]);

  const transferProviderOptions = Object.keys(PAYMENT_PROVIDER).map((provider) => {
    return {
      key: PAYMENT_PROVIDER[provider].name,
      value: i18n.getMessage('paymentProvider.' + PAYMENT_PROVIDER[provider].name)
    };
  });

  const transitDirectionOptions = Object.keys(TRANSIT_TRANSACTION_DIRECTION).map((direction) => {
    return {
      key: direction,
      value: i18n.getMessage('transitTransactionDirection.' + direction)
    };
  });

  const handleChangeValue = (name, value) => {
    return setFieldValue(name, value);
  };

  const handleChangeTransferProvider = (name, value) => {
    const currentProviderCurrencies = value ? PAYMENT_PROVIDER[value].currencies : [];

    if (currentProviderCurrencies.length > 0) {
      setCurrencyOptions(
        currentProviderCurrencies.map((currency) => {
          return {
            key: currency,
            value: currency
          };
        })
      );
    }

    return setFieldValue(name, value);
  };

  return (
    <div className={'transit-transaction-wrapper'}>
      <form className={'transit-transaction-form'} onSubmit={handleSubmit}>
        <div className={'transit-transaction-row-block'}>
          <InputDropDown
            isRequired
            className={'transit-transaction-select'}
            label={i18n.getMessage('createTransaction.transit.transferProvider')}
            id={'transferProvider'}
            name={'transferProvider'}
            value={values.transferProvider}
            options={transferProviderOptions}
            onChange={handleChangeTransferProvider}
            error={errors?.transferProvider}
          />

          <Input
            className={'transit-transaction-input'}
            label={i18n.getMessage('createTransaction.transit.externalId')}
            id={'externalId'}
            name={'externalId'}
            value={values.externalId}
            onChange={handleChange}
            error={errors?.externalId}
          />

          <InputDropDown
            isRequired
            className={'transit-transaction-select'}
            label={i18n.getMessage('createTransaction.transit.transitDirection')}
            id={'transitDirection'}
            name={'transitDirection'}
            value={values.transitDirection}
            options={transitDirectionOptions}
            onChange={handleChangeValue}
            isDisabled={true}
          />
        </div>

        <div className={'transit-transaction-row-block'}>
          <CustomDatePicker
            isRequired
            className={'transit-transaction-date-input'}
            label={i18n.getMessage('createTransaction.transit.providerCreatedAt')}
            name={'providerCreatedAt'}
            value={values.providerCreatedAt}
            onChange={handleChangeValue}
            maxDate={currentDate}
            error={errors?.providerCreatedAt}
          />

          <AmountInput
            isRequired
            className={'transit-transaction-input'}
            label={i18n.getMessage('createTransaction.transit.amount.label')}
            placeholder={i18n.getMessage('createTransaction.transit.amount.placeholder')}
            name={'amount'}
            value={values?.amount}
            returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
            onChange={handleChange}
            error={errors?.amount}
          />

          <InputDropDownSearch
            isRequired
            className={'transit-transaction-select-currency'}
            label={i18n.getMessage('createTransaction.transit.currency')}
            id={'currency'}
            name={'currency'}
            value={values?.currency}
            options={currencyOptions}
            onChange={handleChangeValue}
            error={errors?.currency}
          />
        </div>

        <div className={'transit-transaction-submit-button-wrapper'}>
          <Button
            className={'transit-transaction-submit-button'}
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            onClick={() => {}}
            isDisabled={transitTransactionsStore.isLoading}
          >
            {transitTransactionsStore.isLoading ? (
              <Loader />
            ) : (
              i18n.getMessage('createTransaction.transit.button.createTransaction')
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

TransitTransaction.propTypes = {
  transitTransactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  transitTransactionsStore: stores.transitTransactionsStore
}))(observer(TransitTransaction));
