import { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CUSTOM_TABS_DIRECTION,
  PAYMENT_METHOD,
  TRANSACTION_PARTY_TYPE,
  TRANSACTION_REQUISITES_TAB
} from 'components/constants';
import countries from 'components/countries';
import CustomTabs from 'components/CustomTabs';
import i18nContext from 'components/i18n-context';
import i18n from 'i18n';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './ExternalTransactionPartyControl.scss';

const REQUISITES_TABS = [
  {
    name: TRANSACTION_REQUISITES_TAB.IBAN,
    title: i18n.getMessage('createTransaction.payment.ibanTab.label')
  },
  {
    name: TRANSACTION_REQUISITES_TAB.LOCAL_BANK_ACCOUNT,
    title: i18n.getMessage('createTransaction.payment.localBankAccount.label')
  },
  {
    name: TRANSACTION_REQUISITES_TAB.NON_IBAN_BANK_COUNTRIES,
    title: i18n.getMessage('createTransaction.payment.bankAccount.label')
  }
];

const ExternalTransactionPartyControl = ({
  data,
  errors,
  handleChangeValue,
  handleChangeCountryValue,
  partyLabel,
  setFieldValue,
  validateField
}) => {
  const i18n = useContext(i18nContext);
  const isAdvanced = data?.paymentMethod && [PAYMENT_METHOD.SWIFT, PAYMENT_METHOD.TARGET2].includes(data.paymentMethod);
  const isCompany = data?.partyType && data.partyType === TRANSACTION_PARTY_TYPE.COMPANY;
  const isIndividual = data?.partyType && data.partyType === TRANSACTION_PARTY_TYPE.INDIVIDUAL;

  const transactionPartyTypeOptions = Object.keys(TRANSACTION_PARTY_TYPE).map((type) => {
    return {
      key: TRANSACTION_PARTY_TYPE[type],
      value: i18n.getMessage('transactionPartyType.' + TRANSACTION_PARTY_TYPE[type])
    };
  });

  const countriesOptions = Object.values(countries).map((value) => ({
    key: value,
    value: i18n.getMessage(value)
  }));

  const handleChangePartyType = (name, value) => {
    setFieldValue(name, value);
  };

  return (
    <div className={'external-transaction-party-control-wrapper'}>
      <div className={'external-transaction-party-control-party-info-row'}>
        <InputDropDown
          isRequired
          className={'external-transaction-party-control-select'}
          label={i18n.getMessage('createTransaction.payment.partyType', { partyLabel: partyLabel })}
          id={'partyType'}
          name={'partyType'}
          value={data.partyType}
          options={transactionPartyTypeOptions}
          onChange={handleChangePartyType}
        />

        {isIndividual && (
          <>
            <Input
              isRequired
              className={'external-transaction-party-control-input'}
              label={i18n.getMessage('createTransaction.payment.firstName')}
              id={'partyFirstName'}
              name={'partyFirstName'}
              value={data.partyFirstName}
              onChange={handleChangeValue}
              onBlur={() => validateField('partyFirstName')}
              error={errors?.partyFirstName}
            />
            <Input
              isRequired
              className={'external-transaction-party-control-input'}
              label={i18n.getMessage('createTransaction.payment.lastName')}
              id={'partyLastName'}
              name={'partyLastName'}
              value={data.partyLastName}
              onChange={handleChangeValue}
              onBlur={() => validateField('partyLastName')}
              error={errors?.partyLastName}
            />
          </>
        )}

        {isCompany && (
          <Input
            isRequired
            className={'external-transaction-party-control-input'}
            label={i18n.getMessage('createTransaction.payment.companyName')}
            id={'partyCompanyName'}
            name={'partyCompanyName'}
            value={data.partyCompanyName}
            onChange={handleChangeValue}
            onBlur={() => validateField('partyCompanyName')}
            error={errors?.partyCompanyName}
          />
        )}
      </div>

      {isAdvanced && (
        <div className={'external-transaction-party-control-block-wrapper'}>
          <div className={'external-transaction-party-control-party-info-row'}>
            <Input
              className={'external-transaction-party-control-input'}
              label={i18n.getMessage('createTransaction.payment.registrationNumber')}
              id={'registrationNumber'}
              name={'registrationNumber'}
              value={data.registrationNumber}
              onChange={handleChangeValue}
              onBlur={() => validateField('registrationNumber')}
              error={errors?.registrationNumber}
            />
            <Input
              className={'external-transaction-party-control-input'}
              label={i18n.getMessage('createTransaction.payment.partyCity', { partyLabel: partyLabel })}
              id={'partyCity'}
              name={'partyCity'}
              value={data.partyCity}
              onChange={handleChangeValue}
              onBlur={() => validateField('partyCity')}
              error={errors?.partyCity}
            />
          </div>

          <div className={'external-transaction-party-control-party-info-row'}>
            <InputDropDownSearch
              isRequired
              className={'external-transaction-party-control-search-select'}
              label={i18n.getMessage('createTransaction.payment.partyCountry', { partyLabel: partyLabel })}
              id={'partyCountry'}
              name={'partyCountry'}
              options={countriesOptions}
              value={data.partyCountry}
              onChange={(name, value) => handleChangeCountryValue(name, value)}
              onBlur={() => validateField('partyCountry')}
              error={errors?.partyCountry}
            />
            <Input
              className={'external-transaction-party-control-input'}
              label={i18n.getMessage('createTransaction.payment.partyPostCode', { partyLabel: partyLabel })}
              id={'partyPostCode'}
              name={'partyPostCode'}
              value={data.partyPostCode}
              onChange={handleChangeValue}
              onBlur={() => validateField('partyPostCode')}
              error={errors?.partyPostCode}
            />
          </div>

          <div className={'external-transaction-party-control-party-info-row'}>
            <Input
              className={'external-transaction-party-control-input'}
              label={i18n.getMessage('createTransaction.payment.partyAddress', { partyLabel: partyLabel })}
              id={'partyAddress'}
              name={'partyAddress'}
              value={data.partyAddress}
              onChange={handleChangeValue}
              onBlur={() => validateField('partyAddress')}
              error={errors?.partyAddress}
            />
          </div>
        </div>
      )}

      <div className={'external-transaction-party-control-party-tabs'}>
        <CustomTabs
          tabList={REQUISITES_TABS}
          direction={CUSTOM_TABS_DIRECTION.HORIZONTAL}
          activeTab={data.activePartyTab}
          setActiveTab={(value) => setFieldValue('activePartyTab', value)}
        />
      </div>

      <div className={'external-transaction-party-control-block-wrapper'}>
        {/* If activeParty tab is IBAN */}
        {data.activePartyTab === TRANSACTION_REQUISITES_TAB.IBAN && (
          <div className={'external-transaction-party-control-party-info-row'}>
            <Input
              isRequired
              className={'external-transaction-party-control-input'}
              label={i18n.getMessage('createTransaction.payment.partyIban.label', { partyLabel: partyLabel })}
              placeholder={i18n.getMessage('createTransaction.payment.partyIban.placeholder')}
              id={'iban'}
              name={'iban'}
              value={data.iban}
              onChange={handleChangeValue}
              onBlur={() => validateField('iban')}
              error={errors?.iban}
            />
          </div>
        )}

        {/* If activeParty tab is Local bank account */}
        {data.activePartyTab === TRANSACTION_REQUISITES_TAB.LOCAL_BANK_ACCOUNT && (
          <div className={'external-transaction-party-control-party-info-row'}>
            <Input
              isRequired
              className={'external-transaction-party-control-input'}
              label={i18n.getMessage('createTransaction.payment.accountNumber')}
              id={'accountNumber'}
              name={'accountNumber'}
              value={data.accountNumber}
              onChange={handleChangeValue}
              onBlur={() => validateField('accountNumber')}
              error={errors?.accountNumber}
            />
            <Input
              isRequired
              className={'external-transaction-party-control-input'}
              label={i18n.getMessage('createTransaction.payment.sortCode')}
              id={'sortCode'}
              name={'sortCode'}
              value={data.sortCode}
              onChange={handleChangeValue}
              onBlur={() => validateField('sortCode')}
              error={errors?.sortCode}
            />
          </div>
        )}

        {/* If activeParty tab is Non-IBAN bank countries */}
        {data.activePartyTab === TRANSACTION_REQUISITES_TAB.NON_IBAN_BANK_COUNTRIES && (
          <div className={'external-transaction-party-control-block-wrapper'}>
            <div className={'external-transaction-party-control-party-info-row'}>
              <InputDropDownSearch
                isRequired
                className={'external-transaction-party-control-search-select'}
                label={i18n.getMessage('createTransaction.payment.bankCountry')}
                id={'bankCountry'}
                name={'bankCountry'}
                options={countriesOptions}
                value={data.bankCountry}
                onChange={(name, value) => handleChangeCountryValue(name, value)}
                onBlur={() => validateField('bankCountry')}
                error={errors?.bankCountry}
              />
              <Input
                isRequired
                className={'external-transaction-party-control-input'}
                label={i18n.getMessage('createTransaction.payment.bankName')}
                id={'bankName'}
                name={'bankName'}
                value={data.bankName}
                onChange={handleChangeValue}
                onBlur={() => validateField('bankName')}
                error={errors?.bankName}
              />
            </div>

            <div className={'external-transaction-party-control-party-info-row'}>
              <Input
                isRequired
                className={'external-transaction-party-control-input'}
                label={i18n.getMessage('createTransaction.payment.accountNumber')}
                id={'accountNumber'}
                name={'accountNumber'}
                value={data.accountNumber}
                onChange={handleChangeValue}
                onBlur={() => validateField('accountNumber')}
                error={errors?.accountNumber}
              />
              <Input
                isRequired
                className={'external-transaction-party-control-input'}
                label={i18n.getMessage('createTransaction.payment.bic')}
                id={'bic'}
                name={'bic'}
                value={data.bic}
                onChange={handleChangeValue}
                onBlur={() => validateField('bic')}
                error={errors?.bic}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ExternalTransactionPartyControl.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  handleChangeValue: PropTypes.func,
  handleChangeCountryValue: PropTypes.func,
  partyLabel: PropTypes.string,
  setFieldValue: PropTypes.func,
  validateField: PropTypes.func
};

export default ExternalTransactionPartyControl;
