import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './AddNewPaymentProviderScheme.scss';

const AddNewPaymentProviderScheme = ({ isLoading, paymentProviders, onClose, addNewProvider }) => {
  const i18n = useContext(i18nContext);
  const [newProvider, setNewProvider] = useState('');

  const handleChangeNewProvider = (name, value) => {
    setNewProvider(value);
  };

  const handleAddNewProvider = () => {
    addNewProvider(newProvider);
  };

  const providersOptions = paymentProviders.map((providerName) => {
    return {
      key: providerName,
      value: i18n.getMessage('paymentProvider.' + providerName)
    };
  });

  return (
    <div className={'scheme-wrapper scheme-wrapper-add-new-provider'}>
      <span className={'scheme-success-header scheme-header-add-new-provider'}>
        {i18n.getMessage('providersTab.modal.addPaymentProvider.header')}
      </span>

      <InputDropDown
        className={'table-cell-input'}
        id={'provider'}
        name={'provider'}
        label={i18n.getMessage('providersTab.modal.addPaymentProvider.inputLabel')}
        options={providersOptions}
        value={newProvider}
        onChange={handleChangeNewProvider}
      />

      <div className={'scheme-wrapper-buttons add-new-provider-scheme-buttons-wrapper'}>
        <Button className={'scheme-button'} onClick={onClose} type={'outline'} isDisabled={isLoading}>
          {i18n.getMessage('providersTab.modal.addPaymentProvider.action.back')}
        </Button>
        <Button className={'scheme-button'} onClick={handleAddNewProvider} isDisabled={isLoading}>
          {isLoading ? <Loader /> : i18n.getMessage('providersTab.modal.addPaymentProvider.action.add')}
        </Button>
      </div>
    </div>
  );
};

AddNewPaymentProviderScheme.propTypes = {
  isLoading: PropTypes.bool,
  paymentProviders: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  addNewProvider: PropTypes.func.isRequired
};

export default AddNewPaymentProviderScheme;
