import { useContext } from 'react';
import PropTypes from 'prop-types';
import { countriesOptions, passCheckOptions, yesOrNoOPTIONS } from '../../companyStructureFieldsOption';
import i18nContext from 'components/i18n-context';
import { CORPORATE_ACCOUNT_DETAILS_UPDATE, hasRole } from 'services/roles';
import AmountInput from 'uikit/AmountInput';
import Button from 'uikit/Button/Button';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';

const Beneficiaries = ({ propertyPrefix, data, errors, handleChange, setFieldValue, handleDelete }) => {
  const i18n = useContext(i18nContext);
  const isDisabled = !hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE);

  const handleChangeCustomInput = (name, value) => {
    setFieldValue(name, value);
  };

  const handleChangeAmountInput = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  return (
    <>
      <tr className={'table-row'}>
        <td colSpan={2} className={'table-cell'}>
          <div className={'table-cell-title'}>
            {i18n.getMessage('companyStructure.beneficiary.title', { name: data.name, surname: data.surname })}
            <Button type={'outline'} onClick={handleDelete} isDisabled={isDisabled}>
              {i18n.getMessage('companyStructure.beneficiary.button.delete')}
            </Button>
          </div>
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.name')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.name'}
            value={data.name}
            onChange={handleChange}
            error={errors?.name}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.surname')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.surname'}
            value={data.surname}
            onChange={handleChange}
            error={errors?.surname}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.percentOfEquity')}</td>
        <td className={'table-cell'}>
          <AmountInput
            className={'table-cell-input'}
            isDisabled={isDisabled}
            name={propertyPrefix + '.percentOfEquity'}
            value={data.percentOfEquity}
            disabled={isDisabled}
            precision={2}
            error={errors?.percentOfEquity}
            onChange={handleChangeAmountInput}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.birthday')}</td>
        <td className={'table-cell'}>
          <CustomDatePicker
            isDisabled={isDisabled}
            name={propertyPrefix + '.birthday'}
            value={data.birthday}
            onChange={handleChangeCustomInput}
            error={errors?.birthday}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.passportNumber')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.passportNumber'}
            value={data.passportNumber}
            onChange={handleChange}
            error={errors?.passportNumber}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.passportIssueCountry')}</td>
        <td className={'table-cell'}>
          <InputDropDownSearch
            className={'table-cell-input-dropdown-search'}
            isDisabled={isDisabled}
            options={countriesOptions}
            name={propertyPrefix + '.passportIssueCountry'}
            value={data.passportIssueCountry}
            onChange={handleChangeCustomInput}
            error={errors?.passportIssueCountry}
          />
        </td>
      </tr>

      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.passportIssueDate')}</td>
        <td className={'table-cell'}>
          <CustomDatePicker
            isDisabled={isDisabled}
            name={propertyPrefix + '.passportIssueDate'}
            value={data.passportIssueDate}
            onChange={handleChangeCustomInput}
            error={errors?.passportIssueDate}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.passportExpiryDate')}</td>
        <td className={'table-cell'}>
          <CustomDatePicker
            isDisabled={isDisabled}
            name={propertyPrefix + '.passportExpiryDate'}
            value={data.passportExpiryDate}
            onChange={handleChangeCustomInput}
            error={errors?.passportExpiryDate}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.address1')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.address.address1'}
            value={data.address.address1}
            error={errors?.address?.address1}
            onChange={handleChange}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.address2')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.address.address2'}
            value={data.address.address2}
            error={errors?.address?.address2}
            onChange={handleChange}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.zipCode')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.address.zipCode'}
            value={data.address.zipCode}
            error={errors?.address?.zipCode}
            onChange={handleChange}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.city')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.address.city'}
            value={data.address.city}
            error={errors?.address?.city}
            onChange={handleChange}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.country')}</td>
        <td className={'table-cell'}>
          <InputDropDownSearch
            className={'table-cell-input-dropdown-search'}
            isDisabled={isDisabled}
            options={countriesOptions}
            name={propertyPrefix + '.address.country'}
            value={data.address.country}
            onChange={handleChangeCustomInput}
            error={errors?.address?.country}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.passportCheck')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={passCheckOptions}
            name={propertyPrefix + '.passportCheck'}
            value={data.passportCheck}
            onChange={handleChangeCustomInput}
            error={errors?.passportCheck}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.sanctionList')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={passCheckOptions}
            name={propertyPrefix + '.sanctionList'}
            value={data.sanctionList}
            onChange={handleChangeCustomInput}
            error={errors?.sanctionList}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.pepList')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={passCheckOptions}
            name={propertyPrefix + '.pepList'}
            value={data.pepList}
            onChange={handleChangeCustomInput}
            error={errors?.pepList}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.blackList')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={passCheckOptions}
            name={propertyPrefix + '.blackList'}
            value={data.blackList}
            onChange={handleChangeCustomInput}
            error={errors?.blackList}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.director')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={yesOrNoOPTIONS}
            name={propertyPrefix + '.director'}
            value={data.director}
            onChange={handleChangeCustomInput}
            error={errors?.director}
          />
        </td>
      </tr>
    </>
  );
};

Beneficiaries.propTypes = {
  propertyPrefix: PropTypes.string,
  data: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default Beneficiaries;
