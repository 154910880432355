import { Fragment, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { formatMoney, getCustomerLink, formatDate, parseExternalKycState } from 'services/utils';
import './CustomersTableRow.scss';
import { EXTERNAL_KYC_LINK } from '../../../../../../components/constants';

export const CustomersTableRow = ({ isLoading, isShowActiveCustomers, customerData }) => {
  const i18n = useContext(i18nContext);
  const externalKycState = parseExternalKycState(customerData?.kyc_external_state);

  const getUserInfoBlock = (userBlockInfo, isCustomerId = false) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'customers-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isCustomerId) {
      return (
        <NavLink
          className={'customer-id-text'}
          to={getCustomerLink(userBlockInfo, customerData.status, customerData.type)}
        >
          {userBlockInfo}
        </NavLink>
      );
    } else if (Array.isArray(userBlockInfo)) {
      return (
        <p>
          {userBlockInfo.map((item, index) => {
            if (item) {
              return (
                <Fragment key={index}>
                  {item}
                  {index < userBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </p>
      );
    } else {
      return <p>{userBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'customers-table-cell'}>{getUserInfoBlock(customerData.account_number, true)}</td>
      <td className={'customers-table-cell'}>{getUserInfoBlock(customerData.common_name)}</td>
      <td className={'customers-table-cell'}>
        {customerData.onboardingStatus
          ? getUserInfoBlock(i18n.getMessage(`onboardingStatus.${customerData.onboardingStatus}`))
          : null}
      </td>
      <td className={'customers-table-cell'}>
        {getUserInfoBlock(i18n.getMessage(`customers.type.${customerData.type}`))}
      </td>
      <td className={'customers-table-cell'}>
        {getUserInfoBlock([
          i18n.getMessage(`kyc.status.${customerData.kyc_status}`),
          i18n.getMessage(`customers.status.${customerData.status}`)
        ])}
      </td>
      {!isShowActiveCustomers && (
        <td className={'customers-table-cell'}>
          {getUserInfoBlock([
            formatDate(customerData.user.last_login_date),
            formatDate(customerData.user.registration_date)
          ])}
        </td>
      )}
      {!isShowActiveCustomers && (
        <td className={'customers-table-cell'}>
          {externalKycState.identifier
            ? getUserInfoBlock(
                <a
                  className={'link-normal'}
                  href={`${EXTERNAL_KYC_LINK}${externalKycState.identifier}`}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  {externalKycState.identifier}
                </a>
              )
            : null}
        </td>
      )}
      {!isShowActiveCustomers && (
        <td className={'customers-table-cell'}>{getUserInfoBlock(externalKycState.statuses)}</td>
      )}
      {!isShowActiveCustomers && <td className={'customers-table-cell'}>{getUserInfoBlock(externalKycState.tags)}</td>}
      {isShowActiveCustomers && <td className={'customers-table-cell'}>{getUserInfoBlock(customerData?.source)}</td>}
      {isShowActiveCustomers && (
        <td className={'customers-table-cell'}>{getUserInfoBlock(customerData?.customer_owner?.name)}</td>
      )}
    </tr>
  );
};

CustomersTableRow.propTypes = {
  isLoading: PropTypes.bool,
  isShowActiveCustomers: PropTypes.bool,
  customerData: PropTypes.object
};
