import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import { CardDetailsHeadRow } from './components/CardDetailsHeadRow';
import { CardDetailsTableRow } from './components/CardDetailsTableRow';
import PopUpBlockCardScheme from './PopUpBlockCardScheme';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { PopConfirmationScheme } from 'components/PopUpScheme/PopConfirmationScheme';
import AmountInput from 'uikit/AmountInput';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './CardDetails.scss';

const CardDetails = ({
  isLoading,
  cards,
  getCurrentCustomerCards,
  blockCustomerCard,
  unblockCustomerCard,
  replaceCustomerCard,
  updateCardsPerCustomerLimit,
  updateCardsPerAccountLimit,
  cardsPerCustomerLimit,
  cardsPerAccountLimit
}) => {
  const i18n = useContext(i18nContext);
  const [limitCardsPerCustomer, setLimitCardsPerCustomer] = useState(cardsPerCustomerLimit);
  const [limitCardsPerAccount, setLimitCardsPerAccount] = useState(cardsPerAccountLimit);
  const [isCardBlockOpenPopUp, setIsCardBlockOpenPopUp] = useState(false);
  const [isCardReplaceConfirmationOpenPopUp, setIsCardReplaceConfirmationOpenPopUp] = useState(false);
  const [cardId, setCardId] = useState(null);

  useEffect(() => {
    getCurrentCustomerCards();
  }, []);

  const handleChangeLimitCardsPerCustomer = ({ target: { value } }) => {
    setLimitCardsPerCustomer(value);
  };

  const handleSaveLimitCardsPerCustomer = () => {
    updateCardsPerCustomerLimit(limitCardsPerCustomer);
  };

  const handleChangeLimitCardsPerAccount = ({ target: { value } }) => {
    setLimitCardsPerAccount(value);
  };

  const handleSaveLimitCardsPerAccount = () => {
    updateCardsPerAccountLimit(limitCardsPerAccount);
  };

  const handleIsOpenCardBlockPopUp = (blockCardId) => {
    if (!blockCardId) {
      setCardId(null);
    } else {
      setCardId(blockCardId);
    }
    setIsCardBlockOpenPopUp((prev) => !prev);
  };

  const handleIsOpenCardReplacePopUp = (replaceCardId) => {
    if (!replaceCardId) {
      setCardId(null);
    } else {
      setCardId(replaceCardId);
    }
    setIsCardReplaceConfirmationOpenPopUp((prev) => !prev);
  };

  const handleBlockCard = (blockType) => {
    blockCustomerCard(cardId, blockType).then(() => {
      handleIsOpenCardBlockPopUp();
    });
  };

  const handleReplaceCard = () => {
    replaceCustomerCard(cardId).then(() => {
      handleIsOpenCardReplacePopUp();
    });
  };

  if (!cards) {
    return <Loader className={'application-loader cards-tab-loader'} />;
  }

  return (
    <div className={'card-details-container cards-table'}>
      <table className={'table-wrapper'}>
        <tbody className={'table-body'}>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('cardDetails.limit.cardsPerCustomer')}</td>
            <td className={'table-cell'}>
              <div className={'table-input-wrapper'}>
                <AmountInput
                  className={'table-cell-input'}
                  name={'limitCardsPerCustomer'}
                  value={limitCardsPerCustomer}
                  onChange={handleChangeLimitCardsPerCustomer}
                />
                <Button
                  className={'card-actions-button'}
                  size={'small'}
                  type={'primary'}
                  onClick={handleSaveLimitCardsPerCustomer}
                >
                  {i18n.getMessage('cardDetails.button.save')}
                </Button>
              </div>
            </td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('cardDetails.limit.cardsPerAccount')}</td>
            <td className={'table-cell'}>
              <div className={'table-input-wrapper'}>
                <AmountInput
                  className={'table-cell-input'}
                  name={'limitCardsPerCustomer'}
                  value={limitCardsPerAccount}
                  onChange={handleChangeLimitCardsPerAccount}
                />
                <Button
                  className={'card-actions-button'}
                  size={'small'}
                  type={'primary'}
                  onClick={handleSaveLimitCardsPerAccount}
                >
                  {i18n.getMessage('cardDetails.button.save')}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Table responsive>
        <thead>
          <CardDetailsHeadRow />
        </thead>
        <tbody>
          {cards.map((cardDetailsData, index) => (
            <CardDetailsTableRow
              key={index}
              isLoading={false}
              cardDetailsData={cardDetailsData}
              blockCustomerCard={handleIsOpenCardBlockPopUp}
              unblockCustomerCard={unblockCustomerCard}
              replaceCustomerCard={handleIsOpenCardReplacePopUp}
            />
          ))}
        </tbody>
      </Table>

      <PopUp alignOnCenter show={isCardBlockOpenPopUp || isCardReplaceConfirmationOpenPopUp}>
        {isCardBlockOpenPopUp ? (
          <PopUpBlockCardScheme
            isLoading={isLoading}
            onClose={handleIsOpenCardBlockPopUp}
            onConfirm={handleBlockCard}
          />
        ) : (
          <PopConfirmationScheme
            title={i18n.getMessage('cardDetails.modal.replaceCard.header')}
            description={i18n.getMessage('cardDetails.modal.replaceCard.description')}
            onConfirmButtonTitle={i18n.getMessage('cardDetails.modal.replaceCard.button.replace')}
            onCloseButtonTitle={i18n.getMessage('cardDetails.modal.replaceCard.button.back')}
            onConfirm={handleReplaceCard}
            onClose={handleIsOpenCardReplacePopUp}
          />
        )}
      </PopUp>
    </div>
  );
};

CardDetails.propTypes = {
  isLoading: PropTypes.bool,
  cards: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string.isRequired,
        name: PropTypes.string,
        card_id: PropTypes.string.isRequired,
        card_number: PropTypes.string,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        blocked_at: PropTypes.string,
        wallet_number: PropTypes.string.isRequired,
        block_type: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  getCurrentCustomerCards: PropTypes.func,
  blockCustomerCard: PropTypes.func,
  unblockCustomerCard: PropTypes.func,
  replaceCustomerCard: PropTypes.func,
  updateCardsPerCustomerLimit: PropTypes.func,
  updateCardsPerAccountLimit: PropTypes.func,
  cardsPerCustomerLimit: PropTypes.number,
  cardsPerAccountLimit: PropTypes.number
};

export default inject((stores) => ({}))(observer(CardDetails));
