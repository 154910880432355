import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import './BlacklistsTableHead.scss';

export const BLACKLISTS_TABLE_HEADER = [
  {
    label: 'id'
  },
  {
    label: 'value'
  },
  {
    label: 'status'
  },
  {
    label: 'createdAt',
    sortBy: 'createdAt'
  },
  {
    label: 'updatedAt'
  },
  {
    label: 'actions'
  }
];

const BlacklistsTableHead = ({ handleOnSortTable, sortBy, direction }) => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {BLACKLISTS_TABLE_HEADER.map(
        (head) =>
          head.label && (
            <th key={head.label} className={'blacklists-table-head'}>
              <div className={'blacklists-table-head-block'}>
                <span>{i18n.getMessage(`settings.blacklists.table.header.${head.label}`)}</span>
                {head.sortBy && (
                  <ArrowSelect
                    onClick={() => handleOnSortTable(head.sortBy)}
                    className={`blacklists-sorting-arrow-icon ${sortBy === head.sortBy && direction}`}
                  />
                )}
              </div>
            </th>
          )
      )}
    </tr>
  );
};

BlacklistsTableHead.propTypes = {
  handleOnSortTable: PropTypes.func,
  sortBy: PropTypes.string,
  direction: PropTypes.string
};

export default BlacklistsTableHead;
