import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';
import CustomSearchInput from 'components/CustomSearchInput';
import { BLACKLIST_STATUS } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './BlacklistsTableFiltersBar.scss';

const BlacklistsTableFiltersBar = ({
  isLoading,
  isInitialized,
  filters,
  totalElements,
  handleSetFilters,
  handleOpenCreateBlacklistModal
}) => {
  const i18n = useContext(i18nContext);
  const [searchValue, setSearchValue] = useState(filters.searchText ? filters.searchText : '');
  const currentDate = new Date();

  const debouncedSearch = useRef(
    debounce((name, value) => {
      handleSetFilters(name, value);
    }, 400)
  ).current;

  const handleSearchTextInputChange = ({ target: { name, value } }) => {
    setSearchValue(value);

    debouncedSearch(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const statusOptions = Object.keys(BLACKLIST_STATUS).map((status) => {
    return {
      key: BLACKLIST_STATUS[status],
      value: i18n.getMessage('blacklistStatus.' + BLACKLIST_STATUS[status])
    };
  });

  return (
    <div className={'blacklists-filters-wrapper'}>
      <div className={'blacklists-actions-wrapper'}>
        <div className={'blacklists-date-range-filters-wrapper'}>
          <div>
            <span>{i18n.getMessage('settings.blacklists.filter.from.label')}</span>
            <CustomDatePicker
              onChange={handleSetFilters}
              name={'from'}
              value={filters.from}
              maxDate={filters.to || currentDate}
            />
          </div>
          <div>
            <span>{i18n.getMessage('settings.blacklists.filter.to.label')}</span>
            <CustomDatePicker
              onChange={handleSetFilters}
              name={'to'}
              value={filters.to}
              maxDate={currentDate}
              minDate={filters.from}
            />
          </div>
        </div>
        <div className={'export-blacklists-reports-actions-wrapper'}>
          <Button
            className={'blacklists-button'}
            size={'medium'}
            type={'primary'}
            onClick={handleOpenCreateBlacklistModal}
            isDisabled={!isInitialized || isLoading}
          >
            {isLoading ? <Loader /> : i18n.getMessage('settings.blacklists.button.createNewBlackList')}
          </Button>
        </div>
      </div>

      <InputDropDown
        className={'blacklists-filter-wrapper'}
        name={'status'}
        label={i18n.getMessage('settings.blacklists.filter.status.label')}
        value={filters.status}
        options={statusOptions}
        onChange={handleSetFilters}
      />
      <CustomSearchInput
        wrapperClassName={'search-wrapper'}
        inputClassName={'blacklists-filter-wrapper'}
        label={i18n.getMessage('settings.blacklists.filter.search.label')}
        placeholder={i18n.getMessage('settings.blacklists.filter.search.placeholder')}
        name={'searchText'}
        value={searchValue}
        onChange={handleSearchTextInputChange}
        totalElements={totalElements}
      />
    </div>
  );
};

BlacklistsTableFiltersBar.propTypes = {
  isLoading: PropTypes.bool,
  isInitialized: PropTypes.bool,
  filters: PropTypes.object,
  totalElements: PropTypes.number,
  handleSetFilters: PropTypes.func,
  handleOpenCreateBlacklistModal: PropTypes.func
};

export default BlacklistsTableFiltersBar;
