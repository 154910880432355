import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { MonitoringTabHeadRow } from './components/MonitoringTabHeadRow';
import { MonitoringTabTableRow } from './components/MonitoringTabTableRow';
import Loader from 'components/Loader';
import i18nContext from 'components/i18n-context';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import './MonitoringTab.scss';

const MonitoringTab = ({ isLoading, documents, loadDocuments, downloadDocument, downloadAllDocuments }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();

  useEffect(() => {
    loadDocuments();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenTransactionDetails = (transactionNumber) => {
    navigate(`${ROUTE_PATHS.TRANSACTIONS}/${transactionNumber}`);
  };

  if (!documents) {
    return <Loader className={'application-loader monitoring-tab-loader'} />;
  }

  return (
    <div className={'monitoring-container'}>
      <div className={'monitoring-actions-wrapper'}>
        <Button
          className={'monitoring-button'}
          size={'medium'}
          type={'primary'}
          onClick={downloadAllDocuments}
          isDisabled={isLoading}
        >
          {i18n.getMessage('monitoringTab.button.downloadAll')}
        </Button>
      </div>
      <Table responsive>
        <thead>
          <MonitoringTabHeadRow />
        </thead>
        <tbody>
          {documents.map((data, index) => (
            <MonitoringTabTableRow
              key={'monitoring-tab-row-' + index}
              isLoading={false}
              monitoringData={data}
              handleOpenTransactionDetails={handleOpenTransactionDetails}
              downloadDocumentById={downloadDocument}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

MonitoringTab.propTypes = {
  isLoading: PropTypes.bool,
  documents: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  loadDocuments: PropTypes.func,
  downloadDocument: PropTypes.func,
  downloadAllDocuments: PropTypes.func
};

export default MonitoringTab;
