import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './ConfigurationTableHeadRow.scss';

export const CONFIGURATION_TABLE_HEADER = [
  {
    label: 'propertyName'
  },
  {
    label: 'description'
  },
  {
    label: 'value'
  }
];

const ConfigurationTableHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {CONFIGURATION_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'configuration-table-head'}>
          <div className={'configuration-table-head-block'}>
            <span>{i18n.getMessage(`settings.configuration.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};

export default ConfigurationTableHeadRow;
