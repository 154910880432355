import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { ReactComponent as DeleteIconButton } from 'assets/iconButtons/delete.svg';
import { Placeholder } from 'react-bootstrap';
import { formatDateTime } from 'services/utils';
import './NotesTabTableRow.scss';
import Button from '../../../uikit/Button/Button';

const NotesTabTableRow = ({ isLoading, noteData, handleRemoveNote, handleDownloadDocument }) => {
  const getDateAndTimeRow = (noteDate) => {
    const date = formatDateTime(noteDate);

    return <i className={'customer-note-table-cell-note-info-created-date'}>{date}</i>;
  };

  const handleDeleteNote = () => {
    handleRemoveNote(noteData.id);
  };

  const getCustomerNoteInfoBlock = (customerNoteBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'customer-note-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{customerNoteBlockInfo}</p>;
    }
  };

  return (
    <tr key={noteData.id}>
      <td className={'customer-note-table-cell'}>
        <div className={'customer-note-table-cell-note-info'}>
          {getCustomerNoteInfoBlock(noteData.author.name)}
          {getCustomerNoteInfoBlock(getDateAndTimeRow(noteData.createdAt))}
        </div>
      </td>
      <td className={'customer-note-table-cell'}>
        {noteData?.assignee?.name ? getCustomerNoteInfoBlock(noteData?.assignee?.name) : null}
      </td>
      <td className={'customer-note-table-cell'}>
        <div className={'customer-notes-table-cell-note'}>
          <div>{parse(noteData.note)}</div>
          {noteData.deleteAvailable && (
            <div className={'customer-notes-table-cell-note-delete-icon'}>
              <DeleteIconButton onClick={handleDeleteNote} />
            </div>
          )}
        </div>

        <div>
          {noteData.documents &&
            noteData.documents.map((document, index) => {
              return (
                <div key={index}>
                  <Button
                    className={'link-button document-link'}
                    onClick={() => handleDownloadDocument(document.id)}
                    isDisabled={false}
                  >
                    {document.name}
                  </Button>
                </div>
              );
            })}
        </div>
      </td>
    </tr>
  );
};

NotesTabTableRow.propTypes = {
  isLoading: PropTypes.bool,
  noteData: PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    note: PropTypes.string,
    operator_name: PropTypes.string,
    deleteAvailable: PropTypes.bool
  }),
  handleRemoveNote: PropTypes.func,
  handleDownloadDocument: PropTypes.func
};

export default NotesTabTableRow;
