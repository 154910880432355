import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Loader from 'components/Loader';
import NavigationBar from 'components/NavigationBar';
import { PopUpSessionAutoLogOutScheme } from 'components/PopUpScheme/PopUpSessionAutoLogOutScheme';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';

const ApplicationLayout = ({ authStore }) => {
  const [compactMenu, setCompactMenu] = useState(true);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const isDesktop = useMediaQuery(MEDIA_QUERY.DESKTOP);

  useEffect(() => {
    if (isDesktop === false && !compactMenu && isMobile === false) {
      setCompactMenu(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isDesktop]);

  return (
    <div className={'page application'}>
      {!authStore.isLoginSuccess || authStore.isLoading ? (
        <Loader className={'application-loader'} />
      ) : (
        <>
          <Header collapseMenu={setCompactMenu} compact={compactMenu} />
          <main className={`content ${compactMenu ? 'compact' : isMobile ? 'bg-transparent' : ''}`}>
            <PopUpSessionAutoLogOutScheme />
            <NavigationBar isMobile={isMobile} collapseMenu={setCompactMenu} compact={compactMenu} />
            <Outlet />
          </main>
          <Footer />
        </>
      )}
    </div>
  );
};

ApplicationLayout.propTypes = {
  authStore: MobXPropTypes.observableObject
};

export default inject('authStore')(observer(ApplicationLayout));
