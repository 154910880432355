import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcon } from 'assets/error-icon.svg';
import Button from 'uikit/Button/Button';

export const PopErrorScheme = ({ title, description, onCloseButtonTitle, onClose }) => {
  return (
    <div>
      <div className={'scheme-wrapper pop-up-logOut'}>
        <ErrorIcon className={'icon-error'} />
        {title && <h2 className={'scheme-success-header'}>{title}</h2>}
        {description && <p className={'scheme-error-message'}>{description}</p>}
        <div className={'scheme-wrapper-buttons'}>
          <Button className={'scheme-button scheme-error-button'} size={'large'} onClick={onClose}>
            {onCloseButtonTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

PopErrorScheme.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onCloseButtonTitle: PropTypes.string,
  onClose: PropTypes.func
};
