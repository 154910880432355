import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import i18n from 'i18n';
import { ReactComponent as CommissionTransactionIcon } from 'assets/iconButtons/commissions.svg';
import { ReactComponent as PaymentTransactionIcon } from 'assets/iconButtons/transactions.svg';
import { ReactComponent as TransitTransactionIcon } from 'assets/iconButtons/transit-transaction.svg';
import { ReactComponent as CryptoTransactionIcon } from 'assets/iconButtons/crypto.svg';
import { CUSTOM_TABS_DIRECTION } from 'components/constants';
import CustomTabs from 'components/CustomTabs';
import i18nContext from 'components/i18n-context';
import { ROUTE_PATHS } from 'routes/constants';
import { Container } from 'uikit/Container/Container';
import './CreateTransaction.scss';

const CREATE_TRANSACTION_TABS = [
  {
    name: 'payment',
    title: i18n.getMessage('createTransaction.tab.payment'),
    icon: PaymentTransactionIcon,
    route: ROUTE_PATHS.CREATE_PAYMENT_TRANSACTION
  },
  {
    name: 'transit',
    title: i18n.getMessage('createTransaction.tab.transit'),
    icon: TransitTransactionIcon,
    route: ROUTE_PATHS.CREATE_TRANSIT_TRANSACTION
  },
  {
    name: 'commission',
    title: i18n.getMessage('createTransaction.tab.commission'),
    icon: CommissionTransactionIcon,
    route: ROUTE_PATHS.CREATE_COMMISSION_TRANSACTION
  },
  {
    name: 'crypto',
    title: i18n.getMessage('createTransaction.tab.crypto'),
    icon: CryptoTransactionIcon,
    route: ROUTE_PATHS.CREATE_CRYPTO_TRANSACTION
  }
];

const CreateTransaction = () => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const path = location.pathname;

    const activeTabFromPath = CREATE_TRANSACTION_TABS.find((tab) => tab.route === path);
    if (activeTabFromPath) {
      setActiveTab(activeTabFromPath.name);
    } else if (path === ROUTE_PATHS.CREATE_TRANSACTION) {
      setActiveTab(CREATE_TRANSACTION_TABS[0].name);
      navigate(CREATE_TRANSACTION_TABS[0].route);
    }
  }, [location.pathname, navigate]);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    const selectedTab = CREATE_TRANSACTION_TABS.find((tab) => tab.name === tabName);
    if (selectedTab) {
      navigate(selectedTab.route);
    }
  };

  return (
    <section className={'right-section'}>
      <Container className={'create-transaction-container'} header={i18n.getMessage('container.createTransaction')}>
        <div className={'create-transaction-wrapper'}>
          <CustomTabs
            tabList={CREATE_TRANSACTION_TABS}
            direction={CUSTOM_TABS_DIRECTION.HORIZONTAL}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
          />
          <Outlet />
        </div>
      </Container>
    </section>
  );
};

export default CreateTransaction;
