import { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { getApplicationState, getApplicationConstants, getApplicationConfiguration } from 'services/requestAgent';

const ApplicationContext = createContext({
  state: null,
  constants: null,
  configuration: null
});

const ApplicationProvider = ({ children, authStore }) => {
  const [state, setState] = useState(null);
  const [constants, setConstants] = useState(null);
  const [configuration, setConfiguration] = useState(null);

  useEffect(() => {
    const fetchConfiguration = async () => {
      const configuration = await getApplicationConfiguration();
      const parametersByKey = {};
      configuration.parameters.forEach((obj) => (parametersByKey[obj.name] = obj.value));
      return { ...configuration, parametersByKey };
    };

    const fetchData = async () => {
      try {
        const [stateData, constantsData, configurationData] = await Promise.all([
          getApplicationState(),
          getApplicationConstants(),
          fetchConfiguration()
        ]);

        setState(stateData);
        setConstants(constantsData);
        setConfiguration(configurationData);
      } catch (error) {
        console.error('Error fetching application data:', error);
      }
    };

    if (authStore.isLoginSuccess) {
      fetchData().then(() => {});
    }
  }, [authStore.isLoginSuccess]);

  return (
    <ApplicationContext.Provider value={{ state, constants, configuration }}>{children}</ApplicationContext.Provider>
  );
};

ApplicationProvider.propTypes = {
  children: PropTypes.node,
  authStore: MobXPropTypes.observableObject
};

export default inject('authStore')(observer(ApplicationProvider));

export const useApplicationContext = () => {
  return useContext(ApplicationContext);
};
