import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { BLACKLIST_STATUS, DIRECTION } from 'components/constants';
import i18n from 'i18n';
import {
  activateBlacklistRequest,
  createBlacklistRequest,
  deactivateBlacklistRequest,
  getApplicationPropertiesRequest,
  getBlacklistsRequest,
  updateBlacklistRequest,
  updatePaymentWorkTimeListRequest
} from 'services/requestAgent';
import {
  convertDateToServerTimeString,
  convertDateWithTime,
  convertToFullDate,
  convertToTimeDate
} from 'services/utils';

class SettingsStore {
  isInitialized = false;
  isLoading = false;
  isCreateOrUpdateBlacklistModalOpen = false;
  error = null;
  paymentWorkTimeList = [];
  blacklists = [];
  updatableBlacklist = {
    id: null,
    value: null
  };
  totalElements = null;
  sortColumn = {
    sort_column: 'createdAt',
    sort_direction: DIRECTION.DESC
  };
  pagination = {
    size: 20,
    page: 0,
    totalPages: null
  };
  filters = {
    searchText: null,
    from: null,
    to: null,
    status: BLACKLIST_STATUS.ALL
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetSettingsStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.isCreateOrUpdateBlacklistModalOpen = false;
    this.error = null;
    this.paymentWorkTimeList = [];
    this.blacklists = [];
    this.updatableBlacklist = {
      id: null,
      value: null
    };
    this.totalElements = null;
    this.sortColumn = {
      sort_column: 'createdAt',
      sort_direction: DIRECTION.DESC
    };
    this.pagination = {
      size: 20,
      page: 0,
      totalPages: null
    };
    this.filters = {
      searchText: null,
      from: null,
      to: null,
      status: BLACKLIST_STATUS.ALL
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsCreateOrUpdateBlacklistModalOpen = (status) => {
    this.isCreateOrUpdateBlacklistModalOpen = status;
  };

  setPaymentWorkTime = (timeDirection, newTime, paymentWorkTimeData) => {
    this.paymentWorkTimeList = this.paymentWorkTimeList.map((el) =>
      el.type === paymentWorkTimeData.type && el.payment_method === paymentWorkTimeData.payment_method
        ? { ...paymentWorkTimeData, [timeDirection]: newTime }
        : el
    );
  };

  setPaymentNonWorkingDays = (newNonWorkingDays, paymentWorkTimeData) => {
    this.paymentWorkTimeList = this.paymentWorkTimeList.map((el) =>
      el.type === paymentWorkTimeData.type && el.payment_method === paymentWorkTimeData.payment_method
        ? {
            ...paymentWorkTimeData,
            non_working_days: newNonWorkingDays.length > 0 ? newNonWorkingDays.map((d) => d.format()) : []
          }
        : el
    );
  };

  setUpdatableBlacklistData = (id, value) => {
    this.updatableBlacklist.id = id;
    this.updatableBlacklist.value = value;
  };

  setPageNumber = (page) => {
    this.pagination.page = page;
  };

  setPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setSortData = (sortData) => {
    this.sortColumn.sort_column = sortData.sortBy;
    this.sortColumn.sort_direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    const filters = { ...this.filters };
    const pagination = { ...this.pagination };

    // Mapping URL filter parameters to corresponding properties in the 'filters' object
    const filterParamsMapping = {
      searchText: 'searchText',
      from: 'from',
      to: 'to',
      status: 'status'
    };

    // Iterating over each URL parameter and assigning its values to 'filters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        let value = params[param];

        // Convert `from` and `to` if they exist
        if (param === 'from' || param === 'to') {
          value = convertToFullDate(value);
        }

        if (param === 'status') {
          if (value === 'true') {
            value = BLACKLIST_STATUS.ACTIVATED;
          } else if (value === 'false') {
            value = BLACKLIST_STATUS.DEACTIVATED;
          }
        }

        // Handle only array values as arrays, keep others as their original types
        if (Array.isArray(value)) {
          filters[filterParamsMapping[param]] = value;
        } else {
          filters[filterParamsMapping[param]] = value;
        }
      }
    }

    // Assigning pagination parameters from the URL
    pagination.page = parseInt(params.page) || pagination.page;
    pagination.size = parseInt(params.size) || pagination.size;

    // Assigning sorting parameters from the URL
    this.sortColumn.sort_column = params.sort_column || this.sortColumn.sort_column;
    this.sortColumn.sort_direction = params.sort_direction || this.sortColumn.sort_direction;

    // Updating the state of filters and pagination
    this.filters = filters;
    this.pagination = pagination;
  };

  prepareFiltersParams = () => {
    const params = { ...this.filters };

    if (this.filters.from) {
      params.from = convertDateWithTime(this.filters.from);
    } else {
      delete params.from;
    }

    if (this.filters.to) {
      params.to = convertDateWithTime(this.filters.to, true);
    } else {
      delete params.to;
    }

    return params;
  };

  getApplicationProperties = async () => {
    this.setIsLoading(true);
    try {
      const propertiesResponse = await getApplicationPropertiesRequest();

      const paymentWorkTimesArray = propertiesResponse.payment_work_time.map((el) => ({
        ...el,
        from: convertToTimeDate(el.from),
        to: convertToTimeDate(el.to)
      }));

      runInAction(() => {
        this.isInitialized = true;
        this.paymentWorkTimeList = paymentWorkTimesArray;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  updatePaymentWorkTimeList = async () => {
    this.setIsLoading(true);

    const preparedPaymentWorkTimeList = this.paymentWorkTimeList.map((el) => ({
      ...el,
      from: convertDateToServerTimeString(el.from),
      to: convertDateToServerTimeString(el.to)
    }));

    const toastPromise = toast.promise(updatePaymentWorkTimeListRequest(preparedPaymentWorkTimeList), {
      pending: i18n.getMessage('settingsStore.updatePaymentWorkTimeList.toastPromise.pending'),
      success: i18n.getMessage('settingsStore.updatePaymentWorkTimeList.toastPromise.success')
    });

    try {
      const resPaymentWorkTimes = await toastPromise;

      const paymentWorkTimesArray = resPaymentWorkTimes.map((el) => ({
        ...el,
        from: convertToTimeDate(el.from),
        to: convertToTimeDate(el.to)
      }));

      runInAction(() => {
        this.isInitialized = true;
        this.paymentWorkTimeList = paymentWorkTimesArray;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getBlacklists = async () => {
    this.setIsLoading(true);
    try {
      const filtersParams = this.prepareFiltersParams();

      const res = await getBlacklistsRequest(
        this.pagination,
        this.sortColumn.sort_column,
        this.sortColumn.sort_direction,
        filtersParams
      );

      runInAction(() => {
        this.isInitialized = true;
        this.blacklists = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  createBlacklist = async (blacklistValue) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createBlacklistRequest(blacklistValue), {
      pending: i18n.getMessage('settingsStore.createBlacklist.toastPromise.pending'),
      success: i18n.getMessage('settingsStore.createBlacklist.toastPromise.success')
    });

    try {
      const newBlacklistData = await toastPromise;

      runInAction(() => {
        this.blacklists = [newBlacklistData, ...this.blacklists];
        this.isCreateOrUpdateBlacklistModalOpen = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  updateBlacklist = async (blacklistId, blacklistValue) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateBlacklistRequest(blacklistId, blacklistValue), {
      pending: i18n.getMessage('settingsStore.updateBlacklist.toastPromise.pending'),
      success: i18n.getMessage('settingsStore.updateBlacklist.toastPromise.success')
    });

    try {
      const updatedBlacklistData = await toastPromise;

      const updatedBlacklists = this.blacklists.map((el) => (el.id === blacklistId ? updatedBlacklistData : el));

      runInAction(() => {
        this.blacklists = updatedBlacklists;
        this.isCreateOrUpdateBlacklistModalOpen = false;
        this.updatableBlacklist = { id: null, value: null };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  activateBlacklist = async (blacklistId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(activateBlacklistRequest(blacklistId), {
      pending: i18n.getMessage('settingsStore.activateBlacklist.toastPromise.pending'),
      success: i18n.getMessage('settingsStore.activateBlacklist.toastPromise.success')
    });

    try {
      const activatedBlacklistData = await toastPromise;

      const updatedBlacklists = this.blacklists.map((el) => (el.id === blacklistId ? activatedBlacklistData : el));

      runInAction(() => {
        this.blacklists = updatedBlacklists;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  deactivateBlacklist = async (blacklistId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deactivateBlacklistRequest(blacklistId), {
      pending: i18n.getMessage('settingsStore.deactivateBlacklist.toastPromise.pending'),
      success: i18n.getMessage('settingsStore.deactivateBlacklist.toastPromise.success')
    });

    try {
      const deactivatedBlacklistData = await toastPromise;

      const updatedBlacklists = this.blacklists.map((el) => (el.id === blacklistId ? deactivatedBlacklistData : el));

      runInAction(() => {
        this.blacklists = updatedBlacklists;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };
}

export default new SettingsStore();
