import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import { MAX_BLACKLIST_VALUE_SIZE } from 'components/constants';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import './PopUpCreateOrUpdateBlacklistScheme.scss';

export const PopUpCreateOrUpdateBlacklistScheme = ({
  handleClose,
  updatableBlacklistId,
  updatableBlacklistValue,
  handleCreateBlacklist,
  handleUpdateBlacklist
}) => {
  const i18n = useContext(i18nContext);
  const [blacklistValue, setBlacklistsValue] = useState(updatableBlacklistValue ? updatableBlacklistValue : '');

  const handleClickSubmitButton = () => {
    if (updatableBlacklistId) {
      handleUpdateBlacklist(updatableBlacklistId, blacklistValue);
    } else {
      handleCreateBlacklist(blacklistValue);
    }
  };

  return (
    <div className={'scheme-wrapper blacklist-container'}>
      <div className={'scheme-header blacklist-container-header'}>
        <h2>
          {updatableBlacklistId
            ? i18n.getMessage('settings.blacklists.modal.header.updateBlacklist')
            : i18n.getMessage('settings.blacklists.modal.header.createNewBlacklist')}
        </h2>
        <CloseIcon className={'blacklist-container-header-icon'} onClick={handleClose} />
      </div>
      <div className={'blacklist-form'}>
        <Input
          className={'blacklist-form-input'}
          type={'textarea'}
          value={blacklistValue}
          rows={3}
          max={MAX_BLACKLIST_VALUE_SIZE}
          label={i18n.getMessage('settings.blacklists.modal.input.label')}
          name={'blacklistValue'}
          onChange={(e) => {
            setBlacklistsValue(e.target.value);
          }}
          subText={i18n.getMessage('settings.blacklists.modal.input.symbolsLeft', {
            symbolsLeft: MAX_BLACKLIST_VALUE_SIZE - blacklistValue.length
          })}
        />
        <div className={'blacklist-form-button-wrapper'}>
          <Button roleType={'button'} className={'blacklist-form-button'} onClick={handleClickSubmitButton}>
            {updatableBlacklistId
              ? i18n.getMessage('settings.blacklists.button.updateBlacklist')
              : i18n.getMessage('settings.blacklists.button.createNewBlackList')}
          </Button>
        </div>
      </div>
    </div>
  );
};

PopUpCreateOrUpdateBlacklistScheme.propTypes = {
  handleClose: PropTypes.func,
  updatableBlacklistId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updatableBlacklistValue: PropTypes.string,
  handleCreateBlacklist: PropTypes.func,
  handleUpdateBlacklist: PropTypes.func
};
