import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import i18nContext from 'components/i18n-context';
import Input from 'uikit/Input/Input';
import './CustomSearchInput.scss';

const CustomSearchInput = ({
  wrapperClassName,
  inputClassName,
  label,
  placeholder,
  name,
  value,
  onChange,
  totalElements
}) => {
  const i18n = useContext(i18nContext);

  return (
    <div className={wrapperClassName}>
      <Input
        className={inputClassName}
        label={label}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        Icon={SearchIcon}
      />
      <div className={'total-results-text'}>
        {i18n.getMessage('search.total')}
        <b>{totalElements}</b>
        {i18n.getMessage('search.results')}
      </div>
    </div>
  );
};

CustomSearchInput.propTypes = {
  wrapperClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  totalElements: PropTypes.number
};

export default CustomSearchInput;
