import { PAYMENT_METHOD, TRANSACTION_PARTY_TYPE, TRANSACTION_TYPES as TRANSACTION_TYPE } from 'components/constants';

export const toDto = (data) => ({
  payment_method: data.paymentMethod,
  amount: Number(data.amount),
  commission: Number(data.commission),
  currency: data.currency,
  comment: data.comment,
  comment_to_admin: data.commentToAdmin,
  documents: data.documents.map((d) => d.id),
  ...(data.transactionType === TRANSACTION_TYPE.OUTGOING
    ? {
        sender_wallet: data.senderAccount?.wallet_number,
        recipient: toTransactionParty(data)
      }
    : {
        ...(data.recipientAccount
          ? { recipient_wallet: data.recipientAccount.wallet_number }
          : { transfer_provider: data.paymentProvider }),
        sender: toTransactionParty(data),
        transit_transaction_id: data.transitTransaction?.transaction_id
      })
});

export const fromErrorDto = (fields) => ({
  amount: fields.amount,
  currency: fields.currency,
  commission: fields.commission,
  comment: fields.comment,
  commentToAdmin: fields.commentToAdmin,
  senderAccount: fields.senderWallet,
  recipientAccount: fields.recipientWallet,
  iban: fields.iban,
  bankCountry: fields.bankCountry,
  accountNumber: fields.accountNumber,
  sortCode: fields.sortCode,
  bankName: fields.bankName,
  bic: fields.bic,
  firstName: fields.firstName,
  lastName: fields.lastName,
  companyName: fields.companyName,
  registrationNumber: fields.registrationNumber,
  partyAddress: fields.beneficiaryAddress,
  partyCountry: fields.beneficiaryCountry,
  partyPostCode: fields.beneficiaryPostCode,
  partyCity: fields.beneficiaryCity,
  transitTransaction: fields.transitTransactionId
});

const toTransactionParty = (data) => {
  if (data.paymentMethod === PAYMENT_METHOD.INTERNAL) {
    return {
      wallet_number:
        data.transactionType === TRANSACTION_TYPE.INCOMING
          ? data.senderAccount?.wallet_number
          : data.recipientAccount?.wallet_number
    };
  }

  let requisites;
  if (data.bankCountry) {
    requisites = {
      bank_country: data.bankCountry,
      bank_name: data.bankName,
      account_number: data.accountNumber,
      bic: data.bic
    };
  } else if (data.sortCode) {
    requisites = {
      account_number: data.accountNumber,
      sort_code: data.sortCode
    };
  } else {
    requisites = {
      iban: data.iban,
      bank_country: data.bankCountry
    };
  }

  return {
    party: {
      type: data.partyType,
      ...(data.partyType === TRANSACTION_PARTY_TYPE.COMPANY
        ? {
            company_name: data.partyCompanyName
          }
        : {
            first_name: data.partyFirstName,
            last_name: data.partyLastName
          })
    },
    requisites,
    ...([PAYMENT_METHOD.SWIFT, PAYMENT_METHOD.TARGET2].includes(data.paymentMethod)
      ? {
          registration_number: data.registrationNumber,
          beneficiary_country: data.partyCountry,
          beneficiary_address: data.partyAddress,
          beneficiary_city: data.partyCity,
          beneficiary_post_code: data.partyPostCode
        }
      : {})
  };
};
