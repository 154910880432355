import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcon } from 'assets/message_error_user.svg';
import i18nContext from 'components/i18n-context';
import '../RepresentativesTab.scss';

export const ErrorInvalidEmail = ({ closePopUp }) => {
  const i18n = useContext(i18nContext);

  return (
    <div className='scheme-representatives-wrapper pop-up-success representatives-notVerified'>
      <ErrorIcon />
      <h2 className='scheme-success-header'>{i18n.getMessage('representatives.header.invalidEmail')}</h2>
      <button className='scheme-error-button' onClick={() => closePopUp()}>
        {i18n.getMessage('popUp.success.button')}
      </button>
    </div>
  );
};

ErrorInvalidEmail.propTypes = {
  closePopUp: PropTypes.func.isRequired
};
