import { Fragment, useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { BLACKLIST_STATUS } from 'components/constants';
import i18nContext from 'components/i18n-context';
import { formatDateTime } from 'services/utils';
import Button from 'uikit/Button/Button';
import './BlacklistsTableRow.scss';

const BlacklistsTableRow = ({
  isLoading,
  blacklistData,
  handleOpenUpdateBlacklistModal,
  activateBlacklist,
  deactivateBlacklist
}) => {
  const i18n = useContext(i18nContext);

  const getBlacklistInfoBlock = (blacklistBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'blacklists-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (Array.isArray(blacklistBlockInfo)) {
      return (
        <p>
          {blacklistBlockInfo.map((item, index) => {
            if (item) {
              return (
                <Fragment key={index}>
                  {item}
                  {index < blacklistBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </p>
      );
    } else {
      return <p>{blacklistBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'blacklists-table-cell'}>{getBlacklistInfoBlock(blacklistData?.id)}</td>
      <td className={'blacklists-table-cell'}>{getBlacklistInfoBlock(blacklistData?.value)}</td>
      <td className={'blacklists-table-cell'}>
        {getBlacklistInfoBlock(i18n.getMessage(`blacklistStatus.${blacklistData.active}`))}
      </td>
      <td className={'blacklists-table-cell'}>{getBlacklistInfoBlock(formatDateTime(blacklistData?.createdAt))}</td>
      <td className={'blacklists-table-cell'}>
        {getBlacklistInfoBlock(blacklistData?.updatedAt ? formatDateTime(blacklistData.updatedAt) : '')}
      </td>
      <td className={'blacklists-table-cell'}>
        {
          <div className={'blacklists-table-cell-actions'}>
            <Button
              onClick={() => handleOpenUpdateBlacklistModal(blacklistData.id, blacklistData.value)}
              type={'primary'}
              size={'small'}
              fullWidth
            >
              {i18n.getMessage('settings.blacklists.button.update')}
            </Button>

            {blacklistData.active === BLACKLIST_STATUS.DEACTIVATED && (
              <Button
                onClick={() => activateBlacklist(blacklistData.id)}
                type={'outline'}
                size={'small'}
                fullWidth
                isDisabled={isLoading}
              >
                {i18n.getMessage('settings.blacklists.button.activate')}
              </Button>
            )}

            {blacklistData.active === BLACKLIST_STATUS.ACTIVATED && (
              <Button
                onClick={() => deactivateBlacklist(blacklistData.id)}
                type={'outline'}
                size={'small'}
                fullWidth
                isDisabled={isLoading}
              >
                {i18n.getMessage('settings.blacklists.button.deactivate')}
              </Button>
            )}
          </div>
        }
      </td>
    </tr>
  );
};

BlacklistsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  blacklistData: PropTypes.object,
  handleOpenUpdateBlacklistModal: PropTypes.func,
  activateBlacklist: PropTypes.func,
  deactivateBlacklist: PropTypes.func
};

export default BlacklistsTableRow;
