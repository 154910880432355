import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import InputMask from 'react-input-mask';
import RichTextEditor from 'react-rte';
import { ReactComponent as IconPassword } from './eye.svg';
import { ReactComponent as IconPasswordOff } from './eye-off.svg';
import { ReactComponent as IconError } from './warning.svg';
import { classNames } from '../utils';
import { DEFAULT_COUNTRY_CODE } from 'components/constants';
import { convertHtmlToString } from 'services/utils';
import './Input.scss';
import 'react-phone-input-2/lib/style.css';
import { EditorValue } from 'react-rte/lib/RichTextEditor';
import EditorState from 'draft-js/lib/EditorState';
import ContentState from 'draft-js/lib/ContentState';
import unidecode from 'unidecode-plus';

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ]
};

const Input = ({
  type = 'text',
  name,
  error,
  isApiError,
  value,
  setValue,
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  label,
  placeholder,
  subText,
  className,
  Icon,
  initialStatus,
  isDisabled,
  autoComplete = 'on',
  isRequired,
  autoFocus = false,
  max,
  rows,
  setCountryCode,
  excludeCountries = []
}) => {
  const [inputType, setInputType] = useState(type);
  const editorContainerRef = useRef();

  const handleBeforeInputRichTextEditor = () => {
    if (convertHtmlToString(value).length >= max) {
      return 'handled';
    }
  };

  const [rteEditorValue, setRteEditorValue] = useState(value);
  useEffect(() => {
    setRteEditorValue(value);
  }, [value]);

  const handleRteChange = (editorValue) => {
    setRteEditorValue(editorValue);
    onChange(editorValue);
  };

  const handleRteFix = (e) => {
    e.preventDefault();
    const editorState = rteEditorValue.getEditorState();
    const contentState = editorState.getCurrentContent();

    const skipRanges = [
      [0x41, 0x7a], // English letters (A-Z, a-z)
      [0x24, 0x24], // Dollar sign ($)
      [0xa3, 0xa3] // Pound sign (£)
      // currently we support only english messages, but maybe in future...
      // [0x410, 0x44F], // Russian letters (А-Я, а-я)
      // [0x100, 0x17F]  // Latvian letters (Ā-ž)
    ];
    const newText = unidecode(contentState.getPlainText(), { skipRanges });

    const newContentState = ContentState.createFromText(newText);
    const newEditorState = EditorState.createWithContent(newContentState);
    const newEditorValue = rteEditorValue.setEditorState(newEditorState);

    handleRteChange(newEditorValue);
  };

  const handlePastTextRichTextEditor = (pastedText) => {
    const totalLength = convertHtmlToString(value).length + pastedText.length;
    if (totalLength > max) {
      return 'handled';
    }
  };

  const toggleShowingPassword = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const inputChange = (e) => {
    onChange(e);
  };

  const handleBlur = (e) => onBlur(e);

  const handleFocus = () => onFocus();

  const handleLinkClick = (e) => {
    if (isDisabled) {
      e.preventDefault();
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter' && isDisabled) {
      e.preventDefault();
    }
  };

  const inputClasses = classNames({
    'input-wrapper': true,
    error: !!error || !!isApiError,
    hit: !error && !isApiError && !!initialStatus,
    disabled: isDisabled,
    'icon-wrapper': Icon || type === 'password' || error,
    [className]: true
  });

  const renderIcons = () => {
    if (Icon) {
      return <Icon className={'input-icon'} />;
    }

    if (type === 'password') {
      return (
        <div
          role={'button'}
          tabIndex={0}
          onKeyDown={toggleShowingPassword}
          onClick={toggleShowingPassword}
          className={`input-icon eye ${error ? 'error' : ''}`}
        >
          {inputType === 'text' ? <IconPassword /> : <IconPasswordOff />}
          {error && <IconError />}
        </div>
      );
    }

    return error && <IconError className={'input-icon'} />;
  };

  return type === 'phone' ? (
    <div className={inputClasses}>
      <PhoneInput
        /* eslint-disable-next-line max-len */
        containerClass={`custom-react-tel-input ${(error || isApiError) && 'invalid-tel-number'} ${
          !error && !isApiError && !!initialStatus && 'hit-tel-number'
        }`}
        country={DEFAULT_COUNTRY_CODE.toLowerCase()}
        excludeCountries={excludeCountries}
        specialLabel={label + (isRequired ? '*' : '')}
        inputProps={{ name, required: isRequired, autoFocus }}
        copyNumbersOnly={false}
        value={value}
        onBlur={onBlur}
        onChange={(phoneNumber, countryData, e, formattedValue) => {
          if (e.type === 'click') {
            setValue(name, formattedValue);
          } else {
            onChange(e);
          }
          setCountryCode(countryData?.countryCode);
        }}
      />
      {(error || subText) && <p className={`input-sub-text ${error ? 'input-error' : ''}`}>{error || subText}</p>}
    </div>
  ) : type === 'textarea' ? (
    <div className={inputClasses}>
      {label && <label htmlFor={name}>{label + (isRequired ? '*' : '')}</label>}
      <textarea
        name={name}
        value={value || ''}
        onChange={inputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
        disabled={isDisabled}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        maxLength={max}
        rows={rows}
        className={'input'}
      />
      {renderIcons()}
      {(error || subText) && <p className={`input-sub-text ${error ? 'input-error' : ''}`}>{error || subText}</p>}
    </div>
  ) : type === 'text-editor' ? (
    <div
      className={`text-editor-wrapper ${className}`}
      onClick={handleLinkClick}
      onKeyDown={handleEnterPress}
      tabIndex={0}
      role={isDisabled ? 'button' : ''}
    >
      <label className={'text-editor-label'} htmlFor={'message'}>
        {label}
      </label>
      <RichTextEditor
        name={name}
        className={`RichTextEditor ${isDisabled ? 'disabled' : ''}`}
        readOnly={isDisabled}
        autoFocus={autoFocus}
        toolbarConfig={toolbarConfig}
        value={rteEditorValue}
        onChange={handleRteChange}
        onBlur={onBlur}
        handleBeforeInput={handleBeforeInputRichTextEditor}
        handlePastedText={handlePastTextRichTextEditor}
        handleReturn={handleBeforeInputRichTextEditor}
        ref={editorContainerRef}
        customControls={[
          () => (
            <button className={'editor-toolbar-fix-button'} onClick={handleRteFix}>
              Fix
            </button>
          )
        ]}
      />
      {(error || subText) && (
        <p className={`text-editor-sub-text ${error ? 'text-editor-sub-text-error' : ''}`}>{error || subText}</p>
      )}
    </div>
  ) : type === 'date-mask' ? (
    <div className={inputClasses}>
      {label && <label htmlFor={name}>{label + (isRequired ? '*' : '')}</label>}
      <InputMask
        className={'input'}
        name={name}
        mask={'9999-99-99'} // Date mask for YYYY-MM-DD
        maskChar={'_'}
        value={value || ''}
        onChange={inputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
        disabled={isDisabled}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
      />
      {renderIcons()}
      {(error || subText) && <p className={`input-sub-text ${error ? 'input-error' : ''}`}>{error || subText}</p>}
    </div>
  ) : (
    <div className={inputClasses}>
      {label && <label htmlFor={name}>{label + (isRequired ? '*' : '')}</label>}
      <input
        name={name}
        type={inputType}
        value={value || ''}
        onChange={inputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
        disabled={isDisabled}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        maxLength={max}
        className={'input'}
      />
      {renderIcons()}
      {(error || subText) && <p className={`input-sub-text ${error ? 'input-error' : ''}`}>{error || subText}</p>}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isApiError: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.any]),
  setValue: PropTypes.func,
  subText: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  initialStatus: PropTypes.number,
  Icon: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  max: PropTypes.number,
  rows: PropTypes.number,
  setCountryCode: PropTypes.func,
  excludeCountries: PropTypes.array
};

export default Input;
