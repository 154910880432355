import React, { useContext, useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Button from 'uikit/Button/Button';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './PopUpAddNewLimitSheme.scss';
import i18nContext from 'components/i18n-context';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import { LimitActionsInputControl } from '../inpunts/LimitActionsInputControl';

// TODO: Remove this mock data and use real data
const options = {
  transactionsTypes: [
    { key: '1', value: 'Transaction type 1' },
    { key: '2', value: 'Transaction type 2' },
    { key: '3', value: 'Transaction type 3' }
  ],
  transferTypes: [
    { key: '1', value: 'Transfer type 1' },
    { key: '2', value: 'Transfer type 2' },
    { key: '3', value: 'Transfer type 3' }
  ],
  limitTypes: [
    { key: '1', value: 'Limit type 1' },
    { key: '2', value: 'Limit type 2' },
    { key: '3', value: 'Limit type 3' }
  ],
  transferProviders: [
    { key: '1', value: 'Transfer provider 1' },
    { key: '2', value: 'Transfer provider 2' },
    { key: '3', value: 'Transfer provider 3' }
  ],
  periods: [
    { key: '1', value: 'Period 1' },
    { key: '2', value: 'Period 2' },
    { key: '3', value: 'Period 3' }
  ],
  currencyCodes: [
    { key: '1', value: 'Currency code 1' },
    { key: '2', value: 'Currency code 2' },
    { key: '3', value: 'Currency code 3' }
  ]
};

const LIST_INPUT_DROPDOWN = [
  { name: 'type', optionsName: 'transactionsTypes', label: 'typeOfTransaction' },
  { name: 'transferType', optionsName: 'transferTypes', label: 'typeOfTransfer' },
  { name: 'limitType', optionsName: 'limitTypes', label: 'typeOfLimit' },
  { name: 'transferProvider', optionsName: 'transferProviders', label: 'transferProvider' },
  { name: 'period', optionsName: 'periods', label: 'period' },
  { name: 'currency', optionsName: 'currencyCodes', label: 'currency' }
];

const PopUpAddNewLimitScheme = ({ onClose, limits, isAccountLimit, onAddNewLimitType, formData, group }) => {
  const i18n = useContext(i18nContext);
  const [isError, setIsError] = useState(false);

  const form = useFormik({
    initialValues: {
      type: '',
      transferType: '',
      limitType: '',
      transferProvider: '',
      period: '',
      hold: '',
      reject: '',
      currency: '',
      enabled: false,
      count: ''
    },
    onSubmit: async (values) => {
      //TODO: Submit logic here
    }
  });

  const { values, handleSubmit, setFieldValue } = form;

  const changeAmount = (type, value) => {
    return setFieldValue(type, value);
  };

  return (
    <div className={'scheme-wrapper scheme-wrapper-newLimitType'}>
      <h2 className={'scheme-success-header'}>{i18n.getMessage('limits.details.addNewLimitType.header')}</h2>
      <form onSubmit={handleSubmit} className={'form-newLimitType'}>
        {LIST_INPUT_DROPDOWN.map((item) => (
          <InputDropDown
            key={item.name}
            className={'table-cell-input'}
            name={item.name}
            id={item.name}
            label={i18n.getMessage(`limits.details.addNewLimitType.input.${item.label}`)}
            options={options[item.optionsName]}
            value={values[item.name]}
            onChange={(name, value) => {
              return setFieldValue(name, value);
            }}
          />
        ))}
        <div className={'form-newLimitType-status'}>
          <span>Status</span>
          <div className={'form-newLimitType-status-wrapper'}>
            <CheckBox
              className={'form-newLimitType-status-wrapper-checkbox'}
              showCheckBox={true}
              isActive={values.enabled}
              onChange={() => {
                setFieldValue('enabled', !values.enabled);
              }}
            />
            <span>{i18n.getMessage('limits.details.addNewLimitType.input.status.enabled')}</span>
          </div>
        </div>
        {/*TODO add notification*/}
        <LimitActionsInputControl
          notification={''}
          onChange={changeAmount}
          reject={values.reject}
          editable={true}
          hold={values.hold}
          currency={values.currency}
        />
        <div className={'scheme-wrapper-buttons form-newLimitType-buttons-wrapper'}>
          <Button className={'scheme-button'} onClick={onClose} type={'outline'}>
            {i18n.getMessage('limits.details.addNewLimitType.button.cancel')}
          </Button>
          <Button roleType={'submit'} className={'scheme-button'}>
            {i18n.getMessage('limits.details.addNewLimitType.button.add')}
          </Button>
        </div>
      </form>
    </div>
  );
};

PopUpAddNewLimitScheme.propTypes = {
  onClose: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  limits: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isAccountLimit: PropTypes.bool
};

export default inject((stores) => ({}))(observer(PopUpAddNewLimitScheme));
