import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SuccessIcon } from 'assets/message_open_error.svg';
import ComplexTranslate from 'components/ComplexTranslate';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';
import '../RepresentativesTab.scss';

export const DeleteUserScheme = ({ representativeMail, handleRemoveClick, closePopUp, clearError }) => {
  const i18n = useContext(i18nContext);
  const [isDeleteClick, setIsDeleteClick] = useState(false);

  const handleDeleteClick = async () => {
    if (!isDeleteClick) return handleRemoveClick();

    setIsDeleteClick(true);
  };

  return (
    <div className={'scheme-wrapper pop-up-success delete'}>
      <SuccessIcon />
      <h2 className={'scheme-success-header'}>{i18n.getMessage('representatives.header.removeRepresentative')}</h2>
      <p className={'scheme-success-message'}>
        <ComplexTranslate
          text={'representatives.removeRepresentative'}
          interpolation={{
            representative: <strong>{representativeMail}</strong>
          }}
        />
      </p>
      <div className={'scheme-wrapper-buttons'}>
        <Button
          className={'scheme-button'}
          size={'large'}
          type={'outline'}
          onClick={handleDeleteClick}
          isDisabled={isDeleteClick}
        >
          {i18n.getMessage(isDeleteClick ? 'representatives.text.confirm' : 'representatives.text.yes')}
        </Button>
        <Button className={'scheme-error-button scheme-button'} size={'large'} onClick={closePopUp}>
          {i18n.getMessage('representatives.text.no')}
        </Button>
      </div>
    </div>
  );
};

DeleteUserScheme.propTypes = {
  representativeMail: PropTypes.string,
  handleRemoveClick: PropTypes.func,
  closePopUp: PropTypes.func.isRequired,
  error: PropTypes.object,
  clearError: PropTypes.func
};
