export const KEY_ROLES = 'roles';

export const // eslint-disable-line one-var
  INDIVIDUAL_ACCOUNT_CREATE = 'INDIVIDUAL_ACCOUNT_CREATE',
  INDIVIDUAL_ACCOUNT_DETAILS_READ = 'INDIVIDUAL_ACCOUNT_DETAILS_READ',
  INDIVIDUAL_ACCOUNT_DETAILS_UPDATE = 'INDIVIDUAL_ACCOUNT_DETAILS_UPDATE',
  CORPORATE_ACCOUNT_CREATE = 'CORPORATE_ACCOUNT_CREATE',
  CORPORATE_ACCOUNT_DETAILS_READ = 'CORPORATE_ACCOUNT_DETAILS_READ',
  CORPORATE_ACCOUNT_DETAILS_UPDATE = 'CORPORATE_ACCOUNT_DETAILS_UPDATE',
  USER_DETAILS_READ = 'USER_DETAILS_READ',
  USER_DETAILS_UPDATE = 'USER_DETAILS_UPDATE',
  RESET_PASSWORD_USER_UPDATE = 'RESET_PASSWORD_USER_UPDATE',
  RESET_PIN_UPDATE = 'RESET_PIN_UPDATE',
  RESET_PASSWORD_ADMIN = 'RESET_PASSWORD_ADMIN',
  ACCOUNT_STATUS_UPDATE = 'ACCOUNT_STATUS_UPDATE',
  USER_STATUS_UPDATE = 'USER_STATUS_UPDATE',
  DOCUMENT_READ = 'DOCUMENT_READ',
  DOCUMENT_UPDATE = 'DOCUMENT_UPDATE',
  DOCUMENT_STATUS = 'DOCUMENT_STATUS',
  TARIFF_GROUPS_READ = 'TARIFF_GROUPS_READ',
  TARIFF_GROUPS_CREATE = 'TARIFF_GROUPS_CREATE',
  TARIFF_GROUPS_UPDATE = 'TARIFF_GROUPS_UPDATE',
  LIMIT_GROUPS_READ = 'LIMIT_GROUPS_READ',
  LIMIT_GROUPS_CREATE = 'LIMIT_GROUPS_CREATE',
  LIMIT_GROUPS_UPDATE = 'LIMIT_GROUPS_UPDATE',
  TRANSACTION_READ = 'TRANSACTION_READ',
  TRANSACTION_CREATE = 'TRANSACTION_CREATE',
  TRANSACTION_UPDATE = 'TRANSACTION_UPDATE',
  CURRENCY_EXCHANGE_READ = 'CURRENCY_EXCHANGE_READ',
  CURRENCY_EXCHANGE_UPDATE = 'CURRENCY_EXCHANGE_UPDATE',
  CURRENCY_EXCHANGE_CREATE = 'CURRENCY_EXCHANGE_CREATE',
  WALLET_READ = 'WALLET_READ',
  WALLET_CREATE = 'WALLET_CREATE',
  WALLET_UPDATE = 'WALLET_UPDATE',
  FULL_ACCESS = 'FULL_ACCESS',
  MESSAGES_READ = 'MESSAGES_READ',
  MESSAGES_CREATE = 'MESSAGES_CREATE';

let roles = null;

export const getCachedRoles = () => {
  return (localStorage.getItem(KEY_ROLES) || '').split(',');
};

export const processRolesArray = (array) => {
  roles = {};
  array.forEach((role) => (roles[role] = true));
};

export const hasRole = (role) => {
  if (roles === null) {
    processRolesArray(getCachedRoles());
  }
  return roles[role] || roles[FULL_ACCESS];
};
