import { useContext } from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { formatDateTime } from 'services/utils';
import { RULE_ENGINE_PROVIDER } from 'components/constants';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import './RuleEngineProviders.scss';

export const RULE_ENGINE_PROVIDERS_HEADER = [
  {
    label: 'providerName'
  },
  {
    label: 'externalReference'
  },
  {
    label: 'createdAt'
  },
  {
    label: 'actions'
  }
];

const RuleEngineProviders = ({
  linkedRuleEngineProvider,
  ruleEngineProviders,
  handleAddRuleEngineProvider,
  handleRemoveRuleEngineProvider,
  ruleEngineCallInProgress
}) => {
  const i18n = useContext(i18nContext);

  return (
    <div className={'rule-engine-provider-container'}>
      <span className={'rule-engine-provider-container-header'}>
        {i18n.getMessage('providerTab.ruleEngineProvider.title')}
      </span>
      <Table responsive>
        <thead>
          <tr>
            {RULE_ENGINE_PROVIDERS_HEADER.map((head) => (
              <th key={head.label} className={'provider-table-head'}>
                <div className={'provider-table-head-block'}>
                  <span>{i18n.getMessage(`providerTab.ruleEngineProvider.table.header.${head.label}`)}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {linkedRuleEngineProvider ? (
            <tr>
              <td className={'rule-engine-provider-table-cell'}>
                {i18n.getMessage(`ruleEngineProvider.${linkedRuleEngineProvider.provider}`)}
              </td>
              <td className={'rule-engine-provider-table-cell'}>{linkedRuleEngineProvider.externalReference}</td>
              <td className={'rule-engine-provider-table-cell'}>
                {formatDateTime(linkedRuleEngineProvider.createdAt)}
              </td>
              <td className={'rule-engine-provider-table-cell'}>
                <div className={'rule-engine-provider-table-cell-actions'}>
                  <Button type={'primary'} size={'small'} onClick={handleRemoveRuleEngineProvider}>
                    {ruleEngineCallInProgress ? (
                      <Loader />
                    ) : (
                      i18n.getMessage('providerTab.ruleEngineProvider.button.delete')
                    )}
                  </Button>
                </div>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: 'center' }}>
                {i18n.getMessage('providerTab.ruleEngineProvider.noRuleEngineProvidersLinked')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {!linkedRuleEngineProvider && ruleEngineProviders?.includes(RULE_ENGINE_PROVIDER.FINCHECKER) && (
        <div className={'rule-engine-provider-container-button'}>
          <Button
            className={'rule-engine-provider-container-button-add'}
            isDisabled={ruleEngineCallInProgress}
            type={'primary'}
            size={'small'}
            onClick={() => handleAddRuleEngineProvider(RULE_ENGINE_PROVIDER.FINCHECKER)}
          >
            {ruleEngineCallInProgress ? <Loader /> : i18n.getMessage('providerTab.ruleEngineProvider.button.add')}
          </Button>
        </div>
      )}
    </div>
  );
};

RuleEngineProviders.propTypes = {
  linkedRuleEngineProvider: PropTypes.object,
  ruleEngineProviders: PropTypes.array,
  ruleEngineCallInProgress: PropTypes.bool,
  handleAddRuleEngineProvider: PropTypes.func,
  handleRemoveRuleEngineProvider: PropTypes.func
};

export default RuleEngineProviders;
