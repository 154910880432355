import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';
import i18nContext from 'components/i18n-context';
import { CARD_AUTHORIZATION_STATUS } from 'components/constants';
import CustomSearchInput from 'components/CustomSearchInput';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './CardAuthorisationsFiltersBar.scss';

const CardAuthorisationsFiltersBar = ({ filters, totalElements, handleSetFilters }) => {
  const i18n = useContext(i18nContext);
  const [searchValue, setSearchValue] = useState(filters.search_text ? filters.search_text : '');
  const currentDate = new Date();

  const debouncedSearch = useRef(
    debounce((name, value) => {
      handleSetFilters(name, value);
    }, 400)
  ).current;

  const handleSearchTextInputChange = ({ target: { name, value } }) => {
    setSearchValue(value);

    debouncedSearch(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const cardAuthorizationsStatusesOptions = Object.keys(CARD_AUTHORIZATION_STATUS).map((status) => {
    return {
      key: CARD_AUTHORIZATION_STATUS[status],
      value: i18n.getMessage('cardAuthorizationStatus.' + CARD_AUTHORIZATION_STATUS[status])
    };
  });

  return (
    <div className={'card-authorizations-filters-wrapper'}>
      <div className={'card-authorizations-date-range-filters-wrapper'}>
        <div>
          <span>{i18n.getMessage('cardAuthorizations.filter.from.label')}</span>
          <CustomDatePicker
            onChange={handleSetFilters}
            name={'from_date'}
            value={filters.from_date}
            maxDate={filters.to_date || currentDate}
          />
        </div>
        <div>
          <span>{i18n.getMessage('cardAuthorizations.filter.to.label')}</span>
          <CustomDatePicker
            onChange={handleSetFilters}
            name={'to_date'}
            value={filters.to_date}
            maxDate={currentDate}
            minDate={filters.from_date}
          />
        </div>
      </div>

      <InputDropDown
        className={'card-authorizations-filter-wrapper'}
        label={i18n.getMessage('cardAuthorizations.filter.status.label')}
        name={'statuses'}
        value={filters.statuses}
        options={cardAuthorizationsStatusesOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <CustomSearchInput
        wrapperClassName={'search-wrapper'}
        inputClassName={'card-authorizations-filter-wrapper'}
        label={i18n.getMessage('cardAuthorizations.filter.search.label')}
        placeholder={i18n.getMessage('cardAuthorizations.filter.search.placeholder')}
        name={'search_text'}
        value={searchValue}
        onChange={handleSearchTextInputChange}
        totalElements={totalElements}
      />
    </div>
  );
};

CardAuthorisationsFiltersBar.propTypes = {
  filters: PropTypes.object,
  totalElements: PropTypes.number,
  handleSetFilters: PropTypes.func
};

export default CardAuthorisationsFiltersBar;
