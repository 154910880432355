import { Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PAYMENT_OBLIGATION_STATUS } from 'components/constants';
import i18nContext from 'components/i18n-context';
import { getCustomerInfoRequest } from 'services/requestAgent';
import { formatDate, formatMoney, getCustomerLink } from 'services/utils';
import Button from 'uikit/Button/Button';
import './PaymentObligationsTableRow.scss';

const PaymentObligationsTableRow = ({
  isLoading,
  paymentObligationData,
  releasePaymentObligation,
  chargePaymentObligation
}) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();

  const handleRedirectToCustomer = async (customerNumber) => {
    const customer = await getCustomerInfoRequest(customerNumber);

    navigate(getCustomerLink(customerNumber, customer.status, customer.type));
  };

  const getPaymentObligationInfoBlock = (paymentObligationBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'payment-obligations-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (Array.isArray(paymentObligationBlockInfo)) {
      return (
        <p>
          {paymentObligationBlockInfo.map((item, index) => {
            if (item) {
              return (
                <Fragment key={index}>
                  {item}
                  {index < paymentObligationBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </p>
      );
    } else {
      return <p>{paymentObligationBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'payment-obligations-table-cell'}>
        {getPaymentObligationInfoBlock(formatMoney(paymentObligationData.amount, 2))}
      </td>
      <td className={'payment-obligations-table-cell'}>
        {getPaymentObligationInfoBlock(paymentObligationData.currency)}
      </td>
      <td className={'payment-obligations-table-cell'}>
        {getPaymentObligationInfoBlock(i18n.getMessage(`paymentObligationStatus.${paymentObligationData.status}`))}
      </td>
      <td className={'payment-obligations-table-cell'}>
        <Button
          className={'link-button payment-obligations-nav-link-text'}
          onClick={async () => await handleRedirectToCustomer(paymentObligationData.accountNumber)}
          isDisabled={false}
        >
          {paymentObligationData.accountNumber}
        </Button>
      </td>
      <td className={'payment-obligations-table-cell'}>
        {getPaymentObligationInfoBlock(paymentObligationData.comment)}
      </td>
      <td className={'payment-obligations-table-cell'}>
        {getPaymentObligationInfoBlock(formatDate(paymentObligationData.createdAt))}
      </td>
      <td className={'payment-obligations-table-cell'}>
        {getPaymentObligationInfoBlock(formatDate(paymentObligationData.updatedAt))}
      </td>
      <td className={'payment-obligations-table-cell'}>
        {paymentObligationData.status === PAYMENT_OBLIGATION_STATUS.OPEN && (
          <div className={'payment-obligations-table-cell-actions'}>
            <Button
              onClick={() => releasePaymentObligation(paymentObligationData.obligationId)}
              type={'outline'}
              size={'small'}
              fullWidth
            >
              {i18n.getMessage('paymentObligations.table.action.cancel')}
            </Button>
            <Button
              onClick={() => chargePaymentObligation(paymentObligationData.obligationId)}
              type={'primary'}
              size={'small'}
              fullWidth
            >
              {i18n.getMessage('paymentObligations.table.action.charge')}
            </Button>
          </div>
        )}
      </td>
    </tr>
  );
};

PaymentObligationsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  paymentObligationData: PropTypes.object,
  releasePaymentObligation: PropTypes.func,
  chargePaymentObligation: PropTypes.func
};

export default PaymentObligationsTableRow;
