import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';
import i18nContext from 'components/i18n-context';
import {
  CURRENCY,
  DOCUMENT_STATUS,
  PAYMENT_METHOD,
  PAYMENT_PROVIDER,
  TRANSACTION_STATUS,
  TRANSACTION_TYPES,
  TRANSFER_TYPE
} from 'components/constants';
import CustomSearchInput from 'components/CustomSearchInput';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './TransactionsFiltersBar.scss';

const TransactionsFiltersBar = ({
  isLoading,
  isInitialized,
  filters,
  totalElements,
  handleSetFilters,
  handleExportTransactionList,
  handleExportIfxTransactionList
}) => {
  const i18n = useContext(i18nContext);
  const [searchValue, setSearchValue] = useState(filters.search_text ? filters.search_text : '');
  const currentDate = new Date();

  const debouncedSearch = useRef(
    debounce((name, value) => {
      handleSetFilters(name, value);
    }, 400)
  ).current;

  const handleSearchTextInputChange = ({ target: { name, value } }) => {
    setSearchValue(value);

    debouncedSearch(name, value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const transferProviderOptions = Object.keys(PAYMENT_PROVIDER).map((provider) => {
    return {
      key: PAYMENT_PROVIDER[provider].name,
      value: i18n.getMessage('paymentProvider.' + PAYMENT_PROVIDER[provider].name)
    };
  });

  const transactionsTypesOptions = Object.keys(TRANSACTION_TYPES).map((status) => {
    return {
      key: TRANSACTION_TYPES[status],
      value: i18n.getMessage('transactionType.' + TRANSACTION_TYPES[status])
    };
  });

  const transactionsStatusesOptions = Object.keys(TRANSACTION_STATUS).map((status) => {
    return {
      key: TRANSACTION_STATUS[status],
      value: i18n.getMessage('transactionStatus.' + TRANSACTION_STATUS[status])
    };
  });

  const currencyOptions = Object.keys(CURRENCY).map((status) => {
    return {
      key: CURRENCY[status],
      value: CURRENCY[status]
    };
  });

  const paymentMethodOptions = Object.keys(PAYMENT_METHOD).map((status) => {
    return {
      key: PAYMENT_METHOD[status],
      value: i18n.getMessage('paymentMethod.' + PAYMENT_METHOD[status])
    };
  });

  const transferTypeOptions = Object.keys(TRANSFER_TYPE).map((status) => {
    return {
      key: TRANSFER_TYPE[status],
      value: i18n.getMessage('transferType.' + TRANSFER_TYPE[status])
    };
  });

  const documentStatusOptions = Object.keys(DOCUMENT_STATUS).map((status) => {
    return {
      key: DOCUMENT_STATUS[status],
      value: i18n.getMessage('documentStatus.' + DOCUMENT_STATUS[status])
    };
  });

  return (
    <div className={'transactions-filters-wrapper'}>
      <div className={'transactions-actions-wrapper'}>
        <div className={'transactions-date-range-filters-wrapper'}>
          <div>
            <span>{i18n.getMessage('transactions.filter.from.label')}</span>
            <CustomDatePicker
              onChange={handleSetFilters}
              name={'from_date'}
              value={filters.from_date}
              maxDate={filters.to_date || currentDate}
            />
          </div>
          <div>
            <span>{i18n.getMessage('transactions.filter.to.label')}</span>
            <CustomDatePicker
              onChange={handleSetFilters}
              name={'to_date'}
              value={filters.to_date}
              maxDate={currentDate}
              minDate={filters.from_date}
            />
          </div>
        </div>
        <div className={'export-transactions-reports-actions-wrapper'}>
          <Button
            className={'transactions-button'}
            size={'medium'}
            type={'primary'}
            onClick={handleExportTransactionList}
            isDisabled={!isInitialized || isLoading}
          >
            {isLoading ? <Loader /> : i18n.getMessage('transactions.button.downloadCsv')}
          </Button>
          <Button
            className={'transactions-button'}
            size={'medium'}
            type={'primary'}
            onClick={handleExportIfxTransactionList}
            isDisabled={!isInitialized || isLoading}
          >
            {isLoading ? <Loader /> : i18n.getMessage('transactions.button.ifxReport')}
          </Button>
        </div>
      </div>

      <InputDropDown
        className={'transactions-filter-wrapper'}
        name={'transfer_providers'}
        label={i18n.getMessage('transactions.filter.transferProvider.label')}
        value={filters.transfer_providers}
        options={transferProviderOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'transactions-filter-wrapper'}
        label={i18n.getMessage('transactions.filter.transactionType.label')}
        name={'types'}
        value={filters.types}
        options={transactionsTypesOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'transactions-filter-wrapper'}
        label={i18n.getMessage('transactions.filter.transactionStatus.label')}
        name={'statuses'}
        value={filters.statuses}
        options={transactionsStatusesOptions}
        onChange={handleSetFilters}
        isTransactionStatuses={true}
        isMulti
      />
      <InputDropDown
        className={'transactions-filter-wrapper'}
        label={i18n.getMessage('transactions.filter.currency.label')}
        name={'currencies'}
        value={filters.currencies}
        options={currencyOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'transactions-filter-wrapper'}
        label={i18n.getMessage('transactions.filter.paymentMethod.label')}
        name={'payment_methods'}
        value={filters.payment_methods}
        options={paymentMethodOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'transactions-filter-wrapper'}
        label={i18n.getMessage('transactions.filter.transferType.label')}
        name={'transfer_types'}
        value={filters.transfer_types}
        options={transferTypeOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'transactions-filter-wrapper'}
        label={i18n.getMessage('transactions.filter.documentStatus.label')}
        name={'document_statuses'}
        value={filters.document_statuses}
        options={documentStatusOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <CustomSearchInput
        wrapperClassName={'search-wrapper'}
        inputClassName={'transactions-filter-wrapper'}
        label={i18n.getMessage('transactions.filter.search.label')}
        placeholder={i18n.getMessage('transactions.filter.search.placeholder')}
        name={'search_text'}
        value={searchValue}
        onChange={handleSearchTextInputChange}
        totalElements={totalElements}
      />
    </div>
  );
};

TransactionsFiltersBar.propTypes = {
  isLoading: PropTypes.bool,
  isInitialized: PropTypes.bool,
  filters: PropTypes.object,
  totalElements: PropTypes.number,
  handleSetFilters: PropTypes.func,
  handleExportTransactionList: PropTypes.func,
  handleExportIfxTransactionList: PropTypes.func
};

export default TransactionsFiltersBar;
