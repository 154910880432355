import { ERROR_CODES } from 'components/constants';
import i18n from 'i18n';

export const fromErrorDto = (fields) => {
  return (
    Object.entries(fields)
      //eslint-disable-next-line no-unused-vars
      .filter(([key, value]) => value !== undefined)
      .reduce((acc, [key, value]) => {
        const mappedKey =
          key === 'accountData.email'
            ? 'email'
            : key === 'accountData.companyName'
              ? 'companyName'
              : key === 'accountData.firstName'
                ? 'firstName'
                : key === 'accountData.lastName'
                  ? 'lastName'
                  : key;

        acc[mappedKey] = value;
        return acc;
      }, {})
  );
};

export const parseUserCreationErrorResponse = (code, fields) => {
  const errors = {};

  if (code === ERROR_CODES.ARGUMENT_NOT_VALID && fields) {
    const converted = fromErrorDto(fields);

    Object.keys(converted).forEach((key) => {
      errors[key] = converted[key] ? i18n.getMessage(`error.field.${converted[key]}`) : undefined;
    });
  } else if (code === ERROR_CODES.USER_ALREADY_REGISTERED) {
    errors.phone = i18n.getMessage('error.createUser.phoneAlreadyInUse');
  } else if (code === ERROR_CODES.EMAIL_ALREADY_IN_USE) {
    errors.email = i18n.getMessage('error.createUser.emailAlreadyInUse');
  }

  return errors; // Return the constructed errors object
};
