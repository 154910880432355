export const UNAUTHORIZED_ROUTE_PATHS = {
  LOGIN: '/login'
};

export const ROUTE_PATHS = {
  ROOT: '/',
  CUSTOMERS: '/customers',
  CUSTOMER_CORPORATE: '/customers/corporative/:id',
  CUSTOMER_INDIVIDUAL: '/customers/individual/:id',
  ACTIVE_CUSTOMERS: '/active-customers',
  ACTIVE_CUSTOMER_CORPORATE: '/active-customers/corporative/:id',
  ACTIVE_CUSTOMER_INDIVIDUAL: '/active-customers/individual/:id',
  USERS: '/users',
  USER_DETAILS: '/users/:id',
  USER_CREATE: '/users/create',
  ACCOUNTS: '/accounts',
  SYSTEM_ACCOUNTS: '/system-accounts',
  TRANSACTIONS: '/transactions',
  TRANSACTION_DETAILS: '/transactions/:id',
  TRANSIT_TRANSACTIONS: '/transit-transactions',
  CREATE_TRANSACTION: '/new-transaction',
  CREATE_PAYMENT_TRANSACTION: '/new-transaction/payment',
  CREATE_TRANSIT_TRANSACTION: '/new-transaction/transit',
  CREATE_COMMISSION_TRANSACTION: '/new-transaction/commission',
  CREATE_CRYPTO_TRANSACTION: '/new-transaction/crypto',
  LIMITS: '/limits',
  MESSAGES: '/messages',
  NEW_MESSAGE: '/messages/new',
  TOPIC_MESSAGES: '/messages/:id',
  COMMISSIONS: '/commissions',
  COMMISSION_ACCOUNTS: '/commissions/commission-accounts',
  COMMISSION_TRANSACTIONS: '/commissions/commission-transactions',
  COMMISSION_WITHDRAWAL: '/commissions/withdrawal-transactions',
  TARIFFS: '/tariffs',
  PAYMENT_OBLIGATIONS: '/payment-obligations',
  CARD_AUTHORIZATIONS: '/card-authorizations',
  EMAIL_STATISTICS: '/email-statistics',
  SETTINGS: '/settings',
  SETTINGS_CONFIGURATION: '/settings/configuration',
  SETTINGS_PAYMENTS_CUTOFF_TIMES: '/settings/payments-cutoff-times',
  SETTINGS_BLACKLISTS: '/settings/blacklists',
  ...UNAUTHORIZED_ROUTE_PATHS
};

export const INITIAL_PATH_AFTER_LOGIN = ROUTE_PATHS.ROOT;
