import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { PAYMENT_PROVIDER, SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT } from 'components/constants';
import i18nContext from 'components/i18n-context';
import CustomAsyncSelect from 'components/CustomAsyncSelect';
import { getAccountsRequest } from 'services/requestAgent';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './InternalTransactionPartyControl.scss';

const InternalTransactionPartyControl = ({
  account,
  paymentProvider,
  paymentMethod,
  handleChangeValue,
  accountLabel,
  property,
  isShowPaymentProvider,
  isShowPaymentMethod,
  errors
}) => {
  const i18n = useContext(i18nContext);

  const sortAndFilterPaymentMethods = (transferProvider) => {
    return transferProvider ? Object.keys(PAYMENT_PROVIDER[transferProvider].paymentMethods) : [];
  };

  const getPaymentMethodsOptions = (paymentMethods) => {
    if (!paymentMethods || paymentMethods.length === 0) {
      return [];
    } else {
      return paymentMethods.map((method) => {
        return {
          key: method,
          value: i18n.getMessage('paymentMethod.' + method)
        };
      });
    }
  };

  const [paymentMethodOptions, setPaymentMethodOptions] = useState(
    getPaymentMethodsOptions(sortAndFilterPaymentMethods(paymentProvider))
  );

  const noOptionsMessage = () => i18n.getMessage('createTransaction.payment.accountSelect.noAccountsFound');
  const loadingMessage = ({ inputValue }) =>
    inputValue.trim().length < SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT
      ? i18n.getMessage('createTransaction.payment.accountSelect.searchWillStartWith') +
        SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT +
        i18n.getMessage('createTransaction.payment.accountSelect.characters')
      : i18n.getMessage('createTransaction.payment.accountSelect.searching');

  const transferProviderOptions = Object.keys(PAYMENT_PROVIDER).map((provider) => {
    return {
      key: PAYMENT_PROVIDER[provider].name,
      value: i18n.getMessage('paymentProvider.' + PAYMENT_PROVIDER[provider].name)
    };
  });

  const handleChangeAccountOption = (account) => {
    const sortedAndFilteredPaymentMethods = sortAndFilterPaymentMethods(account.value.transfer_provider);

    handleChangeValue(property, account.value);
    handleChangeValue('paymentProvider', account.value.transfer_provider);
    handleChangeValue('paymentMethod', sortedAndFilteredPaymentMethods[0]);
    handleChangeValue('currency', account.value.currency);
    setPaymentMethodOptions(getPaymentMethodsOptions(sortedAndFilteredPaymentMethods));
  };

  const handlePaymentProviderChange = (name, value) => {
    const sortedAndFilteredPaymentMethods = sortAndFilterPaymentMethods(value);

    handleChangeValue('paymentProvider', value);
    handleChangeValue('paymentMethod', sortedAndFilteredPaymentMethods[0]);
    setPaymentMethodOptions(getPaymentMethodsOptions(sortedAndFilteredPaymentMethods));
  };

  const loadOptions = async (searchText) => {
    let response = null;
    if (searchText.length >= SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT) {
      response = await getAccountsRequest('iban', 0, 10, 'ASC', searchText);
    }

    return response
      ? response.content.map((account) => ({
          value: account,
          label: `${account.multi_iban || account.iban} (${account.wallet_number})`
        }))
      : [];
  };

  return (
    <div className={'internal-transaction-party-control-wrapper'}>
      <CustomAsyncSelect
        isRequired
        className={'internal-transaction-party-control-account'}
        isAutoFocus={true}
        onChange={handleChangeAccountOption}
        loadList={loadOptions}
        handleInputChange={handleChangeValue}
        name={property}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        label={accountLabel}
        placeholder={i18n.getMessage('createTransaction.payment.accountSelect.placeholder')}
        error={errors?.[property]}
      />

      {isShowPaymentMethod && (
        <>
          <InputDropDown
            isRequired
            className={'internal-transaction-party-control-select'}
            label={i18n.getMessage('createTransaction.payment.paymentProvider')}
            id={'paymentProvider'}
            name={'paymentProvider'}
            value={paymentProvider}
            options={transferProviderOptions}
            onChange={handlePaymentProviderChange}
            error={errors?.paymentProvider}
            isDisabled={!!account || !isShowPaymentProvider}
          />
          <InputDropDown
            isRequired
            className={'internal-transaction-party-control-select'}
            label={i18n.getMessage('createTransaction.payment.paymentMethod')}
            id={'paymentMethod'}
            name={'paymentMethod'}
            value={paymentMethod}
            options={paymentMethodOptions}
            onChange={handleChangeValue}
            error={errors?.paymentMethod}
          />
        </>
      )}
    </div>
  );
};

InternalTransactionPartyControl.propTypes = {
  account: PropTypes.object,
  paymentProvider: PropTypes.string,
  paymentMethod: PropTypes.string,
  handleChangeValue: PropTypes.func,
  property: PropTypes.string,
  accountLabel: PropTypes.string,
  isShowPaymentProvider: PropTypes.bool,
  isShowPaymentMethod: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])])
};

export default InternalTransactionPartyControl;
