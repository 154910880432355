import { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  DIRECTION,
  SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT,
  TRANSIT_TRANSACTION_DIRECTION,
  TRANSIT_TRANSACTION_STATUS
} from 'components/constants';
import i18nContext from 'components/i18n-context';
import CustomAsyncSelect from 'components/CustomAsyncSelect';
import { getTransitTransactionsListRequest } from 'services/requestAgent';
import './TransitTransactionAsyncSelect.scss';

const TransitTransactionAsyncSelect = ({ property, handleChangeValue, error }) => {
  const i18n = useContext(i18nContext);

  const noOptionsMessage = () => i18n.getMessage('createTransaction.payment.transitTransactionSelect.noAccountsFound');
  const loadingMessage = ({ inputValue }) =>
    inputValue.trim().length < SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT
      ? i18n.getMessage('createTransaction.payment.transitTransactionSelect.searchWillStartWith') +
        SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT +
        i18n.getMessage('createTransaction.payment.transitTransactionSelect.characters')
      : i18n.getMessage('createTransaction.payment.transitTransactionSelect.searching');

  const handleChangeTransitTransactionOption = (account) => {
    handleChangeValue(property, account.value);
  };

  const loadOptions = async (searchText) => {
    let response = null;
    if (searchText.length >= SEARCH_BOUND_MINIMUM_LENGTH_ACCOUNT) {
      response = await getTransitTransactionsListRequest(
        {
          page: 0,
          size: 10
        },
        'created_at',
        DIRECTION.ASC,
        searchText,
        {
          statuses: [TRANSIT_TRANSACTION_STATUS.FAILED, TRANSIT_TRANSACTION_STATUS.CREATED],
          direction: [TRANSIT_TRANSACTION_DIRECTION.IN]
        }
      );
    }

    return response
      ? response.content.map((transitTransaction) => ({
          value: transitTransaction,
          label: transitTransaction
            ? `${transitTransaction.transaction_id} (${transitTransaction.amount} ${transitTransaction.currency})`
            : ''
        }))
      : [];
  };

  return (
    <div className={'transit-transaction-async-select-wrapper'}>
      <CustomAsyncSelect
        isRequired
        className={'transit-transaction-async-select-input'}
        onChange={handleChangeTransitTransactionOption}
        loadList={loadOptions}
        handleInputChange={handleChangeValue}
        name={property}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        label={i18n.getMessage('createTransaction.payment.transitTransactionSelect.label')}
        placeholder={i18n.getMessage('createTransaction.payment.transitTransactionSelect.placeholder')}
        error={error}
      />
    </div>
  );
};

TransitTransactionAsyncSelect.propTypes = {
  property: PropTypes.string,
  handleChangeValue: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])])
};

export default TransitTransactionAsyncSelect;
