import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import { CUSTOMER_PERMISSIONS } from 'components/constants';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import '../RepresentativesTab.scss';

export const PermissionScheme = ({ closePopUp, email, submitPermissions, permissions, representativeForUpdate }) => {
  const i18n = useContext(i18nContext);
  const [currentPermissions, setCurrentPermissions] = useState(
    representativeForUpdate?.permissions || [CUSTOMER_PERMISSIONS.READ_ACCOUNT]
  );

  const onConfirmClick = async () => {
    await submitPermissions(currentPermissions);
  };

  const onCheckBoxChange = (key) => {
    if (key === CUSTOMER_PERMISSIONS.CREATE_MESSAGE) {
      // If 'View & Create messages' is selected, ensure 'View messages' is also selected
      setCurrentPermissions((prev) => {
        const updatedPermissions = [...prev];
        if (!updatedPermissions.includes(CUSTOMER_PERMISSIONS.READ_MESSAGES)) {
          updatedPermissions.push(CUSTOMER_PERMISSIONS.READ_MESSAGES);
        }
        if (!updatedPermissions.includes(CUSTOMER_PERMISSIONS.CREATE_MESSAGE)) {
          updatedPermissions.push(CUSTOMER_PERMISSIONS.CREATE_MESSAGE);
        } else {
          updatedPermissions.splice(updatedPermissions.indexOf(CUSTOMER_PERMISSIONS.CREATE_MESSAGE), 1);
        }
        return updatedPermissions;
      });
    } else if (key === CUSTOMER_PERMISSIONS.READ_MESSAGES) {
      // If 'View messages' is deselected, also remove 'View & Create messages'
      setCurrentPermissions((prev) => {
        const updatedPermissions = [...prev];
        if (!updatedPermissions.includes(CUSTOMER_PERMISSIONS.READ_MESSAGES)) {
          updatedPermissions.push(CUSTOMER_PERMISSIONS.READ_MESSAGES);
        } else {
          updatedPermissions.splice(updatedPermissions.indexOf(CUSTOMER_PERMISSIONS.READ_MESSAGES), 1);
          if (updatedPermissions.includes(CUSTOMER_PERMISSIONS.CREATE_MESSAGE)) {
            updatedPermissions.splice(updatedPermissions.indexOf(CUSTOMER_PERMISSIONS.CREATE_MESSAGE), 1);
          }
        }
        return updatedPermissions;
      });
    } else if (key === CUSTOMER_PERMISSIONS.CREATE_CARDS) {
      // If 'View cards & Create cards ' is selected, ensure 'View Cards' is also selected
      setCurrentPermissions((prev) => {
        const updatedPermissions = [...prev];
        if (!updatedPermissions.includes(CUSTOMER_PERMISSIONS.READ_CARDS)) {
          updatedPermissions.push(CUSTOMER_PERMISSIONS.READ_CARDS);
        }
        if (!updatedPermissions.includes(CUSTOMER_PERMISSIONS.CREATE_CARDS)) {
          updatedPermissions.push(CUSTOMER_PERMISSIONS.CREATE_CARDS);
        } else {
          updatedPermissions.splice(updatedPermissions.indexOf(CUSTOMER_PERMISSIONS.CREATE_CARDS), 1);
        }
        return updatedPermissions;
      });
    } else if (key === CUSTOMER_PERMISSIONS.READ_CARDS) {
      // If 'View Cards' is deselected, also remove 'View cards & Create cards'
      setCurrentPermissions((prev) => {
        const updatedPermissions = [...prev];
        if (!updatedPermissions.includes(CUSTOMER_PERMISSIONS.READ_CARDS)) {
          updatedPermissions.push(CUSTOMER_PERMISSIONS.READ_CARDS);
        } else {
          updatedPermissions.splice(updatedPermissions.indexOf(CUSTOMER_PERMISSIONS.READ_CARDS), 1);
          if (updatedPermissions.includes(CUSTOMER_PERMISSIONS.CREATE_CARDS)) {
            updatedPermissions.splice(updatedPermissions.indexOf(CUSTOMER_PERMISSIONS.CREATE_CARDS), 1);
          }
        }
        return updatedPermissions;
      });
    } else {
      // For other permissions, toggle the permission state
      if (!currentPermissions.includes(key)) {
        setCurrentPermissions((prev) => [...prev, key]);
      } else {
        setCurrentPermissions(currentPermissions.filter((perm) => perm !== key));
      }
    }
  };

  const isSelectAll = !permissions.filter((permission) => {
    return !currentPermissions.includes(permission);
  }).length;

  const renderButtonText = () => {
    return i18n.getMessage(representativeForUpdate ? 'representatives.label.change' : 'representatives.label.add');
  };

  return (
    <div className={'scheme-wrapper permission'}>
      <div className={'representatives-add-header-wrapper'}>
        <h2 className={'representatives-popUp-header'}>{i18n.getMessage('representatives.header.permission')}</h2>
        <CloseIcon className={'representatives-icon'} onClick={closePopUp} />
      </div>
      <p className={'representatives-email'}>{email || 'Name@example.com'}</p>
      <CheckBox
        className={'representatives-checkbox full-checkbox'}
        isActive={isSelectAll}
        showCheckBox={true}
        onChange={() => setCurrentPermissions(isSelectAll ? [CUSTOMER_PERMISSIONS.READ_ACCOUNT] : permissions)}
        value={i18n.getMessage('representatives.label.fullAccess')}
      />
      <div className={'permission-checkboxes-wrapper'}>
        {Object.keys(CUSTOMER_PERMISSIONS).map((permission) => (
          <CheckBox
            className={'representatives-checkbox'}
            key={`representatives-permission-${permission}-id`}
            isActive={currentPermissions?.includes(permission)}
            onChange={() => onCheckBoxChange(permission)}
            disabled={permission === CUSTOMER_PERMISSIONS.READ_ACCOUNT}
            showCheckBox={true}
            value={i18n.getMessage(`representatives.permission.${permission}`)}
          />
        ))}
      </div>
      <Button size={'large'} fullWidth={true} onClick={onConfirmClick}>
        {renderButtonText()}
      </Button>
    </div>
  );
};

PermissionScheme.propTypes = {
  closePopUp: PropTypes.func.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  submitPermissions: PropTypes.func,
  representativeForUpdate: PropTypes.object,
  permissions: PropTypes.array
};
