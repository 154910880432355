export default (data) => ({
  name: data.name || '',
  surname: data.surname || '',
  birthday: (data.details || {}).birthday || '',
  passportNumber: (data.details || {}).passport_number || '',
  passportCountry: (data.details || {}).passport_issue_country || '',
  passportDate: (data.details || {}).passport_issue_date || '',
  passportExpiryDate: (data.details || {}).passport_expiry_date || '',
  address1: ((data.details || {}).address || {}).address_1 || '',
  address2: ((data.details || {}).address || {}).address_2 || '',
  hashtags: data.hashtags ? data.hashtags.join('\n') : '',
  addressCity: ((data.details || {}).address || {}).city || '',
  addressCountry: ((data.details || {}).address || {}).country || '',
  zip: ((data.details || {}).address || {}).zip_code || '',
  kycStatus: data.kyc_status,
  declaredAccountTurnover: data.declaredAccountTurnover,
  partners: data.partners,
  additionalComments: data.additionalComments,
  commentDocuments: data.commentToDocuments,
  passportCheck: data.passportCheck,
  sanctionList: data.sanctionList,
  pepList: data.pepList,
  blacklist: data.blackList,
  nextAMLCheckDate: data?.next_aml_check_date ? new Date(data.next_aml_check_date) : '',
  sanctionsRelated: data.sanctions_related,
  adverseMediaInvolved: data.adverse_media_involved,
  position: (data.details || {}).position || '',
  title: (data.details || {}).title || '',
  accountOpeningPurpose: (data.details || {}).account_opening_purpose || ''
});
