import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InternalCryptoTransactionPartyControl from './components/InternalCryptoTransactionPartyControl';
import { fromErrorDto, toDto } from './components/CryptoTransactionModel';
import {
  AMOUNT_INPUT_VALUE_TYPE,
  CRYPTO_CURRENCY_PRECISION,
  MAX_TRANSACTION_COMMENT_LENGTH,
  PAYMENT_METHOD,
  TRANSACTION_TYPES,
  ERROR_CODES,
  PAYMENT_PROVIDERS
} from 'components/constants';
import { useApplicationContext } from 'contexts/ApplicationContext';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { getAllCryptoCurrencies } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import AmountInput from 'uikit/AmountInput';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './CryptoTransaction.scss';

const CryptoTransaction = ({ transactionsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const { constants } = useApplicationContext();
  const [currencyOptions, setCurrencyOptions] = useState([]);

  const form = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      transactionType: TRANSACTION_TYPES.INCOMING,
      paymentProvider: PAYMENT_PROVIDERS.GRAPHEREX,
      paymentMethod: PAYMENT_METHOD.CRYPTO,
      senderAccount: null,
      recipientAccount: null,
      senderWalletAddress: '',
      recipientWalletAddress: '',
      amount: '',
      currency: '',
      commentToAdmin: ''
    },
    validationSchema: Yup.object({
      paymentProvider: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      paymentMethod: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      senderAccount: Yup.object().when(['transactionType', 'paymentMethod'], {
        is: (transactionType, paymentMethod) =>
          transactionType === TRANSACTION_TYPES.OUTGOING ||
          (transactionType === TRANSACTION_TYPES.INCOMING && paymentMethod === PAYMENT_METHOD.INTERNAL),
        then: () => Yup.object().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.object().nullable().notRequired()
      }),
      recipientAccount: Yup.object().when(['transactionType', 'paymentMethod'], {
        is: (transactionType, paymentMethod) =>
          transactionType === TRANSACTION_TYPES.INCOMING ||
          (transactionType === TRANSACTION_TYPES.OUTGOING && paymentMethod === PAYMENT_METHOD.INTERNAL),
        then: () => Yup.object().required(i18n.getMessage('createTransaction.validation.isRequired')),
        otherwise: () => Yup.object().nullable().notRequired()
      }),
      senderWalletAddress: Yup.string().when(['transactionType', 'paymentMethod'], {
        is: (transactionType, paymentMethod) =>
          transactionType === TRANSACTION_TYPES.INCOMING && paymentMethod === PAYMENT_METHOD.CRYPTO,
        then: () =>
          Yup.string()
            .required(i18n.getMessage('createTransaction.validation.isRequired'))
            .max(50, i18n.getMessage('createTransaction.validation.maxSize', { size: 50 })),
        otherwise: () =>
          Yup.string()
            .nullable()
            .notRequired()
            .max(50, i18n.getMessage('createTransaction.validation.maxSize', { size: 50 }))
      }),
      recipientWalletAddress: Yup.string().when(['transactionType', 'paymentMethod'], {
        is: (transactionType, paymentMethod) =>
          transactionType === TRANSACTION_TYPES.OUTGOING && paymentMethod === PAYMENT_METHOD.CRYPTO,
        then: () =>
          Yup.string()
            .required(i18n.getMessage('createTransaction.validation.isRequired'))
            .max(50, i18n.getMessage('createTransaction.validation.maxSize', { size: 50 })),
        otherwise: () =>
          Yup.string()
            .nullable()
            .notRequired()
            .max(50, i18n.getMessage('createTransaction.validation.maxSize', { size: 50 }))
      }),
      amount: Yup.string()
        .required(i18n.getMessage('createTransaction.validation.isRequired'))
        .test(
          'min-amount',
          i18n.getMessage('createTransaction.validation.isTooLow', { min: 1.0 }),
          (value) => !value || parseFloat(value) >= 1
        ),
      currency: Yup.string().required(i18n.getMessage('createTransaction.validation.isRequired')),
      commentToAdmin: Yup.string()
        .nullable()
        .max(
          MAX_TRANSACTION_COMMENT_LENGTH,
          i18n.getMessage('createTransaction.validation.maxSize', { size: MAX_TRANSACTION_COMMENT_LENGTH })
        )
    }),
    onSubmit: async (values) => {
      values.transactionType === TRANSACTION_TYPES.OUTGOING
        ? await transactionsStore.createOutgoingTransaction(toDto(values))
        : await transactionsStore.createIncomingTransaction(toDto(values));
    }
  });

  const { values, errors, handleSubmit, handleChange, setErrors, setFieldValue, resetForm, validateField } = form;

  useEffect(() => {
    if (transactionsStore.isNewTransactionCreated) {
      resetForm();
      transactionsStore.setIsNewTransactionCreated(false);
      navigate(`${ROUTE_PATHS.TRANSACTIONS}/${transactionsStore.newTransactionId}`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsStore.isNewTransactionCreated]);

  const parseServerErrors = (code, fields) => {
    if (code === ERROR_CODES.ARGUMENT_NOT_VALID) {
      const converted = fromErrorDto(fields);
      Object.keys(converted).forEach((key) => {
        converted[key] = converted[key] ? i18n.getMessage(`error.field.${converted[key]}`) : undefined;
      });

      setErrors(converted);
    }
  };

  useEffect(() => {
    if (transactionsStore.error) {
      parseServerErrors(transactionsStore.error.code, transactionsStore.error.fields);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsStore.error]);

  const transactionsTypesOptions = Object.keys(TRANSACTION_TYPES).map((type) => {
    return {
      key: TRANSACTION_TYPES[type],
      value: i18n.getMessage('transactionType.' + TRANSACTION_TYPES[type])
    };
  });

  const getCurrenciesOptions = () => {
    if (constants) {
      const allCryptoCurrencies = getAllCryptoCurrencies(constants);

      return allCryptoCurrencies.map((currency) => {
        return {
          key: currency,
          value: currency
        };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (constants?.providerProperties?.GRAPHEREX) {
      setCurrencyOptions(getCurrenciesOptions());
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constants?.providerProperties?.GRAPHEREX]);

  useEffect(() => {
    if (values.recipientAccount) {
      validateField('recipientAccount');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.recipientAccount]);

  useEffect(() => {
    if (values.senderAccount) {
      validateField('senderAccount');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.senderAccount]);

  const handleChangeValue = (name, value) => {
    return setFieldValue(name, value);
  };

  const onBlurValidate = (fieldName) => {
    validateField(fieldName);
  };

  const handleChangeTransactionType = (name, transactionType) => {
    if (transactionType !== values.transactionType) {
      resetForm();
      setFieldValue(name, transactionType);
    }
  };

  return (
    <div className={'crypto-transaction-wrapper'}>
      <div className={'crypto-transaction-type-wrapper'}>
        <InputDropDown
          className={'crypto-transaction-select-transaction-type'}
          label={i18n.getMessage('createTransaction.crypto.transactionType')}
          id={'transactionType'}
          name={'transactionType'}
          value={values.transactionType}
          options={transactionsTypesOptions}
          onChange={handleChangeTransactionType}
        />
      </div>
      <form className={'crypto-transaction-form'} onSubmit={handleSubmit}>
        {values.transactionType === TRANSACTION_TYPES.INCOMING && (
          <>
            <InternalCryptoTransactionPartyControl
              account={values.recipientAccount}
              paymentProvider={values.paymentProvider}
              paymentMethod={values.paymentMethod}
              setCurrencyOptions={setCurrencyOptions}
              handleChangeByFormik={handleChange}
              handleChangeValue={handleChangeValue}
              property={'recipientAccount'}
              values={values}
              accountLabel={i18n.getMessage('createTransaction.crypto.toAccount')}
              isShowPaymentMethod={true}
              isShowPaymentProvider={true}
              errors={errors}
              validateField={onBlurValidate}
            />

            <InternalCryptoTransactionPartyControl
              account={values.senderAccount}
              paymentProvider={values.paymentProvider}
              paymentMethod={values.paymentMethod}
              handleChangeByFormik={handleChange}
              handleChangeValue={handleChangeValue}
              property={values.paymentMethod === PAYMENT_METHOD.CRYPTO ? 'senderWalletAddress' : 'senderAccount'}
              values={values}
              accountLabel={i18n.getMessage('createTransaction.crypto.fromAccount')}
              isShowPaymentMethod={false}
              isShowRegularAccountField={values.paymentMethod === PAYMENT_METHOD.CRYPTO}
              errors={errors}
              validateField={onBlurValidate}
            />
          </>
        )}

        {values.transactionType === TRANSACTION_TYPES.OUTGOING && (
          <>
            <InternalCryptoTransactionPartyControl
              account={values.senderAccount}
              paymentProvider={values.paymentProvider}
              paymentMethod={values.paymentMethod}
              handleChangeByFormik={handleChange}
              handleChangeValue={handleChangeValue}
              property={'senderAccount'}
              values={values}
              accountLabel={i18n.getMessage('createTransaction.crypto.fromAccount')}
              isShowPaymentProvider={true}
              isShowPaymentMethod={true}
              validateField={onBlurValidate}
            />

            <InternalCryptoTransactionPartyControl
              account={values.recipientAccount}
              paymentProvider={values.paymentProvider}
              paymentMethod={values.paymentMethod}
              handleChangeByFormik={handleChange}
              handleChangeValue={handleChangeValue}
              property={values.paymentMethod === PAYMENT_METHOD.CRYPTO ? 'recipientWalletAddress' : 'recipientAccount'}
              values={values}
              accountLabel={i18n.getMessage('createTransaction.crypto.toAccount')}
              isShowPaymentMethod={false}
              isShowRegularAccountField={values.paymentMethod === PAYMENT_METHOD.CRYPTO}
              validateField={onBlurValidate}
            />
          </>
        )}

        <div className={'crypto-transaction-block-wrapper'}>
          <div className={'crypto-transaction-row-block'}>
            <AmountInput
              isRequired
              className={'crypto-transaction-input'}
              label={i18n.getMessage('createTransaction.crypto.amount.label')}
              placeholder={i18n.getMessage('createTransaction.crypto.amount.placeholder')}
              name={'amount'}
              value={values?.amount}
              returnValueType={AMOUNT_INPUT_VALUE_TYPE.STRING}
              precision={CRYPTO_CURRENCY_PRECISION}
              onChange={handleChange}
              onBlur={() => onBlurValidate('amount')}
              isDisabled={!!values.transitTransaction}
              error={errors?.amount}
            />
            <InputDropDownSearch
              isRequired
              className={'crypto-transaction-select-currency'}
              label={i18n.getMessage('createTransaction.crypto.currency')}
              id={'currency'}
              name={'currency'}
              value={values?.currency}
              options={currencyOptions}
              onChange={handleChangeValue}
              onBlur={() => onBlurValidate('currency')}
              isDisabled={
                (!!values.recipientAccount && values.transactionType === TRANSACTION_TYPES.INCOMING) ||
                values.transactionType === TRANSACTION_TYPES.OUTGOING
              }
              error={errors?.currency}
            />
          </div>

          <Input
            type={'textarea'}
            rows={2}
            className={'crypto-transaction-textarea'}
            label={i18n.getMessage('createTransaction.crypto.commentToAdmin')}
            id={'commentToAdmin'}
            name={'commentToAdmin'}
            value={values.commentToAdmin}
            onChange={handleChange}
            onBlur={() => onBlurValidate('commentToAdmin')}
            error={errors?.commentToAdmin}
            max={MAX_TRANSACTION_COMMENT_LENGTH}
            subText={i18n.getMessage('createTransaction.crypto.symbolsLeft', {
              symbolsLeft: MAX_TRANSACTION_COMMENT_LENGTH - values.commentToAdmin.length
            })}
          />
        </div>

        <div className={'crypto-transaction-submit-button-wrapper'}>
          <Button
            className={'crypto-transaction-submit-button'}
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            onClick={() => {}}
            isDisabled={transactionsStore.isLoading}
          >
            {transactionsStore.isLoading ? (
              <Loader />
            ) : (
              i18n.getMessage('createTransaction.crypto.button.createCryptoTransaction')
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

CryptoTransaction.propTypes = {
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  transactionsStore: stores.transactionsStore
}))(observer(CryptoTransaction));
