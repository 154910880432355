import { PAYMENT_METHOD, TRANSACTION_PARTY_TYPE, TRANSACTION_TYPES as TRANSACTION_TYPE } from 'components/constants';

export const toDto = (data) => ({
  payment_method: data.paymentMethod,
  amount: Number(data.amount),
  commission: 0,
  // comment: "",
  comment_to_admin: data.commentToAdmin,
  // documents: data.documents.map((d) => d.id),
  ...(data.transactionType === TRANSACTION_TYPE.OUTGOING
    ? {
        sender_wallet: data.senderAccount?.wallet_number,
        recipient: toTransactionParty(data)
      }
    : {
        ...(data.recipientAccount
          ? { recipient_wallet: data.recipientAccount.wallet_number }
          : { transfer_provider: data.paymentProvider }),
        sender: toTransactionParty(data)
      })
});

export const fromErrorDto = (fields) => ({
  amount: fields.amount,
  currency: fields.currency,
  commission: fields.commission,
  comment: fields.comment,
  commentToAdmin: fields.commentToAdmin,
  senderAccount: fields.senderWallet,
  recipientAccount: fields.recipientWallet
});

const toTransactionParty = (data) => {
  if (data.paymentMethod === PAYMENT_METHOD.INTERNAL) {
    return {
      wallet_number:
        data.transactionType === TRANSACTION_TYPE.INCOMING
          ? data.senderAccount?.wallet_number
          : data.recipientAccount?.wallet_number
    };
  } else {
    return {
      walletAddress:
        data.transactionType === TRANSACTION_TYPE.INCOMING ? data.senderWalletAddress : data.recipientWalletAddress
    };
  }
};
