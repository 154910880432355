import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { DocumentsHeadRow } from './components/DocumentsHeadRow';
import DocumentsTableRow from './components/DocumentsTableRow';
import Loader from 'components/Loader';
import i18nContext from 'components/i18n-context';
import { PopUpCustomerDocumentDetailsScheme } from 'components/PopUpScheme/PopUpCustomerDocumentDetailsScheme';
import { convertDate } from 'services/utils';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './DocumentsTab.scss';

const DocumentsTab = ({
  isLoading,
  documents,
  getDocuments,
  uploadDocument,
  updateDocument,
  deleteDocument,
  downloadDocument
}) => {
  const i18n = useContext(i18nContext);
  const [isOpen, setIsOpen] = useState(false);
  const [editedDocument, setEditedDocument] = useState(null);

  useEffect(() => {
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setEditedDocument(null);
  };

  const handleEditedDocument = (document) => {
    setEditedDocument(document);
    handleOpen();
  };

  const handleUploadOrEditDocument = async (note, notificationDate, expirationDate, document) => {
    const info = {
      note,
      notificationDate: convertDate(notificationDate),
      expirationDate: convertDate(expirationDate)
    };

    if (editedDocument) {
      await updateDocument(editedDocument.documentUid, info);
    } else {
      const data = new FormData();
      data.append(
        'info',
        new Blob([JSON.stringify(info)], {
          type: 'application/json'
        })
      );
      data.append('file', document);

      await uploadDocument(data);
    }
    handleClose();
  };

  if (!documents) {
    return <Loader className={'application-loader documents-tab-loader'} />;
  }

  return (
    <div className={'documents-container'}>
      <Table responsive>
        <thead>
          <DocumentsHeadRow />
        </thead>
        <tbody>
          {documents.map((document) => (
            <DocumentsTableRow
              key={document.documentUid}
              isLoading={isLoading}
              documentData={document}
              handleOpenPopUp
              handleDownloadDocument={downloadDocument}
              handleEditedDocument={handleEditedDocument}
              handleDeleteDocument={deleteDocument}
            />
          ))}
        </tbody>
      </Table>
      <Button className={'documents-container-button'} onClick={handleOpen}>
        {i18n.getMessage('documents.addNew')}
      </Button>
      <PopUp show={isOpen} onClose={handleClose} alignOnCenter className={'documents-container-popUp'}>
        <PopUpCustomerDocumentDetailsScheme
          handleClose={handleClose}
          editedDocument={editedDocument}
          handleUploadOrEditDocument={handleUploadOrEditDocument}
        />
      </PopUp>
    </div>
  );
};

DocumentsTab.propTypes = {
  isLoading: PropTypes.bool,
  documents: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  getDocuments: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  updateDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired
};

export default DocumentsTab;
