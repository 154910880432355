import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './TariffsTableHeadRow.scss';

export const TARIFFS_TABLE_HEADER = [
  {
    label: 'name'
  },
  {
    label: 'amount'
  },
  {
    label: 'action',
    width: '10%'
  }
];

export const TariffsTableHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {TARIFFS_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'tariffs-table-head'}>
          <div className={'tariffs-table-head-block'}>
            <span>{i18n.getMessage(`tariffsTab.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};
