import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import TransactionsFiltersBar from './components/TransactionsFiltersBar';
import TransactionsTableHead from './components/TransactionsTableHead';
import TransactionsTableRow from './components/TransactionsTableRow';
import i18nContext from 'components/i18n-context';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { Container } from 'uikit/Container/Container';
import './TransactionsTable.scss';

const TransactionsTable = ({ transactionsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination, sortColumn) => {
    const filtersParams = transactionsStore.prepareFiltersParams();
    const queryParams = {
      ...filtersParams,
      page: pagination.page,
      size: pagination.size,
      sort_column: sortColumn.sort_column,
      sort_direction: sortColumn.sort_direction
    };
    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    transactionsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!transactionsStore.isInitialized) {
      transactionsStore.getTransactionList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsStore.isInitialized]);

  useEffect(() => {
    if (transactionsStore.isInitialized) {
      transactionsStore.resetTransactionStore();
      loadFiltersFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(transactionsStore.filters, transactionsStore.pagination, transactionsStore.sortColumn);
    if (transactionsStore.isInitialized) {
      transactionsStore.getTransactionList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transactionsStore.filters.search_text,
    transactionsStore.filters.from_date,
    transactionsStore.filters.to_date,
    transactionsStore.filters.transfer_providers,
    transactionsStore.filters.types,
    transactionsStore.filters.statuses,
    transactionsStore.filters.currencies,
    transactionsStore.filters.payment_methods,
    transactionsStore.filters.transfer_types,
    transactionsStore.filters.document_statuses,
    transactionsStore.pagination.size,
    transactionsStore.pagination.page,
    transactionsStore.sortColumn.sort_column,
    transactionsStore.sortColumn.sort_direction
  ]);

  const handleOnSortTable = (newSortBy) => {
    transactionsStore.setSortData({
      sortBy: newSortBy,
      direction:
        transactionsStore.sortColumn.sort_column !== newSortBy
          ? DIRECTION.ASC
          : transactionsStore.sortColumn.sort_direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  if (!transactionsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'transactions-container'} header={i18n.getMessage('container.transactions')}>
      <TransactionsFiltersBar
        isLoading={transactionsStore.isLoading}
        isInitialized={transactionsStore.isInitialized}
        filters={transactionsStore.filters}
        totalElements={transactionsStore.totalElements}
        handleSetFilters={transactionsStore.setFilter}
        handleExportTransactionList={transactionsStore.exportTransactionList}
        handleExportIfxTransactionList={transactionsStore.exportIfxTransactionList}
      />

      <Pagination
        isShowPaginationSize={true}
        paginationSize={transactionsStore.pagination.size}
        handleChangePaginationSize={transactionsStore.setPageSize}
        isLoading={transactionsStore.isLoading}
        currentPage={transactionsStore.pagination.page}
        numberOfPages={transactionsStore.pagination.totalPages}
        setCurrentPage={transactionsStore.setPageNumber}
      />

      <Table responsive>
        <thead>
          <TransactionsTableHead
            handleOnSortTable={handleOnSortTable}
            sortBy={transactionsStore.sortColumn.sort_column}
            direction={transactionsStore.sortColumn.sort_direction}
          />
        </thead>
        <tbody>
          {transactionsStore.transactionList?.map((transaction, index) => (
            <TransactionsTableRow
              key={index}
              type={'list'}
              isLoading={transactionsStore.isLoading}
              transactionData={transaction}
            />
          ))}
        </tbody>
      </Table>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={transactionsStore.pagination.size}
        handleChangePaginationSize={transactionsStore.setPageSize}
        isLoading={transactionsStore.isLoading}
        currentPage={transactionsStore.pagination.page}
        numberOfPages={transactionsStore.pagination.totalPages}
        setCurrentPage={transactionsStore.setPageNumber}
        menuDirection={MENU_DIRECTION.UP}
      />
    </Container>
  );
};

TransactionsTable.propTypes = {
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  transactionsStore: stores.transactionsStore
}))(observer(TransactionsTable));
