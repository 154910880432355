import { makeAutoObservable, runInAction } from 'mobx';
import { DIRECTION } from 'components/constants';
import { getEmailStatisticsRequest } from 'services/requestAgent';
import { convertDate, convertToFullDate } from 'services/utils';

class EmailStatisticsStore {
  isInitialized = false;
  isLoading = false;
  error = null;
  emailStatisticsList = [];
  totalElements = null;
  sortColumn = {
    sort_column: null,
    sort_direction: DIRECTION.DESC
  };
  pagination = {
    size: 20,
    page: 0,
    totalPages: null
  };
  filters = {
    searchText: null,
    fromDate: null,
    toDate: null
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetEmailStatisticsStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.error = null;
    this.emailStatisticsList = [];
    this.totalElements = null;
    this.sortColumn = {
      sort_column: null,
      sort_direction: DIRECTION.DESC
    };
    this.pagination = {
      size: 20,
      page: 0,
      totalPages: null
    };
    this.filters = {
      searchText: null,
      fromDate: null,
      toDate: null
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setPageNumber = (page) => {
    this.pagination.page = page;
  };

  setPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setSortData = (sortData) => {
    this.sortColumn.sort_column = sortData.sortBy;
    this.sortColumn.sort_direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    const filters = { ...this.filters };
    const pagination = { ...this.pagination };

    // Mapping URL filter parameters to corresponding properties in the 'filters' object
    const filterParamsMapping = {
      searchText: 'searchText',
      fromDate: 'fromDate',
      toDate: 'toDate'
    };

    // Iterating over each URL parameter and assigning its values to 'filters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        let value = params[param];

        // Convert `fromDate` and `toDate` if they exist
        if (param === 'fromDate' || param === 'toDate') {
          value = convertToFullDate(value);
        }

        // Handle only array values as arrays, keep others as their original types
        if (Array.isArray(value)) {
          filters[filterParamsMapping[param]] = value;
        } else {
          filters[filterParamsMapping[param]] = value;
        }
      }
    }

    // Assigning pagination parameters from the URL
    pagination.page = parseInt(params.page) || pagination.page;
    pagination.size = parseInt(params.size) || pagination.size;

    // Assigning sorting parameters from the URL
    this.sortColumn.sort_column = params.sort_column || this.sortColumn.sort_column;
    this.sortColumn.sort_direction = params.sort_direction || this.sortColumn.sort_direction;

    // Updating the state of filters and pagination
    this.filters = filters;
    this.pagination = pagination;
  };

  prepareFiltersParams = () => {
    const params = { ...this.filters };

    if (this.filters.fromDate) {
      params.fromDate = convertDate(this.filters.fromDate);
    } else {
      delete params.fromDate;
    }

    if (this.filters.toDate) {
      params.toDate = convertDate(this.filters.toDate);
    } else {
      delete params.toDate;
    }

    return params;
  };

  getEmailStatisticsList = async () => {
    this.setIsLoading(true);
    try {
      const filtersParams = this.prepareFiltersParams();

      const res = await getEmailStatisticsRequest(
        this.pagination,
        this.sortColumn.sort_column,
        this.sortColumn.sort_direction,
        filtersParams
      );

      runInAction(() => {
        this.isInitialized = true;
        this.emailStatisticsList = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };
}

export default new EmailStatisticsStore();
