import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './ConfigurationTableRow.scss';

const ConfigurationTableRow = ({ isLoading, configurationParameterData }) => {
  const getConfigurationInfoBlock = (configurationBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'configuration-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{configurationBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'configuration-table-cell'}>{getConfigurationInfoBlock(configurationParameterData.name)}</td>
      <td className={'configuration-table-cell'}>
        {getConfigurationInfoBlock(configurationParameterData.description)}
      </td>
      <td className={'configuration-table-cell'}>{getConfigurationInfoBlock(configurationParameterData.value)}</td>
    </tr>
  );
};

ConfigurationTableRow.propTypes = {
  isLoading: PropTypes.bool,
  configurationParameterData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string
  })
};

export default ConfigurationTableRow;
