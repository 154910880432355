import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import CommissionAccountsHeadRow from './components/CommissionAccountsHeadRow';
import CommissionAccountsTableRow from './components/CommissionAccountsTableRow';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import './CommissionAccountsTable.scss';

const CommissionAccountsTable = ({ commissionTransactionsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();

  useEffect(() => {
    if (commissionTransactionsStore.isInitialized) {
      commissionTransactionsStore.resetCommissionTransactionStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (!commissionTransactionsStore.isInitialized) {
      commissionTransactionsStore.getCommissionAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissionTransactionsStore.isInitialized]);

  if (!commissionTransactionsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <div className={'commission-accounts-wrapper'}>
      <div className={'commission-accounts-actions-wrapper'}>
        <Button
          className={'commission-accounts-button'}
          size={'medium'}
          type={'primary'}
          onClick={commissionTransactionsStore.handleExportCommissionBalances}
          isDisabled={
            !commissionTransactionsStore.isInitialized || commissionTransactionsStore.isLoadingCommissionBalances
          }
        >
          {commissionTransactionsStore.isLoadingCommissionBalances ? (
            <Loader />
          ) : (
            i18n.getMessage('commissions.commissionAccounts.button.downloadCommissionBalances')
          )}
        </Button>
      </div>

      <Table responsive>
        <thead>
          <CommissionAccountsHeadRow />
        </thead>
        <tbody>
          {commissionTransactionsStore.commissionAccounts &&
            Object.values(commissionTransactionsStore.commissionAccounts).map((commissionAccount, index) => (
              <CommissionAccountsTableRow
                key={index}
                type={'list'}
                isLoading={commissionTransactionsStore.isLoading}
                commissionAccountData={commissionAccount}
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

CommissionAccountsTable.propTypes = {
  commissionTransactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  commissionTransactionsStore: stores.commissionTransactionsStore
}))(observer(CommissionAccountsTable));
