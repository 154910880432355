import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import './UsersHeadRow.scss';

export const USERS_TABLE_HEADER = [
  {
    label: 'usersId',
    sortBy: 'user_id'
  },
  {
    label: 'status',
    sortBy: 'status'
  },
  {
    label: 'phone',
    sortBy: 'phone'
  },
  {
    label: 'lastLoginDate',
    sortBy: 'last_login_at'
  },
  {
    label: 'registrationDate',
    sortBy: 'created_at'
  },
  {
    label: 'registrationIp'
  }
];

export const UsersHeadRow = ({ handleOnSortTable, sortBy, direction }) => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {USERS_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'users-table-head'}>
          <div className={'users-table-head-block'}>
            <span>{i18n.getMessage(`users.table.header.${head.label}`)}</span>
            {head.sortBy && (
              <ArrowSelect
                onClick={() => handleOnSortTable(head.sortBy)}
                className={`arrow-icon ${sortBy === head.sortBy && direction}`}
              />
            )}
          </div>
        </th>
      ))}
    </tr>
  );
};

UsersHeadRow.propTypes = {
  handleOnSortTable: PropTypes.func,
  sortBy: PropTypes.string,
  direction: PropTypes.string
};
