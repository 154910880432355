import PropTypes from 'prop-types';
import { ReactComponent as ConfirmationIcon } from 'assets/confirmation-icon.svg';
import Button from 'uikit/Button/Button';

export const PopConfirmationScheme = ({
  title,
  description,
  onConfirmButtonTitle,
  onCloseButtonTitle,
  onConfirm,
  onClose
}) => {
  return (
    <div>
      <div className={'scheme-wrapper pop-up-logOut'}>
        <ConfirmationIcon className={'icon-default'} />
        <h2 className={'scheme-success-header'}>{title}</h2>
        <p className={'scheme-success-message'}>{description}</p>
        <div className={'scheme-wrapper-buttons'}>
          <Button className={'scheme-button'} size={'large'} type={'contained'} onClick={onConfirm}>
            {onConfirmButtonTitle}
          </Button>
          <Button className={'scheme-button'} size={'large'} type={'outline'} onClick={onClose}>
            {onCloseButtonTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

PopConfirmationScheme.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onConfirmButtonTitle: PropTypes.string,
  onCloseButtonTitle: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};
