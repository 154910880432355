import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { DIRECTION, MAX_UPLOADED_FILES_SIZE } from 'components/constants';
import i18n from 'i18n';
import {
  createNewTopicRequest,
  downloadTopicDocumentRequest,
  getTopicByIdRequest,
  getTopicMessagesRequest,
  getTopicsListRequest,
  markTopicAsReadRequest,
  sendTopicDocumentRequest,
  sendTopicMessageRequest,
  updateTopicOwnerOperatorRequest,
  updateTopicStatusRequest
} from 'services/requestAgent';
import { convertDate, convertToFullDate } from 'services/utils';

class MessagesStore {
  isInitialized = false;
  isLoading = false;
  isFileUploading = false;
  isNewMessageCreated = false;
  error = null;
  topics = [];
  currentTopicInfo = null;
  currentTopicMessages = [];
  uploadedFiles = [];
  totalElements = null;
  sortColumn = {
    sort_column: 'last_message_sent_at',
    sort_direction: DIRECTION.DESC
  };
  pagination = {
    size: 20,
    page: 0,
    totalPages: null
  };
  filters = {
    search_text: null,
    from: null,
    to: null,
    statuses: [],
    operator: null,
    unread_only: false
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetMessagesStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.isFileUploading = false;
    this.isNewMessageCreated = false;
    this.error = null;
    this.topics = [];
    this.currentTopicInfo = null;
    this.currentTopicMessages = [];
    this.uploadedFiles = [];
    this.totalElements = null;
    this.sortColumn = {
      sort_column: 'last_message_sent_at',
      sort_direction: DIRECTION.DESC
    };
    this.pagination = {
      size: 20,
      page: 0,
      totalPages: null
    };
    this.filters = {
      search_text: null,
      from: null,
      to: null,
      statuses: [],
      operator: null,
      unread_only: false
    };
  };

  clearUploadedFiles = () => {
    this.uploadedFiles = [];
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsFileUploading = (status) => {
    this.isFileUploading = status;
  };

  setIsNewMessageCreated = (status) => {
    this.isNewMessageCreated = status;
  };

  setPageNumber = (page) => {
    this.pagination.page = page;
  };

  setPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setSortData = (sortData) => {
    this.sortColumn.sort_column = sortData.sortBy;
    this.sortColumn.sort_direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    const filters = { ...this.filters };
    const pagination = { ...this.pagination };

    // Mapping URL filter parameters to corresponding properties in the 'filters' object
    const filterParamsMapping = {
      search_text: 'search_text',
      from: 'from',
      to: 'to',
      statuses: 'statuses',
      unread_only: 'unread_only'
    };

    // Iterating over each URL parameter and assigning its values to 'filters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        let value = params[param];

        // Convert `from` and `to` if they exist
        if (param === 'from' || param === 'to') {
          value = convertToFullDate(value);
        }

        if (param === 'unread_only') {
          value = value !== 'false';
        }

        // Handle only array values as arrays, keep others as their original types
        if (Array.isArray(value)) {
          filters[filterParamsMapping[param]] = value;
        } else {
          filters[filterParamsMapping[param]] = value;
        }
      }
    }

    // Assigning pagination parameters from the URL
    pagination.page = parseInt(params.page) || pagination.page;
    pagination.size = parseInt(params.size) || pagination.size;

    // Assigning sorting parameters from the URL
    this.sortColumn.sort_column = params.sort_column || this.sortColumn.sort_column;
    this.sortColumn.sort_direction = params.sort_direction || this.sortColumn.sort_direction;

    // Updating the state of filters and pagination
    this.filters = filters;
    this.pagination = pagination;
  };

  prepareFiltersParams = () => {
    const params = { ...this.filters };

    if (this.filters.from) {
      params.from = convertDate(this.filters.from);
    } else {
      delete params.from;
    }

    if (this.filters.to) {
      params.to = convertDate(this.filters.to);
    } else {
      delete params.to;
    }

    return params;
  };

  getTopicsList = async () => {
    this.setIsLoading(true);
    try {
      const filtersParams = this.prepareFiltersParams();

      const res = await getTopicsListRequest(
        this.pagination,
        this.sortColumn.sort_column,
        this.sortColumn.sort_direction,
        filtersParams.search_text,
        filtersParams.unread_only,
        filtersParams
      );

      runInAction(() => {
        this.isInitialized = true;
        this.topics = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getTopicById = async (topicId) => {
    this.setIsLoading(true);
    try {
      let topicInfo = await getTopicByIdRequest(topicId);

      runInAction(() => {
        this.isInitialized = true;
        this.currentTopicInfo = topicInfo;
      });

      if (!topicInfo?.readByOperator) {
        await this.markTopicAsRead(topicId);
      }
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getTopicMessages = async (topicId) => {
    this.setIsLoading(true);
    try {
      const res = await getTopicMessagesRequest(topicId, this.pagination);

      runInAction(() => {
        this.isInitialized = true;
        this.currentTopicMessages = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  downloadTopicDocument = async (documentId) => {
    this.setIsFileUploading(true);

    const toastPromise = toast.promise(downloadTopicDocumentRequest(documentId), {
      pending: i18n.getMessage('messagesStore.downloadTopicDocument.toastPromise.pending'),
      success: i18n.getMessage('messagesStore.downloadTopicDocument.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsFileUploading(false);
    }
  };

  markTopicAsRead = async (topicId) => {
    this.setIsLoading(true);
    try {
      const topicInfo = await markTopicAsReadRequest(topicId);

      runInAction(() => {
        this.currentTopicInfo = topicInfo;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  removeAttachment = (fileId) => {
    this.uploadedFiles = this.uploadedFiles.filter((file) => file?.id !== fileId);
  };

  uploadAttachments = async ({ target: { files } }) => {
    if (!Object.keys(files).length) {
      return null;
    }

    this.setIsFileUploading(true);

    try {
      const selectedFiles = [...files];
      const Data = new FormData();

      selectedFiles.forEach((file) => {
        if (file.size > MAX_UPLOADED_FILES_SIZE) {
          throw { code: 'REQUEST_HAS_BEEN_TERMINATED' };
        } else {
          Data.append('file', file, file?.name);
        }
      });

      const documentArray = await sendTopicDocumentRequest(Data);

      runInAction(() => {
        this.isFileUploading = false;
        this.uploadedFiles = [
          ...this.uploadedFiles,
          ...documentArray.map((file) => ({ name: file?.name, id: file?.id }))
        ];
      });
    } catch (err) {
      runInAction(() => {
        this.isFileUploading = false;
        this.error = { type: 'attachDoc', ...err };
      });
    }
  };

  createNewTopic = async (type, topicData) => {
    this.setIsLoading(true);
    try {
      const topicInfo = await createNewTopicRequest(type, topicData);

      runInAction(() => {
        this.topics = [topicInfo, ...this.topics];
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  sendTopicMessage = async (topicId, message) => {
    this.setIsLoading(true);
    try {
      await sendTopicMessageRequest(topicId, message).then(async () => {
        await this.getTopicMessages(topicId);
        this.clearUploadedFiles();
      });

      runInAction(() => {
        this.isNewMessageCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  updateTopicOwnerOperator = async (topicId, ownerOperatorId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateTopicOwnerOperatorRequest(topicId, ownerOperatorId), {
      pending: i18n.getMessage('messagesStore.updateTopicOwnerOperator.toastPromise.pending'),
      success: i18n.getMessage('messagesStore.updateTopicOwnerOperator.toastPromise.success')
    });

    try {
      const topicInfo = await toastPromise;

      runInAction(() => {
        this.currentTopicInfo = topicInfo;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  updateTopicStatus = async (topicId, status) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateTopicStatusRequest(topicId, status), {
      pending: i18n.getMessage('messagesStore.updateTopicStatus.toastPromise.pending'),
      success: i18n.getMessage('messagesStore.updateTopicStatus.toastPromise.success')
    });

    try {
      const topicInfo = await toastPromise;

      runInAction(() => {
        this.currentTopicInfo = topicInfo;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };
}

export default new MessagesStore();
